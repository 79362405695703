import { Injectable } from '@angular/core';

import * as moment from 'moment'
import { AsyncSubject } from 'rxjs';
import { ProjectmasterService } from 'src/app/projectmaster/projectmaster.service';

import { FilterBase } from './filter-base.service';
// import {  PrincipleService } from './principle.service';

export class DropdownFilter extends FilterBase<string> {
  controlType = 'dropdown';
}

export class FilterDropdownFilter extends FilterBase<string> {
  controlType = 'filterDropdown';
}

export class TextboxFilter extends FilterBase<string> {
  controlType = 'textbox';
}

export class DateboxFilter extends FilterBase<string> {
  controlType = 'datebox';
}

export class YearboxFilter extends FilterBase<string> {
  controlType = 'yearbox';
}

export class MonthboxFilter extends FilterBase<string> {
  controlType = 'monthbox';
}

export class UploadFileFilter extends FilterBase<string> {
  controlType = 'uploadFile';
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {} /* public principle: PrincipleService */

  getFilterParams(role1, page): any {
    let filterPayload: any;

    console.log('role==> ', role1);
    console.log('page==> ', page);

    let role: any = 'Admin';
    /* if (this.principle.otherUser) {
      console.log('*******---otheruser', this.principle.otherUserRole);
      role = this.principle.otherUserRole;
    } else {
      role = this.principle.role;
    } */

    console.log('role*************************', role);

    switch (role && page) {
      case ('ROLE_ADMIN' || 'Admin') && 'Customer Bills':
        return this.ticketManagement();
        break;

      default:
        return '';
        break;
    }
  }

  public ticketManagement(
    projectList?,
    ticketTypeList?,
    ticketPriorityList?,
    ticketStatusList?,
    filtered?,
  ) {
    console.log('ticket type', ticketTypeList);
    const filterPayload: FilterBase<any>[] = [
      // new TextboxFilter({
      //   key: 'pageName',
      //   label: 'Page Name',
      //   value: 'Ticket',
      //   required: false,
      //   order: 1
      // }),
      // new DropdownFilter({
      //   key: 'projectName',
      //   label: 'Project',
      //   value: filtered.projectCode? filtered.projectCode : 'All',
      //   options:projectList.map(item => { return {'key': item.projectName, 'value':item.projectName}}),
      //   order: 2
      // }),
      new TextboxFilter({
        key: 'ticketCode',
        label: 'Ticket No',
        value: filtered.ticketCode ? filtered.ticketCode : '',
        required: false,
        order: 1,
      }),
      new TextboxFilter({
        key: 'subject',
        label: 'Subject',
        value: filtered.subject ? filtered.subject : '',
        required: false,
        order: 2,
      }),
      new DropdownFilter({
        key: 'ticketTypeCode',
        label: 'Ticket Type',
        value: filtered.ticketTypeCode ? filtered.ticketTypeCode : '',
        options: ticketTypeList.map((item) => {
          return {key: item.ticketTypeName, value: item};
        }),
        required: false,
        order: 3,
      }),
      // new DropdownFilter({
      //   key: 'moduleCode',
      //   label: 'Module',
      //   value: filtered.moduleCode? filtered.moduleCode : '',
      //   options:ticketTypeList.map(item => { return {'key': item.moduleName, 'value':item}}),
      //   required: false,
      //   order: 4
      // }),

      new DropdownFilter({
        key: 'ticketPriorityCode',
        label: 'Ticket Priority / Severity',
        value: filtered.ticketPriorityCode ? filtered.ticketPriorityCode : '',
        options: ticketPriorityList.map((item) => {
          return {key: item.priorityName, value: item};
        }),
        required: false,
        order: 4,
      }),
      new DropdownFilter({
        key: 'ticketStatusCode',
        label: 'Ticket Status',
        value: filtered.ticketStatusCode ? filtered.ticketStatusCode : '',
        options: ticketStatusList.map((item) => {
          return {key: item.statusName, value: item};
        }),
        required: false,
        order: 5,
      }),
    ];
    return filterPayload;
  }

  public projectMaster(companyList?, productList?, projectList?, filtered?) {
    const filterPayload: FilterBase<any>[] = [
      new FilterDropdownFilter({
        key: 'idprojectCode',
        label: 'Project Name',
        value: filtered.idprojectCode ? filtered.idprojectCode : '',
        options: projectList.map((item) => {
          return {key: item.projectName, value: item};
        }),
        required: false,
        order: 1,
      }),
      new FilterDropdownFilter({
        key: 'companyCode',
        label: 'Company Name',
        value: filtered.companyCode ? filtered.companyCode : '',
        options: companyList.map((item) => {
          return {key: item.companyName, value: item};
        }),
        required: false,
        order: 2,
      }),
      new FilterDropdownFilter({
        key: 'productCode',
        label: 'Product Name',
        value: filtered.productCode ? filtered.productCode : '',
        options: productList.map((item) => {
          return {key: item.productName, value: item};
        }),
        required: false,
        order: 3,
      }),
      new DropdownFilter({
        key: 'projectType',
        label: 'Project Type',
        required: false,
        order: 4,
      }),
    ];
    return filterPayload;
  }

  public assetMaster(departmentList?, categoryList?, filtered?) {
    const filterPayload: FilterBase<any>[] = [
      new FilterDropdownFilter({
        key: 'deptCode',
        label: 'Department',
        value: filtered.deptCode ? filtered.deptCode : '',
        options: departmentList.map((item) => {
          return {key: item.deptName, value: item};
        }),
        required: false,
        order: 1,
      }),

      new FilterDropdownFilter({
        key: 'idCatId',
        label: 'Category',
        value: filtered.idCatId ? filtered.idCatId : '',
        options: categoryList.map((item) => {
          return {key: item.catName, value: item};
        }),
        required: false,
        order: 1,
      }),
    ];
    return filterPayload;
  }
}
