import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";

@Component({
  selector: "app-projectwiseview",
  templateUrl: "./projectwiseview.component.html",
  styleUrls: ["./projectwiseview.component.sass"],
})
export class ProjectwiseviewComponent implements OnInit, AfterViewInit {
  public afterInit: boolean = false;
  isLoading: boolean = true;
  bookedHistory: any = [];

  constructor(
    public dialogRef: MatDialogRef<ProjectwiseviewComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.isLoading = false;
    this.bookedHistory = this.data.ticket.bookedHistory;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  tConvert(time) {
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(2, 3) + ampm;
    return time;
  }

  ngAfterViewInit() {}
}
