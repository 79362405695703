import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./agent-dialog.component.html",
  styleUrls: ["./agent-dialog.component.sass"],
})
export class AgentDialogComponent implements OnInit, AfterViewInit {
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;

  projectCode: any;
  dialogTitle: any;
  idTicketCode: any;
  idAssignedSubGroup: any;
  selectedgroup: any;
  subgrpList: any[];
  agentList: any[];
  agentEditList: any[];
  action: string;
  ticketEditData: TicketEditDialogModel;
  tempAgent: any = [];
  agentForm: FormGroup;
  allocatedHrs: any = "00";
  allocatedMins: any = "00";
  checkHrsMins: boolean = true;
  allocatedCheck: boolean = true;
  ticketAgentList: any = [];
  proposedHrs: any;
  proposedMins: any;
  splitHoursCheck: any;
  localHrsMins: any;
  localHrs: any;
  localMins: any;
  searchAgentName: any;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AgentDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketService
  ) {
    this.action = data.action;
    console.log("add agent", data);
    this.dialogTitle = "Add Agent";
    this.projectCode = data.ticketEditDialog.projectCode;
    this.idTicketCode = data.ticketEditDialog.idTicketCode;
    this.proposedHrs = data.proposedHours;
    this.proposedMins = data.proposedMinutes;
    this.ticketAgentList = data.ticketAgentList;
    this.splitHoursCheck = data.splitHours;

    console.log("agentData", this.ticketAgentList);

    this.getProjectSubgroup(this.projectCode);
    this.ticketEditData = new TicketEditDialogModel({});
    this.agentForm = this.createAgentForm(); 
    this.agentForm
      .get("idAssignedSubGroup")
      .setValidators([Validators.required]);
    this.agentForm.get("idUserCode").setValidators([Validators.required]);
    if (this.splitHoursCheck == "Y") {
      this.agentForm.controls["allocatedHrs"].setValidators([
        this.numberValidator,
        this.customValidator1,
      ]);
      this.agentForm.controls["allocatedMins"].setValidators([
        this.numberValidator,
        this.customValidator,
      ]);
    } else {
      this.agentForm.get("allocatedHrs").clearValidators();
      this.agentForm.get("allocatedMins").clearValidators();
    }
    this.agentForm.get("allocatedHrs").updateValueAndValidity();
    this.agentForm.get("allocatedMins").updateValueAndValidity();
  }
  createAgentForm(): FormGroup {
    return this.fb.group({
      id: 0,
      // idAssignedSubGroup: new FormControl([
      //   { idAssignedSubGroup: this.ticketEditData.idAssignedSubGroup },
      //   [Validators.required],
      // ]),
      // idTicketCode: [this.ticketEditData.idTicketCode],
      // idUserCode: new FormControl([
      //   { idUserCode: this.ticketEditData.idUserCode },
      //   [Validators.required],
      // ]),

       idAssignedSubGroup: [],
       idTicketCode: [this.ticketEditData.idTicketCode],
       idUserCode: [],
       allocatedHrs: [this.allocatedHrs],
       allocatedMins: [this.allocatedMins],
    });
  }
  customValidator(control: FormControl) {
    // console.log('control',control);

    if (
      (("" + control.value).match(/^\d+\.?\d*%?$/) || false) &&
      parseFloat(control.value) >= 0 &&
      parseFloat(control.value) <= 59
    ) {
      return null;
    }
    return {
      customValidator: true,
    };
  }

  customValidator1(control: FormControl) {
    //  console.log('control',control);

    if (
      (("" + control.value).match(/^\d+\.?\d*%?$/) || false) &&
      parseFloat(control.value) >= 0 &&
      parseFloat(control.value) <= 999
    ) {
      return null;
    }
    return {
      customValidator: true,
    };
  }

  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }
  pad(value) {
    let dummyValue = value.toString();
    if (dummyValue.length < 2) {
      return "0" + value;
    } else {
      return value;
    }
  }
  public ngOnInit(): void {}
  /* Subgroup dropdown */
  async getProjectSubgroup(projectCode) {
    console.log("PROJECTCODE", projectCode);
    let reqObj = { projectCode };
    const response = await this.ticketService.getProjectSubgroup(reqObj);
    this.subgrpList = response.transactions;
    // this.ticketTypeList = response.ticketTypeList;
  }
  async getProjectSubGroupEdit(projectCode) {
    console.log("PROJECTCODE....", projectCode);
    let reqObj = { projectCode };
    const response = await this.ticketService.getProjectSubgroup(reqObj);
    this.subgrpList = response.transactions;
    // this.ticketTypeList = response.ticketTypeList;
    //this.getagentNameedit()
    //this.select();
  }
  // select() {
  //   let seleObj = this.agentForm.value;
  //   this.selectedgroup = seleObj.idAssignedSubGroup;

  //   this.getAgentNameEdit(this.selectedgroup);
  // }

  onSelectGroup(value: any) {
    console.log('value',value);
    if(value == undefined || value == "" || value == null){
      this.selectedgroup = "";
      this.agentList = [];
      this.agentForm.get('idUserCode').setValue("");
    }
    else{
      this.agentForm.get('idUserCode').setValue("");
      this.selectedgroup = value.idAssignedSubGroup;
      this.getagentName(this.selectedgroup, this.idTicketCode);
    }
  
  }
  /* Agent dropdown */
  async getagentName(subGroupCode, idTicketCode) {
    console.log("valueeeee", subGroupCode, idTicketCode);
    //let reqObj = { subGroupCode};
    const response = await this.ticketService.getProjectAgent(
      subGroupCode,
      idTicketCode
    );

    this.agentList = response.transactions.unAssignedAgents;
    console.log("this.ticketAgentList", this.ticketAgentList);
    console.log("this.agentList", this.agentList);
    this.agentList = this.getDifference(this.agentList, this.ticketAgentList);
    console.log(
      this.getDifference(this.agentList, this.ticketAgentList),
      "diff"
    );
  }

  getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        let obj = object2.idUserCode.idUserCode
          ? object2.idUserCode.idUserCode
          : object2.idUserCode;
        return object1.idUserCode == obj;
      });
    });
  }
  async confirmAdd() {

    this.isSubmitted = true;
    let exact = this.proposedHrs + ":" + this.proposedMins;
    console.log("exact", exact);
    let exactTime = this.sharedService.convertDurationToMinutes(exact);
    console.log("exactTime", exactTime);
    let local =
      this.agentForm.value.allocatedHrs +
      ":" +
      this.agentForm.value.allocatedMins;
    let localTime = this.sharedService.convertDurationToMinutes(local);
    console.log("local", localTime);

    let agentTotal = this.ticketAgentList.reduce(
      (accumulator, currentValue) => {
        return (
          accumulator +
          this.sharedService.convertDurationToMinutes(
            currentValue.allocatedHrsMins
          )
        );
      },
      0
    );
    console.log("agentTotal", agentTotal);

    let finalAgentTotal = Number(agentTotal) + Number(localTime);

    console.log(finalAgentTotal, "finalTotal");

    this.localHrsMins = `${this.pad(
      this.agentForm.value.allocatedHrs
    )}:${this.pad(this.agentForm.value.allocatedMins)}`;
    this.localHrs = this.localHrsMins.split(":")[0];
    this.localMins = this.localHrsMins.split(":")[1];

    if (finalAgentTotal <= exactTime) {
      this.checkHrsMins = true;
     
      
    } else {
      this.isSubmitted = false;
      this.checkHrsMins = false;
      this.allocatedCheck = true;
      
    }
    if (this.checkHrsMins) {
      let updateAlctdHrs = Number(this.agentForm.value.allocatedHrs);
      let updateAlctdMins = Number(this.agentForm.value.allocatedMins);
      if (this.splitHoursCheck == "Y") {
        if (updateAlctdHrs > 0 || updateAlctdMins > 0) {
        
          let addAgentData = {
            ...this.agentForm.value,
            allocatedHrsMins: `${this.pad(updateAlctdHrs)}:${this.pad(
              updateAlctdMins
            )}`,
            fromService: "N",
          };
          console.log("add agent", addAgentData);
          
          this.tempAgent.push(addAgentData);
          this.onNoClick();
          setTimeout(()=>{
            this.isSubmitted = false;
          }, 10000);
         
       
          
          this.allocatedCheck = true;
          
        } else {
          this.isSubmitted = false;
          this.allocatedCheck = false;
        }
      } else {
       
        let addAgentData = {
          ...this.agentForm.value,
          allocatedHrsMins: `${this.pad(updateAlctdHrs)}:${this.pad(
            updateAlctdMins
          )}`,
          fromService: "N",
        };
        console.log("add agent", addAgentData);
        this.tempAgent.push(addAgentData);
        setTimeout(()=>{
          this.isSubmitted = false;
        }, 10000);
        this.onNoClick();
        this.allocatedCheck = true;
      }
    } else {
    }
  }
  onNoClick(): void {
    let listData = this.tempAgent;
    this.sharedService.setTicketAttach(listData);
    this.tempAgent = listData;
    console.log("TempAGENT", this.tempAgent);
    this.dialogRef.close({ listData: this.tempAgent });
  }
  filterDropDowns(searchValue: any) {
    setTimeout(()=>  this.searchAgentName = "", 2000);
   
    
  }
  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
  compareFn(d1, d2): boolean {
    if (d1.idAssignedSubGroup) {
      return d1 && d2
        ? d1.idAssignedSubGroup === d2.idAssignedSubGroup
        : d1 === d2;
    }
    if (d1.idUserCode) {
      return d1 && d2 ? d1.idUserCode === d2.idUserCode : d1 === d2;
    }
  }
}
