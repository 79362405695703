import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/core/service/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransportTypeService } from 'src/app/transport-type/transport-type.service';
import { PartRequestService } from 'src/app/part-request/part-request.service';

import { E } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { FieldMsg } from 'src/app/core/service/field-msg.service';
import { DispatchTyeService } from 'src/app/dispatch-type/dispatch-tye.service';
import { BrandVendorService } from 'src/app/brand-vendor/brand-vendor.service';
import { ReasonMasterService } from 'src/app/reason-master/reason-master.service';
import {TicketNewService} from "../../../ticket-new/ticket.service";

@Component({
  selector: 'app-ticket-dispatch-dialog',
  templateUrl: './ticket-dispatch-dialog.component.html',
  styleUrls: ['./ticket-dispatch-dialog.component.scss'],
})
export class TicketDispatchDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  approvalPartsForm: FormGroup;
  transportCondition: any = 'Y';
  isLoading: boolean;
    partPendingFlag: boolean = false;
    resolvedFlag: boolean = false;
    partPendingalert: boolean = false;
  viewPartsList: any = [];
  ticketPartsData: any;
  selectedRowsData = [];
  rejectRemarks: any = '';
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  transportTypeList: any = [
    // {
    //   label: 'Vehicle',
    //   value: 'Vehicle',
    // },
    // {
    //   label: 'By Hand',
    //   value: 'By Hand',
    // },
    // {
    //   label: 'Courier',
    //   value: 'Courier',
    // },
    // {
    //   label: 'Parcel Service',
    //   value: 'Parcel Service',
    // },
  ];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  reasonList: any = [];
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<TicketDispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private transportType: TransportTypeService,
    private ticketService: PartRequestService,
    private ticketnewService: TicketNewService,
    public fieldMsg: FieldMsg,
    public dispatchTyeService: DispatchTyeService,
    public brandVendorService: BrandVendorService,
    public reasonMasterService: ReasonMasterService
  ) {
    this.dispatchForm = this.createDispatchForm();
    this.approvalPartsForm = this.createApprovalPartsForm();
    this.ticketPartsData = this.data.ticketDispatchData.data;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.roleCheck = this.currentUser.data[0].roles;
    if (this.ticketPartsData.mspTlApprovalFlag == 'Y') {
      this.partStatus = 'Approved';
    } else if (this.ticketPartsData.mspTlApprovalFlag == 'R') {
      this.partStatus = 'Rejected';
    } else {
      this.partStatus = 'Pending';
    }
    console.log('partStatus', this.partStatus);

    this.dialogTitle =
      this.data.action == 'dispatchTicket'
        ? `Dispatch :: ${this.ticketPartsData.ticketCode}`
        : `View Parts :: ${this.ticketPartsData.ticketCode} :: ${this.ticketPartsData.partReqId}`;

    this.getTicketParts(
      this.ticketPartsData.ticketCode,
      this.ticketPartsData.partReqId,
    );
    this.transportList();
    this.transportInfo();
  }
  createDispatchForm(): FormGroup {
    return this.fb.group({
      transportType: [''],
      referenceNo: [],
      driverName: [],
      vehicleNo: [],
      mobileNo: [],
      personName: [],
      courierName: [],
      parcelServiceName: [],
    });
  }
  createApprovalPartsForm(): FormGroup {
    return this.fb.group({
      dispatchType: [''],
      brandVendor: [''],
    });
  }
  ngOnInit(): void { }
  transportInfo() {
    console.log('transport Condition', this.transportCondition);
    // this.dispatchForm.reset();
    // if (this.transportCondition) {
    //   if (this.transportCondition == 'Y') {
    //     //  this.transportCheck = true;
    //     this.dispatchForm
    //       .get('transportType')
    //       .setValidators([Validators.required]);
    //     this.dispatchForm.get('transportType').updateValueAndValidity();
    //   } else {
    //     //  this.transportCheck = false;
    //     this.dispatchForm.get('transportType').clearValidators();
    //     this.dispatchForm.get('transportType').updateValueAndValidity();
    //   }
    // }
  }
  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }

  onChangeDetails(event) {
    console.log('event', event);

    this.dispatchForm.get('driverName').clearValidators();
    this.dispatchForm.get('driverName').updateValueAndValidity();
    this.dispatchForm.get('vehicleNo').clearValidators();
    this.dispatchForm.get('vehicleNo').updateValueAndValidity();
    this.dispatchForm.get('mobileNo').clearValidators();
    this.dispatchForm.get('mobileNo').updateValueAndValidity();
    this.dispatchForm.get('personName').clearValidators();
    this.dispatchForm.get('personName').updateValueAndValidity();
    this.dispatchForm.get('referenceNo').clearValidators();
    this.dispatchForm.get('referenceNo').updateValueAndValidity();
    this.dispatchForm.get('courierName').clearValidators();
    this.dispatchForm.get('courierName').updateValueAndValidity();
    this.dispatchForm.get('parcelServiceName').clearValidators();
    this.dispatchForm.get('parcelServiceName').updateValueAndValidity();

    if (event.value) {

      this.dispatchForm.get('referenceNo').reset();
      this.dispatchForm.get('driverName').reset();
      this.dispatchForm.get('vehicleNo').reset();
      this.dispatchForm.get('mobileNo').reset();
      this.dispatchForm.get('personName').reset();
      this.dispatchForm.get('courierName').reset();
      this.dispatchForm.get('parcelServiceName').reset();

      if (event.value.transportTypeName == "Vehicle") {
        this.dispatchForm.get('driverName').setValidators([Validators.required]);
        this.dispatchForm.get('vehicleNo').setValidators([Validators.required]);
        this.dispatchForm.get('driverName').updateValueAndValidity();
        this.dispatchForm.get('vehicleNo').updateValueAndValidity();
        this.dispatchForm.get('mobileNo').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
        this.dispatchForm.get('mobileNo').updateValueAndValidity();
      }


      if (event.value.transportTypeName == "By Hands") {
        this.dispatchForm.get('mobileNo').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
        this.dispatchForm.get('mobileNo').updateValueAndValidity();
        this.dispatchForm.get('personName').setValidators([Validators.required]);
        this.dispatchForm.get('personName').updateValueAndValidity();
      }
        if (event.value.transportTypeName == "Bus") {
            this.dispatchForm.get('mobileNo').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
            this.dispatchForm.get('mobileNo').updateValueAndValidity();
            this.dispatchForm.get('personName').setValidators([Validators.required]);
            this.dispatchForm.get('personName').updateValueAndValidity();
        } if (event.value.transportTypeName == "Parcel Service") {
            this.dispatchForm.get('parcelServiceName').setValidators([Validators.required]);
            this.dispatchForm.get('parcelServiceName').updateValueAndValidity();
            this.dispatchForm.get('referenceNo').setValidators([Validators.required]);
            this.dispatchForm.get('referenceNo').updateValueAndValidity();
        }

    }
  }
  onChangeDispatchDetails(event) {
    if (event.value) {
      if (event.value.dispatchTypeName == 'Forward To Branding Vendor') {
        this.approvalPartsForm
          .get('brandVendor')
          .setValidators([Validators.required]);
        this.approvalPartsForm.get('brandVendor').updateValueAndValidity();
      } else {
        this.approvalPartsForm.get('brandVendor').clearValidators();
        this.approvalPartsForm.get('brandVendor').updateValueAndValidity();
      }
    }
  }
  // selectionChanged(data: any) {


  //   data.currentSelectedRowKeys.forEach((key) => {
  //     data.component.byKey(key).done((item) => {
  //       if (item.dispatchStatus == "Y") {
  //         data.component.deselectRows(key);
  //         this.selectedRowsData = [];
  //       }
  //       else {
  //         this.selectedRowsData = data.selectedRowKeys;
  //       }
  //     });
  //   });

  //   console.log(this.selectedRowsData, 'this.selectedRowsData');

  //   if (this.selectedRowsData.length > 0) {



  //     this.transportCheck = true;
  //   } else {
  //     this.transportCheck = false;
  //   }
  // }
  async transportList() {
    const response = await this.transportType.getTransportType();
    this.transportTypeList = response?.body?.data || [];
  }
  async getTicketParts(ticketCode, partReqId) {
    this.isLoading = true;
    let reqObj = { ticketCode, partReqId };
    const response = await this.ticketService.getTicketPartList(reqObj);
    console.log('complaint response', response);
    this.viewPartsList = response.data || [];

    this.dispatchPartList = response.data.filter((item) => {
      return item.mspTlApprovalFlag == 'Y';
    });
    let partPending = response.data.filter((item) => {
        return item.mspTlApprovalFlag == 'P';
    });
      let partRejected = response.data.filter((item) => {
          return item.mspTlApprovalFlag == 'R';
      });
      let partFilterItems = this.dispatchPartList.filter((item) => {
          if (item.dispatchStatus == 'Y') {
              return {
                  item,
              };
          }
      });
      this.resolvedFlag = partFilterItems;
      console.log("parts reject part list",partRejected)
      console.log("parts pending ",partPending.length)

      if(partPending.length === 0 ){
        this.partPendingFlag = true;
        this.partPendingalert = false;
      }else{
          this.partPendingFlag = false;
          this.partPendingalert = true;
      }
    console.log('dispatchPartList', this.dispatchPartList);

    this.isLoading = false;



    if (partFilterItems.length > 0) {
      this.transportCheck = false;
    }
    else {
      this.transportCheck = true;
    }


  }
  onEditorPreparing(e: any): void {
    console.log('e', e.editorOptions);
    if (e.dataField == 'approvedQuantity' && e.parentType == 'dataRow') {
      e.editorOptions.disabled =
        e.row.data.mspTlApprovalFlag == 'Y' ||
          e.row.data.mspTlApprovalFlag == 'R'
          ? true
          : false;

      this.requiredFlag =
        e.row.data.mspTlApprovalFlag == 'Y' ||
          e.row.data.mspTlApprovalFlag == 'R'
          ? false
          : true;
    }
  }
  customCallback(e) {
    if (
      parseInt(e.value) > parseInt(e.data.requestQuantity) ||
      parseInt(e.data.approvedQuantity) < 0
    ) {
      e.rule.message = 'Less then or equal to requested quantity';

      return false;
    } else {
      return true;
    }
  }
  convertStringToInt(str) {
    let Num = parseInt(str);
    return Num;
  }
  async partsConfirm(value, data) {

    await this.getCurrentLocation();
    console.log('parts Data', data);


    if (this.position) {
      if (value == 'approve') {
        this.approvePartsData = data;

        this.partsApproval(value);
        //  this.brandVendors(this.approvePartsData);
        //  this.dispatchList();
        //  this.approvePopupVisible = true;
        //  this.popupTitle = `Part Approval :: ${this.ticketPartsData.ticketCode}`;
      } else {
        this.rejectPopupVisible = true;
        this.rejectPartsData = data;
        this.popupTitle = `Part Reject :: ${this.ticketPartsData.ticketCode}`;
        this.loadReason(6);
      }
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'This app needs the Location service, please turn on the Location',
        'bottom',
        'center',
      );
    }
  }
  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, 'position');
    } catch (e) {
      console.log('e', e);
      this.position = null;
    }
  }
  statusAct(stat) {
    switch (stat.value) {
      case 'Y':
        return 'Approved';
        break;
      case 'R':
        return 'Rejected';
        break;
      case 'P':
        return 'Pending';
        break;
      default:
        return stat.value;
    }
  }
  dispatchStatusAct(stat) {
    switch (stat.value) {
      case 'Y':
        return 'Dispatched';
        break;
      case 'P':
        return 'Not Dispatched';
        break;
      default:
        return stat.value;
    }
  }
  public async updateTicket(key) {
    let ticketSubStatusCode;
    if (key == 'approve') {

    //  ticketSubStatusCode = this.checkSubStatusCode(key, this.approvePartsData, this.viewPartsList);

      ticketSubStatusCode = 18;
    }
    if (key == 'reject') {
    //  ticketSubStatusCode = this.checkSubStatusCode(key, this.rejectPartsData, this.viewPartsList);

      ticketSubStatusCode = 20;
    }
    if (key == 'dispatch') {
      ticketSubStatusCode = 21;
    }
    this.spinner.show();

    let masterObj = [
      {
          hapRemarks: "" ,
          hapGeoAddress: "",
        cancelRemarks: '',
        hapHubCode: '',
        hapHubRemarks: '',
        hapLat: this.position ? this.position.lat : '',
        hapLong: this.position ? this.position.lng : '',
        ticketCode: this.ticketPartsData.ticketCode,
        ticketStatusCode: 16, //part-request
        ticketSubStatusCode: ticketSubStatusCode,
      },
    ];
    var ticketEdit = {
      ticketCode: this.ticketPartsData.ticketCode,
      assetCompliants: [],
      assetIntake: [],
      assetMaterials: [],
      assetTravel: [],
      comments: [],
      jobCard: [],
      master: masterObj,
      users: [],
    };

    console.log('WHOLE EDIT DATA', ticketEdit);

    try {
      let response = await this.ticketService.assetIntakeTicket(ticketEdit);

      if (response?.body?.success) {
        this.sharedService.showNotification(
          'snackbar-success',
          response?.body?.message,
          'bottom',
          'center',
        );

        await this.spinner.hide();
      } else {
        /** spinner hide */
        await this.spinner.hide();
        this.sharedService.showNotification(
          'snackbar-danger',
          response?.body?.message,
          'bottom',
          'center',
        );
      }
      //  this.dialogRef.close({action: 'viewParts'});
    } catch (e) {
      this.dialogRef.close();
    }
  }
  closeRejectPopup() {
    this.rejectPopupVisible = false;
    this.rejectRemarks = '';
    this.rejectPartsData = '';
  }
  closeApprovePopup() {
    this.approvePopupVisible = false;
    this.approvePartsData = '';
    this.approvalPartsForm.reset();
  }
//old
  /*async partsApproval(key) {
      this.approvePopupVisible = false;
      let viewPartStatus = this.viewPartsList.map((obj) => {
          return obj.mspTlApprovalFlag;
      });
      let ticketSubStatusCode;
      console.log("viewPartStatus",viewPartStatus)
      const allEqual = arr => arr.every(val => val === arr[0]);
      let allEqlStatus = allEqual(viewPartStatus);
      console.log("allEqlStatus",allEqlStatus);
      if(allEqlStatus === true && viewPartStatus[0] === 'Y'){
          ticketSubStatusCode = 18;
      }
      if(allEqlStatus === true && viewPartStatus[0] === 'R'){
          ticketSubStatusCode = 20;
      }
      if(viewPartStatus.includes('Y') && viewPartStatus.includes('R')){
          ticketSubStatusCode = 18;
      }
      if(viewPartStatus.includes('Y')){
          ticketSubStatusCode =22;
      }if(viewPartStatus[0] === 'P' || viewPartStatus.includes('P') && viewPartStatus.includes('R')){
          ticketSubStatusCode =25;
      }
      this.isSubmitted = true;
    this.spinner.show();

    let masterObj =
    {
      cancelRemarks: '',
      hapHubCode: '',
      hapHubRemarks: '',
      hapLat: this.position ? this.position.lat : '',
      hapLong: this.position ? this.position.lng : '',
      ticketCode: this.ticketPartsData.ticketCode,
      ticketStatusCode: 16, //part-request
      ticketSubStatusCode: ticketSubStatusCode,
    };



    let partItems = [
      {
        approvedQuantity: this.approvePartsData.row.data.approvedQuantity,
        requestQuantity: this.approvePartsData.row.data.requestQuantity,
        receivedOrNot: false,
        //  catId: this.approvePartsData.row.data.idCatId,              oldParam
        //   companyCode: this.approvePartsData.row.data.idCompanyCode,  oldParam
        //  deptCode: this.approvePartsData.row.data.idDeptCode,        oldParam
        goodOrBad: false,
        partCode: this.approvePartsData.row.data.idPartId,
        reReq: false,
        remarks: this.approvePartsData.row.data.remarks
          ? this.approvePartsData.row.data.remarks
          : '',
        statusFlag: this.approvePartsData.row.data.statusFlag
          ? this.approvePartsData.row.data.statusFlag
          : 'A',
        materialDesc: this.approvePartsData.row.data.materialDesc
          ? this.approvePartsData.row.data.materialDesc
          : '',
        // dispatchTypeId: obj.dispatchType.idDispatchTypeId
        //   ? obj.dispatchType.idDispatchTypeId
        //   : 0,
        // brandVendorCode: obj.brandVendor.idVendorCode
        //   ? obj.brandVendor.idVendorCode
        //   : '',
        // brandVendorName: obj.brandVendor.vendorName
        //   ? obj.brandVendor.vendorName
        //   : '',
        dispatchTypeId: '',
        brandVendorCode: '',
        brandVendorName: '',
        tlApprovedOrNot: true,
      },
    ];


    /!*  old   let partsReqObj = {
       // adminApproved: false,
       assetCode: this.ticketPartsData ? this.ticketPartsData.assetCode : '',
       partLineItem: partItems,
       partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
       partStatusCode: 0,
       //   reReq: false,
       //   receivedOrNot: false,
       // remarks: this.rejectRemarks ? this.rejectRemarks : '',
       ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
       //tlApproved: value ? (value == 'approve' ? true : false) : false,
     };*!/

    let partsReqObj = {
      masterDetails: masterObj,
      partDetails: {
        partLineItem: partItems,
        assetCode: this.ticketPartsData ? this.ticketPartsData.hapAssetCode : '',
        partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
        partStatusCode: 0,
        ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
      }
    };
    try {
      console.log('WHOLE EDIT DATA', partsReqObj);
      let response = await this.ticketService.reqTicketParts(partsReqObj);
      console.log('response', response);
      if (response?.body?.success) {
        this.sharedService.showNotification(
          'snackbar-success',
          response?.body?.message,
          'bottom',
          'center',
        );


      } else {
        this.sharedService.showNotification(
          'snackbar-danger',
          response?.body?.message,
          'bottom',
          'center',
        );
      }
      this.isSubmitted = false;
      await this.spinner.hide();
      await this.updateTicket('approve');
      await this.getTicketParts(
        this.ticketPartsData.ticketCode,
        this.ticketPartsData.partReqId,
      );
    } catch (e) {
      console.log('error>>>', e);
      this.sharedService.showNotification(
        'snackbar-danger',
        e?.error?.message,
        'bottom',
        'center',
      );
      this.isSubmitted = false;
      await this.spinner.hide();
    }
  }*/
  //old
 /* async partsReject(key) {
    this.rejectPopupVisible = false;
   let ticketSubStatusCode;

    if (key == 'reject') {
      ticketSubStatusCode = 20;
    }
  //  let ticketSubStatusCode = this.checkSubStatusCode(key, this.rejectPartsData, this.viewPartsList);


    if (this.rejectRemarks) {
      this.isSubmitted = true;
      this.spinner.show();
      let masterObj =
      {
        cancelRemarks: '',
        hapHubCode: '',
        hapHubRemarks: '',
        hapLat: this.position ? this.position.lat : '',
        hapLong: this.position ? this.position.lng : '',
        ticketCode: this.ticketPartsData.ticketCode,
        ticketStatusCode: 16, //part-request
        ticketSubStatusCode: ticketSubStatusCode,
      };
      let partItems = [
        {

          // approvedQuantity: this.rejectPartsData
          //   ? this.rejectPartsData.row.data.approvedQuantity
          //   : '',
          /!*  catId: this.rejectPartsData
              ? this.rejectPartsData.row.data.idCatId
              : 0,
            companyCode: this.rejectPartsData
              ? this.rejectPartsData.row.data.idCompanyCode
              : 0,
            deptCode: this.rejectPartsData
              ? this.rejectPartsData.row.data.idDeptCode
              : 0,*!/
          approvedQuantity: '',
          goodOrBad: false,
          partCode: this.rejectPartsData
            ? this.rejectPartsData.row.data.idPartId
            : 0,
          reReq: false,
          remarks: this.rejectRemarks ? this.rejectRemarks : '',
          statusFlag: this.rejectPartsData
            ? this.rejectPartsData.row.data.statusFlag
            : 'A',
          materialDesc: this.rejectPartsData
            ? this.rejectPartsData.row.data.materialDesc
            : '',
          requestQuantity: this.rejectPartsData
            ? this.rejectPartsData.row.data.requestQuantity
            : '',
          tlApprovedOrNot: false,
          receivedOrNot: false,
          dispatchTypeId: '',
          brandVendorCode: '',
          brandVendorName: '',
        },
      ];



      /!* old let partsReqObj = {
          //adminApproved: false,
          assetCode: this.ticketPartsData ? this.ticketPartsData.assetCode : '',
          partLineItem: partItems,
          partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
          partStatusCode: 0,
          //   reReq: false,
          //  receivedOrNot: false,
          // remarks: this.rejectRemarks ? this.rejectRemarks : '',
          ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
          //   tlApproved: false,
        };*!/

      let partsReqObj = {
        masterDetails: masterObj,
        partDetails: {
          assetCode: this.ticketPartsData ? this.ticketPartsData.hapAssetCode : '',
          partLineItem: partItems,
          partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
          partStatusCode: 0,
          ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
        }
      };
      try {
        console.log('WHOLE EDIT DATA', partsReqObj);
        let response = await this.ticketService.reqTicketParts(partsReqObj);
        console.log('response', response);
        if (response?.body?.success) {
          this.sharedService.showNotification(
            'snackbar-success',
            response?.body?.message,
            'bottom',
            'center',
          );


        } else {
          this.sharedService.showNotification(
            'snackbar-danger',
            response?.body?.message,
            'bottom',
            'center',
          );
        }

        this.isSubmitted = false;
        await this.spinner.hide();
        await this.updateTicket('reject');
        this.getTicketParts(
          this.ticketPartsData.ticketCode,
          this.ticketPartsData.partReqId,
        );
      } catch (e) {
        console.log('error>>>', e);
        this.sharedService.showNotification(
          'snackbar-danger',
          e?.error?.message,
          'bottom',
          'center',
        );
        this.isSubmitted = false;
        await this.spinner.hide();
      }
      this.rejectRemarks = '';
      this.rejectPartsData = '';
    }
  }
*/
    async partsApproval(key) {
        this.approvePopupVisible = false;
        /* let viewPartStatus = this.viewPartsList.map((obj) => {
             return obj.mspTlApprovalFlag;
         });
         let ticketSubStatusCode;
         const allEqual = arr => arr.every(val => val === arr[0]);
         let allEqlStatus = allEqual(viewPartStatus)
         if(allEqlStatus === true && viewPartStatus[0] === 'Y'){
             ticketSubStatusCode = 18;
         }
         if(allEqlStatus === true && viewPartStatus[0] === 'R'){
             ticketSubStatusCode = 20;
         }
         if(viewPartStatus.includes('Y') && viewPartStatus.includes('R')){
             ticketSubStatusCode = 18;
         }
         if(viewPartStatus.includes('Y') || viewPartStatus.includes('P')){
             ticketSubStatusCode =22;
         }if(viewPartStatus.includes('P') && viewPartStatus.includes('R')){
             ticketSubStatusCode =25;
         }*/


        let viewPartStatus = this.viewPartsList.map((obj) => {
            return obj.mspTlApprovalFlag;
        });
        let ticketSubStatusCode;
        const allEqual = arr => arr.every(val => val === arr[0]);
        let allEqlStatus = allEqual(viewPartStatus);
        console.log("viewPartStatus......", viewPartStatus);
        let filtered = viewPartStatus.filter((obj) => {
            return obj === 'P';
        });
        console.log("filter P......", filtered);
        if (filtered.length === 1) {
            if (allEqlStatus === true && viewPartStatus[0] === 'Y') {
                ticketSubStatusCode = 18;
            }else{
                ticketSubStatusCode = 18;
            }
        }else{
            /* if (allEqlStatus === true && viewPartStatus[0] === 'Y') {
                 ticketSubStatusCode = 18;
             }*/

            /* if (viewPartStatus.includes('Y') && viewPartStatus.includes('R')) {
                 ticketSubStatusCode = 18;
             }*/
            if (viewPartStatus.includes('Y') || viewPartStatus.includes('P')) {
                ticketSubStatusCode = 22;
            }

        }
        this.isSubmitted = true;
        this.spinner.show();

        let masterObj =
            {
                hapRemarks:this.approvePartsData.row.data.remarks
                    ? this.approvePartsData.row.data.remarks
                    : '',
                hapGeoAddress: "",
                cancelRemarks: '',
                hapHubCode: '',
                hapHubRemarks: '',
                hapLat: this.position ? this.position.lat : '',
                hapLong: this.position ? this.position.lng : '',
                ticketCode: this.ticketPartsData.ticketCode,
                ticketStatusCode: 16, //part-request
                ticketSubStatusCode: ticketSubStatusCode,
            };



        let partItems = [
            {
                approvedQuantity: this.approvePartsData.row.data.approvedQuantity,
                requestQuantity: this.approvePartsData.row.data.requestQuantity,
                receivedOrNot: false,
                //  catId: this.approvePartsData.row.data.idCatId,              oldParam
                //   companyCode: this.approvePartsData.row.data.idCompanyCode,  oldParam
                //  deptCode: this.approvePartsData.row.data.idDeptCode,        oldParam
                goodOrBad: false,
                partCode: this.approvePartsData.row.data.idPartId,
                reReq: false,
                remarks: this.approvePartsData.row.data.remarks
                    ? this.approvePartsData.row.data.remarks
                    : '',
                statusFlag: this.approvePartsData.row.data.statusFlag
                    ? this.approvePartsData.row.data.statusFlag
                    : 'A',
                materialDesc: this.approvePartsData.row.data.materialDesc
                    ? this.approvePartsData.row.data.materialDesc
                    : '',
                // dispatchTypeId: obj.dispatchType.idDispatchTypeId
                //   ? obj.dispatchType.idDispatchTypeId
                //   : 0,
                // brandVendorCode: obj.brandVendor.idVendorCode
                //   ? obj.brandVendor.idVendorCode
                //   : '',
                // brandVendorName: obj.brandVendor.vendorName
                //   ? obj.brandVendor.vendorName
                //   : '',
                dispatchTypeId: '',
                brandVendorCode: '',
                brandVendorName: '',
                tlApprovedOrNot: true,
            },
        ];


        /*  old   let partsReqObj = {
           // adminApproved: false,
           assetCode: this.ticketPartsData ? this.ticketPartsData.assetCode : '',
           partLineItem: partItems,
           partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
           partStatusCode: 0,
           //   reReq: false,
           //   receivedOrNot: false,
           // remarks: this.rejectRemarks ? this.rejectRemarks : '',
           ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
           //tlApproved: value ? (value == 'approve' ? true : false) : false,
         };*/

        let partsReqObj = {
            masterDetails: masterObj,
            partDetails: {
                partLineItem: partItems,
                assetCode: this.ticketPartsData ? this.ticketPartsData.hapAssetCode : '',
                partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
                partStatusCode: 0,
                ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
            }
        };
        try {
            console.log('WHOLE EDIT DATA', partsReqObj);
            let response = await this.ticketService.reqTicketParts(partsReqObj);
            console.log('response', response);
            if (response?.body?.success) {
                this.sharedService.showNotification(
                    'snackbar-success',
                    response?.body?.message,
                    'bottom',
                    'center',
            );


            } else {
                this.sharedService.showNotification(
                    'snackbar-danger',
                    response?.body?.message,
                    'bottom',
                    'center',
            );
            }
            this.isSubmitted = false;
            await this.spinner.hide();
            //await this.updateTicket('approve');
            await this.getTicketParts(
                this.ticketPartsData.ticketCode,
                this.ticketPartsData.partReqId,
            );
        } catch (e) {
            console.log('error>>>', e);
            this.sharedService.showNotification(
                'snackbar-danger',
                e?.error?.message,
                'bottom',
                'center',
        );
            this.isSubmitted = false;
            await this.spinner.hide();
        }
    }
    async partsReject(key) {
        this.rejectPopupVisible = false;
        // let ticketSubStatusCode;

        /* if (key == 'reject') {
          // ticketSubStatusCode = 20;
         }*/
        let viewPartStatus = this.viewPartsList.map((obj) => {
            return obj.mspTlApprovalFlag;
        });
        let ticketSubStatusCode;
        const allEqual = arr => arr.every(val => val === arr[0]);

        console.log("viewPartStatus......", viewPartStatus);

        let filtered = viewPartStatus.filter((obj) => {
            return obj === 'P';
        });
        let filteredApproved = viewPartStatus.filter((obj) => {
            return obj === 'Y';
        });
        let filteredRejected = viewPartStatus.filter((obj) => {
            return obj === 'R';
        });
        let allEqlStatus = allEqual(filteredRejected);
        console.log("filter P......", filtered);
        console.log("filter Y......", filteredApproved);
        console.log("filter R......", filteredRejected);
        console.log("filter !viewPartStatus.includes('Y')......", !viewPartStatus.includes('Y'));
        console.log("allEqlStatus......", allEqlStatus);
        if (filtered.length === 1) {
            if (allEqlStatus === true && !viewPartStatus.includes('Y')) {
                ticketSubStatusCode = 20;
            }else if ((viewPartStatus.includes('P') && viewPartStatus.includes('R') && !viewPartStatus.includes('Y'))) {
                ticketSubStatusCode = 25;
            }else{
                ticketSubStatusCode = 18;
            }
        }else{
            if (viewPartStatus.includes('P') && viewPartStatus.includes('R') && !viewPartStatus.includes('Y')
                ||(viewPartStatus.includes('P')  && !viewPartStatus.includes('Y'))) {
                ticketSubStatusCode = 25;
            }else if (viewPartStatus.includes('Y') || viewPartStatus.includes('P')) {
                ticketSubStatusCode = 22;
            }

        }
        //  let ticketSubStatusCode = this.checkSubStatusCode(key, this.rejectPartsData, this.viewPartsList);


        if (this.rejectRemarks) {
            this.isSubmitted = true;
            this.spinner.show();
            let masterObj =
                {
                    hapRemarks:this.rejectRemarks ? this.rejectRemarks : '',
                    hapGeoAddress: "",
                    cancelRemarks: '',
                    hapHubCode: '',
                    hapHubRemarks: '',
                    hapLat: this.position ? this.position.lat : '',
                    hapLong: this.position ? this.position.lng : '',
                    ticketCode: this.ticketPartsData.ticketCode,
                    ticketStatusCode: 16, //part-request
                    ticketSubStatusCode: ticketSubStatusCode,
                };
            let partItems = [
                {

                    // approvedQuantity: this.rejectPartsData
                    //   ? this.rejectPartsData.row.data.approvedQuantity
                    //   : '',
                    /*  catId: this.rejectPartsData
                        ? this.rejectPartsData.row.data.idCatId
                        : 0,
                      companyCode: this.rejectPartsData
                        ? this.rejectPartsData.row.data.idCompanyCode
                        : 0,
                      deptCode: this.rejectPartsData
                        ? this.rejectPartsData.row.data.idDeptCode
                        : 0,*/
                    approvedQuantity: '',
                    goodOrBad: false,
                    partCode: this.rejectPartsData
                        ? this.rejectPartsData.row.data.idPartId
                        : 0,
                    reReq: false,
                    remarks: this.rejectRemarks ? this.rejectRemarks : '',
                    statusFlag: this.rejectPartsData
                        ? this.rejectPartsData.row.data.statusFlag
                        : 'A',
                    materialDesc: this.rejectPartsData
                        ? this.rejectPartsData.row.data.materialDesc
                        : '',
                    requestQuantity: this.rejectPartsData
                        ? this.rejectPartsData.row.data.requestQuantity
                        : '',
                    tlApprovedOrNot: false,
                    receivedOrNot: false,
                    dispatchTypeId: '',
                    brandVendorCode: '',
                    brandVendorName: '',
                },
            ];



            /* old let partsReqObj = {
                //adminApproved: false,
                assetCode: this.ticketPartsData ? this.ticketPartsData.assetCode : '',
                partLineItem: partItems,
                partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
                partStatusCode: 0,
                //   reReq: false,
                //  receivedOrNot: false,
                // remarks: this.rejectRemarks ? this.rejectRemarks : '',
                ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
                //   tlApproved: false,
              };*/

            let partsReqObj = {
                masterDetails: masterObj,
                partDetails: {
                    assetCode: this.ticketPartsData ? this.ticketPartsData.hapAssetCode : '',
                    partLineItem: partItems,
                    partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
                    partStatusCode: 0,
                    ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
                }
            };
            try {
                console.log('WHOLE EDIT DATA', partsReqObj);
                let response = await this.ticketService.reqTicketParts(partsReqObj);
                console.log('response', response);
                if (response?.body?.success) {
                    this.sharedService.showNotification(
                        'snackbar-success',
                        response?.body?.message,
                        'bottom',
                        'center',
                );


                } else {
                    this.sharedService.showNotification(
                        'snackbar-danger',
                        response?.body?.message,
                        'bottom',
                        'center',
                );
                }

                this.isSubmitted = false;
                await this.spinner.hide();
                //  await this.updateTicket('reject');
                this.getTicketParts(
                    this.ticketPartsData.ticketCode,
                    this.ticketPartsData.partReqId,
                );
            } catch (e) {
                console.log('error>>>', e);
                this.sharedService.showNotification(
                    'snackbar-danger',
                    e?.error?.message,
                    'bottom',
                    'center',
            );
                this.isSubmitted = false;
                await this.spinner.hide();
            }
            this.rejectRemarks = '';
            this.rejectPartsData = '';
        }
    }
  async saveDispatch(value) {
    let ticketSubStatusCode;

    if (value == 'dispatch') {
      ticketSubStatusCode = 21;
    }
    await this.getCurrentLocation();
    if (this.position) {
      let obj = this.dispatchForm.value;


      let partFilterItems = this.dispatchPartList.filter((item) => {
        if (item.dispatchStatus !== 'Y') {
          return {
            item,
          };
        }
      });

      let masterObj =
      {
          hapRemarks:"",
          hapGeoAddress: "",
        cancelRemarks: '',
        hapHubCode: '',
        hapHubRemarks: '',
        hapLat: this.position ? this.position.lat : '',
        hapLong: this.position ? this.position.lng : '',
        ticketCode: this.ticketPartsData.ticketCode,
        ticketStatusCode: 16, //part-request
        ticketSubStatusCode: ticketSubStatusCode,
      };

      let partItems = partFilterItems.map((item) => {
        return {

          /* OLD catId: item.idCatId ? item.idCatId : R0,
          companyCode: item.idCompanyCode ? item.idCompanyCode : '',
          deptCode: item.idDeptCode ? item.idDeptCode : 0,*/
          email: '',
          mobile: obj.mobileNo ? obj.mobileNo : '',
          materialDesc: item.materialDesc,
          partCode: item.idPartId,
          partTransportId: '',
          personName: obj.personName ? obj.personName : '',
          quantity: item.approvedQuantity ? item.approvedQuantity : '',
          status: 'A',
          transportTypeId: obj.transportType
            ? obj.transportType.idTransportTypeId
            : 0,
        };
      });

      if (partItems.length > 0) {
        this.isSubmitted = true;
        this.spinner.show();

        /*  OLD let partsReqObj = {
            assetCode: this.ticketPartsData ? this.ticketPartsData.assetCode : '',
            partLineItem: partItems,
            partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
            partStatusCode: 0,
            ticketCode: this.ticketPartsData
              ? this.ticketPartsData.ticketCode
              : '',
          };*/
        //Swetha Added
        let partsReqObj = {
          masterDetails: masterObj,
          transportDetails: {
            assetCode: this.ticketPartsData ? this.ticketPartsData.hapAssetCode : '',
            partLineItem: partItems,
            partReqId: this.ticketPartsData ? this.ticketPartsData.partReqId : '',
            partStatusCode: 0,
            ticketCode: this.ticketPartsData ? this.ticketPartsData.ticketCode : '',
          }
        };

        try {
          console.log('WHOLE EDIT DATA', partsReqObj);
          let response = await this.ticketService.reqTicketDispatchParts(
            partsReqObj,
          );
          console.log('response', response);
          if (response?.body?.success) {
            this.sharedService.showNotification(
              'snackbar-success',
              response?.body?.message,
              'bottom',
              'center',
            );


          } else {
            this.sharedService.showNotification(
              'snackbar-danger',
              response?.body?.message,
              'bottom',
              'center',
            );
          }
          this.isSubmitted = false;
          await this.spinner.hide();
          await this.updateTicket('dispatch');
          // await this.getTicketParts(
          //   this.ticketPartsData.ticketCode,
          //   this.ticketPartsData.partReqId,
          // );
          // this.transportCheck = false;
          // this.dispatchForm.reset();
          this.dialogRef.close({ action: 'dispatchTicket' });
        } catch (e) {
          console.log('error>>>', e);
          this.sharedService.showNotification(
            'snackbar-danger',
            e?.error?.message,
            'bottom',
            'center',
          );
          this.isSubmitted = false;
          await this.spinner.hide();
          // this.getTicketParts(
          //   this.ticketPartsData.ticketCode,
          //   this.ticketPartsData.partReqId,
          // );
        }
      } else {
        this.sharedService.showNotification(
          'snackbar-warning',
          'Selected Parts Already Dispatched',
          'bottom',
          'center',
        );
      }
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'This app needs the Location service, please turn on the Location',
        'bottom',
        'center',
      );
    }
  }

  checkSubStatusCode(key, currentPart, partsData) {



    let allApproved = partsData.filter((item) => item.idPartId != currentPart.row.data.idPartId).every((data) =>
      data.mspTlApprovalFlag == 'Y' && key == 'approve'
    );

    let allRejected = partsData.filter((item) => item.idPartId != currentPart.row.data.idPartId).every((data) =>
      data.mspTlApprovalFlag == 'R' && key == 'reject'
    );
    let anyOneApproved = partsData.filter((item) => item.idPartId != currentPart.row.data.idPartId).every((data) =>
      data.mspTlApprovalFlag == 'P' && key == 'approve'
    );
    let anyOneRejected = partsData.filter((item) => item.idPartId != currentPart.row.data.idPartId).every((data) =>
      data.mspTlApprovalFlag == 'P' && key == 'reject'
    );
   
    



    switch (true) {
      case allApproved:
        return 18;
      case allRejected:
        return 20;
      case anyOneApproved:
        return 22;
      case anyOneRejected:
        return 22;

      default:
        return 22;
    }




  }
  viewTransportDetails(data) {
    this.titleText = `View Transport Details :: ${data.row.data.idTicketCode}`;
    this.viewTransportList = data.row.data.transportDetails || [];
    this.viewTransportPopup = true;
  }
  closeTransportPopup() {
    this.viewTransportPopup = false;
  }
  async dispatchList() {
    try {
      const response = await this.dispatchTyeService.getDispatchType();
      this.dispatchTypeList = response.body.data || [];
    } catch (e) {
      this.dispatchTypeList = [];
    }
  }
  async brandVendors(partsData) {
    let searchCriteria = {
      deptCode: partsData.row.data.idDeptCode,
      catId: partsData.row.data.idCatId,
      brandId: '',
    };
    try {
      const response = await this.brandVendorService.getBrandVendor(
        searchCriteria,
      );

      this.brandVendorList = response?.body?.data || [];
    } catch (e) {
      this.brandVendorList = [];
    }
  }
  async loadReason(categoryId) {

    this.reasonList = [];
    let catId = categoryId;
    let searchCriteria = {
      categoryId: catId,
    };

    try {
      const response = await this.ticketnewService.getReasonList(
        searchCriteria
      );
      let data = response.body.data || [];
      this.reasonList = data.filter((item) => item.roleName === this.roleCheck[0]);

    } catch (e) {
      this.reasonList = [];
    }
  }
}
