import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/core/service/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransportTypeService } from 'src/app/transport-type/transport-type.service';
import { PartRequestService } from 'src/app/part-request/part-request.service';

import { E } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { FieldMsg } from 'src/app/core/service/field-msg.service';
import { DispatchTyeService } from 'src/app/dispatch-type/dispatch-tye.service';
import { BrandVendorService } from 'src/app/brand-vendor/brand-vendor.service';
import { ReasonMasterService } from 'src/app/reason-master/reason-master.service';
import {TicketNewService} from "../../../ticket-new/ticket.service";

@Component({
  selector: 'app-asset-approvereject-dialog',
  templateUrl: './asset-approvereject-dialog.component.html',
  styleUrls: ['./asset-approvereject-dialog.component.scss'],
})
export class AssetApproverejectDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  approvalPartsForm: FormGroup;
  transportCondition: any = 'Y';
  isLoading: boolean;
    partPendingFlag: boolean = false;
    resolvedFlag: boolean = false;
    partPendingalert: boolean = false;
  viewPartsList: any = [];
    ownAssetVerifyData = [];
  selectedRowsData = [];
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
    remarks: any;
    rejectRemarks: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
    isSubmitDisable: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  transportTypeList: any = [

  ];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  reasonList: any = [];
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<AssetApproverejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private ticketnewService: TicketNewService,

  ) {
    this.approvalPartsForm = this.createApprovalPartsForm();
    this.ownAssetVerifyData = this.data;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.roleCheck = this.currentUser.data[0].roles;
  }

  createApprovalPartsForm(): FormGroup {
    return this.fb.group({
      dispatchType: [''],
      brandVendor: [''],
    });
  }
  ngOnInit(): void { }


  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }
    async loadReason(categoryId) {

        this.reasonList = [];
        let catId = categoryId;
        let searchCriteria = {
            categoryId: catId,
        };

        try {
            const response = await this.ticketnewService.getReasonList(
                searchCriteria
            );
            let data = response.body.data || [];
            this.reasonList = data.filter((item) => item.roleName === this.roleCheck[0]);

        } catch (e) {
            this.reasonList = [];
        }
    }
//Own Asset Verify Update Approve/Reject
  assetReject(flag){
      if(flag === 'R'){
          this.rejectPopupVisible = true;
         // this.rejectPartsData =this.ownAssetVerifyData ;
          this.popupTitle = `Own Asset Verification :: Reject`;
          //${}
          this.loadReason(16);
      }
  }
    partsReject(){
        this.remarks = this.rejectRemarks ? this.rejectRemarks : '';
        this.assetConfirm('R');
    }
    async assetConfirm(flag){
    console.log("flag",flag)
        this.isSubmitDisable =true;
        this.isSubmitted =true;
        let selectedTicketData = this.ownAssetVerifyData.map((item) => {
            return {
                sapCode: item.sapCode,
                tagNo: item.tagNo,
                approveFlag: flag,
                approveRemarks: this.remarks ? this.remarks : '',
            };
        });
        let req = selectedTicketData;
        const response = await this.ticketnewService.updateAssetVerify(req);
        this.isSubmitDisable =false;
        this.isSubmitted =false;
        if (response?.body?.success) {
            this.sharedService.showNotification(
                'snackbar-success',
                response?.body?.message,
                'bottom',
                'center',
        );

            await this.spinner.hide();
        } else {
            /** spinner hide */
            await this.spinner.hide();
            this.sharedService.showNotification(
                'snackbar-danger',
                response?.body?.message,
                'bottom',
                'center',
        );
        }
        this.dialogRef.close({ action: 'close' });
        this.closeRejectPopup();

    }
    closeRejectPopup() {
        this.rejectPopupVisible = false;
        this.rejectRemarks = '';
       // this.ownAssetVerifyData = [];
    }
}
