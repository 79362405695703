import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject,lastValueFrom, Observable, of ,Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  rememberMe: boolean = false;
  ticketBaseApi = '/api/nerve/ticket';
    token:any;
    private eventSubject: Subject<boolean> = new Subject<boolean>();

    public readonly statusChanged$: Observable<boolean> = this.eventSubject.asObservable();

    private loggedIn = false;
  constructor(private http: HttpClient, public apiService: ApiService) {

      /* this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem('currentUser'))
      ); */
      this.rememberMe =
          localStorage.getItem('rememberCurrentUser') == 'true' ? true : false;

      if (this.rememberMe == true) {
          this.currentUserSubject = new BehaviorSubject<User>(
              JSON.parse(localStorage.getItem('currentUser')),
          );
      } else {
          this.currentUserSubject = new BehaviorSubject<User>(
              JSON.parse(sessionStorage.getItem('currentUser')),
          );
      }
      this.currentUser = this.currentUserSubject.asObservable();
//Listen to all storage events
      window.onstorage = (a: any) => {
          {
              let loggedIn = sessionStorage.getItem('accessToken') !== null;

              if (localStorage.getItem('signOut')) {
                  loggedIn = false;
              }

              if (this.loggedIn !== loggedIn)//Don't trigger event if no change
              {
                  this.loggedIn = loggedIn;
                  this.eventSubject.next(loggedIn);
              }
          }
          ;
      }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(
    email: string,
    password: string,
    gNo?: string,
    forceLogin?: boolean,
    authMode?: string,
    platformId?:any,
  ) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/admin/auth/v1/signin`, {
        email,
        password,
        gNo,
        forceLogin,
        authMode,
        platformId
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          /* if(user.data){
            let userDetail: User = user.data[0];
            localStorage.setItem('currentUser', JSON.stringify(userDetail));
            sessionStorage.setItem('token', userDetail.token);
          }else{
            localStorage.removeItem('currentUser');
          }

          console.log('user>>>>',user);
         
          this.currentUserSubject.next(user);
          return user; */
          // debugger;
          if (user && user.data && user.data[0].token) {
            /*  if (rememberMe) {
              this.resetcredentials();
              //your logged  out when you click logout
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('rememberCurrentUser', 'true');
              localStorage.setItem('Help', 'ToolTip ON');
            } else {*/
            //your logged  out when page/ browser is closed
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            //}
            // login successful if there's a jwt token in the response
            this.currentUserSubject.next(user);
            // return true;
          } else if (
            user &&
            user.data &&
            user.data[0] &&
            user.message == 'Multiple Companies Availble'
          ) {
            console.log('user.data[0]', user.data);
            this.currentUserSubject.next(user);
            // return true;
          } else if (!user.success) {
            this.currentUserSubject.next(user);
            // return true;
          } else {
            this.currentUserSubject.next(user);
            // return false;
          }

          return user;
        }),
      );
  }

  resetOTPPassword(email: string, gNo?: string, resendFlag?: boolean) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/admin/auth/forgotPassword`, {
        email,
        gNo,
        resendFlag,
      })
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }
  verifyOneTimePassword(email?: string, gNo?: string, newPwd?: any, otp?: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/admin/auth/verifyOneTimePasswordAndReset`,
        {
          email,
          gNo,
          newPwd,
          otp,
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  resetcredentials() {
    //clear all localstorages
    localStorage.removeItem('rememberCurrentUser');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('resetData');
    localStorage.removeItem('Help');
    localStorage.removeItem('filterData');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('mappedHUB');
    sessionStorage.removeItem('parentUrl');
    this.currentUserSubject.next(null);
  }

  logout() {
    // remove user from local storage to log user out
      let isMobile = 'false';
    const addTicketType$ = this.apiService.get('/api/admin/master/v1/signout',
        {isMobile},
        false);
    let response = lastValueFrom(addTicketType$);
    //   console.log("response what",response.body)
    if (response) {
      this.resetcredentials();
    }

     return response;
    //return of({success: false});
  }

 async getMappedHubPlantDetails() {
    const mappedList$ = this.apiService.get(
      `${this.ticketBaseApi}/getMappedHubAndPlantDetails`,
      {},
      true,
    );
    let response = await lastValueFrom(mappedList$);
    return response;
  }
}
