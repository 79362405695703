import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  rememberMe = false;
  constructor(private authenticationService: AuthService) {
    this.rememberMe =
      localStorage.getItem("rememberCurrentUser") == "true" ? true : false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // let currentUser = this.authenticationService.currentUserValue;
    let currentUser;
    /* if(this.rememberMe){
      console.log('this.rememberMe',this.rememberMe);
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }else{ */
    currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    /* } */
    // console.log('currentUser', currentUser);
    let token = currentUser ? currentUser.data[0]?.token : null;
    // console.log('token>>>>', token);
    // if (currentUser && currentUser.token) {
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    // console.log('request',request);
    return next.handle(request);
  }
}
