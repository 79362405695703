import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DualListComponent } from "angular-dual-listbox";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProjectmasterService } from "src/app/projectmaster/projectmaster.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

@Component({
  selector: "app-customer-provider-dialog",
  templateUrl: "./customer-provider-dialog.component.html",
  styleUrls: ["./customer-provider-dialog.component.sass"],
})
export class CustomerProviderDialogComponent implements OnInit {
  public addCusForm: FormGroup;
  customerGroupList: any = [];
  customerCode: any;
  companyCode: any;
  projectCode: any;
  subGroupCode: any;
  keepSorted = true;
  key: string;
  display: any;
  filter = true;
  source: Array<any>;
  confirmed: Array<any>;
  userAdd = "";
  disabled = false;
  sourceLeft = true;
  //format: any = DualListComponent.DEFAULT_FORMAT;

  format: any = {
    add: "Assign Sub Groups",
    remove: "UN Assign Sub Groups",
    all: "Select All",
    none: "Unselect All",
    direction: "left-to-right",
    draggable: true,
    locale: undefined,
  };
  private sourceStations: Array<any>;
  private confirmedStations: Array<any>;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomerProviderDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public projectmasterService: ProjectmasterService,
    private snackBar: MatSnackBar,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.customerCode = data.customerCode;
    this.projectCode = data.projectCode;
    this.companyCode = data.companyCode;
  }

  public ngOnInit(): void {
    this.addCusForm = this.fb.group({
      subGroupCode: [this.subGroupCode],
    });

    this.customerProviderGroup();
  }

  async customerProviderGroup() {
    this.customerGroupList = [];

    //  let gNo ='GN100001';
    let groupType = 2;
    let companyCode = this.companyCode;
    const response = await this.projectmasterService.getSubGroupList(
      this.customerCode,
      this.projectCode,
      groupType,
      companyCode
    );

    console.log("response?.body>>", response?.body?.message);

    this.customerGroupList = response?.body?.data;
    console.log("customerGroupList", this.customerGroupList);

    this.getCustomerSubGroupData();
  }
  getCustomerSubGroupData() {
    const res = [
      ...new Set(
        this.customerGroupList.assignedSubGroup.filter(
          (data) => data.groupType === "Customer"
        )
      ),
    ];
    console.log(res);
    const res1 = [
      ...new Set(
        this.customerGroupList.unAssignedSubGroup.filter(
          (data) => data.groupType === "Customer"
        )
      ),
    ];
    console.log(res1);
    this.sourceStations = JSON.parse(JSON.stringify(res1));
    this.confirmedStations = JSON.parse(JSON.stringify(res));

    console.log("confirmedStations", this.confirmedStations);
    this.useStations();
  }
  useStations() {
    this.key = "idGroupSubCode";
    this.display = "groupSubName";
    this.keepSorted = true;

    this.source = [...this.sourceStations, ...this.confirmedStations];
    this.confirmed = this.confirmedStations;
  }
  closeDialog(): void {
    //  this.dialog.closeAll();
    this.dialogRef.close({ event: "Cancel" });
  }

  filterBtn() {
    return this.filter ? "Hide Filter" : "Show Filter";
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return this.disabled ? "Enable" : "Disabled";
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft
      ? DualListComponent.LTR
      : DualListComponent.RTL;
  }

  async onSubmitClick() {
    //  console.log("Form Value", this.addCusForm.value);
    //   this.dialogRef.afterClosed().subscribe((res) => {
    //     // received data from dialog-component
    //     console.log(res.data);
    //   });
    //   this.dialogRef.close({ event: "Save", data: this.confirmed });
  }
  async saveMapping() {
    this.spinner.show();
    for (let i = 0; i < this.confirmed.length; i++) {
      delete this.confirmed[i].groupSubName;
    }

    this.confirmed.forEach((object) => {
      object.projectGroupCode = this.customerCode;
    });
    console.log("assignedCustomerData", this.confirmed);
    var assignedSubCustomers = this.confirmed.map((item) => ({
      projectSubGroupCode: item.idGroupSubCode,
      projectGroupCode: item.projectGroupCode,
    }));

    let data = {
      idProjectCode: this.projectCode,
      groupType: 2,
      projectUserAssignment: assignedSubCustomers,
    };

    console.log("data", data);

    let response = await this.projectmasterService.subGroupMappingProject(data);
    console.log("response????", response);
    if (response?.body) {
      console.log(response?.body);
      this.spinner.hide();
      this.sharedService.showNotification(
        "snackbar-success",
        response?.body?.message,
        "bottom",
        "center"
      );
      this.dialogRef.close({ event: "Save" });
    }
  }
}
