import { TicketNewService } from "src/app/ticket-new/ticket.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FieldMsg } from "src/app/core//service/field-msg.service";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Observable, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  selector: "app-improper-cleaning",
  templateUrl: "./improper-cleaning.component.html",
  styleUrls: ["./improper-cleaning.component.css"],
})
export class ImproperCleaningComponent implements OnInit {
  public Editor = ClassicEditor;
  base64File: string = null;
  filename: string = null;
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  fileSelectList: any;
  materialList: any = [];
  assetMaterialList: any = [];
  fileValidation: boolean = false;
  filteredOptions: Observable<string[]>;
  materialForm: FormGroup;
  isEditFilter = false;
  ticketData: any;
  searchMaterialCode: any;
  myControl = new FormControl("");
  gNo: string;
  isProxyAllowed: boolean;
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
  checkStatus: boolean = false;
  apicallSub?: Subscription;
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  ticketCode: any;
  position: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  currentDate: any;
  @ViewChild("tagInput", { static: true })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild("priority", { static: false }) priorityField: any;
  @ViewChild("myInput", { static: true }) myInput: ElementRef =
    Object.create(null);
  routerUrl: any;
  currentTime: any;
  statusList: any = [];
  employeeList: any = [];
  uomList: any = [
    {
      uomName: "Ml",
      uomCode: "ml",
    },
    {
      uomName: "Ltr",
      uomCode: "ltr",
    },
  ];
  panelOpenState = false;

  /* auto search */
  minValue: any = 0;
  maxValue: any = 2;
  fileTypeValidation: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ImproperCleaningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg
  ) {
    this.routerUrl = this.sharedService.routerUrl();
    console.log(this.routerUrl, "currentUrl");
    this.action = data.action;
    this.ticketData = data.assetMaterial.data;
    this.ticketCode = this.ticketData.ticketCode;

    this.materialForm = this.createMaterialForm();
    this.dialogTitle = `Analyzer Improper Cleaning :: Ticket - ${this.ticketCode}`;
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.aicList();
  }

  createMaterialForm(): FormGroup {
    return this.fb.group({
      //materialCode: [{ value: "", disabled: false }, [Validators.required]],
      // materialName: [{ value: "", disabled: true }],
      attachments: [""],
      remark: [""],
      quantity: [""], //[Validators.pattern('^(0|[1-9][0-9]*)$')]
    });
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
  }
  //attachment List
  aicList() {
    const abortController = new AbortController();

    console.log(" this.ticketData", this.ticketData);
    let req = {
      statusFlag: "A",
      ticketCode: this.ticketCode,
      signal: abortController.signal,
    };
    const response = this.ticketService.getRecoveryFileAttachment(req);
    response.subscribe(
      (result) => {
        this.assetMaterialList = result?.body?.data;
        this.isSubmitted = false;
      },
      (error) => {
        this.sharedService.showNotification(
          "snackbar-danger",
          "Something went wrong",
          "bottom",
          "center"
        );
        this.isSubmitted = false;
      }
    );
  }

  onFileSelect(e: any): void {
    // console.log('file>>', this.ticketForm);
    console.log("file>>", e);
    try {
      const files = e.target.files;
      const file = e.target.files[0];

      if (files.length > 10 || file.size > 5e6) {
        this.fileValidation = true;
        this.materialForm
          .get("attachments")
          ?.setErrors({ invalid: "Max attachment limit 10" });
        throw "Max attachment limit 10";
      } else {
        this.fileValidation = false;
        this.materialForm.get("attachments")?.setErrors(null);
      }

      const selFiles = [];
      for (var i = 0; i < files.length; i++) {
        selFiles.push(files[i]);
      }
      console.log("selFilesselFilesselFiles", selFiles);
      //   this.fileSelectList = selFiles;
      this.materialForm.get("attachments").setValue(selFiles);
      // console.log('file>>', this.ticketForm);
    } catch (error) {
      this.filename = null;
      this.base64File = null;
    }
  }
  aicSave() {
    let obj = this.materialForm.getRawValue();
    console.log("objjjj", obj.attachments);
    console.log("this.fileSelectList ", this.fileSelectList);
    let recoveryData = {
      recoveryAmount: obj.quantity,
      lineId: "",
      ticketCode: this.ticketCode,
      remarks: obj.remark,
      statusFlag: "A",
    };
    let formData = new FormData();
    if (obj["attachments"]?.length > 0) {
      for (var i = 0; i < obj["attachments"].length; i++) {
        formData.append("attachment", obj["attachments"][i]);
      }
    } else {
      formData.append("attachment", "");
    }
    //  formData.append("attachment",  this.fileSelectList );
    formData.append("recoveryData", JSON.stringify(recoveryData));
    formData.append("master", "");
    const response = this.ticketService.uploadRecoveryFileAttachment(formData);
    response.subscribe(
      (result) => {
        this.isSubmitted = false;
        this.materialForm.reset();
        this.sharedService.showNotification(
          "snackbar-success",
          result?.body?.message,
          "bottom",
          "center"
        );
        this.aicList();
      },
      (error) => {
        this.sharedService.showNotification(
          "snackbar-danger",
          "Error in Saving",
          "bottom",
          "center"
        );
        this.isSubmitted = false;
      }
    );
  }
  onFileSelectProject(e: any): void {
    try {
      const files = e.target.files;
      const file = e.target.files[0];
      console.log("file :>> ", file);
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      this.filename = file.name;
      console.log("ghg", file.name);
      const maxSize = 10 * 1024 * 1024; //5e6
      const fileType = file.type;
      if (!allowedTypes.includes(fileType)) {
        this.filename = null;
        this.fileTypeValidation = true;
      } else {
        this.fileTypeValidation = false;
      }
      if (file.size > maxSize) {
        //console.log("File is too large. Over 10MB");
        this.filename = null;
        this.base64File = null;
        this.fileValidation = true;
      } else {
        // console.log("File is valid");
        // this.materialForm.get("attachments").setValue(file);
        // this.fileValidation = false;
        this.fileValidation = false;
        this.materialForm.get("attachments")?.setErrors(null);
      }
      const selFiles = [];
      for (var i = 0; i < files.length; i++) {
        selFiles.push(files[i]);
      }
      this.fileSelectList = selFiles;
      console.log("selFilesselFilesselFiles", selFiles);
      const fileNames = [];
      selFiles.map((dt) => {
        fileNames.push(dt.name);
      });
      console.log("selFilesselFilesselFiles", fileNames);

      this.materialForm.get("attachments").setValue(selFiles);
    } catch (error) {
      this.filename = null;
      this.base64File = null;
      console.log("no file was selected...");
    }
  }
  aicClear() {
    this.materialForm.reset();
  }
}
