import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd,
} from "@angular/router";

import { AuthService } from "../service/auth.service";
import { SharedService } from "../service/shared.service";
import { TokenExpirationService } from "../service/token-expiration.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private tokenExpirationService: TokenExpirationService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.browserRestriction();
  }

  currentURl: any;
  currentUser: any;
  tileArray: any = [];

  tokenExpired() {
    if (this.currentUser) {
      let expirationDate = this.currentUser
        ? this.currentUser.data[0]?.expirationDate
        : null;

      const tokenExpirationTime = expirationDate;
      //console.log("tokenExpirationTime", tokenExpirationTime);
      localStorage.setItem("LocalToken", this.currentUser.data[0].token);
      localStorage.setItem("logout-event", "logout" + Math.random());
      if (tokenExpirationTime) {
        if (this.tokenExpirationService.isTokenExpired(tokenExpirationTime)) {
          console.log("expired tokenn true logout");
          this.authService.logout();
        }
      }
    }
  }

  browserRestriction() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURl = event.url;

        /*      let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        let tileList = currentUser ? currentUser.data[0]?.tiles : "";
        let tileItems = tileList.map((a) => a.tileItems);
        let tileArray = [];
        let tileUrlList = tileItems.forEach((tile) => {
          tile.forEach((item) => {
            let tileUrl = item.tileUrl;
            this.tileArray.push(tileUrl);
          });
        });
        const modifiedUrl = this.currentURl.substring(1);
        console.log(modifiedUrl);
        if (
          this.tileArray.includes(modifiedUrl) ||
          this.currentURl === "/dashboard/main" ||
          this.currentURl.includes("menu") ||
          this.currentURl === "/super-login" ||
          this.currentURl === "/authentication/signin"
        ) {
        } else {
          alert("Access denied. You do not have the required role.");
          this.router.navigate(["/dashboard/main"]);
        }*/
        if (this.currentUser) {
          let tileList = this.currentUser
            ? this.currentUser.data[0]?.tiles
            : [];
          let tileItems = tileList.map((a) => a.tileItems);
          tileItems.forEach((tile) => {
            tile.forEach((item) => {
              let tileUrl = item.tileUrl;
              this.tileArray.push(tileUrl);
            });
          });
          const modifiedUrl = this.currentURl.substring(1);

          if (
            !(
              this.tileArray.includes(modifiedUrl) ||
              this.currentURl === "/dashboard/main" ||
              this.currentURl.includes("menu") ||
              this.currentURl === "/authentication/signin"
            )
          ) {
            alert("Access denied. You do not have the required role.");
            this.router.navigate(["/dashboard/main"]);
          }
          if (
            this.currentURl === "/authentication/signin" ||
            this.currentURl === "/"
          ) {
            this.router.navigate(["/dashboard/main"]);
          }
          this.tokenExpired();
        }
      }
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("Current URL:", state.url);
    if (this.authService.currentUserValue) {
      return true;
    }
    // Check if the route is the super-admin login page
    if (state.url === "/super-login") {
      this.router.navigate(["/authentication/super-login"]);
      return false; // Allow access to the super-admin login page without authentication
    } else {
      this.router.navigate(["/authentication/signin"]);

      return false;
    }
  }
}
