import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";
import { ProjectmasterService } from "src/app/projectmaster/projectmaster.service";

import { Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./reset-password-dialog.component.html",
  styleUrls: ["./reset-password-dialog.component.css"],
})
export class ResetPasswordDialogComponent {
  action: string;
  dialogTitle: string;
  isDetails = false;
  changeForm: FormGroup;
  hideOldPwd: boolean = true;
  hideNewPwd: boolean = true;
  firstLogin:any;
  success = "";
  value:any;
  loginCredentials:any;
  hideCfmPwd: boolean = true;
  defaultImg='assets/images/user/usrbig1.jpg';
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private router: Router,
   public projectmasterService: ProjectmasterService,
   private authService: AuthService
  ) {
    this.isDetails = false;
    this.dialogTitle = "Reset Password";
    this.changeForm = this.changePasswordForm();
    console.log(this.checkPasswords, "check");

    this.loginCredentials =
    {
      oldPassword: data.oldPassword,
      firstLogin: data.firstLogin,
      userImage:data.userImage,
      userName:data.userName
    }
    console.log(this.loginCredentials,'loginCredentials')
}

  changePasswordForm(): FormGroup {
    return this.fb.group(
      {
       
        newPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirmPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
        ]),
      }, 
      // { validators: this.checkPasswords }
      {
        validator: ConfirmedValidator('newPassword', 'confirmPassword')
      }
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmPassword.value;
   
    
    return pass === confirmPass ? null : 
    { 
      notSame: true, 
     
    };

    


  }
  submit() {
    // emppty stuff
  }
  get f() {
    return this.changeForm.controls;
  }
  onNoClick(): void {
    this.dialogRef.close({ action: "close" });
  }
  public async changePassword() {
    if (this.changeForm.invalid) {
       return;
    }
   
    this.success = JSON.stringify(this.changeForm.value);
    console.log("success", this.success);
    this.spinner.show(); 
    


    let formData = new FormData();
   
    formData.append("firstLogin", this.loginCredentials.firstLogin);
    formData.append("newPassword", this.changeForm.value.newPassword);
    formData.append("oldPassword", this.loginCredentials.oldPassword);

   
    try {
      let response = await this.projectmasterService.changePassword(
        formData
      );
      console.log("response????", response);
      if (response?.body?.success) {
        this.sharedService.showNotification(
          "snackbar-success",
          response?.body?.message,
          "bottom",
          "center"
        );
        this.spinner.hide();
        this.dialogRef.close();
            let res = await  this.authService.logout();
            let signOutResponse =  res?.body?.message;
            if(signOutResponse === "session terminated successfully"){
                this.router.navigate(['/authentication/signin']);
                this.sharedService.showNotification(
                    "snackbar-success",
                    "LogOut Successfully",
                    "bottom",
                    "center"
            );
            }else{
                this.sharedService.showNotification(
                    "snackbar-danger",
                    response?.body?.message,
                    "bottom",
                    "center"
            );
            }
        /*  if (!res.success) {
            this.router.navigate(["/authentication/signin"]);
            location.reload();
          }
          else{

          }*/

      }
      else{
        this.sharedService.showNotification(
          "snackbar-danger",
          response?.body?.message,
          "bottom",
          "center"
        );
        this.spinner.hide();
      }
    } catch (e) {
      console.log("Error>>>>>>>>>>>>", e);
      let error = e;
      this.sharedService.showNotification(
        "snackbar-danger",
        error,
        "bottom",
        "center"
      );
      this.spinner.hide();
    }


  }

  

}
export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}