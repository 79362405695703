import { NgxSpinnerService } from "ngx-spinner";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SharedService } from "src/app/core/service/shared.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TransportTypeService } from "src/app/transport-type/transport-type.service";
import { PartRequestService } from "src/app/part-request/part-request.service";

import { E } from "@angular/cdk/keycodes";
import { environment } from "src/environments/environment";
import { FieldMsg } from "src/app/core/service/field-msg.service";
import { DispatchTyeService } from "src/app/dispatch-type/dispatch-tye.service";
import { BrandVendorService } from "src/app/brand-vendor/brand-vendor.service";
import { ReasonMasterService } from "src/app/reason-master/reason-master.service";
import { TicketNewService } from "../../../ticket-new/ticket.service";
import { MassTicketUpdateService } from "../../../mass-ticket-update/mass-ticket-update.service";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { MatChipInputEvent } from "@angular/material/chips";
import { BrandingTicketService } from "src/app/branding-ticket/branding-ticket.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export interface ChipColor {
  name: string;
  color: string;
}

@Component({
  selector: "app-mailLog-view-dialog",
  templateUrl: "./mailLog-view-dialog.component.html",
  styleUrls: ["./mailLog-view-dialog.component.scss"],
})
export class MailLogViewDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  mailForm: FormGroup;
  transportCondition: any = "Y";
  isLoading: boolean;
  partPendingFlag: boolean = false;
  resolvedFlag: boolean = false;
  partPendingalert: boolean = false;
  viewPartsList: any = [];
  ownAssetVerifyData = [];
  selectedRowsData: any;
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  remarks: any;
  rejectRemarks: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  isSubmitDisable: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  transportTypeList: any = [];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  reasonList: any = [];
  mailData: any = [];
  action: any;

  tags: string[] = [];
  availableColors: ChipColor[] = [];
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  allTags: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  myTeamTags: string[] = [];
  allMyTeam: string[] = [];
  removable = true;
  removableTeam = true;
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild("tagInput", { static: true })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("tagInput", { static: true })
  tagInput1: ElementRef<HTMLInputElement>;
  public Editor = ClassicEditor;
  ckConfig: any;
  tktCode: any;
  assetCode: any;
  isDisabled: boolean;
  icon: any;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<MailLogViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private massTicketUpdateService: MassTicketUpdateService,
    private brandingTicketService: BrandingTicketService
  ) {
    //debugger;
    console.log("ff", data);
    this.mailForm = this.createMailForm();
    this.selectedRowsData = data;
    this.action = data?.action;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.roleCheck = this.currentUser.data[0].roles;
    this.tktCode = this.selectedRowsData?.oem?.data?.ticketCode;

    this.assetCode = this.selectedRowsData?.oem?.data?.hapAssetCode;
    this.ckConfig = {
      toolbar: ["Bold", "Italic", "|", "bulletedList", "numberedList"],
    };
    if (this.action == "brand") {
      if (this.selectedRowsData?.action2 == "brandView") {
        this.dialogTitle = "Brand Verification";
        this.mailLogView();
        this.mailForm.disable();
        this.isDisabled = true;
        this.icon = "remove_red_eye";
      } else {
        this.dialogTitle = "Brand Verification";
        this.isDisabled = false;
        this.icon = "edit";
      }
    } else if (this.action == "OEM") {
      this.dialogTitle = "OEM Verification";
      this.mailLogView();
      this.mailForm.disable();
      this.isDisabled = true;
      this.icon = "remove_red_eye";
    } else {
    }
    //this.mailForm = this.createMailForm();
  }

  createMailForm(): FormGroup {
    return this.fb.group({
      toId: [],
      ccId: [],
      subject: [],
      body: [],
    });
  }
  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close({ action: "close" });
    this.massTicketUpdateService.cancelRequests();
  }

  mailLogView() {
    this.selectedRowsData;
    console.log(" this.selectedRowsData", this.selectedRowsData);
    let mailLogParam = {
      mailCategory: this.selectedRowsData?.action,
      ticketCode: this.selectedRowsData?.oem?.data?.ticketCode,
    };
    const response = this.massTicketUpdateService.mailLogView(mailLogParam);
    response.subscribe(
      (result) => {
        console.log("response", result);
        this.mailData = result?.body?.data;
        /* if (this.mailData.length == 0) {
          this.sharedService.showNotification(
            "snackbar-danger",
            result?.body?.message,
            "bottom",
            "center"
          );
        } */
      },
      (error) => {
        this.sharedService.showNotification(
          "snackbar-danger",
          error,
          "bottom",
          "center"
        );
        this.isSubmitted = false;
      }
    );
  }
  //mail -START
  add(event: MatChipInputEvent): void {
    console.log("event :>> ", event);
    const input = event.input;
    const value = event.value;
    console.log("tags list", this.tags);
    if (value) {
      if (this.validateEmail(event.value)) {
        // Add our Ticket tag items
        if ((value || "").trim()) {
          if (
            !this.tags.includes(value.trim()) &&
            this.myTeamTags.toString() != value
          ) {
            this.availableColors.push({
              name: value.trim(),
              color: this.randomClr(),
            });
            this.tags.push(value.trim());
            //    this.projectForm.controls['projectEmail'].enable();
          } else {
            // this.projectForm.controls['projectEmail'].disable();
            this.sharedService.showNotification(
              "snackbar-warning",
              "value is exists in CC Mail",
              "bottom",
              "center"
            );
          }
        }
      } else {
        console.log("value", value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.allTags.push(tag);
      this.tags.splice(index, 1);
      this.tagListAdd();
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.availableColors.push({
      name: event.option.viewValue,
      color: this.randomClr(),
    });
    this.tags.push(event.option.viewValue);
    const index = this.allTags.indexOf(event.option.viewValue);
    this.allTags.splice(index, 1);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }
  private validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public randomClr() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
  private tagListAdd() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) === 0
    );
  }
  removeVisibilityTeam(tag: string): void {
    const index = this.myTeamTags.indexOf(tag);

    if (index >= 0) {
      this.allMyTeam.push(tag);
      this.myTeamTags.splice(index, 1);
      // this.tagListMyTeamAdd();
    }
  }
  addVisibilityTeam(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log("tags list", this.myTeamTags);
    if (value) {
      if (this.validateEmail(event.value)) {
        // Add our Ticket tag items
        if ((value || "").trim()) {
          if (
            !this.myTeamTags.includes(value.trim()) &&
            this.tags.toString() != value
          ) {
            this.availableColors.push({
              name: value.trim(),
              color: this.randomClr(),
            });
            this.myTeamTags.push(value.trim());
            //    this.projectForm.controls['projectEmail'].enable();
          } else {
            // this.projectForm.controls['projectEmail'].disable();
            this.sharedService.showNotification(
              "snackbar-warning",
              "value is exists in To Mail",
              "bottom",
              "center"
            );
          }
        }
      } else {
        console.log("value", value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.tagCtrl.setValue(null);
  }
  selectedMyTeam(event: MatAutocompleteSelectedEvent): void {
    this.availableColors.push({
      name: event.option.viewValue,
      color: this.randomClr(),
    });
    this.myTeamTags.push(event.option.viewValue);
    const index = this.allMyTeam.indexOf(event.option.viewValue);
    this.allMyTeam.splice(index, 1);
    this.tagInput1.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }
  //mail -END

  /// send Mail --
  async sendMail() {
    this.isSubmitted = true;
    const obj = this.mailForm.getRawValue();
    const payload = {
      branding: {
        //approvedRemarks: "",
        //approvedStatus: "",
        assetCode: this.assetCode,
        ccId: this.myTeamTags,
        // mailContent: obj?.body,
        mailStatus: "Y",
        //mailSubject: obj?.subject,
        ticketCode: this.tktCode,
        lineId :this.selectedRowsData?.oem?.data?.bLineId,
        toId: this.tags,
      },
      master: {
        hapLat: 0,
        hapLong: 0,
        ticketCode: this.tktCode,
        ticketStatusCode: 3, //3,7
        ticketSubStatusCode: 3, //3,7
      },
    };
    const response = this.brandingTicketService.updateBrandMail(payload);

    response.subscribe(
      (result) => {
        console.log("response", result);
        if (result?.body?.success) {
          this.sharedService.showNotification(
            "snackbar-success",
            result?.body?.message,
            "bottom",
            "center"
          );
          this.isSubmitted = false;
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            result?.body?.message,
            "bottom",
            "center"
          );
          this.isSubmitted = false;
        }
        this.dialogRef.close({ result });
      },
      (error) => {
        this.sharedService.showNotification(
          "snackbar-danger",
          error?.error?.message,
          "bottom",
          "center"
        );
        this.isSubmitted = false;
      }
    );
  }
}
