import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { BrandingTicketService } from "src/app/branding-ticket/branding-ticket.service";
import { SharedService } from "src/app/core/service/shared.service";
import { MassTicketUpdateService } from "src/app/mass-ticket-update/mass-ticket-update.service";

@Component({
  selector: "app-mail-view",
  templateUrl: "./mail-view.component.html",
  styleUrls: ["./mail-view.component.css"],
})
export class MailViewComponent implements OnInit {
  dialogTitle: any;
  selectedRowsData: any;
  action: any;
  mailData: any;
  tktCode: any;
  constructor(
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<MailViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private massTicketUpdateService: MassTicketUpdateService,
    private brandingTicketService: BrandingTicketService
  ) {
    this.selectedRowsData = data;
    this.action = data?.action;
    this.tktCode = this.selectedRowsData?.oem?.data?.ticketCode;
    if (
      this.action == "branding" &&
      this.selectedRowsData?.action2 == "brandView"
    ) {
      this.dialogTitle = "Brand Verification";
      this.mailLogView();
      ///this.mailForm.disable();
      //this.isDisabled = true;
      //this.icon = "remove_red_eye";
    } else if (this.action == "OEM") {
      this.dialogTitle = "OEM Verification";
      this.mailLogView();
      //this.mailForm.disable();
      //this.isDisabled = true;
      //this.icon = "remove_red_eye";
    } else {
    }
  }

  ngOnInit(): void {}
  onNoClick() {
    this.dialogRef.close({ action: "close" });
    this.massTicketUpdateService.cancelRequests();
  }
  mailLogView() {
    this.selectedRowsData;
    console.log(" this.selectedRowsData", this.selectedRowsData);
    let mailLogParam = {
      mailCategory: this.selectedRowsData?.action,
      ticketCode: this.selectedRowsData?.oem?.data?.ticketCode,
    };
    const response = this.massTicketUpdateService.mailLogView(mailLogParam);
    response.subscribe(
      (result) => {
        console.log("response", result);
        this.mailData = result?.body?.data;
        /* if (this.mailData.length == 0) {
          this.sharedService.showNotification(
            "snackbar-danger",
            result?.body?.message,
            "bottom",
            "center"
          );
        } */
      },
      (error) => {
        this.sharedService.showNotification(
          "snackbar-danger",
          error,
          "bottom",
          "center"
        );
      }
    );
  }
}
