import { Injectable } from "@angular/core";
import axios from "axios";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

const serverBaseUrl = environment.apiUrl;
const ticketServiceUrl = environment.ticketUrl;
//const lmsServiceUrl = environment.lmsApiUrl;

// AXIOS CONFIGURATION
const api = axios.create({
  // baseURL: 'environment.ticketUrl',
  baseURL: serverBaseUrl,
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(public http: HttpClient) {}

  public get(
    url: string,
    paramsData?: any,
    ticket?: boolean,
    type?: any
  ): Observable<HttpResponse<any>> {
    const baseUrl = ticket ? ticketServiceUrl : serverBaseUrl;
    const resType = type ? type : "json";
    return this.http.get(`${baseUrl}${url}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      observe: "response",
      params: paramsData,
      responseType: resType,
    });
  }

  public post(
    url: string,
    payload: any,
    paramsData?: any,
    ticket?: boolean,
    type?: any
  ): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    const baseUrl = ticket ? ticketServiceUrl : serverBaseUrl;
    const resType = type ? type : "json";

    return this.http.post(`${baseUrl}${url}`, payload, {
      observe: "response",
      params: paramsData,
      responseType: resType,
    });
  }
  public postFile(url, formData): Observable<HttpResponse<any>> {
    const baseUrl = serverBaseUrl;
    return this.http.post(`${baseUrl}${url}`, formData, {
      responseType: "json",
      observe: "response",
    });
  }
  public put(
    url: string,
    payload?: any,
    paramsData?: any,
    ticket?: boolean
  ): Observable<HttpResponse<any>> {
    const baseUrl = ticket ? ticketServiceUrl : serverBaseUrl;

    return this.http.put(`${baseUrl}${url}`, payload, {
      observe: "response",
      params: paramsData,
    });
  }

  public delete(
    url: string,
    payload?: any,
    paramsData?: any,
    ticket?: boolean
  ): Observable<HttpResponse<any>> {
    const baseUrl = ticket ? ticketServiceUrl : serverBaseUrl;

    return this.http.delete(`${baseUrl}${url}`, {
      observe: "response",
      params: paramsData,
      body: payload,
    });
  }

  // ############### AXIOS CONFIGURATION ##############

  public fetch(url: string, paramsData?: any): any {
    api
      .post(`${url}`, paramsData)
      .then((res) => {
        console.log("res--> ", res);
        return res;
      })
      .catch((ex) => {
        console.log("ex--> ", ex);
      });
  }

  public update(url: string, payload?: any, params?: any): any {
    api
      .put(`${url}`, payload)
      .then((res) => {
        console.log("res--> ", res);
      })
      .catch((ex) => {
        console.log("ex--> ", ex);
      });
  }

  public create(url: string, payload?: any, params?: any): any {
    api
      .post(`${url}`, payload)
      .then((res) => {
        console.log("res--> ", res);
      })
      .catch((ex) => {
        console.log("ex--> ", ex);
      });
  }

  public remove(url: any, params?: any): any {
    api
      .delete(`${url}`, params)
      .then((res) => {
        console.log("res--> ", res);
      })
      .catch((ex) => {
        console.log("ex--> ", ex);
      });
  }
}
