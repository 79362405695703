import { Component, HostListener, Inject, Input, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ProjectmasterService } from "../../../projectmaster/projectmaster.service";
import { requireCheckboxesToBeCheckedValidator } from "src/app/projectmaster/copyProject/require-checkboxes-to-be-checked.validator";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-rule-condition-dialog",
  templateUrl: "./copy-project-dialog.component.html",
  styleUrls: ["./copy-project-dialog.component.css"],
})
export class CopyProjectDialogComponent implements OnInit {
  
  copyProjectDetails:any;

  public projectCopyForm: FormGroup;
  commercialReq:boolean = true;
  saveProjectCode:any;
  action:any;
  oldProjectCode:any;
  isDistruct:any;
  oldProjectName:any;
  

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public projectmasterService: ProjectmasterService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CopyProjectDialogComponent>,
    public sharedService: SharedService
  ) {
     console.log('action',this.action);

    this.copyProjectDetails = data.copyDetails;
    console.log('copyProjectDetails',this.copyProjectDetails);
    this.saveProjectCode = this.copyProjectDetails.idProjectCode;
    this.action = data.action;
    this.oldProjectCode = data.oldProjectCode;
    console.log('copyProjectDetails',this.oldProjectCode);
    this.oldProjectName = data.oldProjectName;
    let req = this.copyProjectDetails.commercialFlag;
    if(req == "N"){
      this.commercialReq = true;
    }
    else{
     this.commercialReq = false;
    }
    this.projectCopyForm = this.createCopyForm();

  }
  createCopyForm(): FormGroup {
    return this.fb.group({
      myCheckboxGroup: new FormGroup(
        {
          copySupportingHours: new FormControl(false),
          copyCommercial: new FormControl(false),
          copyTicketType: new FormControl(false),
          copyClassification: new FormControl(false),
          copyStatus: new FormControl(false),
          copyPriority: new FormControl(false),
          copyRules: new FormControl(false),
          copyApproval: new FormControl(false),
        },
        requireCheckboxesToBeCheckedValidator()
      ),
    });
  }
  public ngOnInit(): void {}
  public async confirmCopy() {
    this.spinner.show();
    var projectCopy = this.projectCopyForm.value;
    var copyFlagDetails = {
      copyFrom: this.oldProjectCode,
      copyTo: this.saveProjectCode,
      copyFlags: [
        {
          supportingHours: projectCopy.myCheckboxGroup.copySupportingHours
            ? "Y"
            : "N",
          commercial: projectCopy.myCheckboxGroup.copyCommercial ? "Y" : "N",
          ticketType: projectCopy.myCheckboxGroup.copyTicketType ? "Y" : "N",
          ttClassification: projectCopy.myCheckboxGroup.copyClassification
            ? "Y"
            : "N",
          rules: "N",
          approvalMatrix: "N",
          status: projectCopy.myCheckboxGroup.copyStatus ? "Y" : "N",
          priority: projectCopy.myCheckboxGroup.copyPriority ? "Y" : "N",
        },
      ],
    };

    try {
      let response = await this.projectmasterService.copyProject(
        copyFlagDetails
      );
      console.log("response????", response);
      if (response?.body?.success) {
        this.sharedService.showNotification(
          "snackbar-success",
          response?.body?.message,
          "bottom",
          "center"
        );
        this.spinner.hide();
        this.dialogRef.close({ action: this.action, response });
      }
    } catch (e) {
      console.log("Error>>>>>>>>>>>>", e);
      let error = e.error;
      this.sharedService.showNotification(
        "snackbar-danger",
        error?.message,
        "bottom",
        "center"
      );
      this.spinner.hide();
    }
  }
  
  ticketCheckChange(e) {
    console.log(e, "event");
    var value = this.projectCopyForm.value;
    console.log("value", value);
    if (e.checked) {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyClassification: false,
          copyStatus: false,
          copyPriority: false,


        },
      });
    } else {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyClassification: false,
          copyStatus: false,
          copyPriority: false,
        },
      });
    }
  }
  onNoClick(): void {
    
    const fieldValueArr1 = Object.values(this.projectCopyForm.value);
    
    const isChanged1 = fieldValueArr1.filter((v) => {
      if (v && v != 0) {
        console.log(v);
        return v;
      }
    });
   
    if (isChanged1.length > 0) {
      this.isDistruct = false;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: "320px",
        data: {
          name: "Your changes won’t be saved",
          msg: "We won’t be able to save your data if you move away from this page.",
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.event === "Yes") {
          this.dialogRef.close({ action: "close" });
        }
        this.isDistruct = true;
      });
    } else {
      this.dialogRef.close({ action: "close" });
      this.isDistruct = true;
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.isDistruct) {
      this.onNoClick();
    }
  }
  classificationCheckChange(e) {
    console.log(e, "event");
    var value = this.projectCopyForm.value;
    console.log("value", value);
    if (e.checked) {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyTicketType: true,
        },
      });
    } else {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyTicketType: true,
          copyStatus: false,
          copyPriority: false,

        },
      });
    }
  }

  classificationStatusChange(e) {
    console.log(e, "event");
    var value = this.projectCopyForm.value;
    console.log("value", value);
    if (e.checked) {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyTicketType: true,
          copyClassification: true,
          copyStatus: true,
          
        },
      });
    } else {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
            copyStatus: false,
        
        },
      });
    }
  }

  classificationPriorityChange(e) {
    console.log(e, "event");
    var value = this.projectCopyForm.value;
    console.log("value", value);
    if (e.checked) {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyTicketType: true,
          copyClassification: true,
          copyPriority: true,
        },
      });
    } else {
      this.projectCopyForm.patchValue({
        myCheckboxGroup: {
          copyPriority: false,
        },
      });
    }
  }
  closeDialog(): void {
    this.dialogRef.close({ event: "Cancel" });
  }
}
