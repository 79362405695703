import * as XLSX from 'xlsx';

export class ExcelExportService {
    exportToExcel(data: any[], fileName: string, sheetName: string = 'Sheet1'): void {
        const headers = Object.keys(data[0]);
        const wsData = [headers].concat(data.map(obj => headers.map(key => obj[key])));

        // Apply bold style to the header row
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        for (let col = 0; col < headers.length; col++) {
            const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
            ws[headerCell].s = { font: { bold: true } };
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, sheetName);

        // Save the workbook to a file
        XLSX.writeFile(wb, fileName);
    }
}



