import { NgModule } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import {
  LocationStrategy,
  HashLocationStrategy,
  AsyncPipe,
} from "@angular/common";
import { fakeBackendProvider } from "./core/interceptor/fake-backend";
import { ErrorInterceptor } from "./core/interceptor/error.interceptor";
import { JwtInterceptor } from "./core/interceptor/jwt.interceptor";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatSelectFilterModule } from "mat-select-filter";
import { CommonModule } from "@angular/common";

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { WINDOW_PROVIDERS } from "./core/service/window.service";
import { UsermasterComponent } from "./user-master/user-master.component";
import { TicketModule } from "./ticket/ticket.module";
import { TileMenuService } from "./layout/tile-menu/tile-menu.service";
import { ComponentsModule } from "./shared/components/components.module";
// import { HighchartsChartModule } from 'highcharts-angular';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from "@angular/material/tooltip";
import { CreatedbyDialogComponent } from "./shared/dialog-boxes/createdby-dialog/createdby-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { MessagingService } from "./service/messaging-service";
import { SharedService } from "./core/service/shared.service";
import { ExcelExportService } from "./service/excel-export-service";

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: "auto",
  position: "before",
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    CreatedbyDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    MatSelectFilterModule,
    NgxSpinnerModule,
    ClickOutsideModule,
    MatChipsModule,
    NgxMatSelectSearchModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),

    // HighchartsChartModule,
    MatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,

        deps: [HttpClient],
      },
    }),
    CoreModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    SharedService,
    ExcelExportService,
    MessagingService,
    AsyncPipe,
    HeaderComponent,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // fakeBackendProvider,
    WINDOW_PROVIDERS,
    TileMenuService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
