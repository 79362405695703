import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./createdby-dialog.component.html",
  styleUrls: ["./createdby-dialog.component.sass"],
})
export class CreatedbyDialogComponent implements OnInit, AfterViewInit {
  public fname: string = "John";
  public lname: string = "Deo";
  rotation: number = 0;
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  projectCode: any;
  agentCode: any;
  infoCode: any;
  userinfo: any;
  photos: any;
  userName: any;
  designation: any;
  city: any;
  email: any;
  image: any;
  phone: any;
  selectedgroup: any;
  subgrpList: any[];
  agentList: any[];
  defaultImg = "assets/images/user/usrbig1.jpg";
  ticketEditData: TicketEditDialogModel;
  action: string;
  agentForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreatedbyDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketService
  ) {
    this.action = data.action;
    if (this.action === "ticketList") {
      this.projectCode = data.ticket.proxyUserCode;
      console.log("this.Createdby", this.projectCode);
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getUserdetail(this.projectCode);
    } else if (this.action === "agentList") {
      this.agentCode = data.ticketeditdialog;
      console.log("agent>>>>>", this.agentCode);
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getAgentdetail(this.agentCode);
    } else if (this.action === "versionuser") {
      this.infoCode = data.infodialog;
      console.log("version>>>>>", this.infoCode);
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getInfodetail(this.infoCode);
    } else if (this.action === "agentProfileList") {
      this.projectCode = data.ticket.respAgentCode;
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getUserdetail(this.projectCode);
    } else if (this.action === "teamProfileList") {
      this.projectCode = data.ticket.teamLeadCode;
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getUserdetail(this.projectCode);
    } else if (this.action === "Attendance") {
      this.image = this.data.image;
    } else if (this.action === "ticketListLastBy") {
      this.projectCode = data.ticket.lastChangedBy;
      console.log("this.Createdby", this.projectCode);
      this.ticketEditData = new TicketEditDialogModel({});
      this.agentForm = this.createAgentForm();
      this.getUserdetail(this.projectCode);
    }
  }
  createAgentForm(): FormGroup {
    return this.fb.group({
      id: 0,
      subGroup: [this.ticketEditData.subGroup],
      // supportTypeName: [this.ticketEditData.supportTypeName]
    });
  }
  public ngOnInit(): void {}
  /*  user Info */
  async getUserdetail(userCode) {
    //console.log("PROJECTCODE",userCode);
    let reqObj = { userCode };
    const response = await this.ticketService.getUserdetail(reqObj);
    this.subgrpList = response.data;
    this.userinfo = this.subgrpList[0];
    this.photos = this.userinfo.photo;
    this.userName = this.userinfo.userName;
    this.designation = this.userinfo.designation;
    this.email = this.userinfo.email;
    this.phone = this.userinfo.mobile;
  } /*  Agent Info */
  async getAgentdetail(userCode) {
    //console.log("PROJECTCODE",userCode);
    let reqObj = { userCode };
    const response = await this.ticketService.getUserdetail(reqObj);
    this.subgrpList = response.data;
    this.userinfo = this.subgrpList[0];
    this.photos = this.userinfo.photo;
    this.userName = this.userinfo.userName;
    this.designation = this.userinfo.designation;
    this.email = this.userinfo.email;
    this.phone = this.userinfo.mobile;
  } /*   Info */
  async getInfodetail(userCode) {
    //console.log("PROJECTCODE",userCode);
    let reqObj = { userCode };
    const response = await this.ticketService.getUserdetail(reqObj);
    this.subgrpList = response.data;
    this.userinfo = this.subgrpList[0];
    this.photos = this.userinfo.photo;
    this.userName = this.userinfo.userName;
    this.designation = this.userinfo.designation;
    this.email = this.userinfo.email;
    this.phone = this.userinfo.mobile;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if (this.afterInit) {
      this.onSelColumns(this.columns);
    }
  }

  isChecked(col) {
    return (
      this.columns.find((c) => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
  rotateImg() {
    this.rotation += 90;
    if (this.rotation === 360) {
      // 360 means rotate back to 0
      this.rotation = 0;
    }
    const rimage = document.getElementById("rimage");
    rimage.style.transform = `rotate(${this.rotation}deg)`;
  }
}
