import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";
import { TicketService } from "../../../ticket/ticket.service";
import { CreatedbyDialogComponent } from "../createdby-dialog/createdby-dialog.component";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./info-dialog.component.html",
  styleUrls: ["./info-dialog.component.sass"],
})
export class InfoDialogComponent implements OnInit, AfterViewInit {
  public fname: string = "John";
  public lname: string = "Deo";
  infoData: TicketEditDialogModel;
  ticketCode: any;
  versionList: any[];
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    private ticketService: TicketService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ticketCode = data.ticketeditdialog;
    console.log("this.data", this.ticketCode);
    this.infoData = new TicketEditDialogModel({});
    this.getVersion(this.ticketCode);
    // console.log('this.data', this.data);
  }

  public ngOnInit(): void {}
  /** popup **/
  createdby(infodialog): void {
    console.log("InfoCode>>>>", infodialog);
    const dialogRef = this.dialog.open(CreatedbyDialogComponent, {
      width: "350px",
      height: "400px",
      disableClose: true,
      data: {
        infodialog: infodialog,
        action: "versionuser",
      },
    });
  }
  /* Version List */
  async getVersion(ticketCode) {
    this.isLoading = true;
    console.log("valueeeee", ticketCode);
    let reqObj = { ticketCode };
    const response = await this.ticketService.getVersion(reqObj);
    this.versionList = response.data;
    this.isLoading = false;
    // this.ticketTypeList = response.ticketTypeList;
    this.sortVersion();
  }
  /* List sorting function */
  sortVersion() {
    this.versionList = this.versionList || [];
    this.versionList.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return +new Date(b.createdOn) - +new Date(a.createdOn);
    });

    console.log("suzzzzyyy", this.versionList);
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if (this.afterInit) {
      this.onSelColumns(this.columns);
    }
  }

  isChecked(col) {
    return (
      this.columns.find((c) => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
}
