import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  AfterContentChecked,
  HostListener,
} from '@angular/core';
import {zip} from 'rxjs';
import {of, catchError} from 'rxjs';

import * as moment from 'moment';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import {TicketEditDialogModel} from './ticket-edit-dialog.model';
import {TicketTimeDialogComponent} from '../ticket-time-dialog/ticket-time-dialog.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {AgentDialogComponent} from '../agent-dialog/agent-dialog.component';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import {PopupDialogComponent} from '../../popup-dialog/popup-dialog.component';
import {TicketNewService} from '../../../ticket-new/ticket.service';
import {SharedService} from '../../../core/service/shared.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreatedbyDialogComponent} from '../createdby-dialog/createdby-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {AttachmentDialogComponent} from '../attachment-dialog/attachment-dialog.component';
import {Observable, Subscription} from 'rxjs';
import {AgentlistDialogComponent} from '../agentlist-dialog/agentlist-dialog.component';
import Swal from 'sweetalert2';
import {EditAgentDialogComponent} from '../edit-agent-dialog/edit-agent-dialog.component';
import {TicketHistoryDialogComponent} from '../time-history-dialog/time-history-dialog.component';
import {MatTabGroup} from '@angular/material/tabs';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MatChipInputEvent} from '@angular/material/chips';
import {ChipColor} from '../create-ticket-dialog/create-ticket-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DatePipe} from '@angular/common';
import {first, take} from 'rxjs/operators';
import {AgentHistoryDialogComponent} from '../agent-history-dialog/agent-history-dialog.component';

export interface ConditionElement {
  attachmentTypeName: string;
  file: string;
  remarks: string;
}

@Component({
  selector: 'app-ticket-time-dialog',
  templateUrl: './ticket-edit-dialog.component.html',
  styleUrls: ['./ticket-edit-dialog.component.scss'],
  providers: [DatePipe],
})
export class TicketEditDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('matTabGroup', {static: true}) matTabGroup: MatTabGroup;

  @ViewChild('fileSelect', {static: false})
  myFileInput: ElementRef;
  @Input() vRemarks: any;
  doSomething: any;
  isTimeSubmitted: boolean;
  isEditSubmitted: boolean;
  ticketIsBillable: any;
  existingResAgent: any = [];
  newResAgent: any[];
  minTargetDateFlag: any;

  ngOnChanges(changes: SimpleChanges) {
    this.doSomething(changes.vRemarks.currentValue);
    console.log('changed.', changes);
  }
  public Editor = ClassicEditor;
  ckConfig: any;
  accessFlag: any;
  dashboardFlag: any;
  /** Changed data**/
  ticstatus: string;
  ticmodule: string;
  ticClassificationName: string;
  ticticketType: string;
  ticResAgnt: string;
  ticPrioName: string;
  ticproposedHrs: string;
  ticremark: string;
  tictargetDate: string;
  respAgentName: string;
  subscription: Subscription;
  respAgentCodechanged: string;
  ticketPriorityName: string;
  ticketStatusCode: string;
  proposedHrss: any = '00';
  proposedMins: any = '00';
  ticketSubStatusCode: string;
  ticketStatusName: string;
  splitSubStatus: any;
  splitStatus: any;
  ticketTypeClassificationName: string;
  ticketTypeName: string;
  mptDue: string;
  moduleCode: string;
  moduleName: string;
  ticketTypeCode: string;
  moduleCodeChanged: string;
  ticketTypeClassificationCode: string;
  ticketPriorityCode: string;
  projectManagerName: string;
  customerManagerName: string;
  irtDue: string;
  datetime: string;
  minHours: any = 0;
  minMinutes: any = 0;

  targetDateChanged: string;
  versionc: any;
  targetDateChangedC: any;
  targetChangedd: any = '';
  splitHoursChangedd: any;
  proposedMincChangedd: any;
  proposedHrscChangedd: any;
  totalMinHours: any = 0;
  totalMinMins: any = 0;
  agentOldHoursTotal: any;
  vRemarksChanged: string;
  proposedHrsVer: any;
  splitHoursChanged: string;
  allocatedHrsc: string;
  itextChanged: any;
  ctextChanged: any;
  ntextChanged: any;
  detailLabel: any = [];

  isLinear = false;
  ticketStatusList: any;
  ticketPriList: any;
  tictypeList: any;
  ticketClsList: any;
  respAgentCodeList: any;
  modulechangedList: any;
  changedTicketType: any;
  removeAttachment: any = [];
  finalAttachmentList: any = [];
  base64File: string = null;
  filename: string = null;
  moduleList: any[];
  assignedUserList: any = [];
  ticketTypeList: any[];
  classficationList: any[];
  priorityList: any[];
  attachmentTypeList: any[];
  versionList: any = [];
  versiondata: any;
  version: any;
  editticket: boolean = false;
  changedFlag: boolean = false;
  isDistruct: boolean = true;
  agentButtonFlag: boolean;
  targetDate: any;
  resAgentNameList: any = [];
  minTargetDate: any = new Date();
  //minTargetDate: any;
  maxTargetDate: any;
  status: any;
  hasChange: boolean = false;
  checkProposedHrsMins: boolean = true;
  createdByName: any;
  createdOn: any;
  splitHours: any;
  ntextLabel: any;
  itextLabel: any;
  ctextLabel: any;
  Nbhdata: any;
  totalBhrsBooked: any;
  totalNbhrsBooked: any;
  statusList: any[];
  tags: any = [];
  selectable = true;
  removable = true;
  resAgentList: any[];
  ticketattachmentList: any = [];
  attachLabel: any = [];
  deleteattachmentList: any[];
  totalBillNbh: any = [];
  ticketagentListOld: any[];
  ticketagentList: any = [];
  ticketagentLocalList: any = [];
  clearUserAss: any;
  closedStatus: any;
  clearAttach: any[];
  subgrpList: any[];
  isDetails = false;
  withcustcheck = false;
  withcust = false;
  searchText: any;
  isLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  searchField: any;
  searchValue: any;
  ticketWorkLogComments: any = [];
  versionLog: any = [];
  basicDetail: any = [];
  ticketClear: any = [];
  isSubmitted: boolean = false;
  ticketList: any = [];
  dataSource = new MatTableDataSource(this.ticketList);
  pageSizeOptions = [5, 10, 15, 100];
  defaultImg = 'assets/images/user/usrbig1.jpg';
  searchTeamLead: any;
  currentUser: any;
  searchCriteria: any;
  searchCriteriaBody: any;
  noteLength: any;
  agentList: any[];
  withCustomerList: any[];
  newText: any;
  withInternalList: any[];
  notesList: any[];
  custparam: any;
  ownerCompanyInfo: any[];
  fileValue: any[];
  intermalList: any[];
  ownerGnoInfo: any[];
  listdataatt: any[];
  listData: ConditionElement[] = [];
  selectedgroup: any;
  disabledd: boolean = true;
  moddisable: boolean = false;
  noteData: boolean = false;
  noteEmpty: boolean = false;
  pmEdit: boolean = false;
  remarksplit: boolean = false;
  disableSplit: boolean = false;
  versiondisable: boolean = false;
  versionTarget: boolean = false;
  disableAllocated: boolean = false;
  scrolledToBottom = false;
  versionVal: string;
  proposedHrs: any;
  projectCode: any;
  isDisabledd: any;
  companyCode: any;
  projectName: any;
  isInternal: boolean = true;
  isInternalCust: boolean = true;
  agentButton: boolean = true;
  fileValidation: boolean = false;
  splitHrs = false;
  idTicketCode: any;
  @ViewChild('tagInput', {static: true})
  tagInput: ElementRef<HTMLInputElement>;
  /**  Worklog and comments props */
  logType = 'With Customer';
  internal = 'Internal';
  notes = 'Notes';
  resAgentValidation: boolean = false;

  ticketTags: any;
  tagCtrl = new FormControl();
  proposedHrsc: any;
  proposedMinc: any;
  referenceTicket: any;
  subject: any;
  targetDatecheck: any;
  ticketType: any;
  classificationCode: any;
  onticketType: any;
  gNo: any;
  userCode: any;
  respAgentCode: any;
  ticketSaved: any;
  tempSaved: any = {};

  //getting old values
  ticketPriorityNameold: any;
  ticketModuleold: any;
  ticketTypeOld: any;
  ticketClasfactionOld: any;
  ticketStatusOld: any;
  ticketResAgentOld: any;

  //label value
  ticketPriorityLabelOld: any;
  projectManagerCode: any;
  proxyManagerCode: any;
  customerManagerCode: any;
  action: string;
  createdDate: any;
  allTags: string[] = [];
  combinedDateString: any;
  combinedDate: any;
  createdTime: any;
  referenceTicketSubject: any;
  idVersion: any;
  ticketTypeClassifiCode: any;
  userInfo: any;
  resFormValue: any;
  ticket: any;
  hide: false;
  allocatedHrs: any;
  agentLabel: any = [];
  availableColors: ChipColor[] = [];
  //fileName: any;
  agentInfo: any = [];
  fileLocal: any = [];
  agentLocal: any = [];
  fileType: any;
  gNoUser: any;
  basevalue: any;
  tempfile: any = [];
  tempAttachment: any = [];
  tempAgent: any = [];
  tempResAgent: any = [];
  accessHide: boolean;
  accessTimeHide: boolean;
  tempList: Subscription;
  _htmlProperty: string;
  public ticketEditForm: FormGroup;
  public form: FormGroup;
  ticketEditData: TicketEditDialogModel;
  logList: any = {};
  minDate: any;
  groupedResponse: any;
  localTagsList: any = [];
  removeTagsList: any = [];
  agentVisibilityFlag: boolean;
  customerVisibilityFlag: boolean;
  closeDialog: boolean = false;
  filteredTags: Observable<string[]>;
  dateTime: any;
  removeAgent: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TicketEditDialogComponent>,
    private ticketService: TicketNewService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    public sharedService: SharedService,
    private datePipe: DatePipe, // private _sanitizer: DomSanitizationService
  ) {
    /* get temp attach array */
    // this.tempList = this.sharedService.getTicketAttach().subscribe((arr) => {
    //   this.tempAttachment = arr;
    //   console.log("back arrayyy ", this.tempAttachment);
    // });
    // this.ticketEditData = new TicketEditDialogModel({});
    this.minDate = new Date().toISOString().slice(0, 10);
    this.action = data.action;
    this.accessFlag = data.accessFlag ? data.accessFlag : 'N';
    this.dashboardFlag = data.dashboardFlag;

    this.initialLoad(data);
  }

  /* Set loaded data in edit form function  */
  public initialLoad(data): void {
    console.log('data', data);
    this.userInfo = data.userInfo;
    this.ownerCompanyInfo =
      this.userInfo[0].userInfo?.ownerCompanyInfo[0]?.ownerCompanyId;
    this.ownerGnoInfo = this.userInfo[0].userInfo?.ownerCompanyInfo[0]?.gNo;
    this.companyCode = this.userInfo[0].userInfo?.companyCode;
    this.gNoUser = this.userInfo[0].userInfo?.gNo;
    this.userCode = this.userInfo[0].userInfo?.userCode;
    this.agentVisibilityFlag = this.userInfo[0].agentVisibilityFlag;
    this.customerVisibilityFlag = this.userInfo[0].customerVisibilityFlag;
    /* Form data to set in props */
    this.respAgentCode = data.ticket.respAgentCode;
    console.log('initial Agent Code', this.respAgentCode);
    this.projectManagerCode = data.ticket.projectManager;
    this.proxyManagerCode = data.ticket.proxyManagerCode;
    this.moduleCode =  data.ticket.moduleCode;
    this.customerManagerCode = data.ticket.customerPm;
    this.projectCode = data.ticket.projectCode;
    this.projectName = data.ticket.projectName;
    this.idTicketCode = data.ticket.ticketCode;
    this.referenceTicket = data.ticket.referenceTicket;
    this.subject = data.ticket.hapAssetName;
    this.targetDatecheck = data.ticket.targetDate;

    this.createdTime = data.ticket.createdTime;
    this.createdDate = data.ticket.createdDate;
    console.log('createdDate', this.createdDate);
    this.gNo = data.ticket.gNo;
    this.referenceTicketSubject = data.ticket.referenceTicketSubject;

    this.ticketTypeClassifiCode = data.ticket.ticketTypeClassificationCode;
    this.ticketType = data.ticket.ticketTypeCode;

    this.ticketEditData = data.ticket;
   
    this.tags = this.data.ticket.ticketTags
      ? this.data.ticket.ticketTags.split(',')
      : [];
    this.ticketSaved = data.ticket;
    this.ticketEditForm = this.createTicketForm();

    this.ticketAccess();
    this.setCreatedDate();
    this.getAttachmentType();
    this.getModule(this.projectCode);
    this.getAssignedUsers(this.idTicketCode)
    this.getHAPStatus(this.projectCode, this.moduleCode)
  //  this.getResAgentedit(this.projectCode);
    this.getProjectSubgroup(this.projectCode);
  //  this.getClassification(this.projectCode, this.ticketType);
    this.groupTicketData();

    
  }
  groupTicketData() {
    this.spinner.show();
    (this.groupedResponse = zip(
      this.getAttachmentList(this.idTicketCode),
      this.getTotalBAndN(this.idTicketCode),
      this.getAgentList(this.idTicketCode),
      this.getVersion(this.idTicketCode),
    ).subscribe((values) => {
      if (
        !!values[0].error &&
        !!values[1].error &&
        !!values[2].error &&
        !!values[3].error
      ) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
        console.log('values', values);

        if (values[0] == undefined || values[0] == null) {
          this.ticketattachmentList = [];
        } else {
          this.ticketattachmentList = values[0];
        }
        if (values[1] == undefined || values[1] == null) {
          this.totalBillNbh = [];
        } else {
          this.totalBillNbh = values[1];
          this.getTotalTimeBooking();
        }
        if (values[2] == undefined || values[2] == null) {
          this.ticketagentList = [];
          this.ticketagentListOld = [];
        } else {
          this.ticketagentList = values[2];

          this.resAgentNameList = this.ticketagentList.filter(
            (item) => item.isRespAgent == 'Y',
          );

          console.log('resAgentNameList', this.resAgentNameList);
          this.resAgentValidation =
            this.ticketagentList.length > 0
              ? this.resAgentNameList.length > 0
                ? false
                : true
              : false;

          this.ticketagentListOld = this.ticketagentList;
        }
        if (values[3] == undefined || values[3] == null) {
          this.versionList = [];
        } else {
          this.versionList = values[3];
        //  this.getVersionDataModify();
        }
        this.agentHoursMinsTotal();
        this.targetDateMinMaxValidation();
      }
    })),
      (error) => {
        this.spinner.hide();
        console.log(error);
      };
    () => console.log('complete');
  }

  getTotalTimeBooking() {
    this.Nbhdata = this.totalBillNbh ? this.totalBillNbh[0] : '';
    this.totalBhrsBooked = this.Nbhdata ? this.Nbhdata.totalBrsHrsMins : '';
    this.totalNbhrsBooked = this.Nbhdata ? this.Nbhdata.totalNBrsHrsMins : '';
    this.ticketEditForm.patchValue({
      totalNbhrsBooked: this.totalNbhrsBooked,
      totalBhrsBooked: this.totalBhrsBooked,
      //  vRemarks: this.vRemarks,
    });
  }

  getVersionDataModify() {
    this.versionList = this.versionList || [];
    this.versionList.sort(function (a, b) {
      return +new Date(b.createdOn) - +new Date(a.createdOn);
    });
    this.versiondata = this.versionList[0];
    console.log(' this.versiondata', this.versiondata);
    //  this.versiondata = this.versionList.sort(
    //   (objA, objB) => Number(objB.createdOn) - Number(objA.createdOn),
    // );
    //   this.versiondata =  this.versionList.sortBy('idVersion');

    this.version = this.versiondata.idVersion;
    this.proposedHrs = this.versiondata.proposedHrsMins.split(':')[0];
    this.proposedMins = this.versiondata.proposedHrsMins.split(':')[1];
    if (this.versiondata.targetDate == null) {
      this.targetDate = this.versiondata.targetDate;
    } else {
      this.targetDate = new Date(this.versiondata.targetDate);
    }

    this.vRemarks = this.versiondata.remarks;
    //  this.createdByName = this.versiondata.createdByName;
    this.createdOn = this.versiondata.createdOn;
    this.splitHours = this.versiondata.splitHours;
    // this.ticketTypeList = response.ticketTypeList;
    this.ticketEditForm.patchValue({
      proposedHrs: this.proposedHrs,
      proposedMins: this.proposedMins,
      targetDate: this.targetDate,
      vRemarks: this.vRemarks,
      proposedByName: this.versiondata.createdByName,
      createdOn: moment(this.createdOn).format('MMM-DD-YYYY HH:mm:ss'),
      version: this.version,
      splitHours: this.checkSplitFlg(this.splitHours),
    });

    this.resFormValue = this.ticketEditForm.get('respAgentCode').value
      ? this.ticketEditForm.get('respAgentCode').value.respAgentCode
      : '';
  }
  public ngOnInit(): void {
    this.ckConfig = {
      toolbar: ['Bold', 'Italic', '|', 'bulletedList', 'numberedList'],
    };
    // this.targetvalueCheck();
    // this.ngOnChanges();
    this.logList = {};
    //this.scrollToBottom();

    this.loadWorklog(this.idTicketCode, this.logType);
    this.loadInternalCmts(this.idTicketCode, this.internal);
    this.loadNotes(this.idTicketCode, this.notes);

    // this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    //this.userInfo = this.currentUser.data;
    this.searchText = '';
    this.isLoading = false;
    this.pageSize = 10;
    this.pageIndex = 0;
    this.totalLength = 0;
    this.sortColumn = 'id';
    this.sortOrder = true;
    this.searchField = '';
    this.searchValue = '';
    this.searchCriteria = {
      page: this.pageIndex,
      size: this.pageSize,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      searchColumn: this.searchField,
      searchValue: this.searchValue,
    };

    window.onbeforeunload = () => this.ngOnDestroy();

    let dashboard = this.dashboardFlag ? this.dashboardFlag[0] : '';
    console.log('accessFlag', this.accessFlag);
    console.log('dashboardFlag', dashboard);
    let roleName = this.userInfo[0].roles[0];
    console.log('roleName', roleName);

    //   if(this.accessFlag == 'Y' && dashboard == ""){
    //     this.accessHide = false;
    //   }
    //   else{
    //   if(this.accessFlag == 'N' && dashboard == "myTeamFlag"){
    //    if(roleName === 'ROLE_PROFFESSIONAL_CUSTOMER'){
    //     this.accessHide = true;
    //   }
    //    else{
    //     this.accessHide = false;
    //   }
    //   }
    //   else{
    //     this.accessHide = true;
    //   }
    // }

    if (this.accessFlag == 'Y') {
      this.accessHide = false;
      this.accessTimeHide = true;
    } else if (this.accessFlag == 'N') {
      this.accessHide = true;
      this.accessTimeHide = false;
    }
  }
  onTabChanged(event) {
    console.log('event tab changed', event);
    this.scrollToBottom();
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  // scrollToBottom(): void {

  //   try {
  //     this.myScrollContainer.nativeElement.scrollTop =
  //       this.myScrollContainer.nativeElement.scrollHeight;
  //   } catch (err) {}
  // }

  scrollToBottom(): void {
    try {
      /**Add the condition**/
      if (!this.scrolledToBottom) {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      }
    } catch (err) {}
  }
  onScroll() {
    this.scrolledToBottom = true;
  }
  /*targetvalueCheck() {
    console.log("print targetDate What", this.targetDatecheck);
    this.targetDate = new Date(this.targetDatecheck);
    console.log("print targetDate Changges:)", this.targetDate);
  }*/
  statusSplit(event) {
    console.log('event', event);
    if (event.value.ticketStatusName.indexOf('-') > 0) {
      let split = event.value.ticketStatusName.split('-');
      this.splitStatus = split[0].trim();
      this.splitSubStatus = split[1].trim();
    } else {
      this.splitSubStatus = event.value.ticketStatusName;
      this.splitStatus = event.value.ticketStatusName;
    }
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  clearDropdown() {
    this.ticketEditForm.get('ticketTypeClassificationCode').reset();
    this.ticketEditForm.get('ticketPriorityCode').reset();
    this.ticketEditForm.get('ticketStatusCode').reset();
  }

  async onChangeBasicDetails(key, event) {
    if (key == 'module') {
      this.changedFlag = true;
      this.logList['module'] = event.value;  
     
    }
    if (key == 'ticketType') {
      this.changedFlag = true;
      this.clearDropdown();
      console.log('onchange valueeee', this.projectCode);
      console.log('onchange valueeee', event.value.ticketTypeCode);
      this.ticketIsBillable = event.value.isNonBillable;
      let ticketType = event.value.ticketTypeCode;
      this.changedTicketType = event.value.ticketTypeCode;
      let projectCode = this.projectCode;
      let reqObj = {projectCode, ticketType};
      const response = await this.ticketService.getClassification(reqObj);
      this.classficationList = response.transactions;
      //this.selectedData(this.projectCode, ticketType);
      this.logList['ticketType'] = event.value;
      this.priorityList = [];
      this.statusList = [];
    }
    if (key == 'classification') {
      this.ticketEditForm.get('ticketPriorityCode').reset();
      this.ticketEditForm.get('ticketStatusCode').reset();
      this.changedFlag = true;
      this.classificationCode = event.value.ticketTypeClassificationCode;
      console.log(
        'classification valllll fr,',
        event.value.ticketTypeClassificationCode,
      );
      console.log('classification valllll frswe,', this.changedTicketType);
      this.getPriority(
        this.projectCode,
        this.changedTicketType,
        this.classificationCode,
      );
      this.getStatus(
        this.projectCode,
        this.changedTicketType,
        this.classificationCode,
      );
      this.logList['ticketClassification'] = event.value;
    }
    if (key == 'priority') {
      this.changedFlag = true;
      this.logList['ticketPriority'] = event.value;
    }
    if (key == 'status') {
      //   this.targetDateMinMaxValidation();
      this.changedFlag = true;
      if (event.value.ticketStatusName.indexOf('-') > 0) {
        let split = event.value.ticketStatusName.split('-');
        this.splitStatus = split[0].trim();
        this.splitSubStatus = split[1].trim();
      } else {
        this.splitSubStatus = event.value.ticketStatusName;
        this.splitStatus = event.value.ticketStatusName;
      }
      this.logList['ticketStatus'] = event.value;
      this.closedStatus = event.value;
    }
    if (key == 'resAgent') {
      this.resFormValue = this.ticketEditForm.get('respAgentCode').value
        ? this.ticketEditForm.get('respAgentCode').value.respAgentCode
        : '';
      //  this.respAgentCode = 0;
      //  console.log('respAgentCode',this.respAgentCode);
      this.changedFlag = true;
      this.logList['resposibleAgent'] = event.value;

      /*
this.tempResAgent =this.logList;
        //this.changedFlag = true;
        let resAgentLocal ={
            idGNo: null,
            assignedSubGroupName: this.logList.resposibleAgent.subGroupName,
            idAssignedSubGroupCode: this.logList.resposibleAgent.subGroupCode,
            idAssignedSubGroup :{assignedSubGroupName: this.logList.resposibleAgent.subGroupName, idAssignedSubGroup: this.logList.resposibleAgent.subGroupCode},
            idTicketCode:"",
            idUserCode:{idUserCode: this.logList.resposibleAgent.respAgentCode, userName: this.logList.resposibleAgent.respAgentName, userProfileUrl: this.logList.resposibleAgent.respAgentUrl},
            userName: this.logList.resposibleAgent.respAgentName,
            userProfileUrl: this.logList.resposibleAgent.respAgentUrl,
            totalBookedBHrsMins: "00:00",
            totalBookedNBHrsMins: "00:00",
            allocatedHrs: "00",
            allocatedHrsMins: "00:00",
            changed: "Y",
            totalAllocated: "00:00",
            fromService: "L"
        };
        const isExist =  this.ticketagentList.filter(item => item.idUserCode == this.logList.resposibleAgent.respAgentCode);
        if(isExist.length == 0){
            const isExistLocal = this.ticketagentList.filter(item => item.fromService == 'L');
            if(isExistLocal.length > 0){
                this.ticketagentList = this.ticketagentList.filter(item => item.fromService != 'L');
            }
            this.agentLocal = resAgentLocal;
            this.ticketagentLocalList = [resAgentLocal, ...this.ticketagentList];
            this.ticketagentList = [resAgentLocal, ...this.ticketagentList];
            console.log("listtytytytyt",this.ticketagentList)
        }else{
            const isExistLocal1= this.ticketagentList.filter(item => item.fromService == 'L');
            if(isExistLocal1.length > 0){
                this.ticketagentList = this.ticketagentList.filter(item => item.fromService != 'L');
            }
        }




        console.log("local sweeeeeee resagent list", resAgentLocal);
        console.log("server agent list", this.ticketagentList);
*/

      //   this.agentHoursMinsTotal();
    }
  }

  /* Field on change  */
  onChangeFunc() {
    // console.log('onChangeFunction');
    // this.subscription =  this.ticketEditForm
    //   .get("moduleCode")
    //   .valueChanges.subscribe((moduleCode: string) => {
    //     this.modulechangedList = moduleCode;
    //     if (this.modulechangedList) {
    //       this.logList["module"] = this.modulechangedList;
    //     }
    //   });
    // this.subscription =  this.ticketEditForm
    // .get("ticketTypeCode")
    // .valueChanges.subscribe((ticketTypeCode: string) => {
    //   this.tictypeList = ticketTypeCode;
    //   if (this.tictypeList) {
    //     this.logList["ticketType"] = this.tictypeList;
    //   }
    // });
    // this.subscription =   this.ticketEditForm
    // .get("ticketTypeClassificationCode")
    // .valueChanges.subscribe((ticketTypeClassificationCode: string) => {
    //   this.ticketClsList = ticketTypeClassificationCode;
    //   if (this.ticketClsList) {
    //     this.logList["ticketClassification"] = this.ticketClsList;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("ticketPriorityCode")
    // .valueChanges.subscribe((ticketPriorityCode: string) => {
    //   this.ticketPriList = ticketPriorityCode;
    //   if (this.ticketPriList) {
    //     this.logList["ticketPriority"] = this.ticketPriList;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("ticketStatusCode")
    // .valueChanges.subscribe((ticketStatusCode: string) => {
    //   this.ticketStatusList = ticketStatusCode;
    //   if (this.ticketStatusList) {
    //     this.logList["ticketStatus"] = this.ticketStatusList;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("respAgentCode")
    // .valueChanges.subscribe((respAgentCode: string) => {
    //   this.respAgentCodeList = respAgentCode;
    //   if (this.respAgentCodeList) {
    //     this.logList["resposibleAgent"] = this.respAgentCodeList;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("projectManagerName")
    // .valueChanges.subscribe((projectManagerName: string) => {
    //   this.projectManagerName = projectManagerName;
    //   if (this.projectManagerName) {
    //     this.logList["pm"] = this.projectManagerName;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("customerManagerName")
    // .valueChanges.subscribe((customerManagerName: string) => {
    //   this.customerManagerName = customerManagerName;
    //   if (this.customerManagerName) {
    //     this.logList["cpm"] = this.customerManagerName;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("createdByName")
    // .valueChanges.subscribe((createdByName: string) => {
    //   this.createdByName = createdByName;
    //   if (this.createdByName) {
    //     this.logList["createdByName"] = this.createdByName;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("irtDue")
    // .valueChanges.subscribe((irtDue: string) => {
    //   this.irtDue = irtDue;
    //   if (this.irtDue) {
    //     this.logList["irtDue"] = this.irtDue;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("datetime")
    // .valueChanges.subscribe((datetime: string) => {
    //   this.datetime = datetime;
    //   if (this.datetime) {
    //     this.logList["datetime"] = this.datetime;
    //   }
    // });
    // this.subscription = this.ticketEditForm
    // .get("mptDue")
    // .valueChanges.subscribe((mptDue: string) => {
    //   this.mptDue = mptDue;
    //   if (this.mptDue) {
    //     this.logList["mptDue"] = this.mptDue;
    //   }
    // });
    // this.ticketEditForm
    //   .get("ticketTags")
    //   .valueChanges.subscribe((ticketTags: string) => {
    //     this.ticketTags = ticketTags;
    //   });
    // this.subscription = this.ticketEditForm
    //   .get("totalBhrsBooked")
    //   .valueChanges.subscribe((totalBhrsBooked: string) => {
    //     this.totalBhrsBooked = totalBhrsBooked;
    //     if (this.totalBhrsBooked) {
    //       this.logList["billableBooked"] = this.totalBhrsBooked;
    //     }
    //   });
    //   this.subscription =  this.ticketEditForm
    //   .get("totalNbhrsBooked")
    //   .valueChanges.subscribe((totalNbhrsBooked: string) => {
    //     this.totalNbhrsBooked = totalNbhrsBooked;
    //     if (this.totalNbhrsBooked) {
    //       this.logList["totalNbhrsBooked"] = this.totalNbhrsBooked;
    //     }
    //   });
    //   this.subscription =  this.ticketEditForm
    //   .get("createdByName")
    //   .valueChanges.subscribe((createdByName: string) => {
    //     this.createdByName = createdByName;
    //     if (this.createdByName) {
    //       this.logList["createdByName"] = this.createdByName;
    //     }
    //   });
    //   this.subscription =  this.ticketEditForm
    //   .get("createdOn")
    //   .valueChanges.subscribe((createdOn: string) => {
    //     this.createdOn = createdOn;
    //     if (this.createdOn) {
    //       this.logList["createdOn"] = this.createdOn;
    //     }
    //   });
    // this.ticketEditForm
    //   .get("targetDate")
    //   .valueChanges.subscribe((targetDate: string) => {
    //     this.targetDateChangedC = targetDate;
    //     if (this.targetDateChangedC) {
    //         this.ticketEditForm.controls["vRemarks"].setValue("");
    //         this.logList["targetDate"] = this.targetDateChangedC;
    //     }
    //   });
    // this.subscription =  this.ticketEditForm
    //   .get("splitHours")
    //   .valueChanges.subscribe((splitHours: string) => {
    //     this.splitHoursChanged = splitHours ? "Y" : "N";
    //     if (this.splitHoursChanged) {
    //       this.logList["splitHours"] = this.splitHoursChanged;
    //     }
    //   });
    /* this.ticketEditForm
      .get("splitHours")
      .valueChanges.subscribe((splitHours: string) => {
        this.splitHoursChanged = splitHours ;
        console.log("Kill this Love",this.splitHoursChanged);
      });*/
    /*this.ticketEditForm
      .get("allocatedHrs")
      .valueChanges.subscribe((allocatedHrs: string) => {
        this.allocatedHrsc = allocatedHrs;
      });*/
  }
  formatDate(d) {
    let dd: any;
    let mm: any;
    let formatDate = new Date(d);
    dd = formatDate.getDate();
    mm = formatDate.getMonth() + 1;
    let yyyy = formatDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return (d = dd + '-' + mm + '-' + yyyy);
  }
  pad(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }
  padding(value) {
    let dummyValue = value.toString();
    if (dummyValue.length < 2) {
      return '0' + value;
    } else {
      return value;
    }
  }
  // onSearchChange(key, searchValue) {

  //   let agentOldHoursTotal = this.ticketagentList.reduce(
  //     (accumulator, currentValue) => {
  //       return (
  //         accumulator +
  //         this.sharedService.convertDurationToHours(
  //           currentValue.allocatedHrsMins
  //         )
  //       );
  //     },
  //     0
  //   );
  //   var minutes = agentOldHoursTotal % 60;
  //   var hours = (agentOldHoursTotal - minutes) / 60;
  //   this.minHours = this.padding(hours);
  //   this.minMinutes = this.padding(minutes);
  //   console.log("minHours", this.minHours);
  //   console.log("minMins", this.minMinutes);
  //   console.log("agentHoursTotal", this.minHours);
  //   if (key == "hours") {
  //     this.changedFlag = true;
  //     this.ticketEditForm.controls["vRemarks"].setValue("");
  //     Validators.required;
  //     this.proposedHrsc = searchValue;

  //     (this.proposedHrscChangedd = `${
  //       this.proposedHrsc
  //         ? this.padding(this.proposedHrsc)
  //         : this.padding(this.proposedHrs)
  //     }:${
  //       this.proposedMinc
  //         ? this.padding(this.proposedMinc)
  //         : this.padding(this.proposedMins)
  //     }`)

  //   }
  //   if (key == "minutes") {
  //     this.changedFlag = true;
  //     console.log("proposed minsss>>>", searchValue);

  //     this.ticketEditForm.controls["vRemarks"].setValue("");
  //     Validators.required;

  //     // let agentOldMinutesTotal = this.ticketagentList.reduce((accumulator, currentValue) =>{
  //     // return accumulator + this.sharedService.convertMinutes(currentValue.allocatedHrsMins);
  //     // }, 0);

  //     //  this.minMinutes =  this.padding(agentOldMinutesTotal);
  //     //  console.log('agentTotal',this.minMinutes);
  //     this.proposedMinc = searchValue;
  //     (this.proposedHrscChangedd = `${
  //       this.proposedHrsc
  //         ? this.padding(this.proposedHrsc)
  //         : this.padding(this.proposedHrs)
  //     }:${
  //       this.proposedMinc
  //         ? this.padding(this.proposedMinc)
  //         : this.padding(this.proposedMins)
  //     }`)
  //   }
  // }

  onTargetChange(targetValue) {
    this.targetDateMinMaxValidation();
    this.changedFlag = true;
    console.log('targetValue', targetValue.value);
    this.targetChangedd = new Date(targetValue.value);
    this.targetDateChangedC = new Date(targetValue.value);
    this.logList['targetDate'] = this.targetChangedd;
    this.ticketEditForm.controls['vRemarks'].setValue('');
    Validators.required;
  }
  onvsplitChange(event): void {
    // if(event.checked){
    //   this.splitHoursChanged = "Y"
    // }
    // else{
    //   this.splitHoursChanged = "N";
    // }
    // this.splitHoursChanged = splitHours ? "Y" : "N";
    // if (this.splitHoursChanged) {
    //   this.logList["splitHours"] = this.splitHoursChanged;
    // }
    // this.logList["splitHours"] = this.splitHoursChanged;
    // console.log("Kill this love>>>", this.splitHoursChanged);
    // this.ticketEditForm.controls["vRemarks"].setValue("");
    Validators.required;
    if (event.checked == false) {
      Swal.fire({
        title: 'Information',
        text: 'Do you want to remove the agent-level Hours allocation? Note: All the hours allocated to agents will be removed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then((result) => {
        console.log('result', result);
        if (result.isConfirmed) {
          this.changedFlag = true;
          this.ticketEditForm.controls['splitHours'].setValue('');
          this.splitHoursChanged = 'N';
          this.logList['splitHours'] = this.splitHoursChanged;
          console.log('split changed', this.splitHoursChanged);
          this.ticketEditForm.controls['vRemarks'].setValue('');
        } else {
          this.changedFlag = false;
          this.ticketEditForm.controls['splitHours'].setValue('Y');
          this.splitHoursChanged = 'Y';
          this.logList['splitHours'] = this.splitHoursChanged;
          console.log('split  not changed', this.splitHoursChanged);
        }
      });
    } else {
      this.changedFlag = true;
      this.splitHoursChanged = 'Y';
      this.logList['splitHours'] = this.splitHoursChanged;
      console.log('split changed', this.splitHoursChanged);
      this.ticketEditForm.controls['vRemarks'].setValue('');
    }
  }
  onvRemarksChange(vRemarks: string): void {
    // console.log("vremarkss>>>", vRemarks);
    this.changedFlag = true;
    this.vRemarksChanged = vRemarks;
    this.logList['vRemarks'] = this.vRemarksChanged;
    this.versionc = this.version;
    // this.targetChangedd = new Date(this.targetDateChangedC);
    this.splitHoursChangedd = this.splitHoursChanged;
    // (this.proposedHrscChangedd = `${
    //   this.proposedHrsc
    //     ? this.padding(this.proposedHrsc)
    //     : this.padding(this.proposedHrs)
    // }:${
    //   this.proposedMinc
    //     ? this.padding(this.proposedMinc)
    //     : this.padding(this.proposedMins)
    // }`),
    /* this.proposedHrscChangedd = this.proposedHrsc;
    this.proposedMincChangedd = this.proposedMinc;*/
    Validators.required;
  }

  /* Customer = 2, Internal = 0, Notes = 1 on change method */

  addComment(cmt, type) {
    this.changedFlag = true;
    if (type == '0') {
      this.itextChanged = cmt;
    }
    if (type == '1') {
      console.log(cmt.target.value, 'cmt notes');

      this.ntextChanged = cmt.target.value;
    }
    if (type == '2') {
      this.ctextChanged = cmt;
    }
  }

  /** Agent info modal **/
  loadAgentInfo(ticketeditdialog): void {
    console.log('InfoCode>>>>', ticketeditdialog);
    const dialogRef = this.dialog.open(CreatedbyDialogComponent, {
      width: '350px',
      height: '400px',
      disableClose: true,
      data: {
        ticketeditdialog: ticketeditdialog,
        action: 'agentList',
      },
    });
  }

  /** Agent UserLog History modal **/

  viewAgentHistory(ticketagent): void {
    const dialogRef = this.dialog.open(AgentHistoryDialogComponent, {
      width: '1100px',
      height: '500px',
      disableClose: true,
      data: {
        agentData: ticketagent,
      },
    });
  }
  /**Validation**/
  public validateTopField(
    userCode,
    respAgentCode,
    projectManagerCode,
    proxyManagerCode,
    customerManagerCode,
  ) {
    console.log('top four field validation', userCode, respAgentCode);
    if (
      userCode == respAgentCode ||
      userCode == projectManagerCode ||
      userCode == proxyManagerCode ||
      userCode == customerManagerCode
    ) {
      this.moddisable = false;
    } else {
      console.log('false', userCode, respAgentCode);
      this.moddisable = true;
    }
  }
  public resAgentByPm(
    userCode,
    projectManagerCode,
    proxyManagerCode,
    respAgentCode,
  ) {
    if (
      userCode == projectManagerCode ||
      userCode == proxyManagerCode ||
      userCode == respAgentCode
    ) {
      this.pmEdit = false;
    } else {
      this.pmEdit = true;
    }
  }
  /* public onAllocatedHrs(value){


  }*/
  public versionValidate(
    userCode,
    projectManagerCode,
    proxyManagerCode,
    respAgentCode,
  ) {
    // console.log("aGENTTTTTTT", userCode, projectManagerCode, respAgentCode);
    if (
      userCode == projectManagerCode ||
      userCode == respAgentCode ||
      userCode == proxyManagerCode
    ) {
      console.log(
        'agen en varala',
        userCode,
        projectManagerCode,
        proxyManagerCode,
        respAgentCode,
      );
      this.remarksplit = false;
      this.disableSplit = false;
      this.versiondisable = true;
      this.versionTarget = false;
      this.agentButton = true;
      this.ticketEditForm.controls['proposedHrs'].enable();
      this.ticketEditForm.controls['proposedMins'].enable();
      this.ticketEditForm.controls['vRemarks'].enable();
      this.ticketEditForm.controls['targetDate'].enable();
      // this.disableAllocated=true;
      /*  this.versionVal = "proposedHrs";
      this.versionVal = "targetDate";
      this.versionVal = "vRemarks"; */
      this.versionVal = 'allocatedHrs';
    } else {
      console.log(
        'agen en varala false',
        userCode,
        projectManagerCode,
        proxyManagerCode,
        respAgentCode,
      );
      // console.log("agen en else");
      this.remarksplit = true;
      this.agentButton = false;
      this.disableSplit = true;
      this.versiondisable = false;
      this.versionTarget = true;
      this.ticketEditForm.controls['proposedHrs'].disable();
      this.ticketEditForm.controls['proposedMins'].disable();
      this.ticketEditForm.controls['vRemarks'].disable();
      this.ticketEditForm.controls['targetDate'].disable();
    }
  }
  public versionValidateView(
    userCode,
    projectManagerCode,
    proxyManagerCode,
    respAgentCode,
  ) {
    // console.log("aGENTTTTTTT", userCode, projectManagerCode, respAgentCode);
    if (
      userCode == projectManagerCode ||
      userCode == respAgentCode ||
      userCode == proxyManagerCode
    ) {
      this.versiondisable = true;
    } else {
      this.versiondisable = false;
    }
  }
  onCheckValidation(event): void {
    if (event) {
      console.log('validate onchangeeeeeeee', event);
      vRemarks: new FormControl(this.vRemarks, [Validators.required]);
    } else {
      console.log('clear');
      vRemarks: new FormControl(this.vRemarks.clearValidators());
    }
  }

  public validatework(companyCode, ownerCompanyInfo, ownerGno, gNo) {
    console.log('enter   ...............');
    console.log('validate', companyCode, ownerCompanyInfo, ownerGno, gNo);

    if (companyCode == ownerCompanyInfo && gNo == ownerGno) {
      console.log('view if');
      this.isInternalCust = true;
    } else {
      this.isInternalCust = false;
      // console.log("view else", this.isInternal);
    }
  }

  /* Ticket tab selection function  */
  selectTab(tab) {
    console.log('tab', tab);
    const element = document.getElementById(tab);
    element.scrollIntoView();
    /*document
      .querySelector(`#${tab}`)
      .scrollIntoView({ behavior: "smooth", block: "center" });*/
  }

  /* Build Ticket Form Function */
  createTicketForm(): FormGroup {
    return this.fb.group({
      id: [this.ticketEditData.id],
      date: [this.ticketEditData.date],
      idTicketCode: [this.ticketEditData.idTicketCode],
      retrieveHours: [this.ticketEditData.retrieveHours],
      // itext: [this.ticketEditData.itext],
      //  ntext: [this.ticketEditData.ntext],
      ticketTags: [this.ticketEditData.ticketTags],
      respAgentCode: [
        {respAgentCode: this.ticketEditData.respAgentCode},
        Validators.required,
      ],
      referenceTicket: [this.ticketEditData.referenceTicket],
      supportStartTime: [this.ticketEditData.supportStartTime],
      hours: [this.ticketEditData.hours],
      supportEndTime: [this.ticketEditData.supportEndTime],
      moduleCode: [{moduleCode: this.ticketEditData.moduleCode}],
      ticketTypeCode: [{ticketTypeCode: this.ticketEditData.ticketTypeCode}],
      ticketTypeClassificationCode: [
        {
          ticketTypeClassificationCode:
            this.ticketEditData.ticketTypeClassificationCode,
        },
        Validators.required,
      ],
      ticketPriorityCode: [
        {ticketPriorityCode: this.ticketEditData.ticketPriorityCode},
        Validators.required,
      ],
      ticketStatusCode: [
        {
          ticketStatusCode: this.ticketEditData.ticketStatusCode,
          ticketSubStatusCode: this.ticketEditData.ticketSubStatusCode,
          ticketStatusName: this.ticketEditData.statusName,
        },
        Validators.required,
      ],
      totalBookedBHours: [this.ticketEditData.totalBookedBHours],
      totalBookedNBHours: [this.ticketEditData.totalBookedNBHours],
      customerManagerName: [
        {value: this.ticketEditData.customerManagerName, disabled: true},
      ],
      projectManagerName: [
        {value: this.ticketEditData.projectManagerName, disabled: true},
      ],
      proxyManagerName: [
        {value: this.ticketEditData.proxyManagerName, disabled: true},
      ],
      createdByName: [
        {value: this.ticketEditData.proxyUserName, disabled: true},
      ],
      proposedByName: [{value: '', disabled: true}],
      createdOn: [{value: this.ticketEditData.createdOn, disabled: true}],
      attachmentTypeCode: [this.ticketEditData.attachmentTypeCode],
      splitHours: [this.checkSplitFlg(this.ticketEditData.splitHours)],
      file: [''],
      irtDue: [
        {
          value: moment(this.ticketEditData.irtDue).format(
            'MMM-DD-YYYY HH:mm:ss',
          ),
          disabled: true,
        },
      ],
      mptDue: [{value: this.ticketEditData.mptDue, disabled: true}],
      totalBhrsBooked: [
        {value: this.ticketEditData.totalBhrsBooked, disabled: true},
      ],
      totalNbhrsBooked: [
        {value: this.ticketEditData.totalNbhrsBooked, disabled: true},
      ],
      datetime: [{value: '', disabled: true}],
      remarks: [this.ticketEditData.remarks],
      idAssignedSubGroup: [this.ticketEditData.idAssignedSubGroup],
      idUserCode: [this.ticketEditData.idUserCode],
      proposedHrs: [{value: this.ticketEditData.proposedHrs, disabled: true}],
      proposedMins: [{value: this.ticketEditData.proposedMins, disabled: true}],
      targetDate: [
        {value: new Date(this.ticketEditData.targetDate), disabled: true},
      ],
      vRemarks: [
        {value: this.ticketEditData.vRemarks, disabled: true},
        this.noWhitespaceValidator,
      ],
      version: [this.ticketEditData.version],
      allocatedHrs: [this.ticketEditData.allocatedHrs],
      //allocatedHrs: this.fb.array([]),
    });
  }
  // get form controls
  get f() {
    return this.ticketEditForm.controls;
  }

  noWhitespaceValidator(control: FormControl) {
    // let userInput = control.value;
    // console.log("user remark",userInput);
    // if (userInput && userInput.length > 0) {
    //   if (userInput[0] === " ") {
    //     return {
    //       forbiddenSpace: {
    //         value: userInput,
    //       },
    //     };
    //   }
    // } else {
    //   return null;
    // }

    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }

  sortData(e: any) {
    this.sortColumn = e.active;
    this.sortOrder = e.direction == 'asc' ? true : false;
    // this.loadWorklog(idTicketCode, logType);
  }

  /** work Log With Customer **/
  async loadWorklog(idTicketCode, logType) {
    console.log('f.ticketStatusCode.value', this.f.ticketStatusCode.value);
    console.log('with Internal');
    this.custparam = {
      idTicketCode: idTicketCode,
      logType: logType,
    };

    // let reqObj = {idTicketCode, logType };
    const response = await this.ticketService.withCustomer(this.custparam);
    this.withCustomerList = response.data;
    //  console.log("suzzy before", this.withCustomerList);
    this.sort();
  }

  /* List sorting function */
  sort() {
    this.withCustomerList = this.withCustomerList || [];
    this.withCustomerList.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return +new Date(a.idLoggedOn) - +new Date(b.idLoggedOn);
    });

    console.log('suzzzzyyy', this.withCustomerList);
  }
  /* Load internal comments */
  async loadInternalCmts(idTicketCode, internal) {
    console.log('with Internal');
    this.custparam = {
      idTicketCode: idTicketCode,
      logType: internal,
    };
    // let reqObj = {idTicketCode, logType };
    const response = await this.ticketService.withCustomer(this.custparam);
    this.intermalList = response.data;
    this.sortInternal();
  }
  /* List sorting function */
  sortInternal() {
    this.intermalList = this.intermalList || [];
    this.intermalList.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return +new Date(a.idLoggedOn) - +new Date(b.idLoggedOn);
    });

    console.log('suz', this.intermalList);
  }
  /* Load internal notes */
  async loadNotes(idTicketCode, notes) {
    console.log('with Internal');
    this.custparam = {
      idTicketCode: idTicketCode,
      logType: notes,
    };
    // let reqObj = {idTicketCode, logType };
    const response = await this.ticketService.withCustomer(this.custparam);
    this.notesList = response.data;
    let notesDa = this.notesList;
    this.loadNotesRes(notesDa);
  }

  /* Notes comment response function */
  loadNotesRes(notesDa) {
    this.noteLength = notesDa;
    if (this.noteLength.length > 0) {
      console.log('NOtes text length');
      this.noteData = true;
      this.noteEmpty = false;
      this.ntextLabel = this.noteLength[0].text;
    } else {
      console.log('NOtes text empty');
      this.noteEmpty = true;
      this.noteData = false;
      this.ntextLabel = '';
    }
  }
  /* Load Reference ticket data function */
  async loadData(referenceTicket) {
    /** spinner start */
    this.spinner.show();
    let myListFlag = 'Y';
    this.isLoading = true;
    this.ticketList = [];
    this.dataSource.data = [];
    // this.dataSource.data = [];
    this.searchCriteria = {
      pageNo: this.pageIndex,
      sortOrder: this.sortOrder,
      sortColumn: this.sortColumn,
      pageSize: this.pageSize,
      searchColumn: this.searchField,
      searchValue: this.searchValue,
      action: 'GetListWithPage',
    };
    this.searchCriteriaBody = {myListFlag, idTicketCode: referenceTicket};
    const response = await this.ticketService.getListRef(
      this.searchCriteria,
      this.searchCriteriaBody,
    );

    console.log('response?.body>>', response?.body);
    if (response?.body?.success) {
      this.totalLength = response?.body?.totalCount;
      this.ticketList = response?.body?.transactions;
      // this.dataSource.data = this.ticketList;
      this.dataSource.data = this.ticketList;
      //this.filteredData = this.ticketList;
      this.isLoading = false;
      this.spinner.hide();
      this.refLink(this.ticketList);
    } else {
      this.totalLength = 0;
      this.ticketList = [];
      //this.filteredData = this.ticketList;
      this.dataSource.data = this.ticketList;
      this.isLoading = false;
      this.spinner.hide();
      this.sharedService.showNotification(
        'snackbar-danger',
        response?.body?.message,
        'bottom',
        'center',
      );
    }
  }
  /* Load edit ticket data function */
  async editloadData(idTicketCode) {
    this.isEditSubmitted = true;
    console.log('idddddddddyufsfu', idTicketCode);
    let ticketCode = idTicketCode;
    console.log('view to edit>>>>>>>>', ticketCode);

    // const response =  this.ticketService.getLockTable(ticketCode);

    const response = await this.ticketService.getLockTable(ticketCode);

    // let responselock = error?.response;
    console.log('response dddd', response?.body?.success);
    if (response?.body?.success == true) {
      this.isEditSubmitted = false;
      const dialogRef = this.dialog.open(TicketEditDialogComponent, {
        data: {
          userInfo: this.userInfo,
          ticket: this.ticketSaved,
          action: 'update',
        },
        width: '1420px',
        height: '620px',
        disableClose: true,
      });
    } else {
      this.isEditSubmitted = false;
      this.sharedService.showNotification(
        'snackbar-danger',
        response?.body?.message,
        'bottom',
        'center',
      );
    }
  }
  /** agent histroy ticket view function **/
  detailsBookingTime(value, ticketagent) {
    let proposedHours = this.ticketEditForm.get('proposedHrs').value;
    let proposedMinutes = this.ticketEditForm.get('proposedMins').value;

    let agent = {ticketagent, value};
    const dialogRef = this.dialog.open(TicketHistoryDialogComponent, {
      data: {
        flag: 'N',
        createdOnDate: this.createdDate,
        ticketAgentList: this.ticketagentList,
        proposedHours: proposedHours,
        proposedMinutes: proposedMinutes,
        ticketEditDialog: agent,
        subject: this.subject,
      },
      width: '95%',
      height: '95%',
      // disableClose: true
    });
  }
  filterDropDowns(searchValue: any) {
    setTimeout(() => (this.searchTeamLead = ''), 2000);
  }
  totalBookingTime(value, ticketData) {
    if (!this.isInternalCust) {
      console.log('true');
    } else {
      let bookingData = {value, ticketData};

      const dialogRef = this.dialog.open(TicketHistoryDialogComponent, {
        data: {
          flag: 'Y',
          totalTimeBookingData: bookingData,
          subject: this.subject,
        },
        width: '95%',
        height: '95%',
      });
    }
  }
  /** Reference ticket view function **/
  refLink(ticket): void {
    console.log('Ref Link', ticket);
    const dialogRef = this.dialog.open(TicketEditDialogComponent, {
      data: {
        userInfo: this.userInfo,
        ticket: ticket[0],
        action: 'reference',
      },
      width: '1420px',
      height: '620px',
      disableClose: true,
    });
  }
  /* View dialog close */
  closeViewDialog(): void {
    this.dialogRef.close({action: this.action, flag: this.closeDialog});
  }
  /* Edit dialog close */
  closeEditDialog(idTicketCode): void {
    if (this.changedFlag) {
      // Swal.fire({
      //   title: "Information",
      //   text: "Your changes won’t be saved. We won’t be able to save your data if you move away from this page.",
      //   //html: `${name[0]} <br> ${name[1]}`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   cancelButtonText: "No",
      //   confirmButtonText: "Yes",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //    // this.dialogRef.close({ action: "update" });

      //   }
      //   else{

      //   }
      // });
      this.isDistruct = false;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: '320px',
        data: {
          name: 'Your changes won’t be saved',
          msg: 'We won’t be able to save your data if you move away from this page.',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        console.log('res event', res);
        if (res.event === 'Yes') {
          this.unLockTable(idTicketCode);
          this.dialogRef.close({action: 'close', flag: this.closeDialog});
          this.changedFlag = false;
        }

        this.isDistruct = true;
      });
    } else {
      this.dialogRef.close({action: 'close', flag: this.closeDialog});
      this.isDistruct = true;
      //this.refreshData()
    //  this.unLockTable(idTicketCode);

      // console.log("idTicketCode", idTicketCode);
      // let ticketCode = idTicketCode;
      // console.log("close", ticketCode);

      // // const response =  this.ticketService.getLockTable(ticketCode);

      // const response = await this.ticketService.getUnLockTable(ticketCode);

      // // let responselock = error?.response;
      // console.log("response dddd", response?.body?.success);
      // if (response?.body?.success == true) {
      //   this.dialogRef.close({ action: this.action, response });
      //   this.isDistruct = true;
      // }
    }
    //   this.dialog.closeAll();
  }

  /* allocatedHrs: [this.ticketEditData.allocatedHrs,
           [Validators.min(1),
            (control: AbstractControl) => Validators.max(Number(this.ticketEditData.allocatedHrs))(control)]],*/
  /* Remove tag function */
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    const localTagIndex = this.localTagsList.indexOf(tag);
    if (index >= 0) {
      let removeTags = this.tags.at(index);
      console.log('remove tag', removeTags);
      this.removeTagsList.push(removeTags);
      this.allTags.push(tag);
      this.tags.splice(index, 1);
    }
    if (localTagIndex >= 0) {
      this.localTagsList.splice(localTagIndex, 1);
      this.removeTagsList.splice(this.removeTagsList.indexOf(tag), 1);
    }
  }
  /* Split flag check function  */
  checkSplitFlg(value) {
    console.log('splitHours>>', value);
    if (value == 'Y') {
      return value;
    } else {
      return '';
    }
  }
  async unLockTable(idTicketCode) {
    console.log('idTicketCode', idTicketCode);
    let ticketCode = idTicketCode;
    console.log('close', ticketCode);

    // const response =  this.ticketService.getLockTable(ticketCode);

    const response = await this.ticketService.getUnLockTable(ticketCode);

    // let responselock = error?.response;
    console.log('response dddd', response?.body?.success);
    if (response?.body?.success == true) {
      //  this.dialogRef.close({ action: this.action, response });
      //   this.isDistruct = true;
    }
  }
  /*  onchecked(value) {
        console.log("Onselect splitHours>>",value.checked);
        if (value.checked == true) {
            this.agentButton=true;
            return value;

        } else {
            this.agentButton=false;
            return "";
        }
    }*/

  /* Created Date and Time set */
  setCreatedDate() {
    var date = this.createdDate,
      time = this.createdTime;
    var momentObj = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm:ss');
    var dateTime = momentObj.format('MMM-DD-YYYY HH:mm:ss');
    this.dateTime = new Date(momentObj.format('YYYY-MM-DD HH:mm:ss'));
    console.log('dateTime', this.dateTime);
    this.ticketEditForm.patchValue({
      datetime: dateTime,
    });
  }

  /* Module dropdown */
  async getModule(projectCode) {
    let reqObj = {projectCode};
    const response = await this.ticketService.getModule(reqObj);
    this.moduleList = response.moduleList;

    this.moduleList = this.moduleList.map((item) => {
      return {
        ...item,
        moduleCode: parseInt(item.moduleCode), 
      };
    });
    this.ticketTypeList = response.ticketTypeList;

    let filterType = this.ticketTypeList.filter(
      (item) => item.ticketTypeCode == this.ticketType,
    );
    //this.ticketIsBillable = filterType[0].isNonBillable;
  }

  async getAssignedUsers( idTicketCode) {
    //let reqObj = { ticketCode };
    let ticketCode=idTicketCode;
    const response = await this.ticketService.getAssignedUsers(ticketCode);
    console.log('res',response);
    this.assignedUserList=response.data;
    // for (let i = 0; i < this.assignedUserList.length; i++) {
    //   const element = this.assignedUserList[i];
    //   console.log('element',element)
    //   if (element.userFlag === 'M') {
    //     this.assignedUserList=element;
    //   }else if(element.userFlag === 'H'){
    //   this.assignedUserList=element;
    //   }else{

    //   }
    // }
   // let assignUser =this.assignedUserList.filter(u =>u.userFlag == 'M');
   // this.assignedUserList=assignUser;
    //console.log('asassignUsers',assignUser)
  }
  /* Ticket classification dropdown */
  async getClassification(projectCode, ticketType) {
    let reqObj = {projectCode, ticketType};
    const response = await this.ticketService.getClassification(reqObj);
    this.classficationList = response.transactions;
    this.selectedData(projectCode, ticketType);
  }
  // async getClassificationEdit(ticketTypee, projectCode) {
  //   console.log("onchange valueeee", projectCode);
  //   console.log("onchange valueeee", ticketTypee.ticketTypeCode);
  //   let ticketType = ticketTypee.ticketTypeCode;
  //   let reqObj = { projectCode, ticketType };
  //   const response = await this.ticketService.getClassification(reqObj);
  //   this.classficationList = response.transactions;
  //   this.selectedData(projectCode, ticketType);
  // }
  selectedData(projectCode, ticketType) {
    let ticketdropObject = this.ticketEditForm.value;
    this.classificationCode = ticketdropObject.ticketTypeClassificationCode;
    console.log('classi cheeeeek', this.ticketTypeClassifiCode);
    this.getPriorityedit(projectCode, ticketType, this.ticketTypeClassifiCode);
    this.getStatusedit(projectCode, ticketType, this.ticketTypeClassifiCode);
  }

  /* Priority dropdown */
  async getPriorityedit(projectCode, ticketType, classificationCode) {
    let reqObj = {projectCode, ticketType, classificationCode};
    const response = await this.ticketService.getPriority(reqObj);
    this.priorityList = response.transactions;
  }
  /* Status dropdown */
  async getStatusedit(projectCode, ticketType, classificationCode) {
    console.log('classsi swe check', classificationCode);
    let reqObj = {projectCode, ticketType, classificationCode};
    const response = await this.ticketService.getStatus(reqObj);
    this.statusList = response.transactions;
  }
  /* Responsible Agent dropdown */
  async getResAgentedit(projectCode) {
    let reqObj = {projectCode};
    const response = await this.ticketService.getResAgentedit(reqObj);

    // this.resAgentList = response.transactions;
    const array = response.transactions;
    console.log('arrrrrrrr', array);
    this.resAgentList = this.getUniqueListBy(array, 'respAgentCode');
    this.resAgentList = [
      ...new Map(
        this.resAgentList.map((obj) => [JSON.stringify(obj), obj]),
      ).values(),
    ];
  }

  onSelectclassification(value: any) {
    this.classificationCode = value.ticketTypeClassificationCode;
    console.log('classification valllll,', value.ticketTypeClassificationCode);
    this.getPriority(
      this.projectCode,
      this.ticketType,
      this.classificationCode,
    );
    this.getStatus(this.projectCode, this.ticketType, this.classificationCode);
  }
  /* Priority dropdown */
  async getPriority(projectCode, ticketType, classificationCode) {
    let reqObj = {projectCode, ticketType, classificationCode};
    const response = await this.ticketService.getPriority(reqObj);
    this.priorityList = response.transactions;
  }
  /* Status dropdown */
  async getStatus(projectCode, ticketType, classificationCode) {
    let reqObj = {projectCode, ticketType, classificationCode};
    const response = await this.ticketService.getStatus(reqObj);
    this.statusList = response.transactions;
  }
  async getHAPStatus(projectCode,moduleCode){
     let reqObj = {projectCode, moduleCode};
    const response = await this.ticketService.getHAPStatus(reqObj);
    this.statusList = response.data;
  }
  /* Attachment dropdown */
  async getAttachmentType() {
    const response = await this.ticketService.getAttachmentType();
    this.attachmentTypeList = response.transactions;
  }
  /* Version List */
  async getVersion(ticketCode) {
    let reqObj = {ticketCode};
    const response = await this.ticketService.getVersion(reqObj);
    //this.versionList = response.data;

    // this.versionList = this.versionList || [];
    // this.versionList.sort(function (a, b) {
    //   return +new Date(b.createdOn) - +new Date(a.createdOn);
    // });
    // this.versiondata = this.versionList[0];
    //  this.versiondata = this.versionList.sort(
    //   (objA, objB) => Number(objB.createdOn) - Number(objA.createdOn),
    // );
    //   this.versiondata =  this.versionList.sortBy('idVersion');

    // this.version = this.versiondata.idVersion;
    // this.proposedHrs = this.versiondata.proposedHrsMins.split(":")[0];
    // this.proposedMins = this.versiondata.proposedHrsMins.split(":")[1];
    // if (this.versiondata.targetDate == null) {
    //   this.targetDate = this.versiondata.targetDate;
    // } else {
    //   this.targetDate = new Date(this.versiondata.targetDate);
    // }

    // this.vRemarks = this.versiondata.remarks;
    // //  this.createdByName = this.versiondata.createdByName;
    // this.createdOn = this.versiondata.createdOn;
    // this.splitHours = this.versiondata.splitHours;
    // // this.ticketTypeList = response.ticketTypeList;
    // this.ticketEditForm.patchValue({
    //   proposedHrs: this.proposedHrs,
    //   proposedMins: this.proposedMins,
    //   targetDate: this.targetDate,
    //   vRemarks: this.vRemarks,
    //   proposedByName: this.versiondata.createdByName,
    //   createdOn: this.createdOn,
    //   version: this.version,
    //   splitHours: this.checkSplitFlg(this.splitHours),
    // });
    return response.data;
  }
  /*closeDialog(): void {
    this.dialog.closeAll();
  }*/
  /* Attachment List */
  async getAttachmentList(ticketCode) {
    let reqObj = {ticketCode};
    const response = await this.ticketService.getAttachmentList(reqObj);

    // this.ticketattachmentList = response?.body?.data;
    let responseattachList = response?.body?.data;
    // this.ticketattachmentList = responseattachList.map((item) => {
    //   return { ...item, fromAttachment: "Y" };
    // });
    // console.log("data", this.ticketattachmentList);

    return responseattachList.map((item) => {
      return {...item, fromAttachment: 'Y'};
    });

    //return responseattachList;
  }

  getFileName(filename) {
    let file = filename ? filename : '';
    if (file == '') {
    } else {
      var splitData = filename.split('/');
      const fileArr = splitData[5].split('.');
      return fileArr[0];
    }
  }
  getFileType(filetype) {
    let file = filetype ? filetype : '';
    if (file == '') {
    } else {
      var splitData = filetype.split('/');
      const fileArr = splitData[5].split('.');
      return fileArr[1];
    }
  }
  /*  Total Billable & Non Billable List */
  async getTotalBAndN(ticketCode) {
    let reqObj = {ticketCode};
    const response = await this.ticketService.getTotalBillNbh(reqObj);

    console.log('response?.body>>', response?.body);
    // if (!response?.body?.success) {
    // this.totalBillNbh = response?.body?.data;
    // this.Nbhdata = this.totalBillNbh ? this.totalBillNbh[0] : "";
    // this.totalBhrsBooked = this.Nbhdata ? this.Nbhdata.totalBrsHrsMins: "";
    // this.totalNbhrsBooked = this.Nbhdata ? this.Nbhdata.totalNBrsHrsMins :"";
    // this.ticketEditForm.patchValue({
    //   totalNbhrsBooked: this.totalNbhrsBooked,
    //   totalBhrsBooked: this.totalBhrsBooked,
    //   //  vRemarks: this.vRemarks,
    // });
    return response?.body?.data;
    //}
  }
  /* Agent List */
  async getAgentList(ticketCode) {
    try {
      let reqObj = {ticketCode};
      const response = await this.ticketService.getAgentList(reqObj);

      console.log('response?.body>>', response?.body);
      // if (!response?.body?.success) {
      let responseList = response?.body?.data;
      // this.ticketagentList = responseList.map((item) => {
      //   return {
      //     ...item,
      //     fromService: "Y",
      //     changed: "N",
      //     totalAllocated: item.allocatedHrsMins,
      //   };
      // });
      // this.ticketagentListOld = this.ticketagentList;
      // console.log("data", this.ticketagentList);
      //}

      return responseList.map((item) => {
        return {
          ...item,
          fromService: 'Y',
          changed: 'N',
          statusFlag: 'A',
          totalAllocated: item.allocatedHrsMins,
        };
      });
    } catch (error) {
      console.log('agent error', error);
    }
    //  return responseList;
  }

  /* Subgroup dropdown */
  async getProjectSubgroup(projectCode) {
    let reqObj = {projectCode};
    const response = await this.ticketService.getProjectSubgroup(reqObj);
    this.subgrpList = response.transactions;
    // this.ticketTypeList = response.ticketTypeList;
  }
  /* onSelectGroup(value: any) {
    this.selectedgroup = value.idAssignedSubGroup;
    this.getagentName(this.selectedgroup);
  }*/
  /* /!* Agent dropdown *!/
  async getagentName(subGroupCode) {
    let reqObj = { subGroupCode };
    const response = await this.ticketService.getProjectAgent(reqObj);
    this.agentList = response.transactions.assignedAgents;
    // this.ticketTypeList = response.ticketTypeList;
  }*/

  /* Retrieve modal */

  retrieveModal(ticketagent): void {
    let proposedHours = this.ticketEditForm.get('proposedHrs').value;
    let proposedMinutes = this.ticketEditForm.get('proposedMins').value;

    let agent = {ticketagent};
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      data: {
        createdOnDate: this.createdDate,
        ticketAgentList: this.ticketagentList,
        proposedHours: proposedHours,
        proposedMinutes: proposedMinutes,
        ticketEditDialog: agent,
      },
      width: '95%',
      height: '95%',
      // disableClose: true
    });
  }

  infoEdit(idTicketCode): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        ticketeditdialog: idTicketCode,
      },
      width: '1100px',
      height: '500px',
      // disableClose: true
    });
  }
  // ticketAttachmentFile() {
  //   console.log("attachment print", this.tempAttachment);
  //   const dialogRef = this.dialog.open(AttachmentDialogComponent, {
  //     data: {
  //       tempData: this.tempAttachment,
  //     },
  //     width: "1100px",
  //     height: "500px",
  //     // disableClose: true
  //   });
  // }
  /**
   * Add Agent
   * @param projectCode
   * @param idTicketCode
   */
  addAgentModal(projectCode, idTicketCode): void {
    let proposedHours = this.ticketEditForm.get('proposedHrs').value;
    let proposedMinutes = this.ticketEditForm.get('proposedMins').value;

    let agent = {projectCode, idTicketCode};
    const dialogRef = this.dialog.open(AgentDialogComponent, {
      data: {
        ticketAgentList: this.ticketagentList,
        proposedHours: proposedHours,
        proposedMinutes: proposedMinutes,
        ticketEditDialog: agent,
        splitHours: this.splitHoursChanged
          ? this.splitHoursChanged
          : this.splitHours,
      },
      width: '40%',
      height: '55%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.tempAgent = result?.listData;
      if (
        this.tempAgent == undefined ||
        this.tempAgent == null ||
        this.tempAgent == ''
      ) {
      } else {
        this.changedFlag = true;
        let agentLocal = this.tempAgent.map((item) => {
          return {
            assignedSubGroupName: item.idAssignedSubGroup.assignedSubGroupName,
            idAssignedSubGroupCode: item.idAssignedSubGroup.idAssignedSubGroup,
            idUserCode: item.idUserCode.idUserCode,
            userName: item.idUserCode.userName,
            userProfileUrl: item.idUserCode.userProfileUrl,
            totalBookedBHrsMins: '00:00',
            totalBookedNBHrsMins: '00:00',
            changed: 'Y',
            isRespAgent: 'N',
            remarks: null,
            agentStatusCode: 1,
            agentStatusName: 'WFT',
            targetDate: null,
            totalAllocated: '00:00',
            statusFlag: 'A',
            ...item,
          };
        });

        this.ticketagentList = [...agentLocal, ...this.ticketagentList];

        this.resAgentNameList = this.ticketagentList.filter(
          (item) => item.isRespAgent == 'Y',
        );

        console.log('resAgentNameList', this.resAgentNameList);
        this.resAgentValidation =
          this.ticketagentList.length > 0
            ? this.resAgentNameList.length > 0
              ? false
              : true
            : false;
        this.agentLocal = agentLocal;
        console.log('local agent list', agentLocal);
        console.log('server agent list', this.ticketagentList);
      }
    });

    // this.agentHoursMinsTotal();
  }

  /**
   * Edit Agent
   * @param ticketagent
   */
  //  this.ticketEditForm.get("splitHours").value
  editAgentModal(ticketagent): void {
    console.log('split hours edit', this.splitHoursChanged);
    console.log('split hours edit1', this.splitHours);

    let splitHours = this.splitHoursChanged
      ? this.splitHoursChanged
      : this.splitHours;
    // if (
    //   this.splitHoursChanged
    //     ? this.splitHoursChanged == "Y"
    //     : this.splitHours == "Y"
    // ) {
    this.ticketSaved.idTicketCode;

    let proposedHours = this.ticketEditForm.get('proposedHrs').value;
    let proposedMinutes = this.ticketEditForm.get('proposedMins').value;
    let agent = {ticketagent};
    let resAgentAuthority =
      this.userCode == this.resAgentNameList[0]?.idUserCode ? true : false;
    console.log('resAgentAuthority', resAgentAuthority);
    console.log('this.ticketIsBillable', this.ticketIsBillable);
    const dialogRef = this.dialog.open(EditAgentDialogComponent, {
      data: {
        ticketAgentList: this.ticketagentList,
        proposedHours: proposedHours,
        proposedMinutes: proposedMinutes,
        ticketEditDialog: agent,
        ticketAgentListOld: this.ticketagentListOld,
        isNonBillable: this.ticketIsBillable,
        maxAgentDate: this.ticketEditForm.get('targetDate').value,
        splitHoursData: splitHours,
        authority: this.versiondisable,
        resAgentAuthority: resAgentAuthority,
        createdDate: this.dateTime,
      },
      width: '40%',
      height: '70%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      let timeUpdAgent = result?.listData[0];

      if (
        timeUpdAgent == undefined ||
        timeUpdAgent == null ||
        timeUpdAgent == ''
      ) {
      } else {
        this.changedFlag = true;
        let agentDetailLocal = {};
        this.tempAgent = timeUpdAgent;

        for (let i = 0; i <= this.ticketagentList.length; i++) {
          if (
            this.ticketagentList[i].idAssignedSubGroup ==
              timeUpdAgent.idAssignedSubGroup.idAssignedSubGroup &&
            this.ticketagentList[i].idUserCode ==
              timeUpdAgent.idUserCode.idUserCode
          ) {
            agentDetailLocal = {
              ...this.ticketagentList[i],
              allocatedHrsMins: timeUpdAgent.allocatedHrsMins,
              agentStatusCode: timeUpdAgent.agentStatusCode,
              agentStatusName: timeUpdAgent.agentStatusName,
              targetDate: timeUpdAgent.targetDate
                ? timeUpdAgent.targetDate
                : null,
              agentStatusOld: timeUpdAgent.agentSaved.agentStatus,
              agentTargetDateOld: timeUpdAgent.agentSaved.targetDate,
              remarksOld: timeUpdAgent.agentSaved.remarks,
              remarks: timeUpdAgent.remarks,
              changed: 'Y',
              statusFlag: 'A',
              // totalBookedBHours: timeUpdAgent.allocatedHrs,
            };

            console.log(this.ticketagentList, 'ticket agent above start');
            this.ticketagentList[i] = agentDetailLocal;
            if (timeUpdAgent.isRespAgent == 'Y' || timeUpdAgent.isRespAgent) {
              console.log('agent start', this.ticketagentList);
              let resAgent = this.ticketagentList.map((agent) => {
                if (
                  agent.idUserCode == timeUpdAgent.idUserCode.idUserCode &&
                  (timeUpdAgent.isRespAgent == 'Y' || timeUpdAgent.isRespAgent)
                ) {
                  return {
                    ...agent,
                    isRespAgent: 'Y',
                  };
                } else {
                  if (
                    agent.idUserCode != timeUpdAgent.idUserCode.idUserCode &&
                    (agent.isRespAgent == 'Y' || agent.isRespAgent)
                  ) {
                    return {
                      ...agent,
                      isRespAgent: 'N',
                    };
                  }
                }
              });

              this.ticketagentList = resAgent;
              console.log('agent endd', this.ticketagentList);
              this.resAgentNameList = this.ticketagentList.filter(
                (item) => item.isRespAgent == 'Y',
              );

              console.log('resAgentNameList', this.resAgentNameList);
              this.resAgentValidation =
                this.ticketagentList.length > 0
                  ? this.resAgentNameList.length > 0
                    ? false
                    : true
                  : false;
              console.log(this.resAgentValidation, 'agentValidation');
            }
            // this.agentLocal.push(agentDetailLocal);
            // console.log("this.agentLocal ", this.agentLocal);
            return;
          }
          //   this.agentHoursMinsTotal();
        }
      }
    });
    // } else {
    //   this.sharedService.showNotification(
    //     "snackbar-danger",
    //     "Agentwise hours allocation is not permitted untill split hours is enabled in Time details",
    //     "bottom",
    //     "center"
    //   );
    // }
  }

  // ticketAgentLocal() {
  //   console.log("Agenttt print", this.tempAgent);
  //   const dialogRef = this.dialog.open(AgentlistDialogComponent, {
  //     data: {
  //       tempData: this.tempAttachment,
  //     },
  //     width: "1100px",
  //     height: "500px",
  //     // disableClose: true
  //   });
  // }
  /* /!** popup **!/
  accesstime(): void {
    const dialogRef = this.dialog.open(TicketTimeDialogComponent, {
      width: "1100px",
      height: "550px",
      disableClose: true,
    });

  }*/
  async timeBookFunc() {
    this.isTimeSubmitted = true;
    var assignedTicket = await this.allocatedTicketDetails(
      this.ticketSaved.idTicketCode,
    );
    console.log('assignedTicket ...', assignedTicket);
    this.isTimeSubmitted = false;
    if (assignedTicket) {
      console.log('edit link to edit', this.ticketSaved);
      const dialogRef = this.dialog.open(TicketTimeDialogComponent, {
        width: '95%',
        height: '95%',
        disableClose: true,
        data: {
          ticket: this.ticketSaved,
        },
      });
    } else {
      Swal.fire({
        title: 'Information',
        text: 'Ticket is not assigned for you. Contact project manager or team lead',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {
          //  this.dialogRef.close();
        }
      });
    }
  }

  /* File Attachment,
   @file types: Excel,Pdf,Docx,Img only allowed */
  onFileSelect(e: any): void {
    try {
      /* const file = e.target.files[0];
            this.filename = file.name;
            this.ticketEditForm.get('file').setValue(file);*/
      const file = e.target.files[0];
      if (file.size > 5e6) {
        // alert("File is too large. Over 10MB");
        this.ticketEditForm.controls['file'].setValue('');
        this.filename = null;
        this.base64File = null;
        this.fileValidation = true;
        console.log('file Validation><><><', this.fileValidation);
      } else {
        console.log('File is valid');
        this.ticketEditForm.get('file').setValue(file);
        this.filename = file.name;
        this.fileValidation = false;
      }
      var blob = file;
      this.fileType = blob.type;
      console.log('attachement content type', this.fileType);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.basevalue = reader.result;
        // this.getFileNamelocal(this.basevalue, this.fileType);
        // this.updateTicket(this.fileName,this.fileType,this.basevalue);
      };
      /* this.filename = file.name;
      this.ticketEditForm.get("file").setValue(file);*/
    } catch (error) {
      this.filename = null;
      this.base64File = null;
      console.log('no file was selected...');
    }
  }

  /* Add attachment(s) for ticket */
  async addAttachment() {
    console.log('file value', this.filename);
    let attachementTypeCheck =
      this.ticketEditForm.get('attachmentTypeCode').value;
    if (
      attachementTypeCheck == '' ||
      attachementTypeCheck == null ||
      attachementTypeCheck == undefined
    ) {
      this.sharedService.showNotification(
        'snackbar-danger',
        'Please Choose Attachment Type',
        'bottom',
        'center',
      );
    } else if (this.filename == null) {
      this.sharedService.showNotification(
        'snackbar-danger',
        'Please Choose File',
        'bottom',
        'center',
      );
    } else {
      //fileLocal this.fileLocal = [];
      this.changedFlag = true;
      //console.log("pusharray", this.fileLocal);
      let baseObject = {baseValue: this.basevalue};
      let formValue = {...this.ticketEditForm.value, ...baseObject};

      let checkFileDuplicate = false;
      if (this.ticketattachmentList.length > 0) {
        // let split = formValue.file.name.split(".");
        // let fname = split.slice(0, split.length - 1).join(".");

        checkFileDuplicate = this.ticketattachmentList.some(
          (arrVal) =>
            formValue.file.name ==
              (arrVal.fromAttachment == 'Y'
                ? arrVal.attachmentFileName
                : arrVal.file.name) &&
            formValue.attachmentTypeCode.attachmentTypeName ==
              (arrVal.fromAttachment == 'Y'
                ? arrVal.attachmentTypeName
                : arrVal.attachmentTypeCode.attachmentTypeName),
        );

        console.log('checkFileDuplicate', checkFileDuplicate);
        if (!checkFileDuplicate) {
          this.listData.push(formValue);
        } else {
          this.sharedService.showNotification(
            'snackbar-danger',
            'File already exsit in attachments',
            'bottom',
            'center',
          );
        }
      } else {
        this.listData.push(formValue);
      }
      // this.dataSource.data = this.listData;
      // this.tempAttachment.push(this.ticketEditForm.value);
      // this.getFileNamelocal(this.basevalue, this.fileType);
      this.tempAttachment = this.listData;

      //this.tempAttachment = responsefileList;
      // let fileLocalatt = this.fileName;
      // let fileLocalatt = [];
      // fileLocalatt = this.tempAttachment.map((item) => {
      //   let split = item.file.name.split(".");
      //   let fname = split.slice(0, split.length - 1).join(".");
      //   console.log('fname',fname);
      //   return fname;
      // });
      console.log('gggggggg', this.tempAttachment);
      let fileLocal = this.tempAttachment.map((item, i) => {
        let split = item.file.name.split('.');
        let fname = split.slice(0, split.length - 1).join('.');
        console.log('fname', fname);
        // let ftype = this.filename ? this.filename.split(".")[1] : "";
        let ftype = split[1];
        let contentType = item.file.type;
        return {
          idGNo: null,
          fromAttachment: 'N',
          attachmentTypeName: item.attachmentTypeCode.attachmentTypeName,
          attachmentUrl: fname,
          attachmentFileType: ftype,
          attachmentUrrl: contentType, // Old Code : this.fileType
          attachmentRemarks: item.remarks,
          attachmentFile: item.baseValue, // Old Code : this.basevalue,
          changed: 'Y',
          ...item,
        };
      });
      // console.log("File Local list", fileLocal);
      this.attachLabel = fileLocal;
      let filterList = this.ticketattachmentList.filter(
        (item) => item.idGNo != null,
      );
      console.log('Old Attached list', filterList);
      this.ticketattachmentList = [...fileLocal, ...filterList];
      console.log('File Local list', this.ticketattachmentList);

      // this.fileLocal = fileLocal;
      //  this.fileLocal = this.ticketattachmentList;
      // this.getFileTypelocal();
      /*  this.sharedService.showNotification(
        "snackbar-success",
        "File added successfully..!!",
        "bottom",
        "center"
      );*/
      this.ticketEditForm.controls['attachmentTypeCode'].setValue('');
      this.ticketEditForm.controls['remarks'].setValue('');
      this.ticketEditForm.controls['file'].setValue('');
      this.filename = null;
      this.base64File = null;
      this.basevalue = '';
    }
  }
  /* Remove/Delete attachment function */
  removeAttachmentFunc(index: number) {
    console.log('index..', index);
    let initialList = this.ticketattachmentList;
    this.changedFlag = true;

    // let filterList = this.ticketattachmentList.filter(
    //   (item) => item.idGNo != null
    // );
    let afterDelete;
    console.log('intialList..', initialList);
    //const index = initialList.indexOf(key, {0);
    let removeAttach = this.ticketattachmentList.at(index);
    if (removeAttach.idGNo != null) {
      removeAttach.statusFlag = 'X';
      removeAttach.changed = 'Y';
      this.removeAttachment.push(removeAttach);
      afterDelete = this.ticketattachmentList.splice(index, 1);
      console.log('remove attachment', this.removeAttachment);
    } else {
      afterDelete = this.ticketattachmentList.splice(index, 1);
      this.listData.splice(index, 1);
    }

    // this.deleteattachmentList = afterDelete;

    //this.ticketattachmentList = this.deleteattachmentList;
    console.log('after Delete..', removeAttach);
    // this.sharedService.showNotification(
    //   "snackbar-success",
    //   "Item removed successfully..!!",
    //   "bottom",
    //   "center"
    // );
  }

  resetFile(element) {
    element.value = '';
  }
  // getFileNamelocal(basevalue, fileType) {
  //   let filename = this.ticketEditForm.value.file.name;
  //   console.log("filename..full..", filename);
  //   var splitData = filename.split("/");
  //   const fileArr = splitData[0].split(".");
  //   this.fileName = fileArr[0];
  //   console.log("filename...now.", this.fileName);
  // }
  ResAgentValue(event) {
    console.log('resagent', event);
  }
  /* Update ticket function */
  public async updateTicket() {
    this.closeDialog = true;
    console.log('formControl value', this.ticketEditForm.value);
    this.changedFlag = false;
    console.log(this.ntextLabel, 'notes Value');
    console.log(this.logList, 'this.logList');
    /** spinner start */
    this.spinner.show();
    //debugger;
    let ticketEditObj = this.ticketEditForm.value;

    let attatchmentList = this.attachmentSave();

    let ticketUserAssignment = this.agentSave();

    console.log('basicDetails', this.logList);
    let ticketObj = {
      moduleCode: this.logList.module?.moduleCode,
      respAgentCode: this.logList.resposibleAgent?.respAgentCode,
      ticketPriorityCode: this.logList.ticketPriority?.ticketPriorityCode,
      ticketStatusCode: this.logList.ticketStatus?.ticketStatusCode,
      ticketSubStatusCode: this.logList.ticketStatus?.ticketSubStatusCode,
      ticketTags: this.tags ? this.tags.join(',') : '',
      ticketTypeClassificationCode:
        this.logList.ticketClassification?.ticketTypeClassificationCode,
      //  ticketTypeCode: this.ticketTypeCode,
      ticketTypeCode: this.logList.ticketType?.ticketTypeCode,
    };

    Object.keys(ticketObj).forEach((key) => {
      if (
        ticketObj[key] == null ||
        ticketObj[key] == '' ||
        ticketObj[key] == undefined
      ) {
        delete ticketObj[key];
      }
    });

    if (Object.keys(ticketObj).length == 0) {
      this.basicDetail = [];
    } else {
      this.basicDetail.push(ticketObj);
    }
    this.saveInternalWorkLog();
    this.saveWorkLog();
    console.log('this.detailLabel', this.detailLabel);

    /* let text = `<b>Ticket Status: </b>${this.ticketStatusCode}`;
        console.log("With Stringify :" , text);*/

    /*if(this.proposedHrscChangedd != null){


}*/

    let verObj = {
      idVersion: this.versionc,
      proposedHrs: this.proposedHrscChangedd,
      remarks: this.vRemarksChanged,
      splitHours: this.splitHoursChangedd,
      targetDate: this.targetChangedd ? new Date(this.targetChangedd) : '',
    };
    //  console.log(verObj, "obj");
    Object.keys(verObj).forEach((key) => {
      if (
        verObj[key] == null ||
        verObj[key] == '' ||
        verObj[key] == undefined
      ) {
        delete verObj[key];
      }
    });

    //  let data =  this.clearEmpties(verObj);
    //     console.log(data);
    if (Object.keys(verObj).length == 0) {
      this.versionLog = [];
    } else {
      this.versionLog.push(verObj);
    }

    if (this.itextChanged || Object.values(this.agentLabel).length > 0) {
      let sentenceAgent = Object.values(this.agentLabel).join('');
      let newTextAgent = sentenceAgent.trim();
      let internalCmt = {
        idTicketCode: ticketEditObj.idTicketCode,
        idLogType: 'Internal',
        //  text: this.itextChanged.trim(),
        text: this.itextChanged
          ? `${newTextAgent}<b>Comments:</b>${this.itextChanged.trim()}`
          : newTextAgent,
      };
      this.ticketWorkLogComments = this.ticketWorkLogComments.filter(
        (item) => item.idLogType != 'Internal',
      );
      this.ticketWorkLogComments.push(internalCmt);
      //  let sentence = Object.values(this.detailLabel).join(",");
    }
    if (this.ctextChanged || Object.values(this.detailLabel).length > 0) {
      let sentence = Object.values(this.detailLabel).join('');
      let newText = sentence.trim();
      let customerCmt = {
        idTicketCode: ticketEditObj.idTicketCode,
        idLogType: 'Customer',
        text: this.ctextChanged
          ? `${newText}<b>Comments:</b>${this.ctextChanged.trim()}`
          : newText,
      };
      this.ticketWorkLogComments = this.ticketWorkLogComments.filter(
        (item) => item.idLogType != 'Customer',
      );
      this.ticketWorkLogComments.push(customerCmt);
    }
    if (this.ntextChanged) {
      let notesCmt = {
        idTicketCode: ticketEditObj.idTicketCode,
        idLogType: 'Notes',
        text: this.ntextChanged
          .trim()
          .replace('/\\n|\\r\\n|\\n\\r|\\r/g', '<br>'),
      };
      this.ticketWorkLogComments = this.ticketWorkLogComments.filter(
        (item) => item.idLogType != 'Notes',
      );
      this.ticketWorkLogComments.push(notesCmt);
    }

    var ticketEdit = {
      idTicketCode: ticketEditObj.idTicketCode,
      ticketAttachment: attatchmentList,
      ticketMaster: this.basicDetail,
      ticketUserAssignment,
      ticketVersion: this.versionLog,
      ticketWorkLogsAndComments: this.ticketWorkLogComments,
    };
    this.ticketClear = ticketEdit;
    console.log('WHOLE EDIT DATA', ticketEdit);

    try {
      if (
        Object.values(this.detailLabel).length > 0 ||
        this.ticketWorkLogComments.length > 0
      ) {
        this.isSubmitted = true;
        let response = await this.ticketService.updateTicket(ticketEdit);
        //  console.log("response????", response);
        if (response?.body) {
          console.log('response', response);
          //   this.dialogRef.({ listData: this.tempAgent });
          if (response?.body?.success) {
            this.isSubmitted = false;
            console.log('response?.body', response?.body);
            this.sharedService.showNotification(
              'snackbar-success',
              response?.body?.message,
              'bottom',
              'center',
            );

            this.ticketClear = [];
            this.basicDetail = [];
            this.versionLog = [];
            this.ticketWorkLogComments = [];
            this.detailLabel = [];
            this.finalAttachmentList = [];
            this.ticketattachmentList = [];
            this.removeAttachment = [];
            this.removeAgent = [];
            this.removeTagsList = [];
            this.localTagsList = [];
            this.clearUserAss = [];
            this.proposedHrscChangedd = '';
            this.vRemarksChanged = '';
            this.versionc = '';
            this.splitHoursChangedd = '';
            this.attachLabel = [];
            this.listData = [];
            (this.targetChangedd = ''),
              (this.ticstatus = ''),
              (this.ticmodule = ''),
              (this.ticticketType = ''),
              (this.ticClassificationName = ''),
              (this.ticResAgnt = ''),
              (this.ticPrioName = ''),
              (this.ticproposedHrs = ''),
              (this.tictargetDate = ''),
              (this.ticremark = ''),
              (this.ctextLabel = '');
            this.itextLabel = '';
            this.ntextLabel = '';
            this.ctextChanged = '';
            this.ntextChanged = '';
            this.itextChanged = '';

            // this.getAttachmentList(this.idTicketCode);
            // this.getAgentList(this.idTicketCode);
            await this.loadWorklog(this.idTicketCode, this.logType);
            await this.loadInternalCmts(this.idTicketCode, this.internal);
            await this.loadNotes(this.idTicketCode, this.notes);
            // this.getVersion(this.idTicketCode);

            this.changeDetector.detectChanges();
            await this.spinner.hide();

            // this.ticketSaved = this.ticketEditForm.value;
            this.ticketSaved = {...this.ticketSaved, ...this.tempSaved};
            //  this.respAgentCode = this.logList.resposibleAgent?.respAgentCode;
            this.respAgentCode = this.logList.resposibleAgent
              ? this.logList.resposibleAgent?.respAgentCode
              : this.data.ticket.respAgentCode;
            console.log('update respAgentCode', this.respAgentCode);
            this.tempSaved = {};
            this.ticketAccess();
            this.groupTicketData();
            /** spinner hide */
            // this.spinner.hide();
            // debugger;
            // let closedStatus = '';
            // if(this.logList.ticketStatus.ticketStatusName){
            //   closedStatus = this.logList.ticketStatus.ticketStatusName;
            // }
            this.logList = {};
            console.log('loglist Empty', this.logList);
            console.log('closedStatus', this.closedStatus);
            if (this.closedStatus) {
              if (this.closedStatus.ticketStatusName == 'Closed') {
                this.dialogRef.close({
                  flag: this.closeDialog,
                  action: this.action,
                  response,
                });
              }
            }
            // this.subscription.unsubscribe();
          } else {
            this.isSubmitted = false;
            /** spinner hide */
            this.spinner.hide();
            this.sharedService.showNotification(
              'snackbar-danger',
              response?.body?.message,
              'bottom',
              'center',
            );
          }

          //  this.dialogRef.close({ action: this.action, response });
          //  console.log(this.companyForm.value, "suzzzzzzzzzz");
        }
      } else {
        /** spinner hide */
        this.spinner.hide();
        this.sharedService.showNotification(
          'snackbar-danger',
          'There is no changes on Ticket',
          'bottom',
          'center',
        );
        this.ticketClear = [];
        this.basicDetail = [];
        this.versionLog = [];
        this.ticketWorkLogComments = [];
        this.detailLabel = [];
        return;
      }
    } catch (e) {
      /** spinner hide */
      this.spinner.hide();
      this.ticketClear = [];
      this.basicDetail = [];
      this.versionLog = [];
      this.ticketWorkLogComments = [];
      this.detailLabel = [];
      this.finalAttachmentList = [];
      this.clearUserAss = [];
      this.isSubmitted = false;
    }
  }

  /* Attachment Save */
  attachmentSave() {
    this.finalAttachmentList = [
      ...this.ticketattachmentList,
      ...this.removeAttachment,
    ];

    let localFinalAttachement = this.finalAttachmentList.filter(
      (item) => item.changed == 'Y',
    );

    let attatchmentList = localFinalAttachement.map((item) => {
      if (item.idGNo == '' || item.idGNo == null || item.idGNo == undefined) {
        return {
          attachmentContentType: item.attachmentUrrl,
          attachmentTypeCode: item.attachmentTypeCode.attachmentTypeCode,
          attachmentFileName: item.file.name,
          remarks: item.remarks,
          attachmentFile: item.attachmentFile,
        };
      } else {
        return {
          idRefCode: item.idRefCode,
          statusFlag: item.statusFlag,
        };
      }
    });
    this.clearAttach = this.finalAttachmentList;
    return attatchmentList;
  }

  /**
   * Agent SAVE
   */
  agentSave() {
    this.existingResAgent = this.ticketagentListOld.filter(
      (item) => item.isRespAgent == 'Y',
    );
    console.log(this.existingResAgent, 'existingResAgent');
    this.newResAgent = [];

    if (this.existingResAgent.length > 0) {
      let userAssignmentOld = this.ticketagentList.filter(
        (item) => item.fromService == 'Y',
      );
      let userAssignmentNew = this.ticketagentList.filter(
        (item) => item.fromService == 'N',
      );
      let newResAgent = userAssignmentOld.filter(
        (item) =>
          this.existingResAgent[0].idUserCode != item.idUserCode &&
          item.isRespAgent == 'Y',
      );
      let newResAgent1 = userAssignmentNew.filter(
        (item) =>
          this.existingResAgent[0].idUserCode != item.idUserCode.idUserCode &&
          item.isRespAgent == 'Y',
      );

      this.newResAgent = [...newResAgent, ...newResAgent1];
    }

    let finalAgent = [...this.removeAgent, ...this.ticketagentList];
    console.log(this.newResAgent, 'newResAgent');
    // let userAssignment = this.ticketagentList.filter(
    //   (item) => item.changed == "Y"
    // );

    let userAssignment = finalAgent.filter((item) => item.changed == 'Y');

    let ticketUserAssignment = userAssignment.map((item) => {
      if (item.idGNo == '' || item.idGNo == null || item.idGNo == undefined) {
        return {
          idAssignedSubGroup: item.idAssignedSubGroup?.idAssignedSubGroup,
          assignedSubGroupName: item.idAssignedSubGroup?.assignedSubGroupName,
          allocatedHrs: item.allocatedHrsMins,
          idUserCode: item.idUserCode?.idUserCode,
          userName: item.idUserCode?.userName,
          agentStatusCode: item.agentStatusCode,
          remarks: item.remarks,
          targetDate: item.targetDate ? new Date(item.targetDate) : null,
          isRespAgent: item.isRespAgent,
          statusFlag: item.statusFlag,
        };
      } else {
        return {
          idAssignedSubGroup: item.idAssignedSubGroup,
          allocatedHrs: item.allocatedHrsMins,
          idUserCode: item.idUserCode,
          userName: item.userName,
          agentStatusCode: item.agentStatusCode,
          targetDate: item.targetDate ? new Date(item.targetDate) : null,
          isRespAgent: item.isRespAgent,
          remarks: item.remarks,
          statusFlag: item.statusFlag,
        };
      }
    });

    //Exist rep agent to new rep agent
    if (this.newResAgent.length > 0 && this.existingResAgent.length > 0) {
      ticketUserAssignment = [
        ...ticketUserAssignment,
        {
          idAssignedSubGroup: this.existingResAgent[0].idAssignedSubGroup,
          assignedSubGroupName: this.existingResAgent[0].assignedSubGroupName,
          allocatedHrs: this.existingResAgent[0].allocatedHrsMins,
          idUserCode: this.existingResAgent[0].idUserCode,
          userName: this.existingResAgent[0].userName,
          agentStatusCode: this.existingResAgent[0].agentStatusCode,
          remarks: this.existingResAgent[0].remarks,
          targetDate: this.existingResAgent[0].targetDate
            ? new Date(this.existingResAgent[0].targetDate)
            : null,
          isRespAgent: 'N',
          statusFlag: this.existingResAgent[0].statusFlag,
        },
      ];
    }
    console.log('ticketUserAssignment', ticketUserAssignment);

    return ticketUserAssignment;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.isDistruct) {
      this.closeEditDialog(this.idTicketCode);
    }
  }

  clearAttachmentTicket() {
    this.ticketEditForm.reset();
  }
  onSubmitClick() {
    console.log('Form Value', this.ticketEditForm.value);
  }
  clearEmpties(o) {
    for (var k in o) {
      if (!o[k] || typeof o[k] !== 'object') {
        continue;
      }

      this.clearEmpties(o[k]);
      if (Object.keys(o[k]).length === 0) {
        delete o[k];
      }
    }

    return o;
  }

  /* Compare two values in mat-select dropdown function */
  compareFn(d1, d2): boolean {
  
    if (d1.moduleCode) {
      return d1 && d2 ? d1.moduleCode === d2.moduleCode : d1 === d2;
    }
    if (d1.ticketTypeCode) {
      return d1 && d2 ? d1.ticketTypeCode === d2.ticketTypeCode : d1 === d2;
    }
    if (d1.ticketTypeClassificationCode) {
      return d1 && d2
        ? d1.ticketTypeClassificationCode === d2.ticketTypeClassificationCode
        : d1 === d2;
    }
    if (d1.ticketPriorityCode) {
      return d1 && d2
        ? d1.ticketPriorityCode === d2.ticketPriorityCode
        : d1 === d2;
    }
    if (d1.subStatusCode) {
      
      //console.log('ticket status compare Fn',d1);
      //console.log('ticket status compare Fn1',d2)
      return d1 && d2
        ? d1.subStatusCode === d2.ticketSubStatusCode
        : d1 === d2;
    }

    if (d1.respAgentCode) {
      return d1 && d2 ? d1.respAgentCode === d2.respAgentCode : d1 === d2;
    }
  }

  /* Assigned ticket details */
  async allocatedTicketDetails(ticket) {
    const response = await this.ticketService.getAllocatedHoursList({
      ticketCode: ticket,
    });

    console.log('response', response);
    let assignedTicket = response?.body?.data;
    console.log('assigned', assignedTicket[0].assigned);
    let assignedFlag = assignedTicket[0].assigned;

    return assignedFlag;
  }

  /*  Ticket access control function */
  public ticketAccess(): void {
    if (this.action === 'update') {
      this.editticket = false;
      this.isDetails = false;
      this.isInternal = true;
      this.resAgentByPm(
        this.userCode,
        this.projectManagerCode,
        this.proxyManagerCode,
        this.respAgentCode,
      );
      this.versionValidate(
        this.userCode,
        this.projectManagerCode,
        this.proxyManagerCode,
        this.respAgentCode,
      );
      this.validateTopField(
        this.userCode,
        this.respAgentCode,
        this.projectManagerCode,
        this.proxyManagerCode,
        this.customerManagerCode,
      );
      this.validatework(
        this.companyCode,
        this.ownerCompanyInfo,
        this.ownerGnoInfo,
        this.gNoUser,
      );
    } else if (this.action === 'reference') {
      this.editticket = false;
      this.isDetails = true;
      this.isInternal = true;
    } else if (this.action === 'details') {
      this.editticket = true;
      this.isDetails = true;
      this.isInternal = true;
      this.disableSplit = true;
      this.validatework(
        this.companyCode,
        this.ownerCompanyInfo,
        this.ownerGnoInfo,
        this.gNoUser,
      );
      this.versionValidateView(
        this.userCode,
        this.projectManagerCode,
        this.proxyManagerCode,
        this.respAgentCode,
      );
    }
  }
  //work log save Internal
  saveInternalWorkLog() {
    let userAssignment = this.ticketagentList.filter(
      (item) => item.changed == 'Y',
    );

    let userAssignmentOld = userAssignment.filter(
      (item) => item.fromService == 'Y',
    );
    let userAssignmentNew = userAssignment.filter(
      (item) => item.fromService == 'N',
    );

    console.log('userAssignmentNew', userAssignmentNew);
    let localinterWorkLogLst = [];
    for (let agent of userAssignmentOld) {
      localinterWorkLogLst.push(
        `<p><b>Agent: </b><b>${agent.userName}</b> Updated - <b> ${agent.assignedSubGroupName} </b><br/></p>`,
      );
      if (agent.agentStatusOld) {
        if (agent.agentStatusOld != agent.agentStatusName) {
          localinterWorkLogLst.push(
            `<p><b>Agent Status: </b><b>${agent.agentStatusOld}</b> > <b> ${agent.agentStatusName} </b><br/></p>`,
          );
        }
      }
      if (agent.agentTargetDateOld) {
        if (
          this.formatDate(agent.agentTargetDateOld) !=
          this.formatDate(agent.targetDate)
        ) {
          localinterWorkLogLst.push(
            `<p><b>Agent Target Date: </b><b>${this.formatDate(
              agent.agentTargetDateOld,
            )}</b> > <b> ${this.formatDate(agent.targetDate)} </b><br/></p>`,
          );
        }
      }
      if (agent.remarksOld) {
        if (agent.remarksOld != agent.remarks) {
          localinterWorkLogLst.push(
            `<p><b>Agent Remarks: </b><b>${agent.remarksOld}</b> > <b> ${agent.remarks} </b><br/></p>`,
          );
        }
      }
      if (!agent.remarksOld) {
        if (agent.remarks != null) {
          localinterWorkLogLst.push(
            `<p><b>Agent Remarks: </b><b> ${agent.remarks} </b><br/></p>`,
          );
        }
      }
    }

    for (let agent of userAssignmentNew) {
      localinterWorkLogLst.push(
        `<p><b>Agent: </b><b>${agent.userName}</b> Added - <b> ${agent.assignedSubGroupName} </b><br/></p>`,
      );

      if (agent.agentStatusName) {
        localinterWorkLogLst.push(
          `<p><b>Agent Status: </b> ${agent.agentStatusName} <br/></p>`,
        );
        if (agent.targetDate) {
          localinterWorkLogLst.push(
            `<p><b>Agent Target Date: </b> ${this.formatDate(
              agent.targetDate,
            )} <br/></p>`,
          );
        }
        if (agent.remarks) {
          localinterWorkLogLst.push(
            `<p><b>Agent Remarks: </b>
            ${agent.remarks}
            <br/></p>`,
          );
        }

        // if(agent.agentStatusOld){
        //   localinterWorkLogLst.push(
        //     `<p><b>Agent Status: </b><b>${agent.agentStatusOld}</b> > <b> ${agent.agentStatusName} </b><br/></p>`
        // );
        //  }
        // if(agent.agentTargetDateOld){
        //   localinterWorkLogLst.push(
        //     `<p><b>Agent Target Date: </b><b>${this.formatDate(agent.agentTargetDateOld)}</b> > <b> ${this.formatDate(agent.targetDate)} </b><br/></p>`
        // );
        // }
      }
    }
    this.ticketagentList.filter((x) => {
      if (
        this.existingResAgent.length == 0 &&
        this.newResAgent.length == 0 &&
        x.isRespAgent == 'Y'
      ) {
        localinterWorkLogLst.push(
          `<p><b>Res.agent: </b><b>${x.userName}</b> Newly Assigned<br/></p>`,
        );
      }
    });
    if (
      this.existingResAgent.length > 0 &&
      this.newResAgent.length > 0 &&
      this.newResAgent[0].isRespAgent == 'Y'
    ) {
      localinterWorkLogLst.push(
        `<p><b>Res.agent: </b><b>${this.existingResAgent[0].userName}</b> > <b> ${this.newResAgent[0].userName} </b><br/></p>`,
      );
    } else {
    }

    for (let agentRemove of this.removeAgent) {
      localinterWorkLogLst.push(
        `<p>Agent <b> ${agentRemove.userName}</b> Removed<br/>`,
      );
    }
    this.agentLabel = localinterWorkLogLst;
  }
  // Work log save function
  saveWorkLog() {
    let userAssignment = this.ticketagentList.filter(
      (item) => item.changed == 'Y',
    );

    let userAssignmentOld = userAssignment.filter(
      (item) => item.fromService == 'Y',
    );
    let userAssignmentNew = userAssignment.filter(
      (item) => item.fromService == 'N',
    );

    let ticketAttachment = this.ticketattachmentList.filter(
      (item) => item.changed == 'Y',
    );
    console.log('ticketAttachment', ticketAttachment);
    let localWorkLogLst = [];
    if (
      Object.keys(this.logList).length > 0 ||
      userAssignment.length > 0 ||
      this.removeAgent.length > 0 ||
      this.attachLabel.length > 0 ||
      this.removeAttachment.length > 0 ||
      this.localTagsList.length > 0 ||
      this.removeTagsList.length > 0
    ) {
      if (
        this.logList.ticketStatus &&
        Object.keys(this.logList.ticketStatus).length > 0
      ) {
        if (
          this.ticketSaved.ticketStatusName
            ? this.ticketSaved.ticketStatusName
            : this.ticketSaved.ticketCurrentStatus !=
              this.logList.ticketStatus.ticketStatusName
        ) {
          localWorkLogLst.push(
            `<b>Ticket Status: </b>${
              this.ticketSaved.ticketStatusName
                ? this.ticketSaved.ticketStatusName
                : this.ticketSaved.ticketCurrentStatus
            }  > ${this.logList.ticketStatus.ticketStatusName}<br/>`,
          );
          /*${this.ticketSaved.ticketStatusName ? this.ticketSaved.ticketStatusName : this.ticketSaved.ticketStatusName - this.ticketSaved.ticketSubStatusName  }>${this.logList.ticketStatus.ticketStatusName}*/
          this.tempSaved['ticketStatusName'] =
            this.logList.ticketStatus.ticketStatusName;
        }
      }
      if (this.logList.module && Object.keys(this.logList.module).length > 0) {
        if (this.ticketSaved.moduleName != this.logList.module.moduleName) {
          localWorkLogLst.push(
            `<b>Module: </b>${this.ticketSaved.moduleName} > ${this.logList.module.moduleName}<br/>`,
          );
          this.tempSaved['moduleName'] = this.logList.module.moduleName;
        }
      }
      if (
        this.logList.ticketType &&
        Object.keys(this.logList.ticketType).length > 0
      ) {
        if (
          this.ticketSaved.ticketTypeName !=
          this.logList.ticketType.ticketTypeName
        ) {
          localWorkLogLst.push(
            `<b>Ticket Type: </b>${this.ticketSaved.ticketTypeName} > ${this.logList.ticketType.ticketTypeName}<br/>`,
          );
          this.tempSaved['ticketTypeName'] =
            this.logList.ticketType.ticketTypeName;
        }
      }
      if (
        this.logList.ticketClassification &&
        Object.keys(this.logList.ticketClassification).length > 0
      ) {
        if (
          this.ticketSaved.ttClassificationName !=
          this.logList.ticketClassification.ticketTypeClassificationName
        ) {
          localWorkLogLst.push(
            `<b>Ticket Classification: </b>${this.ticketSaved.ttClassificationName} > ${this.logList.ticketClassification.ticketTypeClassificationName}<br/>`,
          );
          this.tempSaved['ttClassificationName'] =
            this.logList.ticketClassification.ticketTypeClassificationName;
        }
      }
      if (
        this.logList.resposibleAgent &&
        Object.keys(this.logList.resposibleAgent).length > 0
      ) {
        if (this.ticketSaved.respAgentName == undefined) {
          localWorkLogLst.push(
            `<b>Team Lead: </b>${this.logList.resposibleAgent.respAgentName} newly added<br/>`,
          );
        } else {
          if (
            this.ticketSaved.respAgentName !=
            this.logList.resposibleAgent.respAgentName
          ) {
            localWorkLogLst.push(
              `<b>Team Lead: </b>${this.ticketSaved.respAgentName} >${this.logList.resposibleAgent.respAgentName}<br/>`,
            );
            this.tempSaved['respAgentName'] =
              this.logList.resposibleAgent.respAgentName;
          }
        }
      }
      console.log('swetha worklog', this.ticketSaved);
      if (
        this.logList.ticketPriority &&
        Object.keys(this.logList.ticketPriority).length > 0
      ) {
        if (
          this.ticketSaved.ticketPriorityName
            ? this.ticketSaved.ticketPriorityName
            : this.ticketSaved.priorityName !=
              this.logList.ticketPriority.ticketPriorityName
        ) {
          localWorkLogLst.push(
            `<b>Priority: </b>${
              this.ticketSaved.ticketPriorityName
                ? this.ticketSaved.ticketPriorityName
                : this.ticketSaved.priorityName
            } > ${this.logList.ticketPriority.ticketPriorityName}<br/>`,
          );
          this.tempSaved['ticketPriorityName'] =
            this.logList.ticketPriority.ticketPriorityName;
        }
      }
      if (this.proposedHrscChangedd) {
        localWorkLogLst.push(
          `<b>Proposed Hours / Minutes : </b> ${this.proposedHrs}:${this.proposedMins} > ${this.proposedHrscChangedd}<br/>`,
        );
      }
      if (this.vRemarksChanged) {
        if (
          this.ticketSaved.vRemarks
            ? this.ticketSaved.vRemarks
            : this.vRemarks != this.vRemarksChanged
        ) {
          localWorkLogLst.push(
            `<b>Remarks : </b>${
              this.ticketSaved.vRemarks
                ? this.ticketSaved.vRemarks
                : this.vRemarks
            } > ${this.vRemarksChanged}<br/>`,
          );
          this.tempSaved['vRemarks'] = this.vRemarksChanged;
        }
      }

      for (let tags of this.localTagsList) {
        localWorkLogLst.push(`<b>Tag :</b> ${tags} Added <br/>`);
      }

      for (let tags of this.removeTagsList) {
        localWorkLogLst.push(`<b>Tag :</b> ${tags} Removed <br/>`);
      }
      /*if(ticketLabel.targetDate){
                let targetDate = `<b>TargetDate : </b>${ticketLabel.targetDateOld} -> ${ticketLabel.targetDate}</br>`;
               // let ticketPriorityName = `<b>Priority : </b> ${ticketLabel.ticketPriorityName}</br>`;
                this.tictargetDate =targetDate;


            }*/
      console.log('targetDate', this.logList.targetDate);
      if (this.logList.targetDate) {
        if (
          this.ticketSaved.targetDate == null ||
          this.ticketSaved.targetDate == '' ||
          this.ticketSaved.targetDate == undefined
        ) {
          localWorkLogLst.push(
            `<b>Target Date:</b> ${this.formatDate(
              this.logList.targetDate,
            )}  Proposed <br/>`,
          );
          this.tempSaved['targetDate'] = this.logList.targetDate;
        } else {
          if (
            this.formatDate(this.ticketSaved.targetDate) !=
            this.formatDate(this.logList.targetDate)
          ) {
            localWorkLogLst.push(
              `<b>Target Date: </b>${this.formatDate(
                this.ticketSaved.targetDate,
              )} > ${this.formatDate(this.logList.targetDate)}<br/>`,
            );
            this.tempSaved['targetDate'] = this.logList.targetDate;
          }
        }
      }
      /**Agent Worklog And Comments HTML
       * **/
      // for (let agent of userAssignment) {
      //   localWorkLogLst.push(
      //     `<p><b>Agent: </b><b>${agent.userName}</b> Added - <b> ${agent.assignedSubGroupName} </b><br/></p>`
      //   );
      // }
      /*  for (let agent of userAssignmentOld) {
        localWorkLogLst.push(
          `<p><b>Agent: </b><b>${agent.userName}</b> Updated - <b> ${agent.assignedSubGroupName} </b><br/></p>`
        );
      }

      for (let agent of userAssignmentNew) {
        localWorkLogLst.push(
          `<p><b>Agent: </b><b>${agent.userName}</b> Added - <b> ${agent.assignedSubGroupName} </b><br/></p>`
        );
      }*/
      for (let attachment of ticketAttachment) {
        localWorkLogLst.push(`<p>New Attachment <b> ${
          attachment.attachmentTypeCode.attachmentTypeName
        } - ${attachment.attachmentUrl} </b> Added<br/>
            <b>Attachment Remarks:</b> ${
              attachment.remarks ? attachment.remarks : '-'
            } <br/>`);
      }
      for (let attachmentRemove of this.removeAttachment) {
        localWorkLogLst.push(
          `<p>Attachment <b> ${attachmentRemove.attachmentTypeName} - ${attachmentRemove.attachmentFileName} </b> Removed<br/>`,
        );
      }

      /* let ticketUserAssignmentEditLabel = this.ticketagentList;
        let agentEditInfo = "";
        for (let agent of ticketUserAssignmentEditLabel) {
            agentEditInfo += `<p><b>Agent: </b><b>${agent.userName}</b> Updated-<b> ${agent.assignedSubGroupName} </b><br/></p>`;
        }*/

      this.detailLabel = localWorkLogLst;

      //  console.log("ena basic html filter", detailLabel);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log('tags list', this.tags);
    if (value) {
      // Add our Ticket tag items
      if ((value || '').trim()) {
        if (
          !this.tags.includes(value.trim()) &&
          this.ticketEditForm.value.ticketTags != value
        ) {
          this.availableColors.push({
            name: value.trim(),
            color: this.randomClr(),
          });
          this.tags.push(value.trim());
          this.localTagsList.push(value);
          console.log('val', this.localTagsList);
        }
      } else {
        console.log('value', value);
      }
      //prevent duplicate
      if (input.value === value) {
        input.value = '';
      }
      if (input) {
        input.value = '';
      }

      this.tagCtrl.setValue(null);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.availableColors.push({
      name: event.option.viewValue,
      color: this.randomClr(),
    });
    this.tags.push(event.option.viewValue);
    const index = this.allTags.indexOf(event.option.viewValue);
    this.allTags.splice(index, 1);
    this.tagInput.nativeElement.value = '';
  }
  ngAfterViewInit(): void {
    this.onChangeFunc();
    this.logList = {};
  }
  ngOnDestroy(): void {
    this.closeEditDialog(this.idTicketCode);
  }
  public randomClr() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  targetDateMinMaxValidation() {
    // let statusConditiion = this.ticketSaved.statusName
    // ? this.ticketSaved.statusName
    // : this.splitStatus;
    // console.log('statusConditiion',statusConditiion);
    // if(statusConditiion == "Solved" || statusConditiion == "Hold"){
    // }else{

    //  Get Max date
    this.maxTargetDate = new Date(
      Math.max(
        ...this.ticketagentList.map((element) => {
          return new Date(element.targetDate);
        }),
      ),
    );

    // Get Min date
    this.minTargetDate = new Date(
      Math.min(
        ...this.ticketagentList.map((element) => {
          return new Date(element.targetDate);
        }),
      ),
    );

    console.log('min Target Date', this.minTargetDate);
    console.log('max Target Date', this.maxTargetDate);

    // if(this.minTargetDate.toString() == 'Invalid Date'){
    //   this.minTargetDate = new Date();
    //   this.minTargetDate.setHours(0, 0, 0, 0);
    //   console.log("min Target Date", this.minTargetDate);
    //   this.minTargetDateFlag = 'Y';
    // }
    // else{
    //   // this.minTargetDateFlag = 'N';
    //   /* new changes */
    //   if(new Date() > this.minTargetDate){
    //     this.minTargetDate = new Date();
    //     this.minTargetDate.setHours(0, 0, 0, 0);
    //     this.minTargetDateFlag = 'Y';
    //   }
    //   else{
    //     this.minTargetDateFlag = 'N';
    //   }

    // }
    //  }
  }
  agentHoursMinsTotal() {
    let splitBasedCondition = this.splitHoursChanged
      ? this.splitHoursChanged
      : this.splitHours;
    console.log('splitBasedCondition', splitBasedCondition);

    this.agentOldHoursTotal = this.ticketagentList.reduce(
      (accumulator, currentValue) => {
        if (splitBasedCondition == 'Y') {
          return (
            accumulator +
            this.sharedService.convertDurationToHours(
              currentValue.allocatedHrsMins,
            )
          );
        } else if (splitBasedCondition == 'N') {
          return (
            accumulator +
            this.sharedService.convertDurationToHours(
              currentValue.totalBookedBHrsMins,
            )
          );
        }
      },
      0,
    );
    var minutes = this.agentOldHoursTotal % 60;
    var hours = (this.agentOldHoursTotal - minutes) / 60;
    console.log('this.agentOldHoursTotal', this.agentOldHoursTotal);
    var minutes = this.agentOldHoursTotal % 60;
    var hours = (this.agentOldHoursTotal - minutes) / 60;
    this.totalMinHours = this.padding(hours);
    this.totalMinMins = this.padding(minutes);
    this.minHours = this.padding(hours);
    this.minMinutes = this.padding(minutes);
    console.log('minHours', this.minHours);
    console.log('minMins', this.minMinutes);
    console.log('agentHoursTotal', this.minHours);
    // console.log("minHours", this.totalMinHours);
    // console.log("minMins", this.totalMinMins);
    // console.log("agentHoursTotal", this.totalMinHours);

    if (Number(this.proposedHrs) > Number(this.totalMinHours)) {
      this.minMinutes = Number(0);
    } else {
      this.minMinutes = Number(this.totalMinMins);
    }
  }
  onSearchChange(key, searchValue) {
    this.agentHoursMinsTotal();
    if (key == 'hours') {
      this.changedFlag = true;
      this.ticketEditForm.controls['vRemarks'].setValue('');
      Validators.required;
      this.proposedHrsc = searchValue;
      this.proposedHrscChangedd = `${
        this.proposedHrsc
          ? this.padding(this.proposedHrsc)
          : this.padding(this.proposedHrs)
      }:${
        this.proposedMinc
          ? this.padding(this.proposedMinc)
          : this.padding(this.proposedMins)
      }`;
      if (Number(searchValue) > Number(this.totalMinHours)) {
        this.minMinutes = Number(0);
      } else {
        this.minMinutes = Number(this.totalMinMins);
      }
    }

    if (key == 'minutes') {
      this.changedFlag = true;
      console.log('proposed minsss>>>', searchValue);

      console.log('proposed minsss>>>', searchValue, this.minMinutes);
      this.ticketEditForm.controls['vRemarks'].setValue('');
      Validators.required;
      this.proposedMinc = searchValue;
      this.proposedHrscChangedd = `${
        this.proposedHrsc
          ? this.padding(this.proposedHrsc)
          : this.padding(this.proposedHrs)
      }:${
        this.proposedMinc
          ? this.padding(this.proposedMinc)
          : this.padding(this.proposedMins)
      }`;
    }
  }

  statusAbr(abr) {
    switch (abr) {
      case 'WFT':
        return 'Waiting for turn';
        break;
      case 'WFC':
        return 'Waiting for confirmation';
        break;
      default:
        return abr;
        break;
    }
  }
  // Check status
  chkStatus(status) {
    if (status) {
      switch (status) {
        case 'Solved':
          return false;
          break;
        case 'Closed':
          return false;
          break;
        case 'Cancelled':
          return false;
          break;
        case 'Hold':
          return false;
          break;
        default:
          return true;
      }
    } else {
      return true;
    }
  }

  deleteAgent(ticketAgent, index: number) {
    if (
      ticketAgent.totalBookedBHrsMins == '00:00' &&
      ticketAgent.totalBookedNBHrsMins == '00:00'
    ) {
      Swal.fire({
        title: 'Information',
        text: 'Do you want to remove the agent',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then((result) => {
        console.log('result', result);
        if (result.isConfirmed) {
          this.changedFlag = true;
          let removeAgent = this.ticketagentList.at(index);
          if (removeAgent.idGNo != null) {
            removeAgent.statusFlag = 'X';
            removeAgent.changed = 'Y';
            this.removeAgent.push(removeAgent);
            this.ticketagentList.splice(index, 1);
          } else {
            this.ticketagentList.splice(index, 1);
            this.agentLocal.splice(index, 1);
          }

          this.resAgentNameList = this.ticketagentList.filter(
            (item) => item.isRespAgent == 'Y',
          );

          console.log('resAgentNameList', this.resAgentNameList);
          this.resAgentValidation =
            this.ticketagentList.length > 0
              ? this.resAgentNameList.length > 0
                ? false
                : true
              : false;
        }
      });
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'Agent already filled the booked hours. If you want to remove agent, please maintain booked hours is zero',
        'bottom',
        'center',
      );
    }
  }
  refreshData() {
    let obj = {ticketCode: this.idTicketCode};
    let refresh = this.ticketService.getParticularTicketFetch(obj);
    console.log('palani', refresh);
  }
}
