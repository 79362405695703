import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FilterBase } from './filter-base.service';

@Injectable({
  providedIn: 'root'
})
export class FilterControllerService {

  constructor() { }

  toFormGroup(filters: FilterBase<any>[]) {
    let group: any = {};

    filters.forEach(filter => {
      group[filter.key] = filter.required
        ? new FormControl(filter.value || '', Validators.required)
        : new FormControl(filter.value || '');
    });

    return new FormGroup(group);
  }

}
