import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";

@Component({
  selector: "app-billing-report-view-dialog",
  templateUrl: "./billing-report-view-dialog.component.html",
  styleUrls: ["./billing-report-view-dialog.component.sass"],
})
export class BillingReportViewDialogComponent implements OnInit, AfterViewInit {
  public afterInit: boolean = false;
  isLoading: boolean = true;
  reportView: any = [];
  reportName:any;
  projectName:any;

  constructor(
    public dialogRef: MatDialogRef<BillingReportViewDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.isLoading = false;
    this.reportView = this.data.viewReport;
    this.reportName = this.data.reportName;
    this.projectName = this.data.projectName;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}
}
