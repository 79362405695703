import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: []
})
export class MainLayoutComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,) {}

  ngOnInit(): void {
    /** spinner starts on init */
    // this.spinner.show();

   // setTimeout(() => {
        /** spinner ends after 5 seconds */
        // this.spinner.hide();
   // }, 2000);
  }
}
