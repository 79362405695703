import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";
import { ProjectmasterModel } from "./projectmaster.model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { ApiService } from "../core/service/api.service";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class ProjectmasterService extends UnsubscribeOnDestroyAdapter {
  private destroy$ = new Subject<void>();
  private detailsDestroy$ = new Subject<void>();

  private readonly API_URL = "assets/data/projectmaster.json";
  isTblLoading = true;
  dataChange: BehaviorSubject<ProjectmasterModel[]> = new BehaviorSubject<
    ProjectmasterModel[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    super();
  }
  get data(): ProjectmasterModel[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */

  async addRule(data) {
    const ruleList$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectRuleCondition",
      data
    );
    let response = await lastValueFrom(ruleList$);
    return response;
  }
  async getListField(searchCriteria) {
    let actionLevelCode = searchCriteria.actionLevelCode;
    const fieldList$ = this.apiService.post(
      "/api/admin/master/getTabsByActionLevel",
      { actionLevelCode }
    );
    let response = await lastValueFrom(fieldList$);
    return response;
  }
  async validateEdit(projectCode) {
    // let actionLevelCode = searchCriteria.actionLevelCode;
    const validate$ = this.apiService.get(
      "/api/admin/master/validateProjectEdit",
      { projectCode }
    );
    let response = await lastValueFrom(validate$);
    return response;
  }
  async getListRule(searchCriteria) {
    let ruleCode = searchCriteria.ruleCode;
    const ruleList$ = this.apiService.get(
      "/api/admin/master/getProjectRuleCondition?ruleCode=" + ruleCode
    );
    let response = await lastValueFrom(ruleList$);
    return response;
  }

  async getListRules(searchCriteria) {
    let projectCode = searchCriteria.projectCode;
    const ruleList$ = this.apiService.get(
      "/api/admin/master/getProjectRuleHeader",
      { projectCode },
      false
    );
    let response = await lastValueFrom(ruleList$);
    return response;
  }
  async getListFieldbyName(searchCriteria) {
    let actionLevelCode = searchCriteria.actionLevelCode;
    let tabName = searchCriteria.tabName;
    const fieldList$ = this.apiService.post(
      "/api/admin/master/getFieldsByTabName",
      { actionLevelCode, tabName: searchCriteria.tabName }
    );
    let response = await lastValueFrom(fieldList$);
    return response;
  }
  getAllUser(): void {
    this.subs.sink = this.httpClient
      .get<ProjectmasterModel[]>(this.API_URL)
      .subscribe(
        (data) => {
          this.isTblLoading = false;
          this.dataChange.next(data);
        },
        (error: HttpErrorResponse) => {
          this.isTblLoading = false;
          console.log(error.name + " " + error.message);
        }
      );
  }
  async addProject(projectDetails) {
    // this.dialogData = formData;
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectMaster",
      projectDetails
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }
  async deleteProject(projectDetails) {
    const deleteProject$ = this.apiService.post(
      "/api/admin/master/deleteProject",
      projectDetails
    );
    let response = await lastValueFrom(deleteProject$);

    return response;
  }
  async deleteSupportingProject(projectDetails) {
    const deleteSupportingProject$ = this.apiService.post(
      "/api/admin/master/deleteProjectWorkingHours",
      projectDetails
    );
    let response = await lastValueFrom(deleteSupportingProject$);

    return response;
  }
  async deleteCommercialProject(projectDetails) {
    // this.dialogData = formData;
    const deleteCommercialProject$ = this.apiService.post(
      "/api/admin/master/deleteProjectCommercials",
      projectDetails
    );
    let response = await lastValueFrom(deleteCommercialProject$);

    return response;
  }
  async deleteTicketProject(projectDetails) {
    // this.dialogData = formData;
    const deleteTicketProject$ = this.apiService.post(
      "/api/admin/master/deleteProjectTicketType",
      projectDetails
    );
    let response = await lastValueFrom(deleteTicketProject$);

    return response;
  }
  async deleteTicketClassificationProject(projectDetails) {
    // this.dialogData = formData;
    const deleteTicketClassificationProject$ = this.apiService.post(
      "/api/admin/master/updateProjectTicketTypeAndClassifications",
      projectDetails
    );
    let response = await lastValueFrom(deleteTicketClassificationProject$);

    return response;
  }
  async copyProject(copyProjectDetails) {
    // this.dialogData = formData;
    const copyProject$ = this.apiService.post(
      "/api/admin/master/copyProject",
      copyProjectDetails,
      { headers: new HttpHeaders({ timeout: `${100000}` }) }
    );
    let response = await lastValueFrom(copyProject$);

    return response;
  }
  async addTicketClassificationProject(ticketDetails) {
    //this.dialogData = formData;
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveProjectTicketTypeAndClassifications",
      ticketDetails
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }

  async addSupportingHours(supportingHours) {
    // this.dialogData = formData;
    const addHours$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectSupportHours",
      supportingHours
    );
    let response = await lastValueFrom(addHours$);

    return response;
  }

  async addCommercialProject(commercialDetails) {
    // this.dialogData = formData;
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectCommercials",
      commercialDetails
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }

  async addRuleProject(ruleDetails) {
    // this.dialogData = formData;
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateRuleHeader",
      ruleDetails
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }

  async addAttachmentProject(formData: FormData) {
    this.dialogData = formData;
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectAttachments",
      this.dialogData
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }
  async getListProjectMaster(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    let searchValue = searchCriteria.searchValue;
    let searchField = searchCriteria.searchColumn;
    const projectList$ = this.apiService.post(
      "/api/admin/master/getProjectMasterList",
      searchCriteriaBody,
      { page, size, sort, sortByAscending, searchValue, searchField },
      false
    );
    let response = await lastValueFrom(projectList$);
    return response;
  }
  async getListParticularProject(searchCriteria) {
    const projectList$ = this.apiService.post(
      "/api/admin/master/getProjectMasterList",
      searchCriteria
    );
    let response = await lastValueFrom(projectList$);
    return response;
  }

  async getProjectTypeList(searchCriteria) {
    const getProjectTypeList$ = this.apiService.get(
      "/api/admin/master/getProjectTypesByProductCode?idProductCode=" +
        searchCriteria.idProductCode
    );
    let response = await lastValueFrom(getProjectTypeList$);
    return response;
  }
  async getProjectClassificationList(searchCriteria) {
    const getProjectclassificationList$ = this.apiService.get(
      "/api/admin/master/getSupportingTypesByprojectTypeCode?idProjectTypeCode=" +
        searchCriteria.idProjectTypeCode
    );
    let response = await lastValueFrom(getProjectclassificationList$);
    return response;
  }

  async colorValidation(code) {
    const colorValidation$ = this.apiService.post(
      "/api/admin/master/isProjectColorCodeDuplicate",
      { colorCode: code.randomColor, idProjectCode: code.projectCode },
      false
    );
    let response = await lastValueFrom(colorValidation$);
    return response;
  }

  async getProjectStatusTypeSaved(ticketDetails) {
    const customerManagerList$ = this.apiService.get(
      "/api/admin/master/getProjectStatusTypes?projectCode=" +
        ticketDetails.idProjectCode +
        "&ttClassificationCode=" +
        ticketDetails.idTtClassificationCode +
        "&ttCode=" +
        ticketDetails.idTtCode
    );
    let response = await lastValueFrom(customerManagerList$);
    return response;
  }

  async getProjectPrioritySaved(ticketDetails) {
    const customerManagerList$ = this.apiService.get(
      "/api/admin/master/getProjectPriorities?projectCode=" +
        ticketDetails.idProjectCode +
        "&ttClassificationCode=" +
        ticketDetails.idTtClassificationCode +
        "&ttCode=" +
        ticketDetails.idTtCode
    );
    let response = await lastValueFrom(customerManagerList$);
    return response;
  }

  async getActionNameList(value) {
    const actionNameList$ = this.apiService.get(
      "/api/admin/master/getActionsByActionMode?actionModeCode=" + value
    );
    let response = await lastValueFrom(actionNameList$);
    return response;
  }

  async getActionDetailsList(value, actionMode) {
    const actionDetailsList$ = this.apiService.get(
      "/api/admin/master/getDetailsByAction?actionCode=" +
        value +
        "&actionModeCode=" +
        actionMode
    );
    let response = await lastValueFrom(actionDetailsList$);
    return response;
  }

  async getSubGroupList(groupCode, projectCode, groupType, companyCode) {
    const subGroupList$ = this.apiService.get(
      "/api/admin/master/getSubGroupByGroupCodeAndProjectCode?groupCode=" +
        groupCode +
        "&projectCode=" +
        projectCode +
        "&groupType=" +
        groupType +
        "&companyCode=" +
        companyCode
    );
    let response = await lastValueFrom(subGroupList$);
    return response;
  }

  async subGroupMappingProject(data) {
    const addProject$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectSubGroupMapping",
      data
    );
    let response = await lastValueFrom(addProject$);

    return response;
  }

  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }
  updateUser(user: ProjectmasterModel): void {
    this.dialogData = user;

    /* this.httpClient.put(this.API_URL + contacts.id, contacts).subscribe(data => {
      this.dialogData = contacts;
    },
    (err: HttpErrorResponse) => {
      // error code here
    }
  );*/
  }
  deleteUser(id: number): void {
    console.log(id);

    /*  this.httpClient.delete(this.API_URL + id).subscribe(data => {
      console.log(id);
      },
      (err: HttpErrorResponse) => {
         // error code here
      }
    );*/
  }

  async getRuleList() {
    const projectList$ = this.apiService.get(
      "/api/admin/master/getRulesDropDowns"
    );
    let response = await lastValueFrom(projectList$);
    return response;
  }

  async setTicketSubStatus(req) {
    const subStatusList$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectStatusType",
      req
    );
    let response = await lastValueFrom(subStatusList$);
    return response;
  }

  async setTicketPriority(req) {
    const priorityList$ = this.apiService.post(
      "/api/admin/master/saveOrUpdateProjectPriority",
      req
    );
    let response = await lastValueFrom(priorityList$);
    return response;
  }

  async getSupportingList(projectCode) {
    const supportingList$ = this.apiService.get(
      "/api/admin/master/getProjectSupportHours?projectCode=" + projectCode
    );
    let response = await lastValueFrom(supportingList$);
    return response;
  }

  async getTicketList(projectCode) {
    const ticketList$ = this.apiService.get(
      "/api/admin/master/getProjectTicketTypeAndClassifictions?projectCode=" +
        projectCode
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getAttachmentList(projectCode) {
    const attachmentList$ = this.apiService.get(
      "/api/admin/master/getProjectAttachments?projectCode=" + projectCode
    );
    let response = await lastValueFrom(attachmentList$);
    return response;
  }

  async changePassword(formData: FormData) {
    this.dialogData = formData;
    const changePassword$ = this.apiService.post(
      "/api/admin/master/updatePassword",
      this.dialogData
    );
    let response = await lastValueFrom(changePassword$);
    return response;
  }

  async getSubGroupUsers(searchCriteriaBody) {
    let page = "0";
    let size = "0";

    const subGroupUserList$ = this.apiService.post(
      "/api/admin/master/getSubGroupMembers",
      searchCriteriaBody,
      { page, size },
      false
    );
    let response = await lastValueFrom(subGroupUserList$);
    return response;
  }
  async getDepartmentRoleMapping(projectCode) {
    const departmentRoleMapping$ = this.apiService.get(
      "/api/hap/master/getHapProjectDeptProductModuleRoleMapping?projectCode=" +
        projectCode
    );
    let response = await lastValueFrom(departmentRoleMapping$);
    return response;
  }
  getProjectStatusList(projectCode): Observable<any> {
    const abortController = new AbortController();
    return this.apiService
      .get(
        "/api/hap/master/getHapProjectModuleTicketSubStatus?projectCode=" +
          projectCode,
        { signal: abortController.signal }
      )
      .pipe(takeUntil(this.destroy$));
    /*  let response =  lastValueFrom(projectStatus$);
     return response;*/
  }
  getCommercialList(projectCode): Observable<any> {
    const abortController = new AbortController();
    /* const commercialList$ = this.apiService.get(
            '/api/admin/master/getProjectCommercials?projectCode=' + projectCode,
        );*/
    return this.apiService
      .get(
        "/api/admin/master/getProjectCommercials?projectCode=" + projectCode,
        { signal: abortController.signal }
      )
      .pipe(takeUntil(this.destroy$));
  }
  cancelRequests(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
  }
  getProjectList(searchCriteria) {
    const abortController = new AbortController();
    return this.apiService
      .post(
        "/api/admin/master/getAllMastersByGNo?countryCode=" +
          searchCriteria.countryCode,
        searchCriteria,
        { signal: abortController.signal }
      )
      .pipe(takeUntil(this.detailsDestroy$));
  }
  getClassificationList() {
    const abortController = new AbortController();
    return this.apiService
      .get("/api/admin/master/getMasterTicketTypeClassificationList", {
        signal: abortController.signal,
      })
      .pipe(takeUntil(this.detailsDestroy$));
  }
  getCustomerManagerList(searchCriteria) {
    const abortController = new AbortController();
    return this.apiService
      .get(
        "/api/admin/master/getCustomerManagersAndGroupsByCompanyCode?idCompanyCode=" +
          searchCriteria.companyCode,
        { signal: abortController.signal }
      )
      .pipe(takeUntil(this.detailsDestroy$));
  }
  cancelProjectDetailsRequests(): void {
    this.detailsDestroy$.next();
    this.detailsDestroy$.complete();
    this.detailsDestroy$ = new Subject<void>();
  }
}
