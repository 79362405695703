import { formatDate } from '@angular/common';
export class TicketModel {
  id: any;
  img: string;
  gNo: string ;
  projectCode: string ;
  ticketCode?: string ;
  ticketPriorityCode: string ;
  ticketStatusCode?: string ;
  ticketTags: string ;
  ticketTypeClassificationCode: string ;
  ticketTypeCode: string ;
  internalNotes?: string ;
  irtDue?: number ;
  mptDue?: number ;
  proxyUserCode: string ;
  rating?: string ;
  referenceTicket: string ;
  colorCode?: string ;
  createdBy?: string ;
  createdDate?: string ;
  createdTime?: string ;
  moduleCode?: number;
  attachments: any;
  subject: string;
  body: string;
   

  constructor(ticket) {
    {
      this.id = ticket.id || 0;
      this.gNo = ticket.gNo || '';
      this.img = ticket.img || 'assets/images/user/usrbig1.jpg';
      this.projectCode = ticket.projectCode || '';
      this.ticketPriorityCode = ticket.ticketPriorityCode || '';
      this.ticketTypeClassificationCode = ticket.ticketTypeClassificationCode || '';
      this.ticketTypeCode = ticket.ticketTypeCode || '';
      this.proxyUserCode = ticket.proxyUserCode || '';
      this.referenceTicket = ticket.referenceTicket || '';
      this.attachments = ticket.attachments || [];
      this.ticketTags = ticket.ticketTags || '';
      this.subject = ticket.subject || '';
      this.body = ticket.body || '';
      this.moduleCode = ticket.moduleCode || '';
      
    }
  }
  
}
