import {FieldMsg} from './../../../core/service/field-msg.service';
import {TicketNewService} from 'src/app/ticket-new/ticket.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, Subscription} from 'rxjs';
import {TicketModel} from 'src/app/ticket/ticket.model';
import {TicketService} from 'src/app/ticket/ticket.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SharedService} from 'src/app/core/service/shared.service';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import {environment} from 'src/environments/environment';

import {NgOtpInputComponent, NgOtpInputConfig} from 'ng-otp-input';
import {Employee, HiearachyTreeService} from "./hiearachy-tree.service";

@Component({
  selector: 'app-hiearachy-tree',
  templateUrl: './hiearachy-tree.component.html',
  styleUrls: ['./hiearachy-tree.component.css'],
})
export class HiearachyTreeComponent implements OnInit {
  public Editor = ClassicEditor;

  hiearachyTreeForm: FormGroup;
    currentUser: any;
    roleCheck: any;
    routerUrl: any;
    dialogTitle:any;
    //dummy data
    employees: Employee[] = [];
    selectedEmployee: Employee;

    lookupData: any;
  constructor(
    public dialogRef: MatDialogRef<HiearachyTreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private HiearachyTreeService: HiearachyTreeService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg,
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.roleCheck = this.currentUser.data[0].roles;
    this.routerUrl = this.sharedService.routerUrl();
    console.log(this.routerUrl, 'currentUrl');
this.dialogTitle ='Tree View';

      this.employees = this.HiearachyTreeService.getEmployees();
      this.selectEmployee = this.selectEmployee.bind(this);


  }
  ngOnDestroy(): void {}

  ngOnInit() {

  }
    selectEmployee(e) {
        e.component.byKey(e.currentSelectedRowKeys[0]).done(employee => {
            if(employee) {
                this.selectedEmployee = employee;
            }
        });
    }
    onNoClick() {
        this.dialogRef.close({action: 'close'});
    }

}
