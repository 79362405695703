import { Component, HostListener } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { MessagingService } from "./service/messaging-service";
import { AuthService } from "./core/service/auth.service";
import { SharedService } from "./core/service/shared.service";

import { HeaderComponent } from "./layout/header/header.component";
import { fromEvent, Observable, Subscription } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUrl: string;
  title = "firebase -app";
  message: any;
  private isNavigatingAway = false;
  currentUser: any;
  currentURlWindow: any;
  token: any;
  isLogoutNeeded = false;
  sharedValue: any;

  receviedMessage: any = [];

  online: boolean;
  isNetworkStopped = false;

  constructor(
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private messagingService: MessagingService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    window.addEventListener("push", function (event) {
      console.log("message recivedded appp", event);
    });

    window.addEventListener("storage", function (event) {
      let storageToken = localStorage.getItem("logout-event");
      if (storageToken === null) {
        console.log("storageTokenstorageToken", storageToken);
      } else {
        if (
          event.storageArea.LocalToken === undefined ||
          event.storageArea.LocalToken === null ||
          event.storageArea.LocalToken === ""
        ) {
          console.log("logout on other tab");
          authService.logout();
          localStorage.removeItem("logout-event");
          _router.navigate(["/authentication/signin"]);
        }
      }
    });
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // this.spinner.show();
        /*    this.spinner.show();
        location.onPopState(() => {
          window.location.reload();
        });*/
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        //  this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }
  ngOnInit() {
    this.netWorkLost();
    this.serviceWork();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessaging();
    this.message = this.messagingService.currentMessage;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      document.addEventListener('DOMNodeInserted', function () {
          const elements = document.querySelectorAll('[aria-owns]');

          elements.forEach(element => {
              element.removeAttribute('aria-owns');
          });
      });
    console.log("app message received", this.message);
  }

  netWorkLost() {
    this.online = window.navigator.onLine;
    fromEvent(window, "online").subscribe((e) => {
      this.online = true;
      Swal.fire({
        title: "Back to online",
        icon: "success",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Reload the page if the user clicked "Ok"
          window.location.reload();
        }
      });
    });

    fromEvent(window, "offline").subscribe((e) => {
      this.online = false;
      this.sharedService.loaderFlag = false;
      Swal.fire({
        title: "NetWork Lost",
        text: "You are offline",
        icon: "error",
        showConfirmButton: false,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      });
    });
  }
  //registration browser service worker
  async serviceWork() {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          { scope: "/" }
        );
        console.log(
          "Service Worker registered successfully",
          registration.scope
        );
        navigator.serviceWorker.addEventListener("message", (event) => {
          console.log("Message received:", event.data);
          let notificationList = event.data.notification;
          this.receviedMessage.push(notificationList);
          this.sharedService.notifyMessage = this.receviedMessage;
          this.sharedService.notifyCount = this.receviedMessage.length;

          console.log("notifyCount", this.sharedService.notifyCount);
        });
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    }
  }

  //auto refresh
  @HostListener("window:beforeunload")
  setToSession(): void {
    if (this.currentUser) {
      const session = this.currentUser.data[0].token;
      const user = this.currentUser.data[0].userInfo;
      if (user && session === null) {
        this.authService.logout();
      }
      console.log(this.currentUser);
      const token = this.currentUser.data[0].token;
      console.log(token);
      sessionStorage.setItem("tempToken", JSON.stringify(token));
    }
  }
  //on refresh
  @HostListener("window:load")
  clearLocalStorage(): void {
    if (this.currentUser) {
      const session = this.currentUser.data[0].token;
      const user = this.currentUser.data[0].userInfo;
      if (user && session === null) {
        console.log("TOKEN REMOVED...");
        this.authService.logout();
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }
}
