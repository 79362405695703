import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { lastValueFrom } from "rxjs";
import { ApiService } from "../core/service/api.service";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { AuthService } from "../core/service/auth.service";
import { Router } from "@angular/router";
import { HeaderComponent } from "../layout/header/header.component";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject<any>(null);
  messages: Array<Message> = [];
  token: any;
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    public apiService: ApiService,
    private authService: AuthService,
    public router: Router
  ) {}

  /* this.dialogData = Fuel;
    const data = this.apiService.post(
        `${this.ticketBaseApi}/v1/updateTicket`,
        this.dialogData,
        "",
        true
    );
    let response = await lastValueFrom(data);
    return response;*/
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("TOKEN ==== ", token);
        this.token = token;
      },
      (err) => {
        console.log("Unable to get permission to notify.. (", err);
      }
    );
  }
  async pushNotify() {
    console.log("let firebaseToken", this.token);
    let json = {
      firebaseToken: this.token,
      isWeb: true,
    };
    const data = this.apiService.post(
      `/api/admin/master/saveOrUpdateFireBaseToken`,
      json,
      "",
      false
    );
    let response = await lastValueFrom(data);
  }
  receiveMessaging() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message recieved check>>", payload);
      this.currentMessage.next(payload);
      console.log("notifyyyyy", this.currentMessage.value);
      let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      let roleUser = currentUser.data[0]?.roles[0];
      // console.log("suzyyy..........>>", roleUser);
      let pushNotify = this.currentMessage.value;
      const body = pushNotify.notification.body;
      const title = pushNotify.notification.title;
      if (roleUser !== null || roleUser !== undefined) {
        if (title === "force logout" && body === "auto logout") {
          this.authService.logout();
          this.router.navigate(["/authentication/signin"]);
        }
      }
    });
  }
}
