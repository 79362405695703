import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TokenExpirationService {
    isTokenExpired(tokenExpirationTime: number): boolean {
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
        return currentTime >= tokenExpirationTime;
    }
}
