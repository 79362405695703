import { ScrapConfirmationDialogComponent } from "./dialog-boxes/scrap-confirmation-dialog/scrap-confirmation-dialog.component";
import { TicketDispatchDialogComponent } from "./dialog-boxes/ticket-dispatch-dialog/ticket-dispatch-dialog.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./feather-icons.module";
import { ConfirmationDialogComponent } from "./dialog-boxes/confirmation-dialog/confirmation-dialog.component";
import { BasicFilterDialogComponent } from "./dialog-boxes/basic-filter-dialog/basic-filter-dialog.component";
import { ShowColDialogComponent } from "./dialog-boxes/show-column-dialog/show-column-dialog.component";
import { TicketTimeDialogComponent } from "./dialog-boxes/ticket-time-dialog/ticket-time-dialog.component";
import { ServiceProviderDialogComponent } from "./dialog-boxes/service-provider-dialog/service-provider-dialog.component";
import { CustomerProviderDialogComponent } from "./dialog-boxes/customer-provider-dialog/customer-provider-dialog.component";
import { RuleConditionDialogComponent } from "./dialog-boxes/rule-condition-dialog/rule-condition-dialog.component";
import { CreateTicketDialogComponent } from "./dialog-boxes/create-ticket-dialog/create-ticket-dialog.component";
import { VerifyAssetTagComponent } from "./dialog-boxes/verify-asset-tag/verify-asset-tag.component";
import { VerifyDeviceTagComponent } from "./dialog-boxes/verify-device-tag/verify-device-tag.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PageFilterComponent } from "./components/page-filter/page-filter.component";
import { MatSelectModule } from "@angular/material/select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { MatChipsModule } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { TicketService } from "../ticket/ticket.service";
import { TicketNewService } from "../ticket-new/ticket.service";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { ChangeCompanyDialogComponent } from "./dialog-boxes/change-company-dialog/change-company-dialog.component";
import { UpdatePasswordDialogComponent } from "./dialog-boxes/update-password-dialog/update-password-dialog.component";
import { TicketStatusPriorityDialogComponent } from "./dialog-boxes/ticket-status-priority-dialog/ticket-status-priority-dialog.component";
import { MatTabsModule } from "@angular/material/tabs";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import {
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from "@angular/material/radio";
import { TicketEditDialogComponent } from "./dialog-boxes/ticketeditdialog/ticket-edit-dialog.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatMenuModule } from "@angular/material/menu";
import { MatStepperModule } from "@angular/material/stepper";
import { AgentDialogComponent } from "./dialog-boxes/agent-dialog/agent-dialog.component";
import { CopyProjectDialogComponent } from "./dialog-boxes/copy-project-dialog/copy-project-dialog.component";
import { InfoDialogComponent } from "./dialog-boxes/info-dialog/info-dialog.component";
import { AgentHistoryDialogComponent } from "./dialog-boxes/agent-history-dialog/agent-history-dialog.component";

import { PopupDialogComponent } from "./popup-dialog/popup-dialog.component";
import { HttpClientModule } from "@angular/common/http";
import { AssignedtoDialogComponent } from "./dialog-boxes/assignedto-dialog/assignedto-dialog.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { AttachmentDialogComponent } from "./dialog-boxes/attachment-dialog/attachment-dialog.component";
import { MatBadgeModule } from "@angular/material/badge";
import { AgentlistDialogComponent } from "./dialog-boxes/agentlist-dialog/agentlist-dialog.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ProjectmasterService } from "../projectmaster/projectmaster.service";
import { ResetPasswordDialogComponent } from "./dialog-boxes/reset-password-dialog/reset-password-dialog.component";
import { EditAgentDialogComponent } from "./dialog-boxes/edit-agent-dialog/edit-agent-dialog.component";
import { TicketHistoryDialogComponent } from "./dialog-boxes/time-history-dialog/time-history-dialog.component";
import { CustomergrplistComponent } from "./dialog-boxes/customergrplist/customergrplist.component";
import { ProvidergrplistComponent } from "./dialog-boxes/providergrplist/providergrplist.component";
import { FilterPipe } from "../pipes/filter.pipe";
import { NumberFilterPipe } from "../pipes/filter.pipe";
import { AutofocusDirective } from "../directives/autofocus.directive";
import { ProjectwiseviewComponent } from "./dialog-boxes/projectwiseview/projectwiseview.component";
import { BillingReportViewDialogComponent } from "./dialog-boxes/billing-report-view-dialog/billing-report-view-dialog.component";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { TimeSheetviewComponent } from "./dialog-boxes/timesheetview/timesheetview.component";
import { EmailTicketViewComponent } from "../authentication/emailTicketView/emailTicketView.component";
import { JobCardComponent } from "./dialog-boxes/job-card/job-card-component";
import { AssetMaterialComponent } from "./dialog-boxes/asset-material/asset-material-component";
import { TicketTimeLineComponent } from "./dialog-boxes/ticket-timeline/ticket-timeline.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import {
  DxDataGridModule,
  DxMapModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTreeListModule,
  DxValidatorModule,
  DxNumberBoxModule,
  DxAutocompleteModule,
} from "devextreme-angular";
import { NgOtpInputModule } from "ng-otp-input";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { HiearachyTreeComponent } from "./dialog-boxes/hiearachy-tree-dialog/hiearachy-tree.component";
import { HiearachyTreeService } from "./dialog-boxes/hiearachy-tree-dialog/hiearachy-tree.service";
import { ProjectModuleStatusComponent } from "./dialog-boxes/projectModuleStatus-dialog/projectModuleStatus.component";
import { PartRequestService } from "../part-request/part-request.service";
import { ScrapDetailsDialogComponent } from "./dialog-boxes/scrap-details-dialog/scrap-details-dialog.component";
import { DeviceReplacementDialogComponent } from "./dialog-boxes/device-replacement-dialog/device-replacement-dialog.component";
import { TicketAttachmentDialogComponent } from "./dialog-boxes/ticket-attachment-dialog/ticket-attachment-dialog.component";
import { ScrapDetailsHubDialogComponent } from "./dialog-boxes/scrap-details-hub-dialog/scrap-details-hub-dialog.component";
import { HubTicketDispatchDialogComponent } from "./dialog-boxes/hub-ticket-dispatch-dialog/hub-ticket-dispatch-dialog.component";
import { AssetApproverejectDialogComponent } from "./dialog-boxes/asset-approvereject-dialog/asset-approvereject-dialog.component";
import { MasterDelDialogComponent } from "./master-del-dialog/master-del-dialog.component";
import { ConsumableVerifyAssetTagComponent } from "./dialog-boxes/consumable-verify-asset-tag/consumable-verify-asset-tag.component";
import { ConsumableJobCardComponent } from "./dialog-boxes/consumable-job-card/consumable-job-card-component";
import { MailLogStatusDialogComponent } from "./dialog-boxes/mailLog-status-dialog/mailLog-status-dialog.component";
import { MailLogViewDialogComponent } from "./dialog-boxes/mailLog-view-dialog/mailLog-view-dialog.component";
import { BulkexcelUploadDialogComponent } from "./dialog-boxes/bulkexcel-upload-dialog/bulkexcel-upload-dialog.component";
import { ImproperCleaningComponent } from "./dialog-boxes/improper-cleaning/improper-cleaning-component";
import { MailViewComponent } from "./components/mail-view/mail-view.component";

@NgModule({
  declarations: [
    ProjectModuleStatusComponent,
    MailLogStatusDialogComponent,
    MailLogViewDialogComponent,
    MailViewComponent,
    BulkexcelUploadDialogComponent,
    EmailTicketViewComponent,
    AttachmentDialogComponent,
    AssignedtoDialogComponent,
    ConfirmationDialogComponent,
    BasicFilterDialogComponent,
    ShowColDialogComponent,
    TicketTimeDialogComponent,
    HiearachyTreeComponent,
    CustomergrplistComponent,
    ProvidergrplistComponent,
    ServiceProviderDialogComponent,
    CustomerProviderDialogComponent,
    RuleConditionDialogComponent,
    InfoDialogComponent,
    AgentHistoryDialogComponent,
    ProjectwiseviewComponent,
    TimeSheetviewComponent,
    CopyProjectDialogComponent,
    FileUploadComponent,
    PageFilterComponent,
    CreateTicketDialogComponent,
    ChangeCompanyDialogComponent,
    UpdatePasswordDialogComponent,
    TicketStatusPriorityDialogComponent,
    TicketEditDialogComponent,
    TicketHistoryDialogComponent,
    AgentDialogComponent,
    EditAgentDialogComponent,
    AgentlistDialogComponent,
    PopupDialogComponent,
    ResetPasswordDialogComponent,
    BillingReportViewDialogComponent,
    DeviceReplacementDialogComponent,
    AssetApproverejectDialogComponent,
    TicketAttachmentDialogComponent,
    HubTicketDispatchDialogComponent,
    FilterPipe,
    NumberFilterPipe,
    AutofocusDirective,
    ImproperCleaningComponent,
    VerifyAssetTagComponent,
    VerifyDeviceTagComponent,
    ConsumableVerifyAssetTagComponent,
    ConsumableJobCardComponent,
    JobCardComponent,
    AssetMaterialComponent,
    TicketTimeLineComponent,
    TicketDispatchDialogComponent,
    ScrapConfirmationDialogComponent,
    ScrapDetailsHubDialogComponent,
    ScrapDetailsDialogComponent,
    MasterDelDialogComponent,
  ],
  entryComponents: [TicketEditDialogComponent],
  imports: [
    DxDataGridModule,
    MatAutocompleteModule,
    CKEditorModule,
    CommonModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatSidenavModule,
    DragDropModule,
    MatFormFieldModule,
    NgbModule,
    NgxSpinnerModule,
    FeatherIconsModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTabsModule,
    MatRadioModule,
    AngularDualListBoxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    DxTreeListModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatStepperModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule,
    NgOtpInputModule,
    DxMapModule,
    ZXingScannerModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxAutocompleteModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MatBadgeModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    FileUploadComponent,
    FilterPipe,
    NumberFilterPipe,
    NgxMatSelectSearchModule,
    AutofocusDirective,
    DxPopupModule,
    DxScrollViewModule,
    DxMapModule,
  ],
  /* providers:[ TicketService,{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'accent' },
} ]*/
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: TicketEditDialogComponent },
    TicketService,
    TicketNewService,
    ProjectmasterService,
    HiearachyTreeService,
    PartRequestService,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "accent" },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
