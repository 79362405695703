import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  AbstractControlOptions,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";
import { ProjectmasterService } from "src/app/projectmaster/projectmaster.service";
import { Router } from "@angular/router";
import { FieldMsg } from "src/app/core/service/field-msg.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./update-password-dialog.component.html",
  styleUrls: ["./update-password-dialog.component.sass"],
})
export class UpdatePasswordDialogComponent {
  action: string;
  dialogTitle: string;
  isDetails = false;
  changeForm: FormGroup;
  hideOldPwd: boolean = true;
  hideNewPwd: boolean = true;
  firstLogin: any;
  success = "";
  value: any;

  hideCfmPwd: boolean = true;
  submitted: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private router: Router,
    public fieldMsg: FieldMsg,
    public projectmasterService: ProjectmasterService
  ) {
    console.log("data", data);
    this.isDetails = false;
    this.dialogTitle = "Change Password";
    this.changeForm = this.changePasswordForm();
    console.log(this.changeForm.value);
  }

  changePasswordForm(): FormGroup {
    return this.fb.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: ConfirmedValidator("newPassword", "confirmPassword"),
      } as AbstractControlOptions
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass
      ? null
      : {
          notSame: true,
        };
  }
  submit() {
    // emppty stuff
  }
  get f() {
    return this.changeForm.controls;
  }
  onNoClick(): void {
    this.dialogRef.close({ action: "close" });
  }
  public async changePassword() {
    if (this.changeForm.invalid) {
      return;
    }
    let data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.firstLogin = data ? data.data[0]?.firstLogin : null;
    console.log("firstLogin", this.firstLogin);
    this.success = JSON.stringify(this.changeForm.value);
    console.log("success", this.success);
    this.spinner.show();

    // var changeDetails = {
    //   firstLogin: this.firstLogin,
    //   newPassword: this.changeForm.value.newPassword,
    //   oldPassword: this.changeForm.value.oldPassword,

    // };
    // console.log("change Password", changeDetails);

    let formData = new FormData();

    formData.append("firstLogin", this.firstLogin);
    formData.append("newPassword", this.changeForm.value.newPassword);
    formData.append("oldPassword", this.changeForm.value.oldPassword);

    try {
      let response = await this.projectmasterService.changePassword(formData);
      console.log("response????", response);
      if (response?.body?.success) {
        this.sharedService.showNotification(
          "snackbar-success",
          response?.body?.message,
          "bottom",
          "center"
        );
        this.spinner.hide();
        this.dialogRef.close();
        this.router.navigate(["/authentication/signin"]);
      } else {
        this.sharedService.showNotification(
          "snackbar-danger",
          response?.body?.message,
          "bottom",
          "center"
        );
        this.spinner.hide();
      }
    } catch (e) {
      //console.log("Error>>>>>>>>>>>>", e);
      //let error = e.error;
      this.sharedService.showNotification(
        "snackbar-danger",
        e,
        "bottom",
        "center"
      );
      this.spinner.hide();
    }
  }
  onKeyPress(event: any) {
    console.log("spacesss", event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
}

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
