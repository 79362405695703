import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component implements OnInit {
  constructor(private router: Router, private zone: NgZone,
              public dialogRef: MatDialogRef<Page404Component>,
  ) {}
  ngOnInit() {}

  submit() {
      this.router.navigate(['/dashboard/main']);
  }
    onNoClick() {
      // this.dialogRef.close({action: 'close'});
      this.router.navigate(['/dashboard/main']);
    }
}
