import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  NgZone,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/core/service/auth.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { SharedService } from "src/app/core/service/shared.service";
import { MatDialog } from "@angular/material/dialog";
import { ResetPasswordDialogComponent } from "../../shared/dialog-boxes/reset-password-dialog/reset-password-dialog.component";
import { environment } from "../../../environments/environment";
import { MessagingService } from "../../service/messaging-service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-signin",
  templateUrl: "./signin-superadmin.component.html",
  styleUrls: ["./signin-superadmin.component.scss"],
})
export class SigninSuperadminComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  loginForm: FormGroup;
  submitted: boolean = false;
  forceLoginValue: boolean = false;
  isLoading: boolean = false;
  loginButton: boolean = true;
  error = "";
  gSignErrMsg: any;
  forceLogin: any;
  hide: boolean = true;
  companyList = [];
  sideBar = [];
  isMultiCmpy: boolean = false;
  hidePwd: boolean = true;
  rememberMe: boolean = false;
  title = "loginGoogle";
  auth2: any;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  isSubmitted: boolean = false;
  loginBtn: boolean = true;
  version = environment.appVersion;
  gSigin: any = environment.gSigin;
  gSignInLabel: any = environment.gSignInLabel;
  emailId: any = "";
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private messagingService: MessagingService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    private zone: NgZone,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {
    super();
    this.rememberMe =
      localStorage.getItem("rememberCurrentUser") == "true" ? true : false;
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.email, Validators.minLength(5)],
      ],
      password: ["", Validators.required],
      gNo: [""],
      rememberMe: [this.rememberMe],
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  forceLogins(value) {
    this.forceLogin = value;
    this.onSubmit();
  }
  onSubmit() {
    // this.spinner.show();
    this.submitted = true;
    this.error = "";
    console.log(this.loginForm.invalid, "invalid");

    if (this.emailId ? false : this.loginForm.invalid) {
      this.error = "Username and Password not valid !";

      return;
    } else {
      this.spinner.show();
      let platformId = 1;
      let authMode = this.emailId ? "google auth" : "user login";
      this.isSubmitted = true;
      console.log("form value", this.f);
      this.subs.sink = this.authService
        .login(
          this.emailId ? this.emailId : this.f.email.value,
          this.f.password.value ? this.f.password.value : "welcome",
          this.f.gNo.value,
          this.emailId ? true : this.forceLogin,
          authMode,
          platformId
        )
        .subscribe(
          (res) => {
            console.log("res>>>", res);
            if (res) {
              const status = this.authService.currentUserValue.success;
              const message = this.authService.currentUserValue.message;
              const data = this.authService.currentUserValue;

              this.isMultiCmpy = false;
              this.isSubmitted = false;
              if (status && message == "Success") {
                this.emailId = "";
                this.sharedService.setFlag(false);
                console.log("data", data);
                let firstLogin = data ? data.data[0]?.firstLogin : null;
                let userImage = data.data[0].userInfo.userProfileUrl;
                let userName = data.data[0].userInfo.username;
                let roles = data.data[0].roles[0];
                console.log("firstLogin", firstLogin);

                if (
                  roles == "ROLE_HUB_EMPLOYEE" ||
                  roles == "ROLE_HUB_INCHARGE"
                ) {
                  this.getMappedHubPlantDetails();
                }

                if (firstLogin == "N") {
                  // this.router.navigate(['/dashboard/main']);

                  this.zone.run(() => {
                    setTimeout(() => {
                      this.router.navigate(["/dashboard/main"]);
                    }, 1000);
                  });
                  let notifyResponse = this.messagingService.pushNotify();
                  console.log("notifyResponsenotifyResponse", notifyResponse);
                } else {
                  if (authMode == "user login") {
                    this.resetPassword(firstLogin, userImage, userName);
                  } else {
                    this.router.navigate(["/dashboard/main"]);
                    let notifyResponse = this.messagingService.pushNotify();
                    console.log("second...", notifyResponse);
                  }
                }
              } else if (status && message == "Multiple Companies Available") {
                this.error = "";
                this.companyList = data?.data;
                this.isMultiCmpy = true;

                return;
              } else {
                this.error = message;
                this.gSignErrMsg = message;
                // this.renderer.selectRootElement('#email').focus();
                this.zone.run(() => {
                  setTimeout(() => {
                    this.router.navigate(["/authentication/super-login"]);
                  }, 1000);
                });

                this.emailId = "";
                if (
                  this.error ==
                  "already session is active. do you want to terminate the session and force login?"
                ) {
                  this.forceLoginValue = true;
                  this.loginButton = false;
                } else {
                  this.forceLoginValue = false;
                  this.loginButton = true;
                }
              }
            } else {
              this.emailId = "";
              this.error = "Invalid Login";
              this.zone.run(() => {
                setTimeout(() => {
                  this.router.navigate(["/authentication/super-login"]);
                }, 1000);
              });
            }
            this.spinner.hide();
          },

          (error) => {
            this.spinner.hide();
            this.emailId = "";
            console.log("error", error);
            this.submitted = false;
            this.isSubmitted = false;
            this.error = error;
            this.zone.run(() => {
              setTimeout(() => {
                this.router.navigate(["/authentication/super-login"]);
              }, 1000);
            });
          }
        );
    }
  }
  clearMessage() {
    this.error = "";
    this.forceLoginValue = false;
    this.loginButton = true;
  }

  resetPassword(firstLogin, userImage, userName) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      data: {
        firstLogin: firstLogin,
        userImage: userImage,
        userName: userName,
      },
      direction: tempDirection,
      height: "500px",
      disableClose: false,
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      dialogRef.close();
    });
  }

  //if HAP should be disable
  loginVal(event) {
    let emailValue = this.f.email.value;
    console.log("boolean Check", emailValue.includes("@hap"));
    if (emailValue.includes("@hap")) {
      this.loginBtn = true;
    } else {
      this.loginBtn = false;
    }
  }
  googleAuthSDK() {
    (<any>window)["googleSDKLoaded"] = () => {
      (<any>window)["gapi"].load("auth2", () => {
        this.auth2 = (<any>window)["gapi"].auth2.init(this.gSigin);
        console.log("this.auth2", this.auth2);
        this.callLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement("script");
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }
  callLoginButton() {
    this.forceLogin = true;
    this.auth2.attachClickHandler(
      this.loginElement.nativeElement,
      {},
      (googleAuthUser: any) => {
        let profile = googleAuthUser.getBasicProfile();
        console.log("Token || " + googleAuthUser.getAuthResponse().id_token);
        console.log("ID: " + profile.getId());
        console.log("Name: " + profile.getName());
        console.log("Image URL: " + profile.getImageUrl());
        console.log("Email: " + profile.getEmail());

        /* Write Your Code Here */
        this.emailId = profile.getEmail();

        this.onSubmit();
      },
      (error: any) => {
        console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }
  ngAfterViewInit() {
    this.googleAuthSDK();
  }

  async getMappedHubPlantDetails() {
    const response = await this.authService.getMappedHubPlantDetails();

    console.log("mapped hub plant", response.body?.data);
    let mappedHUBData = response?.body?.data || [];
    sessionStorage.setItem("mappedHUB", JSON.stringify(mappedHUBData));
  }
}
