import {Injectable, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  @Output() createTicket = new EventEmitter();
  @Output() selectColumns = new EventEmitter();

  constructor() { }

}
