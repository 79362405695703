import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { SharedService } from "src/app/core/service/shared.service";
import { ProjectmasterService } from "src/app/projectmaster/projectmaster.service";

@Component({
  selector: "app-ticket-time-dialog",
  templateUrl: "./providergrplist.component.html",
  styleUrls: ["./providergrplist.component.css"],
})
export class ProvidergrplistComponent implements OnInit {
  groupCode: any;
  subGroupCode: any;
  pageSize: number;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  currentUser: any;
  isLoading: boolean;
  groupSubName:any;
  groupName:any;
  searchCriteriaBody:any = {};
  subGroupUsers: any = [];
  noData :any;
  defaultImg = "assets/images/user/usrbig1.jpg";
  pageSizeOptions = [5, 10, 15];
  pageName:any;
  groupedList:any = [];

  constructor(
    public dialogRef: MatDialogRef<ProvidergrplistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public projectmasterService: ProjectmasterService,

    public dialog: MatDialog,
    public sharedService: SharedService
  ) {
    
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.pageName = data.pageName;
    if(data.pageName == "subGroups"){
    this.groupCode = data.groupCode;
    this.subGroupCode = data.subGroup.idGroupSubCode?data.subGroup.idGroupSubCode : "";
    this.groupSubName = data.subGroup.groupSubName?data.subGroup.groupSubName : "";
    console.log("group & subGroup", this.groupCode, this.subGroupCode,this.groupSubName);
    }
    else if(data.pageName == "group"){
      this.groupCode = data.groupCode.idGroupCode;
      this.groupName = data.groupCode.groupName;
      this.subGroupCode = "";
      this.groupSubName = "";
      console.log("group & subGroup", this.groupCode, this.subGroupCode,this.groupName);
    }
   
  }

  public ngOnInit(): void {
  
    this.loadData();
  }

  async loadData() {
      try {
          this.isLoading = true;
          this.subGroupUsers = [];
          this.searchCriteriaBody["groupCode"] = this.groupCode;
          this.searchCriteriaBody["idGroupSubCode"] = this.subGroupCode;
          const response = await this.projectmasterService.getSubGroupUsers(this.searchCriteriaBody);
          this.subGroupUsers = response ?.body ?.transactions;
          const groupBySubGrp = this.groupBy("subGroupName");
          this.groupedList = groupBySubGrp(this.subGroupUsers);
          console.log('groupedList', this.groupedList);
          this.isLoading = false;
      }
      catch(e){
          console.log("erooo",e)
          this.isLoading = false;
          this.noData = e;
         // this.dialogRef.close();
      }
  }
   groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }
}
