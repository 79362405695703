import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./attachmentImg-dialog.component.html",
  styleUrls: ["./attachmentImg-dialog.component.css"],
})
export class AttachmentImgDialogComponent implements OnInit, AfterViewInit {
  public fname: string = "John";
  public lname: string = "Deo";
  rotation: number = 0;
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  projectCode: any;
  agentCode: any;
  infoCode: any;
  userinfo: any;
  photos: any;
  userName: any;
  designation: any;
  city: any;
  email: any;
  image: any;
  phone: any;
  selectedgroup: any;
  subgrpList: any[];
  agentList: any[];
  defaultImg = "assets/images/user/usrbig1.jpg";
  ticketEditData: TicketEditDialogModel;
  action: any;
    attachmentUrl: any;
  agentForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AttachmentImgDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketService
  ) {
    this.action = data.ticket.data;
    this.attachmentUrl =this.action.attachmentUrl;
    console.log("attchment popup",this.action);

  }
  createAgentForm(): FormGroup {
    return this.fb.group({
      id: 0,
      subGroup: [this.ticketEditData.subGroup],

    });
  }

  public ngOnInit(): void {}


  closeDialog(): void {
    this.dialogRef.close();
  }
    ngAfterViewInit() {
    this.afterInit = true;
  }

}
