import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailTicketViewModel } from "./emailTicketView.model";
import * as moment from "moment";
import {
    FormControl,
    Validators,
    FormGroup,
    FormBuilder,
    AbstractControl,
} from "@angular/forms";
@Component({
  selector: 'app-emailTicketView',
  templateUrl: './emailTicketView.component.html',
  styleUrls: ['./emailTicketView.component.scss']
})
export class EmailTicketViewComponent implements OnInit {
  constructor(private router: Router,
              private fb: FormBuilder) {}
    public ticketEditViewForm: FormGroup;
    ticketEditData: EmailTicketViewModel;
  ngOnInit() {
      this.ticketEditViewForm = this.createTicketViewForm();
  }
    /* Build Ticket Form Function */
    createTicketViewForm(): FormGroup {
        return this.fb.group({
            id: [this.ticketEditData.id],
            date: [this.ticketEditData.date],
            idTicketCode: [this.ticketEditData.idTicketCode],
            retrieveHours: [this.ticketEditData.retrieveHours],
            // itext: [this.ticketEditData.itext],
            //  ntext: [this.ticketEditData.ntext],
            ticketTags: [this.ticketEditData.ticketTags],
            respAgentCode: [
                { respAgentCode: this.ticketEditData.respAgentCode },
                Validators.required,
            ],
            referenceTicket: [this.ticketEditData.referenceTicket],
            supportStartTime: [this.ticketEditData.supportStartTime],
            hours: [this.ticketEditData.hours],
            supportEndTime: [this.ticketEditData.supportEndTime],
            moduleCode: [{ moduleCode: this.ticketEditData.moduleCode }],
            ticketTypeCode: [{ ticketTypeCode: this.ticketEditData.ticketTypeCode }],
            ticketTypeClassificationCode: [
                {
                    ticketTypeClassificationCode:
                    this.ticketEditData.ticketTypeClassificationCode,
                },
                Validators.required,
            ],
            ticketPriorityCode: [
                { ticketPriorityCode: this.ticketEditData.ticketPriorityCode },
                Validators.required,
            ],
            ticketStatusCode: [
                {
                    ticketStatusCode: this.ticketEditData.ticketStatusCode,
                    ticketSubStatusCode: this.ticketEditData.ticketSubStatusCode,
                    ticketStatusName: this.ticketEditData.statusName
                },
                Validators.required,
            ],
            totalBookedBHours: [this.ticketEditData.totalBookedBHours],
            totalBookedNBHours: [this.ticketEditData.totalBookedNBHours],
            customerManagerName: [
                { value: this.ticketEditData.customerManagerName, disabled: true },
            ],
            projectManagerName: [
                { value: this.ticketEditData.projectManagerName, disabled: true },
            ],
            proxyManagerName: [
                { value: this.ticketEditData.proxyManagerName, disabled: true },
            ],
            createdByName: [
                { value: this.ticketEditData.proxyUserName, disabled: true },
            ],
            proposedByName: [{ value: "", disabled: true }],
            createdOn: [{ value: this.ticketEditData.createdOn, disabled: true }],
            attachmentTypeCode: [this.ticketEditData.attachmentTypeCode],
            splitHours: [this.checkSplitFlg(this.ticketEditData.splitHours)],
            file: [""],
            irtDue: [{ value: moment(this.ticketEditData.irtDue).format('MMM-DD-YYYY HH:mm:ss'), disabled: true }],
            mptDue: [{ value: this.ticketEditData.mptDue, disabled: true }],
            totalBhrsBooked: [
                { value: this.ticketEditData.totalBhrsBooked, disabled: true },
            ],
            totalNbhrsBooked: [
                { value: this.ticketEditData.totalNbhrsBooked, disabled: true },
            ],
            datetime: [{ value: "", disabled: true }],
            remarks: [this.ticketEditData.remarks],
            idAssignedSubGroup: [this.ticketEditData.idAssignedSubGroup],
            idUserCode: [this.ticketEditData.idUserCode],
            proposedHrs: [{ value: this.ticketEditData.proposedHrs, disabled: true }],
            proposedMins: [
                { value: this.ticketEditData.proposedMins, disabled: true },
            ],
            targetDate: [
                { value: new Date(this.ticketEditData.targetDate), disabled: true },
            ],
            vRemarks: [{ value: this.ticketEditData.vRemarks, disabled: true },this.noWhitespaceValidator],
            version: [this.ticketEditData.version],
            allocatedHrs: [this.ticketEditData.allocatedHrs],
            //allocatedHrs: this.fb.array([]),
        });
    }
    /* Split flag check function  */
    checkSplitFlg(value) {
        console.log("splitHours>>", value);
        if (value == "Y") {
            return value;
        } else {
            return "";
        }
    }
    noWhitespaceValidator(control: FormControl) {
        // let userInput = control.value;
        // console.log("user remark",userInput);
        // if (userInput && userInput.length > 0) {
        //   if (userInput[0] === " ") {
        //     return {
        //       forbiddenSpace: {
        //         value: userInput,
        //       },
        //     };
        //   }
        // } else {
        //   return null;
        // }

        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }
}
