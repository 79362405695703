import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  lastValueFrom,
  takeUntil,
} from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "../../app/core/service/api.service";
import { BrandingTicket } from "./branding-ticket.model";
@Injectable({
  providedIn: "root",
})
export class BrandingTicketService {
  isTblLoading = true;
  private destroy$ = new Subject<void>();
  dataChange: BehaviorSubject<BrandingTicket[]> = new BehaviorSubject<
    BrandingTicket[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {}
  get data(): BrandingTicket[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  // async updateBrandMail(oem) {
  //   this.dialogData = oem;
  //   //console.log("this.dialogData", this.dialogData);
  //   const addRes$ = this.apiService.post(
  //     "/api/nerve/ticket/updateBrandingRequest",
  //     this.dialogData,
  //     {},
  //     true
  //   );
  //   let response = await lastValueFrom(addRes$);
  //   return response;
  // }
  updateBrandMail(massTicket): Observable<any> {
    const abortController = new AbortController();
    ///api/nerve/ticket/updateBrandingRequest
    return this.apiService
      .post(
        `/api/nerve/ticket/v1/updateBrandingRequest`,
        massTicket,
        { signal: abortController.signal },
        true
      )
      .pipe(takeUntil(this.destroy$));
  }
  async getReasonList(searchCriteria) {
    let categoryId = searchCriteria.categoryId;

    const List$ = this.apiService.get(
      "/api/hap/master/getHapReasonMaster",
      { categoryId },
      false
    );
    let response = await lastValueFrom(List$);
    return response;
  }
}
