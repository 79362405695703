import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "src/app/ticket/ticket.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatMenuTrigger } from "@angular/material/menu";
import { SelectionModel } from "@angular/cdk/collections";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./popup-dialog.component.html",
  styleUrls: ["./popup-dialog.component.scss"],
})
export class PopupDialogComponent implements OnInit, AfterViewInit {
  public fname: string = "John";
  public lname: string = "Deo";
  isLoading: boolean;
  public columns: any = [];
  public allColumns: any = [];
  ticketTimeBooking: any = [];
  dataSource = new MatTableDataSource(this.ticketTimeBooking);
  selection = new SelectionModel(true, []);
  pageSize: number;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  valueValidation: boolean;
  searchCriteria: any;
  searchField: any;
  searchValue: any;
  pageSizeOptions = [5, 10, 15];
  searchCriteriaBody: any = {};
  public afterInit: boolean = false;
  retrieveForm: FormGroup;
  assignedSubGroupName: any;
  userName: any;
  dialogTitle: any;
  minDate: any;
  minStartDate: any;
  bookingDetails: any = [];
  userCode: any;
  isSubmitted: boolean;
  retrieveBtnDisable: boolean = false;
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public moveTicketList: any = [];
  RetrieveListForm: FormGroup;
  ticketCode: any;
  maxToDate: any;
  displayedColumns: string[] = [
    "select",
    "idBookedDate",
    "BookingType",
    "idFromTime",
    "toTime",
    "hours",
    "movedHours",
    "movedMinutes",
    "movedHrsType",
    "comments",
    // "actions",
  ];
  // maxHours: any;
  // maxMinutes: any;
  savedHoursMins: any;
  // totalMinutes: number;
  // totalHours: number;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventService: EventService,
    public sharedService: SharedService,
    private ticketService: TicketService,
    private spinner: NgxSpinnerService
  ) {
    console.log("retrieve data", this.data);
    this.searchCriteriaBody["idTicketCode"] =
      this.data.ticketEditDialog.ticketagent.idTicketCode;
    this.searchCriteriaBody["idUserCode"] =
      this.data.ticketEditDialog.ticketagent.idUserCode;
    this.dialogTitle = "Retrieve Hours";
    this.assignedSubGroupName =
      data.ticketEditDialog.ticketagent.assignedSubGroupName;
    this.userName = data.ticketEditDialog.ticketagent.userName;
    this.userCode = this.data.ticketEditDialog.ticketagent.idUserCode;
    this.ticketCode = data.ticketEditDialog.ticketagent.idTicketCode;
    this.retrieveForm = this.createRetrieveForm();
    this.retrieveForm.get("toDate").disable();
    this.minStartDate = data.createdOnDate;
   // this.maxMinutes = 59;
    console.log("min startDate", this.minStartDate);
    this.maxToDate = new Date().toISOString().slice(0, 10);
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  createRetrieveForm(): FormGroup {
    return this.fb.group({
      fromDate: [""],
      toDate: [""],
      hoursType: ["Billable"],
      toTicketId: [""],
    });
  }
  public ngOnInit(): void {
    this.RetrieveListForm = this.fb.group({
      RetrieveListRows: this.fb.array([]),
    });
    this.pageSize = 0;
    this.pageIndex = 0;
    this.sortColumn = "id";
    this.sortOrder = true;
    this.loadData();

    this.entityFilterCtrl.valueChanges.subscribe(
      async (value) => {
        console.log("value", value);

        let userCode = this.userCode;
        console.log(this.userCode, "userCode");
        if (value != "") {
          this.searching = true;
          this.retrieveBtnDisable = false;
          console.log(JSON.stringify(value));
          var ticketCode = value;
          if (JSON.stringify(value) != undefined) {
            let sTicketCode = this.ticketCode;
            let reqObj = { ticketCode, userCode , sTicketCode};
            const response = await this.ticketService.getRetrieveTicketList(
              reqObj
            );
            console.log(response, "retrieveTicketList");
            this.moveTicketList = response.transactions;
            this.searching = false;
          } else {
            this.moveTicketList = [];
            this.searching = false;
          }
        } else {
          this.searching = false;
        }
      },
      (error) => {
        console.log("error", error);
        this.searching = false;
      }
    );
    this.retrieveForm
      .get("toTicketId")
      .valueChanges.subscribe(async (value) => {
        console.log("ticketCode", value.ticketCode);

        if (
          value.ticketCode == undefined ||
          value.ticketCode == "" ||
          value.ticketCode == null
        ) {
          this.retrieveBtnDisable = false;
        } else {
          this.retrieveBtnDisable = true;
        }
      });
  }

  clearSelection() {
    this.retrieveForm.get("toTicketId").setValue("");
  }

  clearRetrieveHours() {
    this.retrieveForm.get("fromDate").setValue("");
    this.retrieveForm.get("toDate").setValue("");
  }

  hoursSplit(value) {
    let hours = value.split(":")[0];
    return hours;
  }

  minutesSplit(value) {
    let mins = value.split(":")[1];
    return mins;
  }

  checkRetrieve(index, key) {
    const select = (<FormArray>this.RetrieveListRows()).controls[index].value[
      "select"
    ];
    //console.log('select',select)
    if (!select) {
      return true;
    } else {
      return false;
    }
  }
  checkInitial(index, key) {
    const isCheck = (<FormArray>this.RetrieveListRows()).controls[index].value[
      "select"
    ];

    this.savedHoursMins = this.ticketTimeBooking.filter(
      (obj) => obj.id == index + 1
    );

    console.log("savedHoursMins", this.savedHoursMins);
   

    let rForm = (<FormArray>this.RetrieveListRows()).at(index);
    if (isCheck) {
   
     if (key == "select") {
        rForm.patchValue({
          movedHrsType: "Billable",
        });
      }
    } else {
      if (key == "select") {
        rForm.patchValue({
          movedHrsType: "",
          movedHours: this.hoursSplit(this.savedHoursMins[0].exactTime),
          movedMinutes: this.minutesSplit(this.savedHoursMins[0].exactTime),
        });
      }
    }
    // if (key == "select") {

    // irtHour: isCheck ? [{ value: [0, [Validators.required]] }]: 0,
    // irtMinute: isCheck ? [{ value: [0, [Validators.required]] }]: 0,
    // }
  }
  RetrieveListRows(): FormArray {
    return this.RetrieveListForm.get("RetrieveListRows") as FormArray;
  }

  async saveRetrieveHours() {
    console.log("this.RetrieveListForm", this.RetrieveListForm.value);
    let customObj = this.retrieveForm.value;
    let retrieveFormValue = this.RetrieveListForm.value;
    let retrieveList = retrieveFormValue.RetrieveListRows.filter(
      (item) => item.select == true
    ).map((obj) => {
      console.log("obj", obj);
      return {
        hoursType: obj.movedHrsType,
        idBookedDate: obj.idBookedDate, //this.ChangeFormateDate(obj.idBookedDate)
      //  idFromTime: obj.idFromTime, 
        idCreatedOn: obj.idFromTime, 
        movedHours:
          (obj.movedHours ? this.padding(obj.movedHours) : "00") +
          ":" +
          (obj.movedMinutes ? this.padding(obj.movedMinutes) : "00"),
      };
    });

    if (retrieveList.length > 0) {
      this.spinner.show();
      this.isSubmitted = true;
      let totalBillableList = retrieveList.filter(
        (item) => item.hoursType == "Billable"
      );

      let totalNonBillableList = retrieveList.filter(
        (item) => item.hoursType == "Non-Billable"
      );
      let totalBillableHours;
      let totalNonBillableHours;
      if (totalBillableList.length > 0) {
        let totalBillableHourMin = totalBillableList.reduce(
          (accumulator, currentValue) => {
            return (
              accumulator +
              this.sharedService.convertDurationToHours(
                currentValue.movedHours + ":" + currentValue.movedMinutes
              )
            );
          },
          0
        );
        console.log("totalBillableHourMin", totalBillableHourMin);
        var minutes = totalBillableHourMin % 60;
        var hours = (totalBillableHourMin - minutes) / 60;
        totalBillableHours = this.padding(hours) + ":" + this.padding(minutes);
      } else {
        totalBillableHours = "00:00";
      }
      if (totalNonBillableList.length > 0) {
        let totalNonBillableHourMin = totalNonBillableList.reduce(
          (accumulator, currentValue) => {
            return (
              accumulator +
              this.sharedService.convertDurationToHours(
                currentValue.movedHours + ":" + currentValue.movedMinutes
              )
            );
          },
          0
        );
        var minutes = totalNonBillableHourMin % 60;
        var hours = (totalNonBillableHourMin - minutes) / 60;
        totalNonBillableHours =
          this.padding(hours) + ":" + this.padding(minutes);
      } else {
        totalNonBillableHours = "00:00";
      }

      console.log("retrieveList", retrieveList);
      if(totalBillableHours === "00:00" && totalNonBillableHours === "00:00"){
        this.sharedService.showNotification(
          "snackbar-warning",
          'Invalid hours / minutes. Please give valid',
          "bottom",
          "center"
        );
        this.spinner.hide();
        this.isSubmitted = false;
      }else{
      var retrieveHoursRequest = {
        bookingDetails: retrieveList,
        fromTicket: this.searchCriteriaBody.idTicketCode,
        idUserCode: this.userCode,
        toTicket: customObj.toTicketId.ticketCode,
        totBHrsMins: totalBillableHours,
        totNBHrsMins: totalNonBillableHours,
      };
      console.log("retrieveHoursRequest", retrieveHoursRequest);

      this.isSubmitted = true;
      try {
        let response = await this.ticketService.addRetrieveHours(
          retrieveHoursRequest
        );

        console.log("response????", response);
        if (response?.body.success) {
          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );
          this.spinner.hide();
          this.isSubmitted = false;
          // this.retrieveForm.reset();
          this.retrieveForm.get("toTicketId").setValue("");
          this.GetTimeBooking();
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
          this.isSubmitted = false;
          this.spinner.hide();
        }
      } catch (e) {
        this.isSubmitted = false;
        console.log("Error>>>>>>>>>>>>", e);
        //  let error = e.error;
        this.sharedService.showNotification(
          "snackbar-danger",
          e,
          "bottom",
          "center"
        );
        this.spinner.hide();
      }
    }
    } else {
      this.sharedService.showNotification(
        "snackbar-warning",
        "Select any date to save retrieve hours",
        "bottom",
        "center"
      );
    }
  
  }
  padding(value) {
    let dummyValue = value.toString();
    if (dummyValue.length < 2) {
      return "0" + value;
    } else {
      return value;
    }
  }
  nextPage(e: any) {
    console.log("event ----> ", e);
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadData();
  }

  async onChangeTicket(event) {
    console.log(event.value);
  }

  changeDate(event) {
    console.log("event", event.value);
    this.minDate = event.value;
    this.retrieveForm.get("toDate").enable();
    var d = new Date(event.value);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);
    console.log(c, "c");
  }

  GetTimeBooking() {
    let retrieveFormValue = this.retrieveForm.value;

    this.searchCriteriaBody["startDate"] = retrieveFormValue.fromDate;
    this.searchCriteriaBody["endDate"] = retrieveFormValue.toDate;
    this.searchCriteriaBody["hrsType"] = retrieveFormValue.hoursType;

    if (
      retrieveFormValue.fromDate == null &&
      retrieveFormValue.toDate == null
    ) {
      this.sharedService.showNotification(
        "snackbar-warning",
        "Please Fill the Mandatory Fields",
        "bottom",
        "center"
      );
    } else {
      this.loadData();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if (this.afterInit) {
      this.onSelColumns(this.columns);
    }
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isChecked(col) {
    return (
      this.columns.find((c) => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }

  async loadData() {
    this.isLoading = true;
    this.spinner.show();
    this.ticketTimeBooking = [];
    this.dataSource.data = [];
    // this.dataSource.data = [];
    this.searchCriteria = {
      pageNo: this.pageIndex,
      sortOrder: this.sortOrder,
      sortColumn: this.sortColumn,
      pageSize: this.pageSize,
      searchColumn: this.searchField,
      searchValue: this.searchValue,
      action: "GetListWithPage",
    };

    const response = await this.ticketService.getRetrieveTimeBookingList(
      this.searchCriteria,
      this.searchCriteriaBody
    );
    console.log("searchCriteriaBody", this.searchCriteriaBody);

    console.log("response?.body>>", response?.body);
    if (response?.body?.success) {
      this.totalLength = response?.body?.totalCount;

      let retrieveTimeBooking = response?.body?.transactions;
      this.ticketTimeBooking = retrieveTimeBooking.map((obj, index) => {
        return {
          ...obj,
          id: index + 1,
        };
      });
      await this.spinner.hide();
      console.log(this.ticketTimeBooking, " this.ticketTimeBooking");
      this.RetrieveListForm = this.fb.group({
        RetrieveListRows: this.fb.array(
          this.ticketTimeBooking.map((val) =>
            this.fb.group({
              id: val.id,
              select: new FormControl(false),
              idBookedDate: new FormControl(val.idBookedDate),
              BookingType: new FormControl(val.hrsType),
             // idFromTime: new FormControl(val.idFromTime),
              idFromTime: new FormControl(val.fromTime),
              toTime: new FormControl(val.toTime),
              hours: new FormControl(val.exactTime),
              movedHours: new FormControl(
                this.hoursSplit(val.exactTime),
                Validators.max(this.hoursSplit(val.exactTime))
              ),

              movedMinutes: new FormControl(
                this.minutesSplit(val.exactTime),
                Validators.max(this.minutesSplit(val.exactTime))
              ),
              movedHrsType: new FormControl(),
              comments: new FormControl(val.comments),
            })
          )
        ), //end of fb array
      }); // end of form group cretation
      this.dataSource = new MatTableDataSource(
        (this.RetrieveListForm.get("RetrieveListRows") as FormArray).controls
      );
      //  this.dataSource.data = this.ticketTimeBooking;

      this.isLoading = false;
    } else {
      this.totalLength = 0;
      this.ticketTimeBooking = [];
      this.isLoading = false;
    }
  }
  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  tConvert(time) {
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(2, 3) + ampm;
    return time;
  }
  ChangeFormateDate(Date) {
    return Date.toString().split("-").reverse().join("-");
  }

  // onSearchChange(key, searchValue) {
  //   this.agentHoursMinsTotal();
  //   if (key == "hours") {
  //     if (Number(searchValue) < Number(this.totalHours)) {
  //       console.log('this.maxMinutes',this.maxMinutes);
  //     } else {
        
  //       console.log('this.maxMinutes',this.maxMinutes);
        
  //     }
     
  //   }

  //   if (key == "minutes") {
    
     
  //   }
  // }

  // agentHoursMinsTotal() {
  //   let hours = this.hoursSplit(this.savedHoursMins[0].exactTime);
  //   let minutes = this.minutesSplit(this.savedHoursMins[0].exactTime);
  //   let value = hours + ':' + minutes;
  //   let totalHoursMins = this.sharedService.convertDurationToHours(value);
  //   console.log("this.agentOldHoursTotal", totalHoursMins);
  //    this.totalMinutes = this.padding(totalHoursMins % 60);
  //    this.totalHours = this.padding((totalHoursMins - this.totalMinutes) / 60);
  //    this.maxHours = this.padding((totalHoursMins - this.totalMinutes) / 60);
  //  //  this.maxMinutes = this.padding(totalHoursMins % 60);
  //    console.log("totalHours", this.maxHours);
  //    console.log("totalMins", this.maxMinutes);
   
   
  // }
}
