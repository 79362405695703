import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  AfterViewInit,
  EventEmitter,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { EventService } from "src/app/core/service/event.service";
import { TicketService } from "src/app/ticket/ticket.service";
import { SharedService } from "src/app/core/service/shared.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateTicketDialogComponent } from "src/app/shared/dialog-boxes/create-ticket-dialog/create-ticket-dialog.component";
import { ChangeCompanyDialogComponent } from "src/app/shared/dialog-boxes/change-company-dialog/change-company-dialog.component";
import { UpdatePasswordDialogComponent } from "src/app/shared/dialog-boxes/update-password-dialog/update-password-dialog.component";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { environment } from "../../../environments/environment";
import { MessagingService } from "../../service/messaging-service";
const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  isNavbarCollapsed = false;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  currentUser: any;
  token: any;
  firstLetter: string;
  toggle: boolean;
  isLoading: boolean;
  gNo: string;
  messageCount: string;
  projectList: string[];
  timeSheetList: any = [];
  notifications: Object[] = [];
  userInfo: any;
  isMultiCmpy = false;
  checked = true;
  checked1: boolean = false;
  emptyList: boolean = false;
  notifyDropList: boolean = false;
  key: string = "Help";
  myItem: string;
  currentRoute: any;
  routerUrl: any;
  roleCheck: any;
  timeSheetButton: boolean = true;
  UserRoles: any = environment?.roles;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    public eventService: EventService,
    public sharedService: SharedService,
    private ticketService: TicketService
  ) {
    super();
    this.routerUrl = this.sharedService.routerUrl();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.token = JSON.parse(sessionStorage.getItem("token"));

    if (this.currentUser) {
      this.roleCheck = this.currentUser.data[0].roles;
      this.userInfo = this.currentUser.data[0].userInfo;
    }
  }
  listLang = [
    { text: "English", flag: "assets/images/flags/india.png", lang: "en" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
  ];

  ngOnInit() {
    setInterval(() => {
      this.messageCount = this.sharedService.notifyCount;
    }, 2000);

    this.timeSheetValidation();
    this.gNo = "";
    this.config = this.configService.configData;

    this.langStoreValue = localStorage.getItem("lang");
    this.isMultiCmpy =
      this.userInfo?.ownerCompanyInfo.length > 1 ? true : false;
    this.firstLetter = this.sharedService.getFLtr(
      this.currentUser.data[0].userInfo?.username
    );
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/india.png";
        
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  notifyList() {
    this.notifications = this.sharedService.notifyMessage;
    if (this.messageCount === undefined) {
      console.log("this.messageCount", this.messageCount);
      this.emptyList = true;
      this.notifyDropList = false;
    } else {
      this.emptyList = false;
      this.notifyDropList = true;


  /*  notifyList(){
        this.notifications = this.sharedService.notifyMessage;
        if( this.messageCount === undefined || this.messageCount === "0"){
            console.log("this.messageCount",this.messageCount)
            this.emptyList = true;
            this.notifyDropList = false;
        }else{
            this.emptyList = false;
            this.notifyDropList = true;
        }*/


    }
  }

  clearNotifications() {
    this.notifications = [];
    this.emptyList = true;
    this.notifyDropList = false;
    this.messageCount = "0";
  }

  timeSheetValidation() {
    if (this.roleCheck.includes(this.UserRoles.customer)) {
      this.timeSheetButton = false;
    } else {
      this.timeSheetButton = true;
    }
  }
  public toggleCheck(event: MatSlideToggleChange) {
    if (event.checked) {
      console.log("header toggle", event.checked);
      this.checked1 = event.checked;
      this.sharedService.setFlag(this.checked1);
    } else {
      this.checked1 = event.checked;
      this.sharedService.setFlag(this.checked1);
    }
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  formatDate(date: Date) {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join("-");
  }

  ngAfterViewInit() {
   
    // set theme on startup
    // this.config.layout.sidebar.collapsed = false;
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    }
  }

  toggleColor() {
    this.checked = true;
    localStorage.setItem(this.key, "ToolTip ON");
    this.myItem = localStorage.getItem(this.key);
    /* this.toggle = !this.toggle;
        console.log("toggle what",this.toggle);
        if(this.toggle == true){
          console.log("toggle true")
            localStorage.setItem(this.key, 'ToolTip ON');
            this.myItem = localStorage.getItem(this.key);
        }else{
            console.log("toggle false")
            localStorage.removeItem('Help');
        }*/
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  public async logout() {
    /*  this.subs.sink = this.authService.logout().subscribe((res) => {

    /!*  if (!res.success) {
        window.open('/authentication/signin', '_self');
   //     this.router.navigate(['/authentication/signin']);
      }*!/
    });*/
    const response = await this.authService.logout();
    let signOutResponse = response?.body?.message;
    if (signOutResponse === "session terminated successfully") {
      this.router.navigate(["/authentication/signin"]);
      location.reload();
      localStorage.removeItem("LocalToken");
      this.sharedService.showNotification(
        "snackbar-success",
        "LogOut Successfully",
        "bottom",
        "center"
      );
    } else {
      this.sharedService.showNotification(
        "snackbar-danger",
        response?.body?.message,
        "bottom",
        "center"
      );
    }

    /*  try{
        //  this.isSubmitted = true;
          let response = await  this.authService.logout();
          console.log('response????' , response);
          if(response?.body){
              this.sharedService.showNotification(
                  "snackbar-danger",
                  "hii",
                  "bottom",
                  "center"
          );
          }}catch(e){
          console.log('error>>>', e);
          this.sharedService.showNotification(
              "snackbar-danger",
              e?.error?.message,
              "bottom",
              "center"
      );

      }*/
  }

  /* Create Ticket dialog */
  addTicket() {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(CreateTicketDialogComponent, {
      disableClose: true,
      // scrollStrategy: overlay.scrollStrategies.noop(),

      data: {
        /* ticketType: this.ticketTypeData,*/
        userInfo: this.userInfo,
        action: "save",
        projectList: this.projectList,
        gNo: this.gNo,
      },
      direction: tempDirection,
      width: "1500px",
      height: "600px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      let response = result?.response;
      if (result?.action == "save") {
        if (response?.body?.success) {
          console.log("response?.body", response?.body);

          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );
          if (result?.url == "/ticket") {
            this.reloadCurrentRoute();
          } else {
            this.router.navigate(["/ticket"], { replaceUrl: true });
          }
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
        }
      }
    });
  }

  /* Project dropdown */
  async getProject(gNo) {
    let reqObj = {
      /* gNo */
    };
    const response = await this.ticketService.getProject(reqObj);
    this.projectList = response.transactions;
    // console.log('response>>>>', this.projectList);
  }

  public createTicket(): void {
    // this.eventService.createTicket.emit({});
    // this.router.navigateByUrl('/ticket');
    this.addTicket();
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  /* Switch company(login) dialog */
  changeComapny() {
    if (this.isMultiCmpy) {
      let tempDirection;
      if (localStorage.getItem("isRtl") === "true") {
        tempDirection = "rtl";
      } else {
        tempDirection = "ltr";
      }
      const dialogRef = this.dialog.open(ChangeCompanyDialogComponent, {
        disableClose: true,
        // scrollStrategy: overlay.scrollStrategies.noop(),

        data: {
          action: "switchCompany",
          userInfo: this.userInfo,
        },
        direction: tempDirection,
        width: "500px",
        height: "400px",
      });

      dialogRef.afterClosed().subscribe((result) => {
        let response = result;
        console.log("response", response);
        if (result?.action == "switchCompany") {
          if (response?.res) {
            console.log("response?.body", response?.body);
            // this.loadData();
            this.sharedService.showNotification(
              "snackbar-success",
              "Company Switched Successfully...!!",
              "bottom",
              "center"
            );
            this.redirectTo("/dashboard/main");
          } else {
            this.sharedService.showNotification(
              "snackbar-danger",
              "Unable Switch Company ...!!",
              "bottom",
              "center"
            );
          }
        }
      });
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  /* Update Password dialog */
  updatePassword() {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      disableClose: true,
      // scrollStrategy: overlay.scrollStrategies.noop(),

      data: {
        /* ticketType: this.ticketTypeData,*/
        action: "save",
        projectList: this.projectList,
        gNo: this.gNo,
      },
      direction: tempDirection,
      width: "500px",
      height: "450px",
    });
  }

  /*  openTimeSheet() {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(TimeSheetCalendarComponent, {
      data: {
        timeSheetData: this.timeSheetList,
      },
      direction: tempDirection,
      width: "80%",
      height: "98%",
    });
  }
  goToAms() {
    this.router.navigateByUrl("/alms");
  }*/
}
