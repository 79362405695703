import { DOCUMENT } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ROUTES } from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";

declare const Waves: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.sass"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public configurationItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  tile: any;
  sideBar: any = [];
  headerHeight = 60;
  routerObj = null;
  // basicSetup:[{
  //       path: "menu/1",
  //       title: "Basic Setup",
  //       moduleName: "Basic Setup",
  //       icon: "codepen",
  //       class: "",
  //       groupTitle: false,
  //       submenu:any[],
  //       tiles: any[]
  //   }]

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split("?")[0];
        this.level1Menu = currenturl.split("/")[1];
        this.level2Menu = currenturl.split("/")[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      //console.log('on load menu', this.authService.currentUserValue);
      const data = this.authService.currentUserValue;
      this.sideBar = data?.data[0]?.tiles;
      this.sideBar = this.sideBar.sort((a, b) =>
        a.tileCategoryOrder < b.tileCategoryOrder ? -1 : 1
      );

      //console.log('sideeeeeerouteee>>>>', this.sideBar);

      //  Old API JSON structure
      // let tempArray = [];
      // Object.keys(this.sideBar).map((item) => {
      //   let menuItem = {};
      //   menuItem['path'] = 'menu/'+item;
      //   menuItem['title'] = item;
      //   menuItem['moduleName'] = item;
      //   menuItem['icon'] = 'grid';
      //   menuItem['class'] = '';
      //   menuItem['groupTitle'] = false;
      //   menuItem['submenu'] = [];
      //   menuItem['tiles'] = [];
      //   console.log('menuItem', menuItem);
      //   tempArray.push(menuItem);
      // });

      // this.sidebarItems = tempArray;

      let tempArray = [];
      this.sideBar.map((item) => {
        let menuItem = {};
        menuItem["path"] = "menu/" + item.tileCategoryName;
        if (item.tileCategoryName == "Dashboard") {
          menuItem["path"] = "/dashboard/";
        }

        menuItem["title"] = item.tileCategoryName;
        menuItem["moduleName"] = item.tileCategoryName;
        menuItem["icon"] = item.tileCategoryIcon;
        menuItem["class"] = "";
        menuItem["groupTitle"] = false;
        menuItem["submenu"] = [];
        menuItem["tiles"] = [];
        //   console.log('menuItem', menuItem);
        tempArray.push(menuItem);
      });

      this.sidebarItems = tempArray;

      //  this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
      // this.configurationItems = ROUTES.filter((sidebarItem) => sidebarItem);
      // if (this.sideBar.dashboard.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = '/dashboard/';
      //   sideBarObj['title'] = 'MENUITEMS.MAIN.TEXT';
      //   sideBarObj['moduleName'] = 'dashboard';
      //   sideBarObj['icon'] = 'grid';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if (this.sideBar.basicSetup.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/1';
      //   sideBarObj['title'] = 'Basic Setup';
      //   sideBarObj['moduleName'] = 'Basic Setup';
      //   sideBarObj['icon'] = 'codepen';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if (this.sideBar.configuration.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/2';
      //   sideBarObj['title'] = 'Configuration';
      //   sideBarObj['moduleName'] = 'Configuration ';
      //   sideBarObj['icon'] = 'settings';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if(this.sideBar.dashboard.length > 0){

      //   var sideBarObj = {};
      //   sideBarObj['path'] = "/dashboard/main";
      //       sideBarObj['title'] =  "MENUITEMS.MAIN.TEXT";
      //       sideBarObj['moduleName']= "dashboard ";
      //       sideBarObj['icon'] = "grid";
      //       sideBarObj['class'] = "";
      //       sideBarObj['groupTitle'] = false;
      //       sideBarObj['submenu']  =[];
      //       sideBarObj['tiles']  = [];

      //     this.sidebarItems.push(sideBarObj);
      // }

      // if (this.sideBar.masters.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/3';
      //   sideBarObj['title'] = 'Masters';
      //   sideBarObj['moduleName'] = 'Masters ';
      //   sideBarObj['icon'] = 'server';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if (this.sideBar.masters.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/6';
      //   sideBarObj['title'] = 'Custom Masters';
      //   sideBarObj['moduleName'] = 'Custom Masters';
      //   sideBarObj['icon'] = 'server';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if (this.sideBar.transaction.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/4';
      //   sideBarObj['title'] = 'Transactions';
      //   sideBarObj['moduleName'] = 'Transactions ';
      //   sideBarObj['icon'] = 'repeat';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }
      // if (this.sideBar.reports.length > 0) {
      //   var sideBarObj = {};
      //   sideBarObj['path'] = 'menu/5';
      //   sideBarObj['title'] = 'Reports';
      //   sideBarObj['moduleName'] = 'Reports ';
      //   sideBarObj['icon'] = 'file-text';
      //   sideBarObj['class'] = '';
      //   sideBarObj['groupTitle'] = false;
      //   sideBarObj['submenu'] = [];
      //   sideBarObj['tiles'] = [];

      //   this.sidebarItems.push(sideBarObj);
      // }

      //console.log('stosuzz>>>', this.sidebarItems);
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");

    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");

    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
}
