import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from "@angular/cdk/drag-drop";

import { BehaviorSubject } from "rxjs";
import { ProjectmasterService } from "../../../projectmaster/projectmaster.service";
import { ProjectmasterModel } from "../../../projectmaster/projectmaster.model";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SharedService } from "src/app/core/service/shared.service";
import { NgxSpinnerService } from "ngx-spinner";

export interface ConditionElement {
  idSequence: number,
  optionName: string,
  tabName: number,
  value: number,
  leftBracket: string,
  rightBracket: string,
}
@Component({
  selector: "app-rule-condition-dialog",
  templateUrl: "./rule-condition-dialog.component.html",
  styleUrls: ["./rule-condition-dialog.component.css"],
})
export class RuleConditionDialogComponent implements OnInit {
  isoperation = false;
    isnum = false;
    isNumber = false;
    isColor = false;
    isTime = false;
    isDateTime = false;
    isdate = false;
  isfieldvalue = false;
  isfield = false;
  iscondition = false;
  isvalue = false;
  istext = false;
  fieldList: any[];
  ruleListList: any[];
  id: any;
  optionedit: any;
  listData: ConditionElement[] = [];
  selectList: any[];
  searchCriteria: any;
  isLoading: boolean = false;
  totalLength: number = 0;
  actionLevelCode: any;
  ruleName: any;
  actionTypeName: any;
  actionLevelName: any;
  idRuleCode: any;
  isleft = false;
  isrigth = false;
  lastSeq: number = 0;
  isEdit: boolean = false;
  isAddItem: boolean = false;
  noData: boolean = false;
  editIndex: number = 0;

  public ruleForm: FormGroup;
  projectData: ProjectmasterModel;

  @ViewChild('table') table: MatTable<ConditionElement>;
  displayedColumns: string[] = ['idSequence','Options', 'LeftBracket', 'Condition/Value', 'RightBracket', 'Action'];
  dataSource = new MatTableDataSource([]);
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public projectmasterService: ProjectmasterService,
    public dialogRef: MatDialogRef<RuleConditionDialogComponent>,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.projectData = new ProjectmasterModel({});
    this.ruleForm = this.createRuleForm();
    this.isleft = false;
    this.isrigth = false;
    this.actionLevelCode = data.projectmaster.actionLevelCode;
    this.ruleName = data.projectmaster.ruleName;
    this.actionLevelName = data.projectmaster.actionLevelName;
    this.actionTypeName = data.projectmaster.actionTypeName;
    this.idRuleCode = data.projectmaster.idRuleCode;
    console.log("printactionlevel", this.idRuleCode);
  }

  public ngOnInit(): void {
    this.loadDataField();
    this.loadDataRuleList();
  }

  createRuleForm(): FormGroup {
    return this.fb.group({
      //id: [this.projectData.id],
      idSequence: [this.projectData.idSequence],
      optionName: [this.projectData.optionName],
      tabName: [this.projectData.tabName],
      value: [this.projectData.value],
      leftBracket: ["N"],
      rightBracket: ["N"],
    });
  }
  /* Rule Value bracket open/close enable/disable method
  * @param index, dir=direction, option
  */
  ruleBracket(index, dir, option) {
   // console.log('index', index);
      let ruleItem = this.listData[index];
      if(dir =='left'){
        this.listData[index] = {...ruleItem, leftBracket: option == 'Y' ? 'N' : 'Y'}
      }
      if(dir =='right'){
        this.listData[index] = {...ruleItem, rightBracket: option == 'Y' ? 'N' : 'Y'}   
      }
      this.dataSource.data = this.listData;
  }

 
  submit() {
    // emppty stuff
  }
  public addItem() {
    this.isAddItem = true;
    const formVal = this.ruleForm.value;
    let { idSequence, optionName, tabname, value } = formVal;
    let error = false;
    let msg = "";
    console.log('formVal', formVal);
    if (optionName !="") {
      if (optionName == "fieldName") {
        if(tabname =="" && value ==""){
            error = true;
            msg = "Please provide Field Name and Value";
        }else if(value ==""){
            error = true;
            msg = "Please provide Value";
        } else  {
          error = false;
          msg = "";
        }
      } else if (optionName == "operation") {
        let checkArray = this.listData.filter(
          (item) => item.optionName == "fieldName"
        );
        if (checkArray.length == 0) {
          error = true;
          msg = "Please provide Field";
        } else if(value ==""){
            error = true;
            msg = "Please provide Value";
        } else  {
          error = false;
          msg = "";
        }
      } else if (optionName == "condition") {
        let checkArray = this.listData.filter(
          (item) => item.optionName == "fieldName"
        );
        if (checkArray.length == 0) {
          error = true;
          msg = "Please provide Field";
        }else if(value ==""){
            error = true;
            msg = "Please provide Condition";
        } else {
          error = false;
          msg = "";
        }
      }else if (optionName == "value") {
        let checkArray = this.listData.filter(
          (item) => item.optionName == "fieldName"
        );
        if (checkArray.length == 0) {
          error = true;
          msg = "Please provide Field";
        }else if(tabname =="" && value ==""){
            error = true;
            msg = "Please provide Value Type and Value";
        }else if(value ==""){
            error = true;
            msg = "Please provide Value";
        } else {
          error = false;
          msg = "";
        }
      }
    } else {
        error = true;
        msg = "Please select Option";
    }
    console.log("this.listData", this.listData);
    console.log("optionName", optionName);
    console.log("error", error);
    if (error) {
      this.sharedService.showNotification(
        "snackbar-danger",
        msg,
        "bottom",
        "center"
      );
      this.isAddItem = false;
      return false;
    }
    console.log('this.isEdit',this.isEdit);
    console.log('this.listData',this.listData);
    if (this.isEdit) {
        this.listData[this.editIndex] = this.ruleForm.value;
        this.sharedService.showNotification(
            "snackbar-success",
            "Item added successfully..!!",
            "bottom",
            "center"
          );
    } else {
        this.listData.push(this.ruleForm.value);
        this.sharedService.showNotification(
            "snackbar-success",
            "Item updated successfully..!!",
            "bottom",
            "center"
          );
    }
    this.dataSource.data = this.listData;
    this.isAddItem = false;
    this.clearItem();
    /* console.log(this.projectData.idSequence, "seq");
    console.log("pushArray", this.ruleForm.value); */
  }
  clearItem() {
    // this.ruleForm.reset();
    // this.ruleForm = this.createRuleForm();
    this.ruleForm.controls['idSequence'].setValue(this.projectData.idSequence);
    this.ruleForm.controls['optionName'].setValue('');
    this.ruleForm.controls['tabName'].setValue('');
    this.ruleForm.controls['value'].setValue('');
    this.ruleForm.controls['leftBracket'].setValue('N');
    this.ruleForm.controls['rightBracket'].setValue('N');
    this.isEdit = false;
      this.isfield = false;
      this.isfieldvalue = false;
      this.isoperation = false;
      this.iscondition = false;
      this.isvalue = false;
      this.isdate = false;
      this.istext = false;
      this.isNumber = false;
      this.isColor = false;
      this.isTime = false;
      this.isDateTime = false;

  }

  async loadDataField() {
    this.isLoading = true;
    this.fieldList = [];
    this.totalLength = 0;
    this.noData = false;
    // this.dataSource.data = [];
    this.searchCriteria = {
      actionLevelCode: this.actionLevelCode,
    };

    const response = await this.projectmasterService.getListField(
      this.searchCriteria
    );
    this.fieldList = response?.body?.data || [];
    this.totalLength = this.fieldList.length;

    this.isLoading = false;
    this.noData = this.totalLength > 0 ? false : true;
    console.log('this.noData',this.noData);
    
  }
  editRule(item, i) {
    this.isEdit = true;
    this.editIndex = i;
    this.id = item.id;
    this.optionedit = item.optionName;
    console.log("option edit", this.optionedit);
    // this.id = item.id;
    this.projectData = item;
    this.ruleForm = this.createRuleForm();
    this.onSelectTeam(this.optionedit);
    this.onSelectValue(item.tabName);
   // console.log("edit settttt item", this.projectData);
  }
  removeItem(index: number) {
    let initialList = this.listData;
    this.listData = this.listData.filter((v, i) => i !== index);
    let afterDelete = this.listData;
    this.dataSource.data = this.listData;
    if(initialList > afterDelete){
        this.sharedService.showNotification(
            "snackbar-success",
            "Item removed successfully..!!",
            "bottom",
            "center"
          );
    }else{
        this.sharedService.showNotification(
        "snackbar-danger",
        "Unnable to remove item",
        "bottom",
        "center"
      );
    }
    
    
  }
  async loadDataRuleList() {
    this.isLoading = true;
    this.listData = [];
    this.dataSource.data = [];
    // this.dataSource.data = [];
    this.searchCriteria = {
      ruleCode: this.idRuleCode,
    };

    const response = await this.projectmasterService.getListRule(
      this.searchCriteria
    );

    this.totalLength = response?.body?.totalCount;
    this.listData = response?.body?.data;
    let ruleSeq = this.listData.map((item) => item.idSequence);
    for (let i = 0; i <= this.lastSeq; i++) {
      if (ruleSeq[i] > this.lastSeq) {
        this.lastSeq = ruleSeq[i];
      }
    }
    console.log("this.lastSeq", this.lastSeq);
    // this.data = this.teamtypeList;
    this.isLoading = false;
    if (this.listData !== null) {
      // this.noData = false;
    } else {
      this.listData = [];
      // this.noData = true;
    }
    this.dataSource.data = this.listData;
  }
  onSelectField(value: any) {
    this.onselectdata(value);
  }
  async onselectdata(value) {
    this.isLoading = true;
    this.selectList = [];
    var tabName = value;
    // this.dataSource.data = [];
    this.searchCriteria = {
      actionLevelCode: this.actionLevelCode,
      tabName: tabName,
    };

    const response = await this.projectmasterService.getListFieldbyName(
      this.searchCriteria
    );

    this.totalLength = response?.body?.totalCount;
    this.selectList = response?.body?.data;
    // this.data = this.teamtypeList;
    this.isLoading = false;
    if (this.selectList !== null) {
      // this.noData = false;
    } else {
      this.selectList = [];
      // this.noData = true;
    }
  }

  onSelectTeam(value: any) {
    console.log("onselect", value);
    this.isoperationrule(value);
  }
  onSelectValue(value: any) {
    console.log("onselect", value);
    this.istextrule(value);
  }
  dropTable(event: CdkDragDrop<ConditionElement[]>) {
    const prevIndex = this.dataSource.data.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);
    this.table.renderRows();
  }
  istextrule(value) {
    var type = value;
    if (type == "number") {
      this.isoperation = false;
      this.isfield = false;
      this.iscondition = false;
      this.isNumber = true;
      this.isvalue = true;
      this.istext = false;
      this.isdate = false;
        this.isTime = false;
        this.isDateTime = false;
        this.isColor=false;
    } else if (type == "date") {
      this.isoperation = false;
      this.isNumber = false;
      this.isfield = false;
      this.iscondition = false;
      this.isvalue = true;
      this.isdate = true;
      this.istext = false;
        this.isTime = false;
        this.isDateTime = false;
        this.isColor=false;
    } else if (type == "text") {
      this.isoperation = false;
      this.isfield = false;
      this.iscondition = false;
      this.isNumber = false;
      this.isdate = false;
      this.isTime = false;
      this.isvalue = true;
      this.istext = true;
      this.isDateTime = false;
        this.isColor=false;
    }else if(type == "time"){
        this.isoperation = false;
        this.isfield = false;
        this.iscondition = false;
        this.isNumber = false;
        this.isdate = false;
        this.isvalue = true;
        this.istext = false;
        this.isTime = true;
        this.isColor=false;
    }else if(type == "datetime"){
        this.isoperation = false;
        this.isfield = false;
        this.iscondition = false;
        this.isNumber = false;
        this.isdate = false;
        this.isvalue = true;
        this.istext = false;
        this.isTime = false;
        this.isDateTime = true;
        this.isColor=false;
    }else if(type == "color"){
        this.isoperation = false;
        this.isfield = false;
        this.iscondition = false;
        this.isNumber = false;
        this.isdate = false;
        this.isvalue = true;
        this.istext = false;
        this.isTime = false;
        this.isDateTime = false;
        this.isColor=true;
    }
  }
  isoperationrule(value) {
    var option = value;
    if (option == "operation") {
      this.isfield = false;
      this.isfieldvalue = false;
      this.isoperation = true;
      this.iscondition = false;
      this.isvalue = false;
      this.istext = false;
    } else if (option == "condition") {
      this.isfield = false;
      this.isfieldvalue = false;
      this.isoperation = false;
      this.iscondition = true;
      this.isvalue = false;
      this.istext = false;
    } else if (option == "fieldName") {
      this.isfield = true;
      this.isfieldvalue = true;
      this.isoperation = false;
      this.iscondition = false;
      this.isvalue = false;
      this.istext = false;
    } else {
      this.isfield = false;
      this.isfieldvalue = false;
      this.isoperation = false;
      this.iscondition = false;
      this.isvalue = true;
      this.istext = true;
    }
  }

  closeDialog(): void {
    //  this.dialog.closeAll();
    this.dialogRef.close({ event: "Cancel" });
  }
  
  public async onSubmitClick() {
    this.spinner.show();
    // this.listData.map((item, index) => { return {...this.listData, sequence: index + 1}})
    var seq = this.listData.map((item, index) => {
      return { ...item, idSequence: index + 1 };
    });
    console.log("srquence>>>>", seq);
    var data = {
      idRuleCode: this.idRuleCode,
      conditions: seq,
    };
    console.log("save Rule", data);
    let response = await this.projectmasterService.addRule(data);
    console.log("response", response);
    if (response?.body) {
      this.spinner.hide();
      this.sharedService.showNotification(
        "snackbar-success",
        response?.body?.message,
        "bottom",
        "center"
      );
      this.loadDataRuleList();
      // this.dialogRef.close({ response });
    }
  }
}
