import { Injectable } from '@angular/core';
import { BehaviorSubject,lastValueFrom } from 'rxjs';
import { TileMenuModel } from './tile-menu.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter } from '../../shared/UnsubscribeOnDestroyAdapter';
import {ApiService} from "../../core/service/api.service";
@Injectable()
export class TileMenuService extends UnsubscribeOnDestroyAdapter {
    private readonly API_URL = 'assets/data/departmentmaster.json';
    isTblLoading = true;
    dataChange: BehaviorSubject<TileMenuModel[]> = new BehaviorSubject<TileMenuModel[]>([]);
    // Temporarily stores data from dialogs
    dialogData: any;
    constructor(private httpClient: HttpClient,public apiService:ApiService) {
        super();
    }
    get data(): TileMenuModel[] {
        return this.dataChange.value;
    }
    getDialogData() {
        return this.dialogData;
    }
    /** Get Menu and Tiles */
    async getMenuTiles(searchCriteria) {
        const menuTilesList$ = this.apiService.get('/api/admin/master/getTileListByRoleCode', searchCriteria);
        let response = await lastValueFrom(menuTilesList$);
        return response;

    }

    
}