import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

@Component({
  selector: "app-master-del-dialog",
  templateUrl: "./master-del-dialog.component.html",
  styleUrls: ["./master-del-dialog.component.sass"],
})
export class MasterDelDialogComponent implements OnInit {
  code: any;
  name: any;
  codeVal: any;
  nameVal: any;
  deleteObj: any;
  apiName: any;
  constructor(
    public dialogRef: MatDialogRef<MasterDelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    public spinner: NgxSpinnerService
  ) {
    console.log("data.hubMaster.data", data);
    let reqObj = data;
    console.log("reqObj", reqObj);
    if (reqObj) {
      this.code = reqObj?.deletId?.label;
      this.name = reqObj?.deleteName?.label;
      this.codeVal = reqObj?.deletId?.value;
      this.nameVal = reqObj?.deleteName?.value;
      this.deleteObj = reqObj.deleteObj;
      this.apiName = reqObj.apiKey.api;
    }
  }

  ngOnInit(): void {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  async confirmDelete() {
    this.deleteObj.statusFlag = "X";

    try {
      this.spinner.show();
      const response = await this.sharedService.deleteMaster(
        this.deleteObj,
        this.apiName
      );
      console.log("response", response);
      this.spinner.hide();
      await this.dialogRef.close({ action: "delete", response: response });
    } catch (e) {
      this.spinner.hide();
    }
  }
}
