import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";
import { T } from "@angular/cdk/keycodes";
import Swal from "sweetalert2";
import { MatCheckbox } from "@angular/material/checkbox";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./edit-agent-dialog.component.html",
  styleUrls: ["./edit-agent-dialog.component.sass"],
})
export class EditAgentDialogComponent implements OnInit, AfterViewInit {
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  isNonBillable: any;
  projectCode: any;
  userName: any;
  assignedSubGroupName: any;
  dialogTitle: any;
  idTicketCode: any;
  idAssignedSubGroup: any;
  selectedgroup: any;
  subgrpList: any[];
  agentList: any[];
  agentEditList: any[];
  ticketAgentList: any = [];
  action: string;
  ticketEditData: TicketEditDialogModel;
  tempAgent: any = [];
  agentForm: FormGroup;
  allocatedHrs: any = "00";
  allocatedMins: any = "00";
  checkHrsMins: boolean = true;
  allocatedCheck: boolean = true;
  existingHrsMins: boolean = true;
  existingAllocated: any;
  localHrsMins: any;
  localHrs: any;
  localMins: any;
  proposedHrs: any;
  proposedMins: any;
  bookedHrs: any;
  bookedMins: any;
  agentStatusList: any;
  minHours: any = 0;
  minMinutes: any = 0;
  maxTargetDate: any;
  minTargetDate: any;
  agentStatusName: any;
  agentSaved: any = {};
  logList: any = {};
  splitLevel: boolean;
  @ViewChild("cb", { static: true }) cb: MatCheckbox;
  authority: any;
  createdDate: any;
  resAgentNameList: any = [];
  resAgentPermission: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditAgentDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketService
  ) {
    this.getAgentStatusList();
    this.action = data.action;
    this.dialogTitle = "Update Agent";
    console.log("agent Update Data", data.ticketEditDialog);
    this.projectCode = data.ticketEditDialog.projectCode;
    this.proposedHrs = data.proposedHours;
    this.proposedMins = data.proposedMinutes;
    this.ticketAgentList = data.ticketAgentList;
    this.maxTargetDate = data.maxAgentDate;
    this.authority = data.authority;
    this.createdDate = data.createdDate;
    this.resAgentPermission = data.resAgentAuthority;
    console.log("maxTargetDate", this.maxTargetDate);
    console.log("ticketAgentList", this.ticketAgentList);
    // this.getProjectSubgroup(this.projectCode);
    this.idTicketCode = data.ticketEditDialog.idTicketCode;
    this.ticketEditData = data.ticketEditDialog.ticketagent;
    this.isNonBillable = data.isNonBillable;
    console.log(data.isNonBillable, "isNonBillable");
    this.assignedSubGroupName =
      data.ticketEditDialog.ticketagent.assignedSubGroupName;
    this.userName = data.ticketEditDialog.ticketagent.userName;
    this.existingAllocated = data.ticketEditDialog.ticketagent.totalAllocated;
    this.allocatedHrs = this.ticketEditData.allocatedHrsMins.split(":")[0];
    this.allocatedMins = this.ticketEditData.allocatedHrsMins.split(":")[1];
    this.agentSaved["agentStatus"] =
      data.ticketEditDialog.ticketagent.agentStatusName;
    this.agentSaved["targetDate"] =
      data.ticketEditDialog.ticketagent.targetDate;
    this.agentSaved["remarks"] = data.ticketEditDialog.ticketagent.remarks;
    let splitHours = data.splitHoursData;
    console.log("splitHours", splitHours);
    this.minTargetDate = new Date();
    this.minTargetDate.setHours(0, 0, 0, 0);
    if (this.isNonBillable != null && this.isNonBillable == "Y") {
      this.bookedHrs = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedNBHrsMins.split(":")[0]
      );
      this.bookedMins = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedNBHrsMins.split(":")[1]
      );

      // this.minMinutes = this.bookedMins;
    } else if (this.isNonBillable != null && this.isNonBillable == "N") {
      this.bookedHrs = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedBHrsMins.split(":")[0]
      );
      this.bookedMins = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedBHrsMins.split(":")[1]
      );

      //  this.minMinutes = this.bookedMins;
    } else if (this.isNonBillable == null) {
      this.bookedHrs = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedBHrsMins.split(":")[0]
      );
      this.bookedMins = this.pad(
        data.ticketEditDialog.ticketagent.totalBookedBHrsMins.split(":")[1]
      );

      // this.minMinutes = this.bookedMins;
    }
    this.minHours = this.bookedHrs;
    if (Number(this.allocatedHrs) > Number(this.bookedHrs)) {
      this.minMinutes = Number(0);
    } else {
      this.minMinutes = Number(this.bookedMins);
    }
    this.resAgentNameList = this.ticketAgentList.filter(
      (item) => item.isRespAgent == "Y"
    );

    this.agentForm = this.createAgentForm();

    this.agentForm.controls["bookedHrs"].disable();
    this.agentForm.controls["bookedMins"].disable();
    console.log(this.authority, "authority");
    if (this.authority) {
      if (splitHours == "N") {
        this.agentForm.controls["allocatedHrs"].disable();
        this.agentForm.controls["allocatedMins"].disable();
      } else if (splitHours == "Y") {
        this.agentForm.controls["allocatedHrs"].enable();
        this.agentForm.controls["allocatedMins"].enable();
      }
    } else {
      this.agentForm.controls["targetDate"].disable();
      this.agentForm.controls["isRespAgent"].disable();
      this.agentForm.controls["allocatedHrs"].disable();
      this.agentForm.controls["allocatedMins"].disable();
    }

    if (this.resAgentPermission) {
      this.agentForm.controls["targetDate"].enable();
    } else {
      if (this.authority) {
        this.agentForm.controls["targetDate"].enable();
      } else {
        this.agentForm.controls["targetDate"].disable();
      }
    }

    // this.allocatedMins = this.ticketEditData.allocatedHrs;
    //  this.getProjectSubGroupEdit(this.projectCode);
    // this.getAgentNameEdit(data.ticketEditDialog.ticketagent.idAssignedSubGroup);
  }

  onSearchChange(key, searchValue) {
    if (key == "hours") {
      if (Number(searchValue) > Number(this.bookedHrs)) {
        this.minMinutes = Number(0);
      } else {
        this.minMinutes = Number(this.bookedMins);
      }
    }
  }
  createAgentForm(): FormGroup {
    return this.fb.group({
      id: 0,
      idAssignedSubGroup: [
        { idAssignedSubGroup: this.ticketEditData.idAssignedSubGroup },
      ],
      idTicketCode: [this.ticketEditData.idTicketCode],
      bookedHrs: [this.bookedHrs],
      bookedMins: [this.bookedMins],
      idUserCode: [{ idUserCode: this.ticketEditData.idUserCode }],

      allocatedHrs: new FormControl(this.allocatedHrs, [
        this.numberValidator,
        this.customValidator1,
      ]),
      allocatedMins: new FormControl(this.allocatedMins, [
        this.numberValidator,
        this.customValidator,
      ]),
      targetDate: [
        this.data.ticketEditDialog.ticketagent.targetDate
          ? new Date(this.data.ticketEditDialog.ticketagent.targetDate)
          : null,
      ],
      agentStatusCode: [this.ticketEditData.agentStatusCode],
      agentStatusName: [this.data.ticketEditDialog.ticketagent.agentStatusName],
      isRespAgent: [
        this.checked(this.data.ticketEditDialog.ticketagent.isRespAgent),
      ],
      remarks: [
        this.data.ticketEditDialog.ticketagent.remarks,
        // this.noWhitespaceValidator,
      ],
    });
  }
  checked(value) {
    console.log("value", value);
    if (value == "Y") {
      return value;
    } else {
      return "";
    }
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  changeAgentStatus(event) {
    // this.agentForm.controls["remarks"].setValue("");
    // this.agentForm.get("remarks").setValidators([Validators.required]);
    // this.agentForm.get("remarks").updateValueAndValidity();
    let filterType = this.agentStatusList.filter(
      (item) => item.agentStatusCode == event.value
    );

    this.agentStatusName = filterType[0].agentStatusName;

    this.logList["agentStatusName"] = this.agentStatusName;

    this.agentForm.get("agentStatusName").setValue(this.agentStatusName);
  }
  customValidator(control: FormControl) {
    if (
      (("" + control.value).match(/^\d+\.?\d*%?$/) || false) &&
      parseFloat(control.value) >= 0 &&
      parseFloat(control.value) <= 59
    ) {
      return null;
    }
    return {
      customValidator: true,
    };
  }

  customValidator1(control: FormControl) {
    if (
      (("" + control.value).match(/^\d+\.?\d*%?$/) || false) &&
      parseFloat(control.value) >= 0 &&
      parseFloat(control.value) <= 999
    ) {
      return null;
    }
    return {
      customValidator: true,
    };
  }

  onvRemarksChange(vRemarks: string): void {
    this.logList["remarks"] = vRemarks;
  }

  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }

  pad(value) {
    let dummyValue = value.toString();
    if (dummyValue.length < 2) {
      return "0" + value;
    } else {
      return value;
    }
  }
  public ngOnInit(): void {}
  /* Subgroup dropdown */
  // async getProjectSubgroup(projectCode) {
  //   console.log("PROJECTCODE", projectCode);
  //   let reqObj = { projectCode };
  //   const response = await this.ticketService.getProjectSubgroup(reqObj);
  //   this.subgrpList = response.transactions;
  //   // this.ticketTypeList = response.ticketTypeList;
  // }
  // async getProjectSubGroupEdit(projectCode) {
  //   console.log("PROJECTCODE....", projectCode);
  //   let reqObj = { projectCode };
  //   const response = await this.ticketService.getProjectSubgroup(reqObj);
  //   this.subgrpList = response.transactions;
  //   // this.ticketTypeList = response.ticketTypeList;
  //   //this.getagentNameedit()
  //   //this.select();
  // }
  // select() {
  //   let seleObj = this.agentForm.value;
  //   this.selectedgroup = seleObj.idAssignedSubGroup;

  //   this.getAgentNameEdit(this.selectedgroup);
  // }
  // async getAgentNameEdit(subGroupCode) {

  //   console.log("hdgfhdghg>>>>", subGroupCode);
  //   const response = await this.ticketService.getProjectAgentedit(subGroupCode);
  //     if(this.action == 'update'){
  //         this.agentEditList = response.transactions.unAssignedAgents;
  //     }

  // }
  // onSelectGroup(value: any) {
  //   this.selectedgroup = value.idAssignedSubGroup;
  //   this.getagentName(this.selectedgroup,this.idTicketCode);
  // }
  /* Agent dropdown */
  // async getagentName(subGroupCode,idTicketCode) {
  //   console.log("valueeeee", subGroupCode);
  //   //let reqObj = { subGroupCode};
  //   const response = await this.ticketService.getProjectAgent(subGroupCode,idTicketCode);
  //   if(this.action == 'update'){
  //     this.agentEditList = response.transactions.assignedAgents;
  //   }
  //   else{
  //     this.agentList = response.transactions.unAssignedAgents;
  //   }

  //   // this.ticketTypeList = response.ticketTypeList;
  // }
  async confirmAdd() {
    console.log(this.agentForm.value, "agentForm");

    for (const prop in this.agentForm.controls) {
      this.agentForm.value[prop] = this.agentForm.controls[prop].value;
    }
    let exact = this.pad(this.proposedHrs) + ":" + this.pad(this.proposedMins);
    console.log("exact", exact);
    let exactTime = this.sharedService.convertDurationToMinutes(exact);
    console.log("exactTime", exactTime);
    let local =
      this.agentForm.value.allocatedHrs +
      ":" +
      this.agentForm.value.allocatedMins;

    let localTime = this.sharedService.convertDurationToMinutes(local);
    console.log("local", localTime);

    this.ticketAgentList = this.ticketAgentList.filter(
      (item) =>
        item.idUserCode != this.data.ticketEditDialog.ticketagent.idUserCode
    );

    let agentTotal = this.ticketAgentList.reduce(
      (accumulator, currentValue) => {
        return (
          accumulator +
          this.sharedService.convertDurationToMinutes(
            currentValue.allocatedHrsMins
          )
        );
      },
      0
    );
    console.log("agentTotal", agentTotal);

    // let existingData = this.existingAllocated.split(':')[0] + ':' + this.existingAllocated.split(':')[1];
    // let existingTime =  this.sharedService.convertDurationToMinutes(existingData);
    // console.log('existingTime',existingTime);
    //  let finalAgentTotal = Number(agentTotal) + Number(localTime) + Number(existingTime);

    let finalAgentTotal = Number(agentTotal) + Number(localTime);

    console.log(finalAgentTotal, "finalTotal");
    this.localHrsMins = `${this.pad(
      this.agentForm.value.allocatedHrs
    )}:${this.pad(this.agentForm.value.allocatedMins)}`;
    this.localHrs = this.localHrsMins.split(":")[0];
    this.localMins = this.localHrsMins.split(":")[1];
    if (finalAgentTotal <= exactTime) {
      this.checkHrsMins = true;
    } else {
      this.checkHrsMins = false;
    }
    if (this.checkHrsMins) {
      let updateAllocatedHrs = Number(this.agentForm.value.allocatedHrs);
      let updateAllocatedMins = Number(this.agentForm.value.allocatedMins);
      // if (updateAllocatedHrs > 0 || updateAllocatedMins > 0) {
      //    this.allocatedCheck = true;
      // if(this.ticketEditData.allocatedHrs > 0){

      // }
      //   updateAllocatedHrs += Number(this.existingAllocated.split(':')[0]);
      //   updateAllocatedMins += Number(this.existingAllocated.split(':')[1])
      let addAgentData = {
        ...this.agentForm.value,
        allocatedHrsMins: `${this.pad(updateAllocatedHrs)}:${this.pad(
          updateAllocatedMins
        )}`,
        fromService: "N",
      };

      console.log("update agent", addAgentData);
      let convertData = this.sharedService.convertDurationToMinutes(
        addAgentData.allocatedHrsMins
      );
      let lastConvertData = this.convertMinsToDuration(convertData);

      let updateAgentData = {
        ...this.agentForm.value,
        allocatedHrsMins: `${this.pad(
          lastConvertData.split(":")[0]
        )}:${this.pad(lastConvertData.split(":")[1])}`,
        fromService: "N",
        logList: this.logList,
        agentSaved: this.agentSaved,
      };
      //  if(this.sharedService.convertDurationToMinutes(this.existingAllocated) <= this.sharedService.convertDurationToMinutes(addAgentData.allocatedHrsMins)){
      this.tempAgent.push(updateAgentData);
      this.onNoClick();
      // this.existingHrsMins = true;
      // } else{
      //   this.existingHrsMins = false;
      // }
      // } else {
      //   this.allocatedCheck = false;
      // }
    } else {
    }
  }
  onNoClick(): void {
    // let listData = this.tempAgent;
    // this.sharedService.setTicketAttach(listData);
    // this.tempAgent = listData;
    console.log("TempAGENT", this.tempAgent);
    this.dialogRef.close({ listData: this.tempAgent });
  }

  onTargetChange(targetValue) {
    this.agentForm.controls["remarks"].setValue("");
    this.agentForm.get("remarks").setValidators([Validators.required]);
    this.agentForm.get("remarks").updateValueAndValidity();
    console.log("targetValue", targetValue.value);
    this.logList["targetDate"] = targetValue.value;
  }
  onAllocatedHrsChange(allocated) {
    this.agentForm.controls["remarks"].setValue("");
    this.agentForm.get("remarks").setValidators([Validators.required]);
    this.agentForm.get("remarks").updateValueAndValidity();
    console.log("allocatedHrs", allocated.value);
    this.logList["allocatedHrs"] = allocated.value;
  }
  onAllocatedMinsChange(allocatedMins) {
    this.agentForm.controls["remarks"].setValue("");
    this.agentForm.get("remarks").setValidators([Validators.required]);
    this.agentForm.get("remarks").updateValueAndValidity();
    console.log("allocatedMins", allocatedMins.value);
    this.logList["allocatedMins"] = allocatedMins.value;
  }
  isRespAgentChange(event) {
    if (this.cb.checked) {
      if (this.resAgentNameList.length > 0) {
        Swal.fire({
          title: "Information",
          text: "Do you want to change the responsible agent",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          console.log("result", result);
          if (result.isConfirmed) {
            this.cb.checked = true;
          } else {
            this.cb.checked = false;
          }
        });
      } else {
        this.cb.checked = true;
      }
    } else {
      this.cb.checked = false;
    }
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
  compareFn(d1, d2): boolean {
    if (d1.idAssignedSubGroup) {
      return d1 && d2
        ? d1.idAssignedSubGroup === d2.idAssignedSubGroup
        : d1 === d2;
    }
    if (d1.idUserCode) {
      return d1 && d2 ? d1.idUserCode === d2.idUserCode : d1 === d2;
    }
  }
  async getAgentStatusList() {
    const response = await this.ticketService.getAgentStatusList();

    console.log("response?.body>>", response?.body);

    let responseList = response?.body?.data;
    this.agentStatusList = responseList;
  }

  convertMinsToDuration(convertData) {
    var minutes = convertData % 60;
    var hours = (convertData - minutes) / 60;
    return hours + ":" + minutes;
  }
}
