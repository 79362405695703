import { formatDate } from '@angular/common';
export class ProjectmasterModel {
  id: number;
    idSequence: number;
   gno: string;
    optionName: string;
    tabName: string;
    value: string;
    leftBracket: string;
    rightBracket: string;
   companyCode: string;
   nrCode:string;
   projectCode: string;
   additionalEmail:string;
   myTeamVisibility:string;
   customerGroup:string;
   friday:string;
   monday:string;
   projectEmail:string;
   projectGroup:string;
   projectManager:string;
   projectLead: string;
   projectName:string;
   projectType:string;
   productCode:string;
   remarks:string;
   saturday:string;
   slaRequired:string;
   statusFlag:string;
   sunday:string;
   supportEndTime:string;
   supportStartTime:string;
   projectClassificationCode:string;
   thursday:string;
   timeZone:any;
   tuesday:string;
   wednesday:string;
   colorCode:string;
   period:string;
   renewalDate:string;
   customer_pm:string;
   costPerUnit:string;
   img: string;
   file:string;
   uom:any;
   units:string;
   ratePerUnits:string;
   ratePerAdditionalUnits:string;
   ratePerCRUnits:string;
   ratePerOTUnits:string;
   unitsCarryFw:string;
   maxUnitsCarryFw:string;
   fromCommercialDate:string;
   renewalCommercialDate:string;
   projectStartDate:string;
   ticketTypeDate:string;
   commercialRemarks:string;
   refCode:any; 
   attachmentCode:string;
   attachmentGroup:string;
   attachmentRemarks:string;
   attachmentTypeCode:any;
   otUnitFlag:string;
   ticketTypeCode:any;
   ticketUnits:any;
   proxyAllowed:string;
    isnonbillable:string;
   unBilledThreshold:any;
   classificationType:any;
   ticketUom:any;
   currency:any;
   calcType:string;
   statusTypeForm:any;
   hoursTypeForm:any;
   hoursType:string;
   attentionType:string;
   commercialEndDate:string;
   commercialFlag:string;
   ruleName:string;
   actionType:string;
   actionLevel:string;
   actionMode:string;
   actionName:string;
   actionDetails:string;
   idDeptCode:any;

  // attachmentUrl:string;

  constructor(projectData) {
    {

        this.id = projectData.id || 0;//|| this.getRandomID();
        this.gno = projectData.gno || '';
        this.img = projectData.avatar || 'assets/images/project_icon.png';
        this.file = projectData.file || '';
        this.companyCode = projectData.companyCode || '';
        this.idSequence = projectData.idSequence || '';
        this.tabName = projectData.tabName || '';
        this.optionName = projectData.optionName || '';
        this.value = projectData.value || '';
        this.leftBracket = projectData.leftBracket || '';
        this.rightBracket = projectData.rightBracket || '';
        this.projectName = projectData.projectName || '';
        this.nrCode = projectData.nrCode || '';
        this.productCode = projectData.productCode || '';
        this.projectType = projectData.projectType || '';
        this.projectClassificationCode = projectData.projectClassificationCode || '';
        this.projectManager = projectData.projectManager || '';
        this.projectLead = projectData.projectLead || '';
        this.supportStartTime = projectData.supportStartTime || null;
        this.supportEndTime = projectData.supportEndTime || null;
        this.customer_pm = projectData.customer_pm || '';
        this.colorCode = projectData.colorCode || '#808080';
        this.customerGroup = projectData.customerGroup || '';
        this.projectGroup = projectData.projectGroup || '';
        this.timeZone = projectData.timeZone || '';
        this.slaRequired = projectData.slaRequired || '';
        this.sunday = projectData.sunday || '';
        this.monday = projectData.monday || 'Y';
        this.tuesday = projectData.tuesday || 'Y';
        this.wednesday = projectData.wednesday || 'Y';
        this.thursday = projectData.thursday || 'Y';
        this.friday = projectData.friday || 'Y';
        this.saturday = projectData.saturday || '';
        this.projectEmail = projectData.projectEmail || '';
        this.additionalEmail = projectData.additionalEmail || '';
        this.myTeamVisibility = projectData.myTeamVisibility || '';
        this.remarks = projectData.remarks || '';
        this.uom = projectData.uom || '';
        this.currency = projectData.currency || '';
        this.ticketUom = projectData.ticketUom || '';
        this.units = projectData.units || '';
        this.ratePerUnits = projectData.ratePerUnits || '';
        this.ratePerAdditionalUnits = projectData.ratePerAdditionalUnits || '';
        this.ratePerCRUnits = projectData.ratePerCRUnits || '';
        this.ratePerOTUnits = projectData.ratePerOTUnits || '';
        this.unitsCarryFw = projectData.unitsCarryFw || '';
        this.period = projectData.period || '';
        this.maxUnitsCarryFw = projectData.maxUnitsCarryFw || '';
        this.fromCommercialDate = projectData.fromCommercialDate || '';
        this.renewalCommercialDate = projectData.renewalCommercialDate || '';
        this.projectStartDate = projectData.projectStartDate || '';
        this.ticketTypeDate = projectData.ticketTypeDate || '';
        this.commercialRemarks = projectData.commercialRemarks || '';
        this.refCode = projectData.refCode || '';
        this.attachmentCode = projectData.attachmentCode || '';
        this.attachmentGroup = projectData.attachmentGroup || '';
        this.attachmentRemarks = projectData.attachmentRemarks || '';
        this.attachmentTypeCode = projectData.attachmentTypeCode || '';
        this.otUnitFlag = projectData.otUnitFlag || '';
        this.ticketTypeCode = projectData.ticketTypeCode || '';
        this.ticketUnits = projectData.ticketUnits || '';
        this.proxyAllowed = projectData.proxyAllowed || '';
        this.isnonbillable = projectData.isnonbillable || '';
        this.unBilledThreshold = projectData.unBilledThreshold || '',
        this.classificationType = projectData.classificationType || ''
        this.calcType = projectData.calcType || '',
        this.statusTypeForm = projectData.statusTypeForm || ''
        this.hoursType = projectData.hoursType || '',
        this.attentionType = projectData.attentionType || '',
        this.hoursTypeForm = projectData.hoursTypeForm || '',
        this.commercialEndDate = projectData.commercialEndDate || '';
        this.commercialFlag = projectData.commercialFlag || 'Y';
        this.ruleName = projectData.ruleName || '';
        this.actionType = projectData.actionType || '';
        this.actionLevel = projectData.actionMode || '';
        this.actionName = projectData.actionName || '';
        this.actionDetails = projectData.actionDetails || '';
        
        this.idDeptCode = projectData.idDeptCode || 0;


       // this.attachmentUrl = projectData.attachmentUrl || '';
   


    }
  }
/*  public getRandomID(): string {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4();
  }*/

  // public getStartTime() {
  //   let startDate = new Date();
  //   return new Date(startDate.getFullYear(),
  //   startDate.getMonth(),
  //   startDate.getDate(),
  //   9,
  //   30);
  // }

//   public getEndTime() {
//     let endDate =  new Date();
//     return new Date(endDate.getFullYear(),
//     endDate.getMonth(),
//     endDate.getDate(),
//     18,
//     30);
//   }
 }

