import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./assignedto-dialog.component.html",
  styleUrls: ["./assignedto-dialog.component.sass"],
})
export class AssignedtoDialogComponent implements OnInit, AfterViewInit {
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  projectCode: any[];
  assignList: any[];
  designation: any;
  subgrpList: any[];
  agentList: any[];
  ticketCode: any;
  isLoading: boolean;
  ticketEditData: TicketEditDialogModel;

  agentForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sharedService: SharedService
  ) {
    this.ticketEditData = new TicketEditDialogModel({});
    this.projectCode = data.ticket.assignedUsers;
    this.ticketCode = data.ticket.idTicketCode;
    console.log(">>>>", this.ticketCode);
    this.agentForm = this.createAgentForm();
  }
  createAgentForm(): FormGroup {
    return this.fb.group({
      id: 0,
      subGroup: [this.ticketEditData.subGroup],
      // supportTypeName: [this.ticketEditData.supportTypeName]
    });
  }
  public ngOnInit(): void {
    this.getAssigned(this.ticketCode);
  }
  async getAssigned(ticketCode) {
    this.isLoading = true;
    let reqObj = { ticketCode };
    const response = await this.ticketService.getTicketassigned(reqObj);
    this.assignList = response.data;
    this.isLoading = false;
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if (this.afterInit) {
      this.onSelColumns(this.columns);
    }
  }

  isChecked(col) {
    return (
      this.columns.find((c) => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
}
