import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SharedService } from "src/app/core/service/shared.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransportTypeService } from "src/app/transport-type/transport-type.service";
import { PartRequestService } from "src/app/part-request/part-request.service";

import { E } from "@angular/cdk/keycodes";
import { environment } from "src/environments/environment";
import { FieldMsg } from "src/app/core/service/field-msg.service";
import { DispatchTyeService } from "src/app/dispatch-type/dispatch-tye.service";
import { BrandVendorService } from "src/app/brand-vendor/brand-vendor.service";
import { ReasonMasterService } from "src/app/reason-master/reason-master.service";
import { TicketNewService } from "../../../ticket-new/ticket.service";
import { MassTicketUpdateService } from "../../../mass-ticket-update/mass-ticket-update.service";

@Component({
  selector: "app-mailLog-status-dialog",
  templateUrl: "./mailLog-status-dialog.component.html",
  styleUrls: ["./mailLog-status-dialog.component.scss"],
})
export class MailLogStatusDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  approvalPartsForm: FormGroup;
  transportCondition: any = "Y";
  isLoading: boolean;
  partPendingFlag: boolean = false;
  resolvedFlag: boolean = false;
  partPendingalert: boolean = false;
  viewPartsList: any = [];
  ownAssetVerifyData = [];
  selectedRowsData = [];
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  remarks: any;
  rejectRemarks: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  isSubmitDisable: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  statusDisable: boolean = false;
  transportTypeList: any = [];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  reasonList: any = [];
  currentstatus: any;
  tktCode: any;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<MailLogStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private massTicketUpdateService: MassTicketUpdateService
  ) {
    this.approvalPartsForm = this.createApprovalPartsForm();
    this.selectedRowsData = this.data;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.roleCheck = this.currentUser.data[0].roles;
    this.currentstatus = this.selectedRowsData[0].ticketStatusCode.toString();
    console.log("this.currentstatus :>> ", this.currentstatus);
  }

  createApprovalPartsForm(): FormGroup {
    return this.fb.group({
      currentstatus: [""],
      brandVendor: [""],
    });
  }
  ngOnInit(): void {
    console.log(" this.selectedRowsData >>>>>>", this.selectedRowsData);
    this.tktCode = this.selectedRowsData[0]?.ticketCode;
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
    this.massTicketUpdateService.cancelRequests();
  }
  isValidStatusTransition(currentStatus, nextStatus) {
    let statusOrder = [1, 3, 7, 5];
    const currentIndex = statusOrder.indexOf(currentStatus);
    const nextIndex = statusOrder.indexOf(nextStatus);
    return currentIndex > nextIndex;
  }
  statusValidation(event) {
    let currentStatus = this.selectedRowsData[0].ticketStatusCode;
    let nextStatus = parseInt(event);
    this.statusDisable = this.isValidStatusTransition(
      currentStatus,
      nextStatus
    );
  }
    async getCurrentLocation() {
        try {
            this.position = await this.sharedService.getCurrentLocation();
            console.log(this.position, 'position');
        } catch (e) {
            console.log('e', e);
            this.position = null;
        }
    }
  async updateTicket() {
      await this.getCurrentLocation();
      if (this.position) {
          this.isSubmitted = true;
          let selectedTicketData = this.selectedRowsData.map((item) => {
              return {
                  hapLat: this.position ? this.position.lat : '',
                  hapLong: this.position ? this.position.lng : '',
                  ticketCode: item.ticketCode,
                  ticketStatusCode: this.currentstatus,
                  ticketSubStatusCode: this.currentstatus,
              };
          });
          const response =
              this.massTicketUpdateService.oemTickets(selectedTicketData);

          response.subscribe(
              (result) => {
                  let successMessage = result ?.body ?.success;
                  if (result ?.body ?.success)
                  {
                      this.sharedService.showNotification(
                          "snackbar-success",
                          result ?.body ?.message,
                          "bottom",
                          "center"
                  )
                      ;
                      this.isSubmitted = false;
                  }
              else
                  {
                      this.sharedService.showNotification(
                          "snackbar-danger",
                          result ?.body ?.message,
                          "bottom",
                          "center"
                  )
                      ;
                      this.isSubmitted = false;
                  }
                  this.dialogRef.close({successMessage});
              },
              (error) => {
                  this.sharedService.showNotification(
                      "snackbar-danger",
                      error ?.error ?.message,
                      "bottom",
                      "center"
              )
                  ;
                  this.isSubmitted = false;
              }
          );
      }
  }
}
