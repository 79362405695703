import { formatDate } from '@angular/common';
export class TicketTimeDialogModel {
    id: number;
    idTicketCode: string;
    hrsType: string;
    idBookedDate: string;
    idFromTime: string;
    toTime: string;
    textArea: string;

    constructor(ticket) {
        {

            this.id = ticket.id || 0;
            this.idTicketCode = '';
            this.hrsType = this.hrsType || 'Billable';
     //     this.idBookedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en') || '';
            this.idBookedDate =  this.idBookedDate || '';
            this.idFromTime = ticket.hours || '';
            this.toTime = ticket.toTime || '';
            this.textArea = ticket.textArea || '';


        }
    }
    
   
}