import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { SharedService } from 'src/app/core/service/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransportTypeService } from 'src/app/transport-type/transport-type.service';
import { PartRequestService } from 'src/app/part-request/part-request.service';

import { E } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { FieldMsg } from 'src/app/core/service/field-msg.service';
import { DispatchTyeService } from 'src/app/dispatch-type/dispatch-tye.service';
import { BrandVendorService } from 'src/app/brand-vendor/brand-vendor.service';
import { ReasonMasterService } from 'src/app/reason-master/reason-master.service';
import {AttachmentImgDialogComponent} from "../attachmentImg-dialog/attachmentImg-dialog.component";

@Component({
  selector: 'app-ticket-attachment-dialog',
  templateUrl: './ticket-attachment-dialog.component.html',
  styleUrls: ['./ticket-attachment-dialog.component.scss'],
})
export class TicketAttachmentDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  approvalPartsForm: FormGroup;
  transportCondition: any = 'Y';
  isLoading: boolean;
  viewPartsList: any = [];
  ticketPartsData: any;
  selectedRowsData = [];
  rejectRemarks: any = '';
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  transportTypeList: any = [
    // {
    //   label: 'Vehicle',
    //   value: 'Vehicle',
    // },
    // {
    //   label: 'By Hand',
    //   value: 'By Hand',
    // },
    // {
    //   label: 'Courier',
    //   value: 'Courier',
    // },
    // {
    //   label: 'Parcel Service',
    //   value: 'Parcel Service',
    // },
  ];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  reasonList: any = [];
    isPopupVisible: boolean = false;
  constructor(
      public dialog: MatDialog,
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<TicketAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private transportType: TransportTypeService,
    private ticketService: PartRequestService,
    public fieldMsg: FieldMsg,
    public dispatchTyeService: DispatchTyeService,
    public brandVendorService: BrandVendorService,
    public reasonMasterService: ReasonMasterService
  ) {
    this.dispatchForm = this.createDispatchForm();
    this.ticketPartsData = this.data.data;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.roleCheck = this.currentUser.data[0].roles;

    console.log('partStatus', this.partStatus);

    this.dialogTitle = `View Travel Expensive Attachment`;

    this.getTravelAttachment(
      this.ticketPartsData.ticketCode,
      this.ticketPartsData.hapAssetCode,
    );

  }
  createDispatchForm(): FormGroup {
    return this.fb.group({
      transportType: [''],
      referenceNo: [],
      driverName: [],
      vehicleNo: [],
      mobileNo: [],
      personName: [],
      courierName: [],
      parcelServiceName: [],
    });
  }

  ngOnInit(): void { }

  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }

    /** popup **/
    attachmentPopup(ticket): void {

            const dialogRef = this.dialog.open(AttachmentImgDialogComponent, {
                width: "350px",
                height: "400px",
                disableClose: true,
                data: {
                    ticket: ticket,
                    action: "ticketList",
                },
            });

    }
  async getTravelAttachment(ticketCode,assetCode) {

    const response = await this.transportType.getAssetTravelExpenseAttachment(ticketCode,assetCode);
    this.transportTypeList = response?.body?.data || [];
  }


}
