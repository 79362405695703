import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventService } from 'src/app/core/service/event.service';
import { SharedService } from 'src/app/core/service/shared.service';
import {TicketEditDialogModel} from "../ticketeditdialog/ticket-edit-dialog.model";
import {TicketService} from "../../../ticket/ticket.service";
import {CreatedbyDialogComponent} from "../createdby-dialog/createdby-dialog.component";
import {ConditionElement} from "../rule-condition-dialog/rule-condition-dialog.component";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-show-column-dialog',
  templateUrl: './agentlist-dialog.component.html',
  styleUrls: ['./agentlist-dialog.component.sass']
})
export class AgentlistDialogComponent implements OnInit, AfterViewInit {
  public fname: string = 'John';
  public lname: string = 'Deo';
    infoData: TicketEditDialogModel;
    tempList:any=[];
    versionList: any=[];
    dataSource = new MatTableDataSource(this.tempList);
    listData: ConditionElement[] = [];
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
    public ticketEditForm: FormGroup;
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AgentlistDialogComponent>,
     public eventService:EventService,public sharedService:SharedService,
              private ticketService: TicketService,
      public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.tempList = data.tempData;
      console.log('filleeeeeeeeeee', this.tempList);

      this.infoData = new TicketEditDialogModel({});
      //this.getVersion(this.ticketCode);
     // console.log('this.data', this.data);
    }

  public ngOnInit(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();

  }






  ngAfterViewInit() {
    this.afterInit= true;
}
}
