import { FieldMsg } from "./../../../core/service/field-msg.service";
import { TicketNewService } from "src/app/ticket-new/ticket.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Subscription } from "rxjs";
import { TicketModel } from "src/app/ticket/ticket.model";
import { TicketService } from "src/app/ticket/ticket.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { environment } from "src/environments/environment";

import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { ProjectModuleStatusService } from "./projectModuleStatus.service";
import { TicketEditDialogModel } from "../ticketeditdialog/ticket-edit-dialog.model";
import { ProjectModuleStatusModel } from "./ProjectModuleStatusModel";

@Component({
  selector: "app-projectModuleStatus",
  templateUrl: "./projectModuleStatus.component.html",
  styleUrls: ["./projectModuleStatus.component.css"],
})
export class ProjectModuleStatusComponent implements OnInit {
  public Editor = ClassicEditor;

  projectModuleStatusForm: FormGroup;
  currentUser: any;
  roleCheck: any;
  routerUrl: any;
  dialogTitle: any;
  searchCriteria: any;
  departmentCode: any;
  productCode: any;
  searchCriteriaRole: any;
  //  ticketEditData: TicketEditDialogModel;
  departmentList: any = [];
  productMasterList: any = [];
  moduleList: any = [];
  ticketmoduleList: any = [];
  ticketStatusList: any = [];
  subStatusList: any = [];
  roleMasterList: any = [];
  moduleMasterList: any = [];
  projectCode: any;
  companyCode: any;
  currentDate: any;
  lookupData: any;
  action: any;
  projectModuleStatusData: ProjectModuleStatusModel;
  searchValue: any;
  searchField: any;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  companyList: any = [];
  constructor(
    public dialogRef: MatDialogRef<ProjectModuleStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private TicketNewService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg,
    
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.roleCheck = this.currentUser.data[0].roles;
    this.routerUrl = this.sharedService.routerUrl();

    console.log(data.action, "action swetha");
    console.log(data, "data");
    console.log(data.projectData, " swetha");
    this.projectCode = data.projectCode;
    this.companyCode = data.projectData.companyCode;
    console.log("Company Code....", this.companyCode);
    // Set the defaults
    this.action = data.action;
    dialogRef.disableClose = true;
    if (this.action === "update") {
      //   this.isDetails = false;
      this.dialogTitle = "Edit Project Module Status";
      //  this.licenHeaderName = data.licenseType.licenseTypeCode;
      this.projectModuleStatusData = data.projectModuleStatusComponent.project;
      this.projectModuleStatusForm = this.createprojectModuleStatusForm();
      //  this.isEdit= true;
    } else {
      //  this.isDetails = false;
      this.dialogTitle = "Add Project Module Status";
      //  this.isEdit= false;
      //this.licenseTypeData = new LicensetypeModel({});
      //this.projectModuleStatusData = new ProjectModuleStatusModel({});
      this.projectModuleStatusForm = this.createprojectModuleStatusForm();
      this.projectModuleStatusForm.patchValue({      
      });
    }
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.loadDataDepartment();
    this.loadDataProductMaster();
    this.getModule(this.projectCode);
    //this.loadDataStatus();
    //this.loadDatasubStatus();
    this.loadProjectData();

    this.currentDate = new Date().toISOString().slice(0, 10);
    console.log("currentDatecurrentDate", this.currentDate);
  }
  createprojectModuleStatusForm(): FormGroup {
    return this.fb.group({
      id: 0,
      idProductCode: [""],
      idDeptCode: [""],
      allowChange: [""],
      allowRemoval: [""],
      idFromDate: [""],
      idStatusCode: [""],
      idSubStatusCode: [""],
      ticketVisible: [""],
      idModuleCode: [""],
      idRoleCode: [""],
    });
  }
  //Role Dropdown
  async loadDataRoleMaster(deptCode) {
    this.roleMasterList = [];

    const response = await this.TicketNewService.getListRoleMaster(deptCode);
    this.roleMasterList = response?.body?.data;
  }
  //Module Dropdown
   loadDataModule(productCode) {
    this.moduleMasterList = [];
    const response =  this.TicketNewService.getListModule(productCode);
      response.subscribe(
          result => {
              this.moduleMasterList = result?.body?.data;
              console.log("moduleList", this.moduleMasterList);
          },
          error => {
              console.error('Error:', error);
          }
      );

  }

  //subStatus dropdown
  /*   async loadDatasubStatus() {
        this.subStatusList = [];
        const response = await this.TicketNewService.getListTicketSubStatus();
        this.subStatusList = response?.body?.data;
    } */
  //Status
  /*   async loadDataStatus() {
        this.ticketStatusList = [];
        try{
            const response = await this.TicketNewService.getListTicketStatus();
            this.ticketStatusList = response?.body?.data;

        }catch(e){
            this.sharedService.showNotification(
                "snackbar-danger",
                "No Data Found",
                "bottom",
                "center"
            );
        }

    } */
  /* Module dropdown */
  async getModule(projectCode) {
    let reqObj = { projectCode };
    const response = await this.TicketNewService.getModule(reqObj);
    this.moduleList = response.moduleList;

    this.moduleList = this.moduleList.map((item) => {
      return {
        ...item,
        moduleCode: parseInt(item.moduleCode),
      };
    });
    this.ticketmoduleList = response.ticketTypeList;

    /* let filterType = this.ticketmoduleList.filter(
            (item) => item.ticketTypeCode == this.ticketType,
        );*/
  }

  //product master dropdown
  async loadDataProductMaster() {
    this.productMasterList = [];
    const response = await this.TicketNewService.getListProductMaster();
    this.productMasterList = response?.body?.data;
  }
  //dept list
  async loadDataDepartment() {
    this.departmentList = [];
    this.searchCriteria = {
      pageNo: 0,
      sortOrder: true,
      sortColumn: "",
      pageSize: 0,
    };
    const response = await this.TicketNewService.getListDepartment(
      this.searchCriteria
    );
    this.departmentList = response?.body?.transactions;
  }
  onRole(event) {
    console.log("onRole", event.value);
    this.departmentCode = event.value;
    this.loadDataRoleMaster(this.departmentCode);
  }

  onProduct(event) {
    console.log("onProduct", event.value);
    this.productCode = event.value;
    this.loadDataModule(this.productCode);
  }
  async confirmAdd() {
    this.spinner.show();
    let projectmoduleStatusObj = this.projectModuleStatusForm.value;

    console.log("obj...swetha", projectmoduleStatusObj);
    let allowChange;
    let ticketVisible;
    let allowRemoval;
    if (projectmoduleStatusObj.allowChange === true) {
      allowChange = "Y";
    } else {
      allowChange = "N";
    }
    if (projectmoduleStatusObj.ticketVisible === true) {
      ticketVisible = "Y";
    } else {
      ticketVisible = "N";
    }
    if (projectmoduleStatusObj.allowRemoval === true) {
      allowRemoval = "Y";
    } else {
      allowRemoval = "N";
    }
    let reqObj = {
      idProductCode: projectmoduleStatusObj.idProductCode,
      idProjectCode: this.projectCode,
      modules: [
        {
          depts: [
            {
              idCompanyCode: this.companyCode,
              idDeptCode: projectmoduleStatusObj.idDeptCode,
              roles: [
                {
                  idRoleCode: projectmoduleStatusObj.idRoleCode,
                  statusConfig: [
                    {
                      allowChange: "Y",
                      allowRemoval: "Y",
                      idFromDate: this.currentDate,
                      idStatusCode: projectmoduleStatusObj.idStatusCode.idStatusCode,
                      idSubStatusCode: projectmoduleStatusObj.idSubStatusCode,
                      inActive: true,
                      orderNo: 0,
                      ticketVisible: "Y",
                    },
                  ],
                },
              ],
            },
          ],
          idModuleCode: projectmoduleStatusObj.idModuleCode, //always empty
        },
      ],
    };
    console.log("obj...reqOBJ SAVE", reqObj);
    let response = await this.TicketNewService.addProjectModuleStatus(reqObj);
    console.log("response????", response);
    if (response?.body?.success) {
      console.log("response?.body", response?.body);
      this.sharedService.showNotification(
        "snackbar-success",
        response?.body?.message,
        "bottom",
        "center"
      );
    } else {
      this.sharedService.showNotification(
        "snackbar-danger",
        response?.body?.message,
        "bottom",
        "center"
      );
    }
    this.spinner.hide();
    this.dialogRef.close({ action: "save" });
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
  }
  async loadProjectData() {
    this.companyList = [];

    var countryCode = "IN";
    this.searchCriteria = {
      pageNo: this.pageIndex,
      sortOrder: this.sortOrder,
      sortColumn: this.sortColumn,
      searchColumn: this.searchField,
      searchValue: this.searchValue,
      countryCode: countryCode,
    };
    const response = await this.TicketNewService.getProjectList(
      this.searchCriteria
    );
    console.log("companyList>>", response);
    console.log("response?.body>>", response?.body.Data);
    const {
      companyList,
      productList,
      // projectTypeList,
      supportTypeList,
      ticketTypeList,
      serviceProviderList,
      carryForwardPeriodList,
      projectManagerList,
      statusAndSubStatusList,
      priorityList,
      timeZoneList,
      attachmentTypeList,
      currencyList,
      uomList,
    } = response?.body.Data;

    console.log("CompanyList", companyList);

    this.companyList = companyList;
    //  this.anotherCompanyList = this.companyList;
    // this.filteredList5 = this.companyList.slice();
    // this.productList = productList;
    // this.anotherProductArray = productList;
    // this.projectTypeList = projectTypeList;
    //this.supportTypeList = supportTypeList;
    // this.ticketTypeList = ticketTypeList;
    // this.serviceProviderList = serviceProviderList;
    // this.carryForwardList = carryForwardPeriodList;
    // this.projectManagerList = projectManagerList;
    //this.anotherManagerArray = projectManagerList;
    //   this.timeZoneList = timeZoneList;
    //  this.attachmentTypeList = attachmentTypeList;
    // this.currencyList = currencyList;
    // this.uomList = uomList;
    this.ticketStatusList = statusAndSubStatusList;
    // this.ticketPriorityList = priorityList;

    console.log("productList", this.ticketStatusList);
  }
  changeStatus(e) {
    console.log("000", e);
    console.log("001", this.projectModuleStatusForm.value);

    this.subStatusList = e.value?.SubStatusList || [];
  }
}
