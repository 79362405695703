import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
// import { LicensetypeService } from '../change-company-dialog.service';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
// import { LicensetypeModel } from '../change-company-dialog.model';
import { formatDate } from "@angular/common";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";

@Component({
  selector: "app-change-company",
  templateUrl: "./change-company-dialog.component.html",
  styleUrls: ["./change-company-dialog.component.sass"],
})
export class ChangeCompanyDialogComponent {
  action: string;
  dialogTitle: string;
  error: string;
  submitted = false;
  loginForm: FormGroup;
  userInfo: any[];
  companyList: any[];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public licensetypeService: LicensetypeService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    // Set the defaults
    this.action = data.action;
    this.userInfo = data.userInfo;
    this.companyList = data.userInfo[0].userInfo?.ownerCompanyInfo;
    this.submitted = false;
    this.dialogTitle = "Switch Company";
  }

  ngOnInit() {
      const {email,gNo } = this.userInfo[0].userInfo;
      console.log('this.userInfo[0].userInfo', this.userInfo[0].userInfo);
    this.loginForm = this.fb.group({
      email: [email],
      password: [null, [Validators.required]],
      rememberMe: [null],
      gNo: [gNo],
        forceLogin:false
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.error = "Username and Password not valid!";
      return;
    } else {
      console.log("form value", this.f);
     // this.authService
        /*.login(
          this.f.email.value,
          this.f.password.value,
          this.f.rememberMe.value,
          this.f.gNo.value

        )
        .subscribe(
          (res) => {
            if (res) {
              // const token = this.authService.currentUserValue.token;
              const status = this.authService.currentUserValue.success;
              const message = this.authService.currentUserValue.message;
              // const data = this.authService.currentUserValue;
              // console.log('data', data);
              if (status) {
                // this.router.navigate(["/dashboard/main"]);
                this.dialogRef.close({action: this.action ,res});
              } else {
                this.error = message;
              }
            } else {
              this.error = "Invalid Login";
            }
          },
          (error) => {
            // this.error = error;
            this.error = error.error.message;
            this.submitted = false;
          }
        );*/
    }
  }
  onNoClick(): void {
    // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   disableClose: true,
    //   data: {
    //     name: 'Cancel'
    //   }
    // });
    // dialogRef.afterClosed().subscribe(res => {
    //   if (res.event === 'Yes') {
    //     this.dialogRef.close({action: 'close'});
    //   }
    // });
  }
  public async confirmAdd() {
    /* let response = await this.licensetypeService.addLicenseType(this.licenseTypeForm.getRawValue());
        console.log('response????' , response);
        if(response?.body){
            this.dialogRef.close({action: this.action ,response});
        } */
  }
}
