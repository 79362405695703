import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TicketNewService } from 'src/app/ticket-new/ticket.service';
import { FieldMsg } from '../../../core/service/field-msg.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ticket-timeline',
  templateUrl: './ticket-timeline.component.html',
  styleUrls: ['./ticket-timeline.component.scss'],
})
export class TicketTimeLineComponent implements OnInit {
  ticketData: any;
  ticketCode: any;
  dialogTitle: any;
  isLoading: boolean = false;
  isGoogleVisible: boolean = false;
  mapTextLabel: any;
  defaultImg = 'assets/images/user/usrbig1.jpg';
  markerUrl =
    'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png';
  ticketTimeLineData: any = [];
  downloadTicketStatus: any = [];
  mapMarkers: any = [];
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TicketTimeLineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketService: TicketNewService,
    public fieldMsg: FieldMsg,
  ) {
    this.ticketData = data.ticket;
    this.ticketCode = data.ticket.ticketCode;

    this.dialogTitle = `Ticket Tracker :: ${this.ticketCode}`;
  }

  ngOnInit() {
    this.getTicketHistory(this.ticketCode);
  }

  async getTicketHistory(ticketCode) {
    this.isLoading = true;
    let reqObj = { ticketCode };
    const response = await this.ticketService.getTicketHistory(reqObj);
    console.log('response?.body>>', response?.body?.data);
    this.ticketTimeLineData = response?.body?.data;
    this.isLoading = false;
  }


  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }

  //Download History
  async downloadTicketStatusHistory(ticketCode) {

    this.isLoading = true;
    const response = await this.ticketService.downloadTicketStatusHistory(ticketCode);
    console.log('response?.body>>Sweeee', response);
    let data = response;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `TicketStatusHistory_${new Date().getDate()}${new Date().getMonth() + 1
      }${new Date().getFullYear()}.xlsx`
    );
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 0);
    //  this.downloadTicketStatus = ;
    this.isLoading = false;
  }

  ticketHistory(row) {
    const dialogRef = this.dialog.open(TicketTimeLineComponent, {
      data: {
        ticket: row?.data,
        action: 'details',
      },
      width: '1420px',
      height: '620px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result view', result.action);
    });
  }

  closeGooglePopup() {
    this.isGoogleVisible = false;
  }
  mapView(index) {
    this.mapTextLabel = `Map View :: ${this.ticketCode}`;

    console.log('  this.ticketTimeLineData', this.ticketTimeLineData);
    let marker = {};
    for (let i = 0; i < this.ticketTimeLineData.length; i++) {
      if (index === i) {
        if (
          this.ticketTimeLineData[i].hapLatitude === 0 ||
          this.ticketTimeLineData[i].hapLongitude === 0
        ) {
          this.isGoogleVisible = false;
          Swal.fire({
            title: '<i class="material-icons">location_off</i>',
            text: 'Location not found',
            // icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            // confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            // confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.value) {
              this.dialogRef.close({ action: 'close' });
            }
          });
        } else {
          this.isGoogleVisible = true;
          marker = {
            location: {
              lat: this.ticketTimeLineData[i].hapLatitude,
              lng: this.ticketTimeLineData[i].hapLongitude,
            },
            tooltip: {
              isShown: true,
              text: this.ticketTimeLineData[i].statusName,
            },
          };
        }
      }
    }

    this.mapMarkers = [marker];

    console.log('MarkerMarker', this.mapMarkers);
  }
}
