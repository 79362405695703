import {NgxSpinnerService} from 'ngx-spinner';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SharedService} from 'src/app/core/service/shared.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { ScrapTicketService } from 'src/app/scrap-ticket/scrap-ticket.service';
@Component({
  selector: 'app-scrap-confirmation-dialog',
  templateUrl: './scrap-confirmation-dialog.component.html',
  styleUrls: ['./scrap-confirmation-dialog.component.scss'],
})
export class ScrapConfirmationDialogComponent implements OnInit {
  dialogTitle: any;
  scrapForm: FormGroup;
  searching: false;
  position: any;
  ticketCode: any;
  isSubmitted: boolean = false;
  scrapData: any;
  minDate: any = new Date();
  scrapStatusList: any = [
    {
      label: 'Initiate Replacement',
      value: 'Initiate Replacement',
    },
    {
      label: 'Approved',
      value: 'Approved',
    },
    {
      label: 'Reject',
      value: 'Reject',
    },
  ];

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<ScrapConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private ticketService: ScrapTicketService,
  ) {
    
    console.log('data', this.data.ticketScrapData.data);
    this.dialogTitle = `Scrap :: ${this.data.ticketScrapData.data.ticketCode}`;
    this.ticketCode = this.data.ticketScrapData.data.ticketCode;
    this.scrapData = this.data.ticketScrapData.data;
    this.scrapForm = this.createScrapForm();
  }
  createScrapForm(): FormGroup {
    return this.fb.group({
      scrapStatus: [(this.scrapData.approvedStatus == 'Y' ? 'Approved' : this.scrapData.approvedStatus == 'N' ? 'Reject' : ''), Validators.required],
      comments: [],
      approvalDate: [],
    });
  }
  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close();
  }
  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, 'position');
    } catch (e) {
      console.log('e', e);
      this.position = null;
    }
  }
  onChangeDetails(event) {
    if (event.value) {
      this.scrapForm.get('comments').reset();
      this.scrapForm.get('approvalDate').reset();

      if (event.value == 'Initiate Replacement') {
        this.scrapForm.get('approvalDate').setValidators([Validators.required]);
        this.scrapForm.get('approvalDate').updateValueAndValidity();
      } else {
        this.scrapForm.get('approvalDate').clearValidators();
        this.scrapForm.get('approvalDate').updateValueAndValidity();
      }
      if (event.value == 'Reject') {
        this.scrapForm.get('comments').setValidators([Validators.required]);
        this.scrapForm.get('comments').updateValueAndValidity();
      } else {
        this.scrapForm.get('comments').clearValidators();
        this.scrapForm.get('comments').updateValueAndValidity();
      }
    }
  }
  async saveScrap() {
    await this.getCurrentLocation();

    if (this.position) {
      console.log('scrapForm Value', this.scrapForm.value);
      let formObj = this.scrapForm.getRawValue();
      /** spinner start */
      this.spinner.show();
      this.isSubmitted = true;

      let assetScrapData = [
        {
          approvedStatus: formObj.scrapStatus == 'Approved' ? 'Y' : 'N',
          assetAgeInDays: '',
          assetAgeInWords: '',
          assetCode: this.data.ticketScrapData.data
            ? this.data.ticketScrapData.data.assetCode
            : '',
          gmApprovedStatus: '',
          gmRemarks: '',
          remarks: formObj.scrapStatus == 'Approved' ? '' : formObj.comments,
          scrapId: this.data.ticketScrapData.data
            ? this.data.ticketScrapData.data.scrapId
            : '',
          ticketCode: '',
        },
      ];

      let masterObj = [
        {
            hapRemarks:formObj.scrapStatus == 'Approved' ? '' : formObj.comments ,
            hapGeoAddress: "",
          cancelRemarks: '',
          hapHubCode: '',
          hapHubRemarks: '',
          hapLat: this.position ? this.position.lat : '',
          hapLong: this.position ? this.position.lng : '',
          ticketCode: '',
          ticketStatusCode: 17, // Scrap,
          ticketSubStatusCode: 26, //Scrap,
        },
      ];
      var ticketEdit = {
        ticketCode: this.ticketCode,
        assetCompliants: [],
        assetIntake: [],
        assetMaterials: [],
        assetTravel: [],
        comments: [],
        jobCard: [],
        master: masterObj,
        users: [],
        assetScrap: assetScrapData,
      };

      console.log('WHOLE EDIT DATA', ticketEdit);

      try {
        let response = await this.ticketService.updateTicket(ticketEdit);

        if (response?.body?.success) {
          this.sharedService.showNotification(
            'snackbar-success',
            response?.body?.message,
            'bottom',
            'center',
          );

          await this.spinner.hide();
          this.dialogRef.close({action: 'assetScrap'});
        } else {
          /** spinner hide */
          await this.spinner.hide();
          this.sharedService.showNotification(
            'snackbar-danger',
            response?.body?.message,
            'bottom',
            'center',
          );
        }
        this.isSubmitted = false;
      } catch (e) {
        await this.spinner.hide();
        this.isSubmitted = false;
        this.dialogRef.close({action: 'close'});
      }
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'This app needs the Location service, please turn on the Location',
        'bottom',
        'center',
      );
    }
  }

  onApprovalChange(event) {}
}
