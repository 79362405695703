import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { DispatchType } from "./dispatch-type.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "../../app/core/service/api.service";
@Injectable({
  providedIn: "root",
})
export class DispatchTyeService {
  isTblLoading = true;
  dataChange: BehaviorSubject<DispatchType[]> = new BehaviorSubject<
    DispatchType[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    // super();
  }
  get data(): DispatchType[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  async getDispatchType() {
    const dispatchTypeList$ = this.apiService.get(
      "/api/hap/master/getHapDispatchTypeMaster"
    );
    let response = await lastValueFrom(dispatchTypeList$);
    return response;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }

  async addDispatchType(dispatchData) {
    this.dialogData = dispatchData;
    //console.log("this.dialogData", this.dialogData);
    const addRes$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateDispatch",
      this.dialogData
    );
    let response = await lastValueFrom(addRes$);
    return response;
  }
}
