import { formatDate } from '@angular/common';
export class TicketEditDialogModel {
    id: number;
    date: string;
    hours: string;
    respAgentCode: string;
    referenceTicket: string;
    itext: string;
    ntext: string;
    city: string;
    countryName: string;
    supportStartTime: string;
    supportEndTime: string;
    moduleCode: string;
    moduleName: string;
    ticketTypeCode: string;
    ticketTypeName: string;
    ticketTypeClassificationCode: string;
    ticketPriorityCode: string;
    ticketStatusCode: any;
    ticketSubStatusCode: any;
    ticketStatusName: any;
    statusName: any;
    totalBookedBHours: string;
    totalBookedNBHours: string;
    projectManagerName: string;
    proxyManagerName:string;
    customerManagerName: string;
    createdByName: string;
    proxyUserName: string;
    proposedByName:string;
    createdOn: string;
    attachmentTypeCode: string;
    splitHours: string;
    subGroup: string;
    mptDue: string;
    irtDue: string;
    allocatedHrs: any;
    allocatedHrsMins:any;
    version: string;
    assignedSubGroupCode: string;
    totalNbhrsBooked: string;
    totalBhrsBooked: string;
    datetime: string;
    remarks: string;
    idAssignedSubGroup: string;
    idUserCode: string;
    proposedHrs: string;
    proposedMins: string;
    targetDate: string;
    vRemarks: string;
    idTicketCode: string;
    retrieveHours: string;
    ticketTags: string;
    userCode: string;
    agentStatusCode: string;
   
   


    constructor(ticket) {
        {

            this.id = ticket.id || 0;
            this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en') || '';
            this.supportStartTime = ticket.supportStartTime || '';
            this.retrieveHours = ticket.retrieveHours || '';
            this.ticketTags = ticket.ticketTags || '';
            this.assignedSubGroupCode = ticket.assignedSubGroupCode || '';
            this.idTicketCode = ticket.idTicketCode || '';
            this.userCode = ticket.userCode || '';
            this.countryName = ticket.countryName || '';
            this.version = ticket.version || '';
            this.city = ticket.city || '';
            this.targetDate = ticket.targetDate || '';
            this.itext = ticket.itext || '';
            this.ntext = ticket.ntext || '';
            this.vRemarks = ticket.vRemarks || '';
            this.idAssignedSubGroup = ticket.idAssignedSubGroup || '';
            this.createdOn = ticket.createdOn || '';
            this.idUserCode = ticket.idUserCode || '';
            this.proposedHrs = ticket.proposedHrs || '00';
            this.proposedMins = ticket.proposedMins || '00';
            this.hours = ticket.hours || '';
            this.respAgentCode = ticket.respAgentCode || '';
            this.referenceTicket = ticket.referenceTicket || '';
            this.createdByName = ticket.createdByName || '';
            this.proxyUserName = ticket.proxyUserName || '';
            this.proposedByName = ticket.proposedByName || '';
            this.attachmentTypeCode = ticket.attachmentTypeCode || '';
            this.splitHours = ticket.splitHours || '';
            this.remarks = ticket.remarks || '';
            this.allocatedHrs = ticket.allocatedHrs || '';
            this.allocatedHrsMins = ticket.allocatedHrsMins || '';
            this.agentStatusCode = ticket.agentStatusCode || '';
            this.totalNbhrsBooked = ticket.totalNbhrsBooked || '';
            this.totalBhrsBooked = ticket.totalBhrsBooked || '';
            this.datetime = ticket.datetime || '';
            this.mptDue = ticket.mptDue || '';
            this.irtDue = ticket.irtDue || '';
            this.supportEndTime = ticket.supportEndTime || '';
            this.ticketStatusCode = {ticketStatusCode: ticket.ticketStatusCode, ticketStatusName: ticket.statusName} || '';
            this.statusName = ticket.statusName || '';
            this.ticketStatusName = ticket.statusName || '';
            this.projectManagerName = ticket.projectManagerName || '';
            this.proxyManagerName = ticket.proxyManagerName || '';
            this.totalBookedBHours = ticket.totalBookedBHours || '';
            this.totalBookedNBHours = ticket.totalBookedNBHours || '';
            this.customerManagerName = ticket.customerManagerName || '';
            this.ticketTypeClassificationCode = ticket.ticketTypeClassificationCode || '';
            this.moduleCode = ticket.moduleCode || '';
            this.moduleName = ticket.moduleName || '';
            this.ticketTypeCode = ticket.ticketTypeCode || '';this.ticketTypeName = ticket.ticketTypeName || '';
            this.subGroup = ticket.subGroup || '';
            this.ticketPriorityCode = ticket.ticketPriorityCode || '';
           


        }
    }
    /* public getRandomID(): string {
        const S4 = () => {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return S4() + S4();
    } */


}