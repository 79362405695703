import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SharedService } from "src/app/core/service/shared.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransportTypeService } from "src/app/transport-type/transport-type.service";
import { PartRequestService } from "src/app/part-request/part-request.service";

import { E } from "@angular/cdk/keycodes";
import { environment } from "src/environments/environment";
import { FieldMsg } from "src/app/core/service/field-msg.service";
import { DispatchTyeService } from "src/app/dispatch-type/dispatch-tye.service";
import { BrandVendorService } from "src/app/brand-vendor/brand-vendor.service";
import { ReasonMasterService } from "src/app/reason-master/reason-master.service";
import { TicketNewService } from "../../../ticket-new/ticket.service";
import { MassTicketUpdateService } from "../../../mass-ticket-update/mass-ticket-update.service";
import {PartsStockService} from "src/app/parts-stock/parts-stock.service";
import {ResponseService} from "../../../service/response.service";
import {Workbook} from "exceljs";
import { writeFile } from 'xlsx';
import {ExcelExportService} from "../../../service/excel-export-service";

@Component({
  selector: "app-bulkexcel-upload-dialog",
  templateUrl: "./bulkexcel-upload-dialog.component.html",
  styleUrls: ["./bulkexcel-upload-dialog.component.scss"],
})
export class BulkexcelUploadDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  bulkUploadForm: FormGroup;
  transportCondition: any = "Y";
  isLoading: boolean;
  partPendingFlag: boolean = false;
  resolvedFlag: boolean = false;
  partPendingalert: boolean = false;
  viewPartsList: any = [];
  ownAssetVerifyData = [];
  selectedRowsData = [];
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  remarks: any;
  rejectRemarks: any;
  rejectPartsData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  isSubmitDisable: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  statusDisable: boolean = false;
    successMessage: boolean = false;
  transportTypeList: any = [];
    verifyExcelData: any = [];
  UserRoles: any = environment?.roles;
  isSubmitted: boolean = false;
  isSave: boolean = false;
  reasonList: any = [];
  currentstatus: any;
  dialogueTitle: any;
    excelPopupVisible: boolean;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<BulkexcelUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private partStockService: PartsStockService,
    public responseService: ResponseService,
    public excelExportService: ExcelExportService

  ) {
    this.bulkUploadForm = this.createBulkExcelUploadForm();
    this.dialogueTitle = this.data.dialogueTitle;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.roleCheck = this.currentUser.data[0].roles;
  }

    createBulkExcelUploadForm(): FormGroup {
    return this.fb.group({
        attachments: [""],

    });
  }
  ngOnInit(): void {
    console.log(" this.selectedRowsData >>>>>>", this.selectedRowsData );
  }

    onFileSelect(e: any): void {

        console.log('file>>', e);
        const file = e.target.files[0];
        const files = e.target.files;
        console.log("FILEEEEEEE",files.length);
        if(files.length > 1){
            const selFiles = [];
            this.bulkUploadForm.get("attachments").setValue(selFiles);
        }else{
            this.spinner.show();
            let formData = new FormData();
            formData.append("file", file);
            const response = this.partStockService.bulkExcelValidation(formData);
            response.subscribe(
                (result) => {
                    console.log("result",result)
                    this.isSave = false;
                    this.successMessage = true;
                    if (result?.body?.success === true) {
                        this.verifyExcelData = result?.body?.data;
                        this.isSubmitted = false;
                    }
                    this.spinner.hide();
                },
                (error) => {
                    this.successMessage = false;
                    let statusCode = this.responseService.errorStatusCode;
                    if(statusCode === 409){
                        this.verifyExcelData = this.responseService.errorResponse;
                        console.log("verifyExcelData>>>>>>",this.verifyExcelData);
                        this.excelPopupVisible= true;
                        this.spinner.hide();
                        this.isSave = true;
                    }else{
                        this.spinner.hide();
                        this.isSave = true;
                        this.sharedService.showNotification(
                            "snackbar-danger",
                            "Error in Saving",
                            "bottom",
                            "center"
                        );
                    }

                })
        }


        }
    uploadExcel(){
      if(this.successMessage === true){
          this.isSave = true;
          this.isSubmitted = true;
          const response = this.partStockService.uploadExcel(this.verifyExcelData);
          response.subscribe(
              (result) => {
                  this.isSubmitted = false;
                  this.isSave = false;
                  console.log("result",result)
                  if (result?.body?.success ) {

                      this.sharedService.showNotification(
                          "snackbar-success",
                          result?.body?.message,
                          "bottom",
                          "center"
                  );
                      this.dialogRef.close({ action: "close" });
                  }

              },
              (error) => {
                  this.sharedService.showNotification(
                      "snackbar-danger",
                      "Error in Saving",
                      "bottom",
                      "center"
                  );
                  this.isSubmitted = false;
                  this.isSave = false;
              })
      }


    }
    exportDataToExcel(): void {
        const data = [
            { 'Asset Category' : '', 'Material No':'' , 'Material Flag': '','MSP Code / HUB Code':'','Stock Count':'' }
        ];

        this.excelExportService.exportToExcel(data, 'Material Stock Bulk Upload Template.xlsx');
    }
  onNoClick() {
    this.dialogRef.close({ action: "close" });
    this.partStockService.cancelRequests();
  }

}
