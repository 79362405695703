import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Subject } from "rxjs";
import { TicketModel } from "./ticket.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { ApiService } from "../core/service/api.service";
import { reject, resolve } from "q";
import { takeUntil } from "rxjs/operators";
@Injectable()
export class TicketService {
  //  private readonly API_URL = 'assets/data/licensetype.json';
  isTblLoading = true;
  ticketBaseApi = "/api/nerve/ticket";
  private detailsDestroy$ = new Subject<void>();
  dataChange: BehaviorSubject<TicketModel[]> = new BehaviorSubject<
    TicketModel[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  dialogDataStatus: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    // super();
  }

  async updateTicket(ticketEdit) {
    this.dialogData = ticketEdit;
    const updateticket$ = this.apiService.post(
      `${this.ticketBaseApi}/editTicket`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(updateticket$);
    return response;
  }
  // get Project Service
  async getProject(body) {
    const projectList$ = this.apiService.post(
      `${this.ticketBaseApi}/getProjectList`,
      body,
      {},
      true
    );
    let response = await lastValueFrom(projectList$);
    return response.body;
  }
  async getHubMaster() {
    const roleMasterList$ = this.apiService.get(
      "/api/hap/master/getHapHubMasters"
    );
    let response = await lastValueFrom(roleMasterList$);
    return response;
  }
  // get Dashboard Project Service
  async getDashboardProject(body) {
    const projectList$ = this.apiService.post(
      `${this.ticketBaseApi}/getDashboardProjectsByProjectTypes`,
      body,
      {},
      true
    );
    let response = await lastValueFrom(projectList$);
    return response.body;
  }
  // get Dashboard ProjectType Service
  async getDashboardProjectType(body) {
    const projectTypeList$ = this.apiService.post(
      `${this.ticketBaseApi}/getProjectTypesForDashboard`,
      body,
      {},
      true
    );
    let response = await lastValueFrom(projectTypeList$);
    return response.body;
  }

  // get User Service
  async getUser(body) {
    const userList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentList`,
      {},
      true
    );
    let response = await lastValueFrom(userList$);
    return response.body;
  }
  async getAssetsByHmb(reqObj: any) {
    const assetList$ = this.apiService.get(
      `${this.ticketBaseApi}/v2/getAssetsByHmb`,
      reqObj,
      true
    );
    let response = await lastValueFrom(assetList$);
    return response.body;
  }

  async getMappedHmb() {
    const assetList$ = this.apiService.get(
      `${this.ticketBaseApi}/getMappedHmbs`,
      {},
      true
    );
    let response = await lastValueFrom(assetList$);
    return response.body;
  }

  async getAssetSubCategory(reqObj: any) {
    const assetSubCategoryList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAssetCompliantNatureBySubCategory`,
      reqObj,
      true
    );
    let response = await lastValueFrom(assetSubCategoryList$);
    return response.body;
  }
  // get Assigned Service
  async getTicketassigned(reqObj: any) {
    const projectList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentDetails`,
      reqObj,
      true
    );
    let response = await lastValueFrom(projectList$);
    return response.body;
  }
  // save attachment
  async addAttachmentTicket(formData: FormData) {
    this.dialogData = formData;
    const addticketattach$ = this.apiService.post(
      `${this.ticketBaseApi}/saveOrUpdateTicketAttachment`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(addticketattach$);

    return response;
  }
  // save agent
  async addAgentTicket(ticketAssignmentRequest) {
    this.dialogData = ticketAssignmentRequest;
    const addticketagent$ = this.apiService.post(
      `${this.ticketBaseApi}/addTicketAgent`,
      ticketAssignmentRequest,
      "",
      true
    );
    let response = await lastValueFrom(addticketagent$);

    return response;
  }
  async addRetrieveHours(retrieveHoursRequest) {
    this.dialogData = retrieveHoursRequest;
    const retrieveHours$ = this.apiService.post(
      `${this.ticketBaseApi}/retrieveHours`,
      retrieveHoursRequest,
      "",
      true
    );
    let response = await lastValueFrom(retrieveHours$);

    return response;
  }
  // get Module Service
  async getModule(reqObj: any) {
    const moduleList$ = this.apiService.get(
      `${this.ticketBaseApi}/getModulesAndTicketType`,
      reqObj,
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }

  //get Module

  async getTicketModule(projets: any) {
    let projetsList = projets.projectCode;
    console.log("projetsList", projetsList);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const moduleList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketModuleListForReport`,
      { project: this.dialogData },
      {},
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }
  // ticket Type

  async getTicketType(projets: any) {
    let projetsList = projets.projectCode;
    console.log("projetsList", projetsList);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const moduleList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketTypeListForReport`,
      { project: this.dialogData },
      {},
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }
  // status
  async getStatusList(projets: any) {
    let projetsList = projets.projectCode;
    let ticketTypeList = projets.ticketType;
    console.log("projetsList", projets);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    this.dialogDataStatus = ticketTypeList.map((item) => {
      return { ticketTypeCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const statusList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketStatusListForReport`,
      { project: this.dialogData, ticketType: this.dialogDataStatus },
      {},
      true
    );
    let response = await lastValueFrom(statusList$);
    return response.body;
  }
  // Severity

  async getSeverity(projets: any) {
    let projetsList = projets.projectCode;
    let severityList = projets.ticketType;
    console.log("projetsList", projets);
    let list = projetsList.map((item) => {
      return { projectCode: item };
    });
    let severity = severityList.map((item) => {
      return { ticketTypeCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const severityList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketPriorityListForReport`,
      { project: list, ticketType: severity },
      {},
      true
    );
    let response = await lastValueFrom(severityList$);
    return response.body;
  }
  //Get Detailed Report
  async getDetailedDataService(getData) {
    console.log("service data", getData);
    // let page = searchCriteria.pageNo;
    // let size = searchCriteria.pageSize;
    // let sort = searchCriteria.sortColumn;
    // let sortByAscending=true;
    this.dialogData = getData;
    const detailedDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/getHubReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(detailedDataList$);
    return response;
  }
  //Get Delivery Report
  async getDeliveryReportService(getData) {
    console.log("service data", getData);
    this.dialogData = getData;
    const deliveryReportList$ = this.apiService.post(
      `${this.ticketBaseApi}/getDeliveryReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(deliveryReportList$);
    return response;
  }

  //Get Closed Ticket Report
  async getClosedReportService(getData) {
    console.log("service data", getData);
    this.dialogData = getData;
    const closedReportList$ = this.apiService.post(
      `${this.ticketBaseApi}/getClosedTicketReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(closedReportList$);
    return response;
  }
  //Get TimeSheet Report
  async getTimeSheetDataService(getData) {
    this.dialogData = getData;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTimeSheetReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get booked history view
  async getbookedHrsViewService(getData) {
    this.dialogData = getData;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketHoursBooking`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get Leave Permission view
  async getLeavePermissionList(getleavePermission) {
    this.dialogData = getleavePermission;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getLmsPunchOrLeaveHistory`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get Project Wise Report
  async getProjectWiseService(searchCriteria, getData) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = true;
    this.dialogData = getData;
    const detailedDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getProjectWiseReport`,
      this.dialogData,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(detailedDataList$);
    return response;
  }
  // get Subgroup Service
  async getProjectSubgroup(reqObj: any) {
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectSubGroups`,
      reqObj,
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  // get User Info
  async getUserdetail(reqObj: any) {
    const userList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentDetailsByUserCode`,
      reqObj,
      true
    );
    let response = await lastValueFrom(userList$);
    return response.body;
  }
  // get Agent Service
  async getProjectAgent(subGroupCode, ticketCode) {
    //  let subGroupCode = reqObj.subGroupCode;
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentsBySubGroup`,
      { subGroupCode, ticketCode },
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  async getProjectAgentedit(subGroupCode) {
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentsBySubGroup`,
      { subGroupCode },
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  // get Ticket Type Service
  /* async getTicketType(reqObj: any){
        const typeList$ = this.apiService.get('/api/ticket/create/getProjectList', reqObj, '' , true);
        let response = await lastValueFrom(typeList$);
        return response;
    } */
  // get Classification Service
  async getClassification(reqObj: any) {
    const classList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectClassifications`,
      reqObj,
      true
    );
    let response = await lastValueFrom(classList$);
    return response.body;
  }
  // get Priority Service
  async getPriority(reqObj: any) {
    console.log("reqobj", reqObj);
    const priorityList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectPriorities`,
      reqObj,
      true
    );
    let response = await lastValueFrom(priorityList$);
    return response.body;
  }

  async getReferenceList(reqObj: any) {
    console.log("reqobj", reqObj);
    const referenceList$ = this.apiService.get(
      `${this.ticketBaseApi}/getReferanceTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(referenceList$);
    return response.body;
  }
  async getRetrieveTicketList(reqObj: any) {
    console.log("reqobj", reqObj);
    const retrieveList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(retrieveList$);
    return response.body;
  }
  // get Status Service
  async getStatus(reqObj: any) {
    console.log("reqobj", reqObj);

    const statusList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectStatus`,
      reqObj,
      true
    );
    let response = await lastValueFrom(statusList$);
    return response.body;
  }
  // get ResponsibleAgent Service
  getResAgentedit(reqObj: any) {
    return this.apiService
      .get(`${this.ticketBaseApi}/getProjectResponsibleAgents`, reqObj, true)
      .pipe(takeUntil(this.detailsDestroy$));
  }
  cancelProjectDetailsRequests(): void {
    this.detailsDestroy$.next();
    this.detailsDestroy$.complete();
    this.detailsDestroy$ = new Subject<void>();
  }
  // get Version Service
  async getVersion(reqObj: any) {
    const versionList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketVersions`,
      reqObj,
      true
    );
    let response = await lastValueFrom(versionList$);
    return response.body;
  }

  // get Agent History Service
  async getAgentHistory(reqObj: any) {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentUserLog`,
      reqObj,
      true
    );
    let response = await lastValueFrom(agentList$);
    return response.body;
  }
  // get Attachment Service
  async getAttachmentType() {
    const attachmentTypeList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAttachmentTypes`,
      "",
      true
    );
    let response = await lastValueFrom(attachmentTypeList$);
    return response.body;
  }
  //Attachment List
  async getAttachmentList(reqObj: any) {
    const attachmentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAttachments`,
      reqObj,
      true
    );
    let response = await lastValueFrom(attachmentList$);
    return response;
  }
  //Total Billable & Non Billable List
  async getTotalBillNbh(reqObj: any) {
    const totalBillNbh$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketTimeLogs`,
      reqObj,
      true
    );
    let response = await lastValueFrom(totalBillNbh$);
    return response;
  }
  //Agent List
  async getAgentList(reqObj: any) {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgents`,
      reqObj,
      true
    );
    let response = await lastValueFrom(agentList$);
    return response;
  }

  async getAgentStatusList() {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentStatus`,
      {},
      true
    );
    let response = await lastValueFrom(agentList$);
    return response;
  }
  // get Proxy Users Service
  async getProxyUser(reqObj: any) {
    const proxyList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProxyUsers`,
      reqObj,
      true
    );
    let response = await lastValueFrom(proxyList$);
    return response.body;
  }
  // get With Customer Service
  async withCustomer(custparam) {
    //console.log("LOGTYPE",logType);
    let logType = custparam.logType;
    let ticketCode = custparam.idTicketCode;
    const withCust$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketWorkLogComments`,
      { ticketCode, logType },
      true
    );
    let response = await lastValueFrom(withCust$);
    return response.body;
  } // get With LockTable
  async getLockTable(ticketCode) {
    console.log("service.>>>>", ticketCode);
    // let ticketCode = custparam.ticketCode;
    const lockTable$ = this.apiService.get(
      `${this.ticketBaseApi}/lockTicket`,
      { ticketCode },
      true
    );
    let response = await lastValueFrom(lockTable$);
    return response;
  } // get With UnLockTable
  async getUnLockTable(ticketCode) {
    //    console.log("unlock.>>>>",ticketCode);
    // let ticketCode = custparam.ticketCode;
    const lockTable$ = this.apiService.get(
      `${this.ticketBaseApi}/unlockTicket`,
      { ticketCode },
      true
    );
    let response = await lastValueFrom(lockTable$);
    return response;
  }
  // Create Ticket
  async createTicket(ticketData) {
    //saveTicket
    this.dialogData = ticketData;
    const newTicket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/saveTicket`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(newTicket$);
    console.log(response, "Success");
    return response;
  }
  /* Get Ticket list by searchcriteria */
  async getList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getTicketDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadTicketManagement`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }

  async getBillingDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadBillingReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  //Hub Report Excel
  async getHubReportDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/downloadHubReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getMspVsHubDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/downloadMspHandledVsHubHandledReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getBuisnessMspVsHubDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/downloadBusinessTypeWiseMSPHandledVsForwardToHubHandledReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  ///Daily Activity Excel
  async getPartExcelDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/excel/downloadHapTicketAssetPartHaView`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getPartReplaceExcelDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/excel/downloadHapTicketAssetPartReplacementView`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getMspPerformanceDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/downloadMspPerformanceReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getScarpExcelDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/excel/downloadHapTicketAssetScrapView`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getFuelClaimExcelDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/excel/downloadHapTicketFuelClaimView`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getHubTicketExcelDownload(searchCriteriaBody) {
    // let deptCode = searchCriteriaBody.deptCode;
    // let fromDate = searchCriteriaBody.fromDate;
    // let toDate = searchCriteriaBody.toDate;
    // let hubs = searchCriteriaBody.hubs;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/report/excel/v1/downloadHapTicketMasterView`,
      // /report/excel/v1/downloadHapTicketMasterView
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  ///Daily Activity Excel
  async getDeliveryDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadDeliveryReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getClosedDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadClosedReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getTicketViewList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasterView`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getRetrieveTimeBookingList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketHoursBooking`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  /* Get Reference Ticket list by searchcriteria */
  async getListRef(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = 0;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;

    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  async getListdash(searchCriteria, dashboardcard) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      dashboardcard,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getListdashboard(searchCriteria, dashboardcard) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasterView`,
      dashboardcard,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  // Ticket Time Booking Service
  async timeBooking(bookingReq) {
    this.dialogData = bookingReq;
    const newTicket$ = this.apiService.post(
      `${this.ticketBaseApi}/saveTimeLog`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(newTicket$);
    console.log(response, "Success");
    return response;
  }

  /* Get Ticket Time Booked list by ticket code */
  async getTimeBookedList(reqObj) {
    const timeBooked$ = this.apiService.get(
      `${this.ticketBaseApi}/getTimeLogByTicketCode`,
      reqObj,
      true
    );
    let response = await lastValueFrom(timeBooked$);
    return response;
  }
  async getAllocatedHoursList(reqObj) {
    const timeAllocatedHrs$ = this.apiService.get(
      `${this.ticketBaseApi}/getHoursSpend`,
      reqObj,
      true
    );
    let response = await lastValueFrom(timeAllocatedHrs$);
    return response;
  }
  /* Get Ticket Card list by searchcriteria */
  async getCards(searchCriteriaBody) {
    const cardList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketViewDashboardCards`,
      searchCriteriaBody,
      {},
      true
    );
    let response = await lastValueFrom(cardList$);
    return response;
  }

  async getDashboardCards(searchCriteriaBody) {
    const cardList$ = this.apiService.post(
      `${this.ticketBaseApi}/getDashboardCard`,
      searchCriteriaBody,
      {},
      true
    );
    let response = await lastValueFrom(cardList$);
    return response;
  }

  async getMyTeamDashboardCards(searchCriteriaMyTeamBody) {
    const dashboardData$ = this.apiService.post(
      `${this.ticketBaseApi}/getMyTeamDashboard`,
      searchCriteriaMyTeamBody,
      {},
      true
    );
    let response = await lastValueFrom(dashboardData$);
    return response;
  }
  // Refersh Ticket(view/edit)
  async getParticularTicketFetch(obj: any) {
    const ticketFetch$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketDetailsByTicketCode`,
      obj,
      true
    );
    let response = await lastValueFrom(ticketFetch$);
    return response;
  }
  async getMyTeamUtilizationDetails(searchCriteriaMyTeamBody) {
    const utilizationData$ = this.apiService.post(
      `${this.ticketBaseApi}/getMyTeamUtilizationDetails`,
      searchCriteriaMyTeamBody,
      {},
      true
    );
    let response = await lastValueFrom(utilizationData$);
    return response;
  }
  //MyTeamTargetBasedDetails
  async getMyTeamTargetBasedDetails(searchCriteriaMyTeamBody) {
    const teamTargetData$ = this.apiService.post(
      `${this.ticketBaseApi}/getMyTeamTargetBasedDetails`,
      searchCriteriaMyTeamBody,
      {},
      true
    );
    let response = await lastValueFrom(teamTargetData$);
    return response;
  }

  // get Refference Ticket Service
  async getRefTicket(reqObj: any) {
    const refTicketList$ = this.apiService.get(
      `${this.ticketBaseApi}/getReferanceTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(refTicketList$);
    return response.body;
  }

  // Get Timsheet report(Dashboard)
  async getTimsheetReport(reqObj) {
    this.dialogData = reqObj;
    const addticketattach$ = this.apiService.post(
      `${this.ticketBaseApi}/getDetailedTimeSheetReport`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(addticketattach$);

    return response.body;
  }
}
