import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, pairwise } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
// import {NotifierService} from 'angular-notifier';

@Injectable({
  providedIn: "root",
})
export class FieldMsg {
  charMin = 1;
  charMax = 45;
  numberMin = 10;
  numberMax = 10;
  req_field = "is required";
  chart_only = "Characters only allow in";
  num_only = "is only allow numbers";
  email_only = "is only allowed";
  char_length = `Min ${this.charMin} and Max ${this.charMax} Characters only allowed`;
  number_length = `Minimum ${this.numberMin} and Maximum ${this.numberMax} Characters only allowed`;
  popAnimation: any = {
    show: {
      type: "pop",
      duration: 600,
      from: {
        scale: 0.55,
      },
    },
    hide: {
      type: "pop",
      duration: 600,
      to: {
        opacity: 0,
        scale: 0.55,
      },
      from: {
        opacity: 1,
        scale: 1,
      },
    },
  };
  capValue: string = "";
  constructor(private router: Router, private _location: Location) {}

  /* @type is number param
    it help to throw error msg as per condition.
    1=required field.
    2=character field.
    3=number field.
    4=email field.
    */

  getErrMsg(field, type) {
    let msg = "";
    if (type == 1) {
      msg = `${field} ${this.req_field}`;
    } else if (type == 2) {
      msg = `${this.chart_only} ${field}`;
    } else if (type == 3) {
      msg = `${field} ${this.num_only}`;
    } else if (type == 4) {
      msg = `${field} ${this.email_only}`;
    } else if (type == 5) {
      msg = `${this.char_length} ${field}`;
    } else if (type == 6) {
      msg = `${this.number_length} ${field}`;
    }
    return msg;
  }

  onKeyPress(event: any) {
    console.log("spacesss", event);
    if (event.target.selectionStart === 0 && event.keyCode == 32) {
      event.preventDefault();
    }
  }

  onKeyNumberPress(e) {
    const event = e.event;
    console.log("keys", e.event);
    const str = event.key || String.fromCharCode(event.which);
    if (
      /^[.,abcdefghijklmnopqrstwxuvyzABCDEFGHIJKLMNOPQRSTWXUVYZ@!#$%^&*+=_|/?<>`~]$/.test(
        str
      )
    ) {
      event.preventDefault();
    }
  }
  onKeyNumberPressMat(e) {
    //const event = e.event;
    console.log("keys", e);
    const str = e.key || String.fromCharCode(e.which);
    if (
      /^[-.,abcdefghijklmnopqrstwxuvyzABCDEFGHIJKLMNOPQRSTWXUVYZ@!#$%^&*+=_|/?<>`~]$/.test(
        str
      )
    ) {
      e.preventDefault();
    }
  }
  onKeyLatPress(e) {
    const event = e.event;
    console.log("keys", e.event);
    const str = event.key || String.fromCharCode(event.which);
    if (/^[,abcdefghijklmnopqrstwxuvyzABCDEFGHIJKLMNOPQRSTWXUVYZ]$/.test(str)) {
      event.preventDefault();
    }
  }
  onKeyAmountPress(e) {
    const event = e.event || e;
    console.log("keys", e);
    const str = event.key || String.fromCharCode(event.which);
    if (
      /^[-,=+_/?`~()!@abcdefghijklmnopqrstwxuvyzABCDEFGHIJKLMNOPQRSTWXUVYZ]$/.test(
        str
      )
    ) {
      event.preventDefault();
    }
  }
  spacePaste(event: ClipboardEvent, controlName: string, formName: any) {
    // debugger;
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text/plain");
    // let trimmedText = pastedText.replace(/[^a-zA-Z0-9-()._ ]/g, "");
    // this.complaintNatureForm.get("compliantName").reset();
    // this.complaintNatureForm.get("compliantName").value;

    formName.get(controlName).setValue(pastedText.trimStart());
    event.preventDefault();
  }
  ///For Number
  onNumKeyPress(event: any) {
    const keyCode = event.keyCode || event.which;

    // Allow only numeric input (0-9) and control keys like backspace
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
      event.preventDefault();
    }
  }
  numPaste(e: any, controlName: string, formName: any) {
    //debugger;
    // lod REGEX -- /^\d+\s$/
    //const clipboardData = e?.event.originalEvent.clipboardData;
    const clipboardData =
      e?.clipboardData || e?.event.originalEvent.clipboardData;
    const pastedText = clipboardData.getData("text");
    if (/^[\d\s]+$/.test(pastedText)) {
      formName.get(controlName).setValue(pastedText);
      e.event.preventDefault();
    } else {
      formName.get(controlName).setValue("");
      e.event.preventDefault();
    }
  }

  alphaNumPaste(
    event: ClipboardEvent,
    controlName: string,
    formGroup: FormGroup
  ): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    // Allow alphabets, numbers, and specific special characters
    const sanitizedText = pastedText.replace(
      /[^a-zA-Z0-9-!@#$%^&*()_+=\[\]{};':"\\|,.<>/?`~ ]/g,
      ""
    );

    // Find the control in the form group and set its value
    const control = formGroup.get(controlName);
    if (control) {
      control.setValue(sanitizedText);
    }
  }

  //Alphabet
  onAlphaKeyPress(event: KeyboardEvent) {
    const regx = /^[a-zA-Z]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regx.test(inputChar)) {
      event.preventDefault();
    }
  }

  //AlphabetNumber
  onAlphaNumKeyPress(event: KeyboardEvent) {
    const regx = /^[a-zA-Z0-9-!@#$%^&*()_+=\[\]{};':"\\|,.<>/?`~ ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regx.test(inputChar)) {
      event.preventDefault();
    }
  }
}
