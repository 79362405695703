import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {formatDate} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {TicketModel} from 'src/app/ticket/ticket.model';
import {TicketService} from 'src/app/ticket/ticket.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SharedService} from 'src/app/core/service/shared.service';
import {ReplaySubject, Subject, take, takeUntil} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';


export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  selector: 'app-create-ticket-dialog',
  templateUrl: './create-ticket-dialog.component.html',
  styleUrls: ['./create-ticket-dialog.component.css'],
})
export class CreateTicketDialogComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  public Editor = ClassicEditor;
  ckeConfig: any;
  base64File: string = null;
  filename: string = null;
  tabs = ['First', 'Second', 'Third'];
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  searchSubject: any;
  fileValidation: boolean = false;
  filteredOptions: Observable<string[]>;
  ticketForm: FormGroup;
  isEditFilter = false;
  isticketpop = false;
  ticketData: TicketModel;
  projectList: any[];
  moduleList: any[];
  anotherArray: any[];
  ticketTypeList: any[];
  classficationList: any[];
  assetTypeList: any = [];
  priorityList: any;
  proxyList: any[];
  myControl = new FormControl('');
  gNo: string;
  isProxyAllowed: boolean;
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
  apicallSub?: Subscription;
  public referenceTicketList: any = [];

  /* Tag Properties */
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  hmbCode: any;
  position: any;
  /* Tag Properties */

  /* Priority */
  priorityDef: any;

  availableColors: ChipColor[] = [
    /* { name: 'none', color: undefined },
    { name: 'Primary', color: 'primary' },
    { name: 'Accent', color: 'accent' },
    { name: 'Warn', color: 'warn' } */
  ];

  @ViewChild('tagInput', {static: true})
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;
  @ViewChild('priority', {static: false}) priorityField: any;
  @ViewChild('myInput', {static: true}) myInput: ElementRef =
    Object.create(null);

  routerUrl: any;
  isDistruct: boolean = true;
  activeUser: any = {};
  checked: boolean = false;
  /* auto search */
  searchProject: string;
  searchAsset: any;
  ckConfig: any;
  currentUser: any;
  mappedHmbDetails: any;
  assetSubCategoryList: any = [];
  defaultImg = 'assets/images/user/usrbig1.jpg';
  assetImg: any;

  constructor(
    public dialogRef: MatDialogRef<CreateTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef,
  ) {
    this.routerUrl = this.sharedService.routerUrl();
    console.log(this.routerUrl, 'currentUrl');
    this.currentUser = this.sharedService.currentUserData();

    console.log('currentUser', this.currentUser);

    this.mappedHmbDetails = this.currentUser.data[0]?.mappedHmbDetails;
    // Set the defaults
    this.isProxyAllowed = false;
    this.action = data.action;
    const {username, userCode} = data.userInfo;
    this.activeUser = {username, userCode};
    console.log('this.activeUser', this.activeUser);
    this.isDetails = false;
    this.isEditFilter = false;
    this.dialogTitle = 'Create New Ticket';
    // this.Editor=this.align();
    //this.projectList = data.projectList;
    //this.gNo = data.gNo;
    this.ticketData = new TicketModel({});
    this.ticketForm = this.createticketForm();

    this.apicallSub = this.sharedService.currentMessage.subscribe((obj) => {
      console.log('Flag Values', obj);

      this.checked = obj;
      this.getProject();
    });
    this.getMappedHmb();
  }
  ngOnDestroy(): void {
    if (this.apicallSub) {
      this.apicallSub.unsubscribe();
    }
    this.getCurrentLocation();
  }

  ngOnInit() {
    this.ckConfig = {
      toolbar: ['Bold', 'Italic', '|', 'bulletedList', 'numberedList'],
    };

    this.entityFilterCtrl.valueChanges.subscribe(
      async (value) => {
        console.log('value', value);
        let projectCode = this.projectCode;
        console.log(projectCode, 'project');
        if (value != '') {
          this.searching = true;
          console.log(JSON.stringify(value));
          var ticketCode = value;
          if (JSON.stringify(value) != undefined) {
            let reqObj = {ticketCode, projectCode};
            const response = await this.ticketService.getReferenceList(reqObj);
            console.log(response, 'referenceTicketList');
            this.referenceTicketList = response.transactions;
            this.searching = false;
          } else {
            this.referenceTicketList = [];
            this.searching = false;
          }
        } else {
          this.searching = false;
        }
      },
      (error) => {
        console.log('error', error);
        this.searching = false;
      },
    );

    this.ticketForm.get('createdName').setValue(this.activeUser.username);
    this.ticketForm.get('createdName').disable();
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  filterMyOptions($event) {
    console.log($event, 'event');
  }

  formControl = new FormControl('', [
    Validators.required,
    // Validators.email,
  ]);

  /* On Select Drop down values */
  onSelectDropDwn(key, value) {
    if (key == 'project') {
      this.clearDropdown();
      this.proxyList = [];
      this.projectCode = value.projectCode;
      this.getModule(this.gNo, value.projectCode);
      //if(value.projectCode == '')
      this.getMappedHmb();
      this.getAssetsByHmb();
    }
    if (key == 'ticketType') {
      //this.ticketForm.get('subject').setValue('');
      let subject = `${value.categoryName} - ${value.assetTagNo}`;
      this.assetImg = value.assetImg;
      this.ticketForm.get('subject').setValue(subject);
      this.ticketForm.get('subject').disable();
      this.proxyList = [];
      const {projectCode} = this.ticketForm.value;
      this.getAssetSubCategory(
        value.assetCatId,
        value.companyCode,
        value.deptCode,
        value.assetSubCatId,
      );

      if (value.isProxyAllowed == 'Y') {
        this.isProxyAllowed = !!value.isProxyAllowed;
        if (this.isProxyAllowed) {
          this.getProxy(
            this.gNo,
            projectCode.projectCode,
            value.ticketTypeCode,
          );
        }
      } else {
        this.isProxyAllowed = false;
        this.proxyList = new Array(this.activeUser);
        console.log('this.proxyList', this.proxyList);
        this.ticketForm.get('proxyUserCode').setValue(this.activeUser);
      }
    }
    if (key == 'classfication') {
      const {projectCode, ticketTypeCode} = this.ticketForm.value;
      this.getPriority(
        this.gNo,
        projectCode.projectCode,
        ticketTypeCode.ticketTypeCode,
        value.ticketTypeClassificationCode,
      );
    }
    if (key == 'subject') {
      let tagList = value.split(' ');
      // console.log('tagList', tagList);
      this.allTags = tagList.filter((item) => item != '');
      // console.log('this.allTags', this.allTags);
      if (this.allTags.length > 0) {
        this.tagListAdd();
      }
    }
  }

  clearDropdown() {
    this.ticketForm.get('moduleCode').reset();
    this.ticketForm.get('ticketTypeCode').reset();
    // this.ticketForm.get('ticketTypeClassificationCode').reset();
    // this.ticketForm.get('ticketPriorityCode').reset();
  }

  /* Project dropdown */
  async getProject() {
    let isEagle = this.checked;
    let reqObj = {
      isEagle,
    };

    const response = await this.ticketService.getProject(reqObj);

    const array = response.transactions;
    this.projectList = this.getUniqueListBy(array, 'projectCode');
    this.anotherArray = this.projectList;
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  /* Module dropdown */
  async getModule(gNo, projectCode) {
    let reqObj = {projectCode}; //gNo
    const response = await this.ticketService.getModule(reqObj);
    this.moduleList = response.moduleList;
    this.ticketTypeList = response.ticketTypeList;
  }
  /* Ticket type dropdown */
  /* async getTicketType(idGNo,idProjectCode){
        let reqObj = {idGNo,idProjectCode};
        const response = await this.ticketService.getTicketType(reqObj);
     } */
  /* Ticket classification dropdown */
  async getClassification(gNo, projectCode, ticketType) {
    let reqObj = {projectCode, ticketType}; //  gNo
    const response = await this.ticketService.getClassification(reqObj);
    this.classficationList = response.transactions;
  }

  /* Priority dropdown */
  async getPriority(gNo, projectCode, ticketType, classificationCode) {
    let reqObj = {projectCode, ticketType, classificationCode}; //gNo,
    const response = await this.ticketService.getPriority(reqObj);
    this.priorityList = response.transactions;
  }

  /* Proxy dropdown */
  async getProxy(gNo, projectCode, ticketType) {
    let reqObj = {projectCode, ticketType}; // gNo,
    const proxyRes = await this.ticketService.getProxyUser(reqObj);
    const proxyResData = [this.activeUser, ...proxyRes.transactions];
    let uniq = {};
    this.proxyList = proxyResData.filter(
      (obj) => !uniq[obj.userCode] && (uniq[obj.userCode] = true),
    );
    console.log('proxyList', this.proxyList);
    this.ticketForm.get('proxyUserCode').setValue(this.activeUser);
  }
  async getMappedHmb() {
    const response = await this.ticketService.getMappedHmb();
    let mappedHmbData = response.data;
    this.hmbCode = mappedHmbData ? mappedHmbData[0].hmbCode : '';
    console.log('hmbCode', this.hmbCode);

    if (this.hmbCode) {
     //this.getAssetsByHmb();
    }
  }

  async getAssetsByHmb() {
    let fuel =this.ticketForm.get('projectCode').value;
   // console.log('+++++++',fuel)
    let reqObj = {hmbCode: this.hmbCode,isFuelClaim:fuel.projectCode === "PR40000004" ?true :false};
    const response = await this.ticketService.getAssetsByHmb(reqObj);
    this.assetTypeList = response.data;
    console.log('assetTypeList', this.assetTypeList);
  }

  async getAssetSubCategory(catId, companyCode, deptCode, subCatId) {
    let reqObj = {catId, companyCode, deptCode, subCatId};
    const response = await this.ticketService.getAssetSubCategory(reqObj);
    this.assetSubCategoryList = response.data;
  }

  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : this.formControl.hasError('email')
      ? 'Not a valid email'
      : '';
  }

  filterDropDowns() {
    setTimeout(() => (this.searchProject = ''), 2000);
  }

  createticketForm(): FormGroup {
    return this.fb.group({
      id: [this.ticketData.id],
      projectCode: [this.ticketData.projectCode, [Validators.required]],
      moduleCode: [this.ticketData.moduleCode, [Validators.required]],
      ticketTypeCode: [this.ticketData.ticketTypeCode, [Validators.required]],
      ticketTypeClassificationCode: [
        this.ticketData.ticketTypeClassificationCode,
      ],
      ticketPriorityCode: [this.ticketData.ticketPriorityCode],
      proxyUserCode: [this.ticketData.proxyUserCode],
      referenceTicket: [this.ticketData.referenceTicket],
      internalNotes: [this.ticketData.internalNotes],
      ticketTags: [this.ticketData.ticketTags],
      subject: [this.ticketData.subject], //[Validators.required]
      body: [this.ticketData.body], //[Validators.required]
      attachments: [],
      files: [],
      createdName: [],
    });
  }
  /* Ticket Attachments*/

  onFileSelect(e: any): void {
    // console.log('file>>', this.ticketForm);
    console.log('file>>', e);
    try {
      const files = e.target.files;
      const file = e.target.files[0];
      if (files.length > 10 || file.size > 5e6) {
        this.fileValidation = true;
        this.ticketForm
          .get('attachments')
          ?.setErrors({invalid: 'Max attachment limit 10'});
        throw 'Max attachment limit 10';
      } else {
        this.fileValidation = false;
        this.ticketForm.get('attachments')?.setErrors(null);
      }

      const selFiles = [];
      for (var i = 0; i < files.length; i++) {
        selFiles.push(files[i]);
      }
      this.ticketForm.get('files').setValue(selFiles);
      // console.log('file>>', this.ticketForm);
    } catch (error) {
      this.filename = null;
      this.base64File = null;
    }
  }

  detailsUser(content) {
    this.modalService.open(content, {size: 'xl'});
  }

  public checkPriority() {
    //Form validation on click function
    console.log('this.ticketForm', this.ticketForm);
    if (this.ticketForm.valid) {
      //Logic for valid form
      const {ticketPriorityCode} = this.ticketForm.value;
      let chkPriority = ticketPriorityCode
        ? ticketPriorityCode?.ticketPriorityName.toLowerCase()
        : '';
      this.priorityDef = ticketPriorityCode?.definition;
      if (chkPriority.indexOf('high') > -1) {
        this.confirmPriority();
      } else {
        this.confirmAdd();
      }
    } else {
      Object.keys(this.ticketForm.controls).forEach((field) => {
        const control = this.ticketForm.get(field);
        control.markAsTouched({onlySelf: true});
      });
    }
  }

  public async confirmAdd() {
    await this.getCurrentLocation();

    if (this.position) {
      /** spinner start */
      console.log('this.ticketForm.value', this.ticketForm.value);
      var reference;
      this.spinner.show();
      const {
        moduleCode,
        referenceTicket,
        proxyUserCode,
        projectCode,
        ticketTypeCode,
        subject,
        body,
        files,
      } = this.ticketForm.value;

      if (referenceTicket == '') {
        reference = '';
      } else {
        let ticket = JSON.stringify(referenceTicket);
        let obj = JSON.parse(ticket);
        reference = obj.ticketCode;
      }
      // Create Ticket request object
      let ticketObj = {
        projectCode: projectCode.projectCode,
        moduleCode: moduleCode.moduleCode,
        hapAssetCode: ticketTypeCode.assetTagNo,
        hapHmbCode: this.hmbCode ? this.hmbCode : '',
        proxyUserCode: proxyUserCode.userCode,
        subject: this.searchSubject,
        body: body,
        referenceTicket: '',
        hapLat: this.position ? this.position.lat : '',
        hapLong: this.position ? this.position.lng : '',
      };

      // Form Data request
      let ticketMaster = JSON.stringify(ticketObj);
      let formData = new FormData();
      formData.append('ticketMaster', ticketMaster);
      // Multiple file iteration
      if (files) {
        for (var i = 0; i < files.length; i++) {
          formData.append('attachments', files[i]);
        }
      } else {
        formData.append('attachments', '');
      }
      this.isSubmitted = true;
      try {
        let response = await this.ticketService.createTicket(formData);
        console.log('response????', response);
        if (response?.body?.success) {
          this.searchSubject = '';
          this.isSubmitted = false;
          this.spinner.hide();
          this.dialogRef.close({
            action: this.action,
            response,
            url: this.routerUrl,
          });
        } else {
          this.isSubmitted = false;
          /** spinner hide */
          this.spinner.hide();
          this.sharedService.showNotification(
            'snackbar-danger',
            response?.body?.message,
            'bottom',
            'center',
          );
        }
      } catch (e) {
        this.isSubmitted = false;
        this.spinner.hide();
        this.sharedService.showNotification(
          'snackbar-danger',
          'Error in Ticket Creation',
          'bottom',
          'center',
        );
      }
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'This app needs the Location service, please turn on the Location',
        'bottom',
        'center',
      );
    }
  }

  /* Create Ticket dialog close function,
any changes without save ,condition checked here.
Confirmation dialog added.
 */
  onNoClick(): void {
    const fieldValueArr = Object.values(this.ticketForm.value);
    //  console.log('fieldValueArr',fieldValueArr);
    const isChanged = fieldValueArr.filter((v) => {
      if (v && v != 0) {
        console.log(v);
        return v;
      }
    });
    //  console.log(isChanged, 'test>>>>>');
    if (isChanged.length > 0) {
      this.isDistruct = false;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: '320px',
        data: {
          name: 'Your changes won’t be saved',
          msg: 'We won’t be able to save your data if you move away from this page.',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.event === 'Yes') {
          this.dialogRef.close({action: 'close'});
        }
        this.isDistruct = true;
      });
    } else {
      this.dialogRef.close({action: 'close'});
      this.isDistruct = true;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.isDistruct) {
      this.onNoClick();
    }
  }

  /* Ticket Tags function */

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Ticket tag items
    let alreadyExit = [];
    alreadyExit = this.tags.filter(
      (item) => item.toLowerCase() == value.toLowerCase(),
    );
    if (alreadyExit.length > 0) {
      this.sharedService.showNotification(
        'snackbar-warning',
        'Tag already Exist..!!',
        'bottom',
        'center',
      );
    } else {
      const index = this.allTags.indexOf(value.toLowerCase());
      if (index != -1) {
        this.allTags.splice(index, 1);
      }
      if ((value || '').trim()) {
        this.availableColors.push({
          name: value.trim(),
          color: this.randomClr(),
        });
        this.tags.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.allTags.push(tag);
      this.tags.splice(index, 1);
      this.tagListAdd();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.availableColors.push({
      name: event.option.viewValue,
      color: this.randomClr(),
    });
    let alreadyExit = [];
    alreadyExit = this.tags.filter(
      (item) => item.toLowerCase() == event.option.viewValue.toLowerCase(),
    );
    if (alreadyExit.length > 0) {
      this.sharedService.showNotification(
        'snackbar-warning',
        'Tag already Exist..!!',
        'bottom',
        'center',
      );
    } else {
      this.tags.push(event.option.viewValue);
      const index = this.allTags.indexOf(event.option.viewValue);
      this.allTags.splice(index, 1);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) === 0,
    );
  }
  /* Add Tag items from subject */
  private tagListAdd() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice(),
      ),
    );
  }
  /* Random Color Generator */
  public randomClr() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  /*** High/ Very High priority 
         definition confirmation function
        ***/

  confirmPriority() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    console.log('this.priorityDef', this.priorityDef);
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        // text: "You won't be able to revert this!",
        html: this.priorityDef,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue to create',
        cancelButtonText: 'Edit to change the priority',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.confirmAdd();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.priorityField.nativeElement.focus();
        }
      });
  }

  ngAfterViewInit() {}

  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, 'position');
    } catch (e) {
       console.log('e',e);
       this.position = null; 
    }
  }
}
