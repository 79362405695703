import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { ApiService } from "../core/service/api.service";
@Injectable({
  providedIn: "root",
})
export class MassTicketUpdateService extends UnsubscribeOnDestroyAdapter {
  ticketBaseApi = "/api/nerve/ticket";
  private destroy$ = new Subject<void>();
  constructor(public apiService: ApiService) {
    super();
  }
  async getDepartment(searchCriteria) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const departmentList$ = this.apiService.post(
      "/api/admin/master/getDepartmentMasterList",
      {},
      { page, size, sort, sortByAscending },
      false
    );
    let response = await lastValueFrom(departmentList$);
    return response;
  }
  async getMappedDepartments() {
    const divisionList$ = this.apiService.get(
      "/api/nerve/ticket/getMappedDepartments",
      {},
      true
    );
    let response = await lastValueFrom(divisionList$);
    return response;
  }

  async getMassTicketList(query, ticketSearchQuery) {
    let page = query.page;
    let size = query.size;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/getHapTicketMasterView`,
      ticketSearchQuery,
      { page, size },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  cancelRequests(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
  }
  oemTickets(massTicket): Observable<any> {
    const abortController = new AbortController();
    return this.apiService
      .post(
        `${this.ticketBaseApi}/v1/massTicketUpdate`,
        massTicket,
        { signal: abortController.signal },
        true
      )
      .pipe(takeUntil(this.destroy$));
  }
  mailLogView(projectCode): Observable<any> {
    const mailCategory = projectCode.mailCategory;
    const ticketCode = projectCode.ticketCode;
    const abortController = new AbortController();
    return this.apiService
      .get(
        `${this.ticketBaseApi}/getMailLoggerByTicketCodeOrCategory`,
        {
          mailCategory,
          ticketCode,
          signal: abortController.signal,
        },
        true
      )
      .pipe(takeUntil(this.destroy$));
  }
  async massUpdate(massTicket) {
    //massTicketUpdate
    //
    const massTicket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/massTicketUpdate`,
      massTicket,
      "",
      true
    );
    let response = await lastValueFrom(massTicket$);

    return response;
  }
}
