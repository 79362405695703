import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventService } from 'src/app/core/service/event.service';
import { SharedService } from 'src/app/core/service/shared.service';

@Component({
  selector: 'app-show-column-dialog',
  templateUrl: './show-column-dialog.component.html',
  styleUrls: ['./show-column-dialog.component.sass']
})
export class ShowColDialogComponent implements OnInit, AfterViewInit {
  public fname: string = 'John';
  public lname: string = 'Deo';
  public addCusForm: FormGroup;
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ShowColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public eventService:EventService,public sharedService:SharedService) {
      this.columns = this.data.columns;
      this.allColumns = this.data.allColumns;
      console.log('this.data', this.data);
    }

  public ngOnInit(): void {
    this.addCusForm = this.fb.group({
      IdProof: null,
      firstname: [
        this.fname,
        [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]
      ],
      lastname: [
        this.lname,
        [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]
      ],
      email: [null, [Validators.required, Validators.email]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if(this.afterInit){
      this.onSelColumns(this.columns);
    } 
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit= true;
}
}
