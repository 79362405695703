import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { BrandVendor } from "./brand-vendor.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { ApiService } from "../core/service/api.service";

@Injectable({
  providedIn: "root",
})
export class BrandVendorService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = "assets/data/productmaster.json";
  isTblLoading = true;
  dataChange: BehaviorSubject<BrandVendor[]> = new BehaviorSubject<
    BrandVendor[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    super();
  }
  get data(): BrandVendor[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }
  async getListDepartment(searchCriteria) {
    let companyCode = searchCriteria.companyCode;

    const departmentList$ = this.apiService.get(
      "/api/admin/master/getDepartmentByCompanyCode",
      { companyCode },
      false
    );
    let response = await lastValueFrom(departmentList$);
    return response;
  }
  async getListCategory(searchCriteria) {
    let companyCode = searchCriteria.companyCode;
    let deptCode = searchCriteria.deptCode;

    const categoryList$ = this.apiService.get(
      //getHapAssetCategroyByDept
      "/api/hap/master/v1/getHapAssetCategroyByDept",
      { companyCode, deptCode },
      false
    );
    let response = await lastValueFrom(categoryList$);
    return response;
  }
  async getBrandVendor(searchCriteria) {
    let brandId = searchCriteria.brandId;
    let catId = searchCriteria.catId;
    let deptCode = searchCriteria.deptCode;

    // let catList=[catId];
    const ekoList$ = this.apiService.get(
      "/api/hap/master/getHapBrandingVendorMaster",
      { brandId, catId, deptCode },
      false
    );
    let response = await lastValueFrom(ekoList$);
    return response;
  }
  async getListCompanyMaster(searchCriteria) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const companyList$ = this.apiService.post(
      "/api/admin/master/getCompanyMasterList",
      {},
      { page, size, sort, sortByAscending },
      false
    );
    let response = await lastValueFrom(companyList$);
    return response;
  }
  async addBrandVendor(brandData) {
    this.dialogData = brandData;
    //console.log("this.dialogData", this.dialogData);
    const addRes$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateBrandingVendorMaster",
      this.dialogData
    );
    let response = await lastValueFrom(addRes$);
    return response;
  }
  async getBrand(searchCriteria) {
    let catId = searchCriteria.catId;
    let companyCode = searchCriteria.companyCode;
    let deptCode = searchCriteria.deptCode;
    let assetBrandName = searchCriteria.assetBrandName;
    let statusFlag = searchCriteria.statusFlag;
    let isCatPassed = searchCriteria.isCatPassed;

    // let catList=[catId];
    const ekoList$ = this.apiService.get(
      "/api/hap/master/getHapAssetBrandMasters",
      { assetBrandName, catId, companyCode, deptCode, statusFlag, isCatPassed },
      false
    );
    let response = await lastValueFrom(ekoList$);
    return response;
  }
}
