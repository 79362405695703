import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/core/service/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransportTypeService } from 'src/app/transport-type/transport-type.service';
import { PartRequestService } from 'src/app/part-request/part-request.service';

import { E } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { FieldMsg } from 'src/app/core/service/field-msg.service';
import { DispatchTyeService } from 'src/app/dispatch-type/dispatch-tye.service';
import { BrandVendorService } from 'src/app/brand-vendor/brand-vendor.service';
import { TicketNewService } from 'src/app/ticket-new/ticket.service';
import { ReasonMasterService } from 'src/app/reason-master/reason-master.service';

@Component({
  selector: 'app-scrap-details-hub-dialog',
  templateUrl: './scrap-details-hub-dialog.component.html',
  styleUrls: ['./scrap-details-hub-dialog.component.scss'],
})
export class ScrapDetailsHubDialogComponent implements OnInit {
  dialogTitle: any;
  transportCheck: boolean = false;
  dispatchForm: FormGroup;
  approvalPartsForm: FormGroup;
  transportCondition: any = 'Y';
  isLoading: boolean;
  scrapDetailList: any = [];
  ticketPartsData: any;
  ticketCode: any;
  selectedRowsData = [];
  rejectRemarks: any = '';
  position: any;
  partStatus: any;
  approvePopupVisible: boolean = false;
  rejectPopupVisible: boolean = false;
  currentUser: any;
  roleCheck: any;
  popupTitle: any;
  rejectScrapData: any;
  approvePartsData: any;
  approveQtyValidation: boolean = false;
  dispatchPartList: any = [];
  viewTransportList: any = [];
  viewTransportPopup: boolean = false;
  titleText: any;
  dispatchTypeList: any = [];
  brandVendorList: any = [];
  requiredFlag: boolean = false;
  isSubmitted: boolean = false;
  reasonList: any = [];
  transportTypeList: any = [
    // {
    //   label: 'Vehicle',
    //   value: 'Vehicle',
    // },
    // {
    //   label: 'By Hand',
    //   value: 'By Hand',
    // },
    // {
    //   label: 'Courier',
    //   value: 'Courier',
    // },
    // {
    //   label: 'Parcel Service',
    //   value: 'Parcel Service',
    // },
  ];
  UserRoles: any = environment?.roles;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<ScrapDetailsHubDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private transportType: TransportTypeService,
    private ticketService: TicketNewService,
    public fieldMsg: FieldMsg,
    public dispatchTyeService: DispatchTyeService,
    public brandVendorService: BrandVendorService,
    public reasonMasterService: ReasonMasterService
  ) {
    this.dispatchForm = this.createDispatchForm();
    this.approvalPartsForm = this.createApprovalPartsForm();
    this.ticketPartsData = this.data;
    console.log("ticketPartsData Whole List", this.ticketPartsData)
    this.ticketCode = this.ticketPartsData.ticket.ticketCode;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.roleCheck = this.currentUser.data[0].roles;
    this.dialogTitle = `Scrap Details :: ${this.ticketCode}`;

    this.getScrapList(this.ticketCode);
    

  }
  createDispatchForm(): FormGroup {
    return this.fb.group({
      transportType: [''],
      referenceNo: [],
      driverName: [],
      vehicleNo: [],
      mobileNo: [],
      personName: [],
      courierName: [],
      parcelServiceName: [],
    });
  }
  createApprovalPartsForm(): FormGroup {
    return this.fb.group({
      dispatchType: [''],
      brandVendor: [''],
    });
  }
  ngOnInit(): void { }

  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }



  async getScrapList(ticketCode) {
    this.isLoading = true;
    let reqObj = { ticketCode };
    const response = await this.ticketService.getScrapList(reqObj);
    this.scrapDetailList = response.data || [];
    this.isLoading = false;
  }


  statusAct(stat) {
    switch (stat.value) {
      case 'Y':
        return 'Approved';
        break;
      case 'R':
        return 'Rejected';
        break;
      case 'P':
        return 'Pending';
        break;
      default:
        return stat.value;
    }
  }

  

  async scrapConfirm(key, value, data) {



    if (value == 'Y') {

      this.updateScrap(key, value, data);

    } else {
      this.rejectPopupVisible = true;
      this.rejectScrapData = {
        key,
        value,
        data
      }
      this.popupTitle = `Scrap Reject :: ${data.data.ticketCode}`;

      if(key == 'recommended'){
        this.loadReason(10);
      }
      if(key == 'verify'){
        this.loadReason(11);
      }
      if(key == 'approve'){
        this.loadReason(12);
      }

    }



  }
  scrapReject() {

    this.rejectPopupVisible = false;
    this.updateScrap(this.rejectScrapData.key, this.rejectScrapData.value, this.rejectScrapData.data);

  }

  closeRejectPopup() {
    this.rejectPopupVisible = false;
    this.rejectRemarks = '';
    this.rejectScrapData = {};
  }



  async updateScrap(key, value, data) {

      let masters =[];
    let scrapId = data.data.scrapId;
    this.spinner.show();
    let assetScrapData =
    {
      assetAgeInDays: data.data.assetAgeInDays ? data.data.assetAgeInDays : '',
      assetAgeInWords: data.data.assetAgeInWords ? data.data.assetAgeInWords : '',
      assetCode: this.ticketPartsData.ticket.hapAssetCode ? this.ticketPartsData.ticket.hapAssetCode : '',
      deptCode: this.ticketPartsData.ticket.hapDeptCode ? this.ticketPartsData.ticket.hapDeptCode : '',
      scrapId: scrapId ? scrapId : '',
      scrapRequestRemarks: data.data.scrapRequestRemarks ? data.data.scrapRequestRemarks : '',
      ticketCode: '',
      recommendRemarks: '',
      recommendStatus: '',
      verifiedRemarks: '',
      verifiedStatus: '',
      approvedRemarks: '',
      approvedStatus: ''

    };

    if (key == 'recommended') {
      assetScrapData = {
        ...assetScrapData,
        recommendRemarks: this.rejectRemarks,
        recommendStatus: value
      }
        masters=[];
    } else if (key == 'verify') {
      assetScrapData = {
        ...assetScrapData,
        verifiedRemarks: this.rejectRemarks,
        verifiedStatus: value
      }

    } else if (key == 'approve') {
        await this.getCurrentLocation();
        if (this.position) {
            assetScrapData = {
                ...assetScrapData,
                approvedRemarks: this.rejectRemarks,
                approvedStatus: value,

            };
            masters = [
                {
                    hapRemarks:this.rejectRemarks ,
                    hapGeoAddress: "",
                    hapLat: this.position ? this.position.lat : '',
                    hapLong: this.position ? this.position.lng : '',
                    ticketCode: "",
                    ticketStatusCode: 17,
                    ticketSubStatusCode: 26
                }
            ];
        }else {
            this.sharedService.showNotification(
                'snackbar-danger',
                'This app needs the Location service, please turn on the Location',
                'bottom',
                'center',
            );
        }
    }

    this.isSubmitted = true;


      if(value === 'R'){
          masters = [
              {
                  hapRemarks:this.rejectRemarks ,
                  hapGeoAddress: "",
                  cancelRemarks: "",
                  hapHubCode: "",
                  hapHubRemarks: "",
                  hapLat: this.position ? this.position.lat : "",
                  hapLong: this.position ? this.position.lng : "",
                  ticketCode: "",
                  ticketStatusCode:10,
                  ticketSubStatusCode:10,
              },
          ];
      } else{
          masters =[];
      }

    var ticketEdit = {
      ticketCode: this.ticketCode,
      assetCompliants: [],
      assetIntake: [],
      assetMaterials: [],
      assetTravel: [],
      comments: [],
      jobCard: [],
      master:masters,
      users: [],
      assetScrap: [assetScrapData],
    };

    console.log('WHOLE EDIT DATA', ticketEdit);

    try {
      let response = await this.ticketService.assetIntakeTicket(ticketEdit);

      if (response?.body?.success) {
        this.sharedService.showNotification(
          'snackbar-success',
          response?.body?.message,
          'bottom',
          'center',
        );

        await this.spinner.hide();
        this.dialogRef.close({ action: 'scrapTicket' });
      } else {
        /** spinner hide */
        await this.spinner.hide();
        this.sharedService.showNotification(
          'snackbar-danger',
          response?.body?.message,
          'bottom',
          'center',
        );
      }

      this.isSubmitted = false;
    } catch (e) {
      this.spinner.hide();
      this.isSubmitted = false;
      this.dialogRef.close({ action: 'close' });
    }

  }
    async getCurrentLocation() {
        try {
            this.position = await this.sharedService.getCurrentLocation();
            console.log(this.position, 'position');
        } catch (e) {
            console.log('e', e);
            this.position = null;
        }
    }
  async loadReason(categoryId) {

    this.reasonList = [];
    let catId = categoryId;
    let searchCriteria = {
      categoryId: catId,
    };
    console.log(this.roleCheck[0],'this.roleCheck');
    try {
      const response = await this.ticketService.getReasonList(
        searchCriteria
      );
      let data = response.body.data || [];
      this.reasonList = data.filter((item)=> item.roleName === this.roleCheck[0]);

    } catch (e) {
      this.reasonList = [];
    }
  }

}
