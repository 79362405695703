import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Form, FormControl, FormGroup } from '@angular/forms';

import { FilterBase } from '../../../core/service/filter-base.service';

@Component({
  selector: 'app-page-filter',
  templateUrl: './page-filter.component.html',
  styleUrls: ['./page-filter.component.scss']
})
export class PageFilterComponent implements OnInit {

  _filter: FilterBase<any>;
  _form: FormGroup;
  _dropDownsList: any = {};
  filterProjectList:any =[];
  filterCompanyList:any =[];
  filterProductList:any =[];
  _uploadCompleted: boolean = false;
  selectfilter = new FormControl();
  selectedFile = [];

  @Input()
  set filter(filter: FilterBase<any>) {
    if (!filter) return;
    this._filter = filter;
  }

  @Input()
  set form(form: FormGroup) {
    if (!form) return;
    this._form = form;
  }
  @ViewChild('fileInput')
  myFileInput: ElementRef;

  @Input()
  set dropDownsList(dropDownsList: any) {
    if (!dropDownsList) return;
    this._dropDownsList = dropDownsList;
  }

  @Input()
  set uploadCompleted(uploadCompleted: boolean) {
    this._uploadCompleted = uploadCompleted;
    // if (this._uploadCompleted === true) {
    //     console.log('native ', this.myFileInput.nativeElement.value);
    // }
  }

  constructor() {}

  ngOnInit(): void {
    
    if(this._filter.key == "idprojectCode"){
      this.filterProjectList = this._filter.options;

  }else if(this._filter.key == "companyCode"){
    this.filterCompanyList= this._filter.options;
  } 
  else if(this._filter.key == "productCode"){
    this.filterProductList = this._filter.options;
  }
}

  selectFile(event) {
 //   console.log('****_filter---> ', this._filter);
  //  console.log('****event---> ', event);
    // this.selectedFile = event.target.files;
    const file = event.target.files[0];

    this._form.get('file').setValue(file);
   // console.log('****_form---> ', this._form);
  }

  filterDropDowns(key,event) {
    if ((event.key && event.key.length === 1) ||
    (event.keyCode >= 'A' && event.keyCode <= 'Z') ||
    (event.keyCode >= 'ZERO' && event.keyCode <= 'NINE') ||
    (event.keyCode === 'SPACE')) {
    event.stopPropagation();
    }
    if(key === "idprojectCode"){

     
      
      console.log('filter project',this.filterProjectList);
      this._filter.options = this.filterProjectList.filter(
        (unit) =>
          unit.key.toLocaleLowerCase().indexOf(
            event.target.value.toLowerCase()
          ) > -1
      );

    }
    else if(key == "companyCode"){

      
    
    this._filter.options = this.filterCompanyList.filter(
      (unit) =>
        unit.key.toLocaleLowerCase().indexOf(
          event.target.value.toLowerCase()
        ) > -1
    );

    }else if(key == "productCode"){

     
   
    this._filter.options = this.filterProductList.filter(
      (unit) =>
        unit.key.toLocaleLowerCase().indexOf(
          event.target.value.toLowerCase()
        ) > -1
    );

    }
    // console.log('key',key)
    // console.log('****_filter---> ', this._filter);
     console.log('****event---> ', event);
   

  
   // console.log('****_form---> ', this._form);
  }

}
