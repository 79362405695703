import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { TicketTimeDialogModel } from "./ticket-time-dialog.model";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "src/app/ticket/ticket.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatMenuTrigger } from "@angular/material/menu";
import * as moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { ViewEncapsulation } from "@angular/core";
import { Observable, Subscription, timer } from "rxjs";
import { ValueType } from "exceljs";

@Component({
  selector: "app-ticket-time-dialog",
  templateUrl: "./ticket-time-dialog.component.html",
  styleUrls: ["./ticket-time-dialog.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TicketTimeDialogComponent implements OnInit {
  @ViewChild("hrs", { static: false })
  hrs: ElementRef;
  public timeBookingForm: FormGroup;
  ticketTimeModel: TicketTimeDialogModel | null;
  ticketInfo: any;
  sTime: any;
  eTime: any;
  splitCheck: any;
  bookableDays: any =[];
  startTime: any;
  endTime: any;
  spendHours: any = [];
  isDistruct: boolean = true;
  ticketBookedList: any = [];
  dataSource = new MatTableDataSource(this.ticketBookedList);
  isLoading: boolean;
  splitHoursN: boolean = false;
  splitHoursY: boolean = false;
  hours: any;
  public endTimeDisable: boolean = true;
  public startTimeDisable: boolean = true;
  pageSize: number;
  id: any;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  minDate: any;
  maxDate: any;
  currentUser: any;
  userName: any;
  userCode: any;
  time1IsBeforeTime2: boolean = true;
  initialDateResponse: boolean = true;
  charactersHeaderLeft: number;
  subscription: Subscription;
  pageSizeOptions = [25, 50, 75, 100];
  userImg = "assets/images/user/usrbig1.jpg";
  ticketImg = "";
  camToggle: string;
  bookingMode: any;
  bookingTimeAllowed: any;
  bookingTimeAllowedConv: any;
  hrsMinInValid: boolean = false;
  checkHours: boolean;
  searchHours: any;
  hoursArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
  ];
  minsArray = ["00","30"]; //["00","05", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
  hrsLimitArray: any = this.hoursArray;
  minsLimitArray: any = this.minsArray;
  displayedColumns: string[] = [];
  // "actions",
  constructor(
    public dialogRef: MatDialogRef<TicketTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private ticketService: TicketService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {
    this.ticketInfo = data.ticket;
    console.log("ticket booking ", this.ticketInfo);
    this.ticketTimeModel = new TicketTimeDialogModel({});
    this.timeBookingForm = this.createTicketTimeForm();
    this.timeBookingForm.get("idFromTime").disable();
    this.timeBookingForm.get("hours").disable();
    this.timeBookingForm.get("toTime").disable();
    this.timeBookingForm.get("hoursMode").disable();
    this.timeBookingForm.get("minsMode").disable();
    this.charactersHeaderLeft = 0;

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.userCode = this.currentUser.data[0].userInfo?.userCode;
    this.userName = this.currentUser.data[0].userInfo?.username;
    this.bookingMode = this.currentUser.data[0].timeBookingMode;
    console.log("this.bookingMode", this.bookingMode);
    this.bookingTimeAllowed = this.currentUser.data[0].minBookingAllowed;

    // this.hrsMinsValidation();

    if (this.bookingMode == "HOURS_BASED") {
      this.displayedColumns = [
          "idBookedDate",
          "BookingType",
          "hours",
          "comments",
      ];
      this.timeBookingForm
        .get("hoursMode")
        .setValidators([Validators.required]);
      this.timeBookingForm.get("hoursMode").updateValueAndValidity();
      this.timeBookingForm.get("minsMode").setValidators([Validators.required]);
      this.timeBookingForm.get("minsMode").updateValueAndValidity();
      this.timeBookingForm.get("idFromTime").clearValidators();
      this.timeBookingForm.get("toTime").clearValidators();
    } else {
        this.displayedColumns = [
            "idBookedDate",
            "BookingType",
            "idFromTime",
            "toTime",
            "hours",
            "comments",
        ];
      this.timeBookingForm
        .get("idFromTime")
        .setValidators([Validators.required]);
      this.timeBookingForm.get("idFromTime").updateValueAndValidity();
      this.timeBookingForm.get("toTime").setValidators([Validators.required]);
      this.timeBookingForm.get("toTime").updateValueAndValidity();
      this.timeBookingForm.get("hoursMode").clearValidators();
      this.timeBookingForm.get("minsMode").clearValidators();
    }

    this.subscription = this.timeBookingForm
      .get("textArea")
      .valueChanges.subscribe((value) => {
        value
          ? (this.charactersHeaderLeft = 0 + value.trim().length)
          : (this.charactersHeaderLeft = 0);
        //   console.log("charactersHeaderLeft",this.charactersHeaderLeft)
      });
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  public ngOnInit(): void {
    // this.createTicketTimeForm();
    this.loadData();
    this.allocatedTicketDetails();

    // this.maxDate = new Date();
    // console.log('date', this.maxDate);
  }

  applyFilter(filterValue: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.searchValue = filterValue.trim().toLowerCase();
    this.loadData();
  }
  async allocatedTicketDetails() {
    this.isLoading = true;
    const response = await this.ticketService.getAllocatedHoursList({
      ticketCode: this.ticketInfo.idTicketCode,
    });

    console.log("response", response);
    this.spendHours = response?.body?.data;
    this.isLoading = false;
    if (this.spendHours.length > 0) {
      this.splitCheck = this.spendHours[0].splitHours;
      this.bookableDays = this.spendHours[0].bookableDays || [];
      const bookableDaysArr = this.bookableDays.map(({bookableDate})=>bookableDate);
      this.maxDate = this.sharedService.minmax_date(bookableDaysArr, 'max');
      this.minDate = this.sharedService.minmax_date(bookableDaysArr, 'min');
      // console.log("spendHours..........", this.bookableDays[0] );
      // console.log("spendHours.what.........", this.bookableDays[6] );
      this.splitHoursCheck(this.splitCheck);
    } else {
      this.spendHours = [];
    }
  }

    activeDates = (d: Date) => {
    const dateSearch = this.dateToString(d);
    // const cDate = new Date().getTime();
    // const dateSearchToSec = new Date(dateSearch).getTime();
        /* let rDateClass = undefined;
       if(dateSearchToSec <= cDate){

           this.bookableDays.forEach((bm)=>{
               //let bkDateToSec = new Date(bm.bookableDate).getTime();

               if(dateSearch == bm.bookableDate){
                   if(bm.description == "Working Day"){
                       rDateClass= "example-custom-date-class";
                        return true;
                   }else if(bm.description =="Weekend"){
                       rDateClass = "example-custom-date-class1";
                       return true;
                   }else{
                       rDateClass = "example-custom-date-class1";
                       return true;
                   }
               }else{
                   rDateClass= undefined;
                   return true;
               }
               return rDateClass;
           });
       }
         return rDateClass;*/
    const w_day = this.bookableDays.find(
        (f) => f.bookableDate == dateSearch && f.description == "Working Day"
    );
    const wkd_day = this.bookableDays.find(
        (f) =>
            f.bookableDate == dateSearch &&
            (f.description == "Weekend" || dateSearch !== "")
    );
    if (
        w_day
    ) {
      return "example-custom-date-class";
    }
    if (
        wkd_day
    ) {
      return "example-custom-date-class1";
    } else {
      return undefined;
    }
  };
  displayMonth() {
    let elements = document.querySelectorAll(".endDate");
    let x = elements[0].querySelectorAll(".mat-calendar-body-cell");
    x.forEach((y) => {
      const dateSearch = this.dateToString(
        new Date(y.getAttribute("aria-label"))
      );
      const data = this.bookableDays.find((f) => f.bookableDate == dateSearch);
      if (data) y.setAttribute("aria-label", data.description);
      console.log("attributre", data);
    });
  }
  streamOpened(event) {
    setTimeout(() => {
      let buttons = document.querySelectorAll("mat-calendar .mat-icon-button");

      buttons.forEach((btn) =>
        this.renderer.listen(btn, "click", () => {
          setTimeout(() => {
            //debugger
            this.displayMonth();
          });
        })
      );
      this.displayMonth();
    });
  }
  dateToString(bookableDate: any) {
    return (
      bookableDate.getFullYear() +
      "-" +
      ("0" + (bookableDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + bookableDate.getDate()).slice(-2)
    );
  }
  clearItem() {
    //  this.timeBookingForm.controls['hrsType'].setValue('');
    // this.timeBookingForm.controls['idBookedDate'].setValue('');
    // this.timeBookingForm.controls['hours'].setValue('');
    // this.timeBookingForm.controls['textArea'].setValue('');
    // this.timeBookingForm.controls['idFromTime'].setValue('');
    // this.timeBookingForm.controls['toTime'].setValue('');
    this.timeBookingForm.reset();
    this.timeBookingForm.controls["hrsType"].setValue("Billable");
    this.timeBookingForm.get("idFromTime").disable();
    this.timeBookingForm.get("toTime").disable();
    this.timeBookingForm.get("hoursMode").disable();
    this.timeBookingForm.get("minsMode").disable();
    // this.timeBookingForm.get("textarea").value?.trim()?.length.reset();
    this.timeBookingForm.controls['textArea'].setValue('');
    // Object.keys(this.timeBookingForm.controls).forEach((key) => {
    //   this.timeBookingForm.get(key).setErrors(null);
    // });
    this.time1IsBeforeTime2 = true;
  }
  editBookingType(row) {
    console.log("losick", row);

    /* this.ticketTimeModel = row;
        this.timeBookingForm = this.createTicketTimeForm();*/
    this.timeBookingForm.valueChanges.subscribe((value) => {
      console.log(JSON.stringify(value));
    });
    this.timeBookingForm.patchValue({
      hrsType: row.hrsType,
      idBookedDate: row.idBookedDate,
      hours: row.bookedHrs,
      textArea: row.comments,
      idFromTime: this.getStartTime(row.idFromTime),
      toTime: this.getEndTime(row.toTime),
    });
  }
  public getStartTime(time) {
    console.log(time, "start time");
    if (time != null) {
      let timeArray = time.split(":");
      let startDate = new Date();
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        timeArray[0],
        timeArray[1]
      );
    } else {
      let startDate = new Date();
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        9,
        30
      );
    }
  }
  public getEndTime(time) {
    console.log(time, "end time");
    if (time != null) {
      let timeArray = time.split(":");
      let endDate = new Date();
      return new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        timeArray[0],
        timeArray[1]
      );
    } else {
      let endDate = new Date();
      return new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        18,
        30
      );
    }
  }
  splitHoursCheck(splitCheck) {
    if (splitCheck == "Y") {
      this.splitHoursY = true;
      this.splitHoursN = false;
    } else {
      this.splitHoursY = false;
      this.splitHoursN = true;
    }
  }
  async loadData() {
    this.isLoading = true;
    this.ticketBookedList = [];
    this.dataSource.data = [];
    const response = await this.ticketService.getTimeBookedList({
      ticketCode: this.ticketInfo.idTicketCode,
      userCode: this.userCode,
    });
    /* MatSort */
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: "licenseTypeCode", direction: "desc" };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    // this.totalLength = response?.body?.totalCount;
    this.dataSource.paginator = this.paginator;
    this.ticketBookedList = response?.body?.data;

    this.sortList();
    // this.dataSource.data = this.ticketBookedList;

    this.isLoading = false;
  }
  sortList() {
    this.ticketBookedList = this.ticketBookedList || [];
    this.ticketBookedList.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return +new Date(b.idBookedDate) - +new Date(a.idBookedDate);
    });
    console.log("this.ticketBookedList", this.ticketBookedList);
    this.dataSource.data = this.ticketBookedList;
  }

  createTicketTimeForm(): FormGroup {
    return this.fb.group({
      id: [this.ticketTimeModel.id],
      idTicketCode: [this.ticketInfo.idTicketCode],
      hoursMode: [],
      minsMode: [],
      hrsType: [this.ticketTimeModel.hrsType],
      idBookedDate: [this.ticketTimeModel.idBookedDate],
      idFromTime: [this.ticketTimeModel.idFromTime],
      toTime: [this.ticketTimeModel.toTime],
      textArea: [this.ticketTimeModel.textArea],
      hours: ["0"],
    });
  }

  public async confirmAdd() {
    /** spinner start */

    var obj = this.timeBookingForm.getRawValue();
    let bookedDate = obj.idBookedDate;
    if (bookedDate == "" || bookedDate == null || bookedDate == undefined) {
      this.sharedService.showNotification(
        "snackbar-danger",
        "Please fill the all fields",
        "bottom",
        "center"
      );
    } else {
      this.spinner.show();
      let internalComments;
      let ticketObj;
      if (this.bookingMode == "CLOCK_BASED") {
        let start = obj.idFromTime.setSeconds(0);
        var startTime = new Date(start).toLocaleTimeString("en-GB"); // 24hrs
        let end = obj.toTime.setSeconds(0);
        var endTime = new Date(end).toLocaleTimeString("en-GB"); // 24hrs
        let convertStartTime = this.tConvert(startTime);
        let convertEndTime = this.tConvert(endTime);

        internalComments = `<p><b>${
          this.userName
        }</b> has booked on <b>${this.formatDate(
          obj.idBookedDate
        )}</b> from <b>${convertStartTime}</b> to <b>${convertEndTime}</b> under <b>${
          obj.hrsType
        }</b></p>`;

        // Ticket Time Booking request object
        ticketObj = {
          comments: {
            idTicketCode: this.ticketInfo.idTicketCode,
            idLogType: "Internal",
            text: internalComments,
          },

          hoursBooking: {
            comments: obj.textArea,
            hrsType: obj.hrsType,
            idBookedDate: new Date(obj.idBookedDate),
            fromTime: startTime,
            idTicketCode: this.ticketInfo.idTicketCode,
            idUserCode: "",
            toTime: endTime,
            hrsMins: "",
          },
        };
      } else {
        //let hrsMins = `${obj.hoursMode}:${obj.minsMode}`;
        this.hours = `${obj.hoursMode}:${obj.minsMode}`;
        internalComments = `<p><b>${
          this.userName
        }</b> has booked on <b>${this.formatDate(
          obj.idBookedDate
        )}</b> from <b> ${this.hours} </b> under <b>${obj.hrsType}</b></p>`;

        // Ticket Time Booking request object
        ticketObj = {
          comments: {
            idTicketCode: this.ticketInfo.idTicketCode,
            idLogType: "Internal",
            text: internalComments,
          },

          hoursBooking: {
            comments: obj.textArea,
            hrsType: obj.hrsType,
            fromTime: "",
            toTime: "",
            idBookedDate: new Date(obj.idBookedDate),
            hrsMins: this.hours,
            idTicketCode: this.ticketInfo.idTicketCode,
            idUserCode: "",
          },
        };
      }
      console.log("time booking Obj", ticketObj);
      let hours = this.hours;
      console.log("hours", hours);

      if (obj.hrsType == "Billable") {
        if (this.spendHours[0].splitHours == "Y") {
          var totalBookableHours =
            this.convertDurationToSeconds(hours) +
            this.convertDurationToSeconds(
              this.spendHours[0].totalBillableUserHrsMins
            );
          if (
            totalBookableHours <=
            this.convertDurationToSeconds(
              this.spendHours[0].totalAllocatedUserHrsMins
            )
          ) {
            console.log("allow");

            this.checkHours = true;
          } else {
            console.log("reject");

            this.checkHours = false;
          }
        } else if (this.spendHours[0].splitHours == "N") {
          var totalBookableHours =
            this.convertDurationToSeconds(hours) +
            this.convertDurationToSeconds(
              this.spendHours[0].totalBillableTicketHrsMins
            );
          if (
            totalBookableHours <=
            this.convertDurationToSeconds(
              this.spendHours[0].totalAllocatedTicketHrsMins
            )
          ) {
            console.log("allow");

            this.checkHours = true;
          } else {
            console.log("reject");

            this.checkHours = false;
          }
        }
      } else if (obj.hrsType == "Non-Billable") {
        if (this.spendHours[0].splitHours == "Y") {
          var totalBookableHours =
            this.convertDurationToSeconds(hours) +
            this.convertDurationToSeconds(
              this.spendHours[0].totalNonBillableUserHrsMins
            );
          if (
            totalBookableHours <=
            this.convertDurationToSeconds(
              this.spendHours[0].totalNonBillableTicketHrsMins
            )
          ) {
            console.log("allow");
            this.checkHours = true;
          } else {
            console.log("reject");
            this.checkHours = false;
          }
        } else if (this.spendHours[0].splitHours == "N") {
          var totalBookableHours =
            this.convertDurationToSeconds(hours) +
            this.convertDurationToSeconds(
              this.spendHours[0].totalNonBillableTicketHrsMins
            );
          if (
            totalBookableHours <=
            this.convertDurationToSeconds(
              this.spendHours[0].totalAllocatedNBTicketHrsMins
            )
          ) {
            console.log("allow");

            this.checkHours = true;
          } else {
            console.log("reject");

            this.checkHours = false;
          }
        }
      }
      if (this.checkHours) {
        try {
          let response = await this.ticketService.timeBooking(ticketObj);
          console.log("response????", response);
          if (response?.body?.success) {
            /** spinner hide */
            this.spinner.hide();
            this.clearItem();
            this.sharedService.showNotification(
              "snackbar-success",
              response?.body?.message,
              "bottom",
              "center"
            );
           /*  this.timeBookingForm.reset();
            this.timeBookingForm.markAllAsTouched();
            this.timeBookingForm.get("idFromTime").disable();
            this.timeBookingForm.get("toTime").disable(); */
            
            this.loadData();
            this.allocatedTicketDetails();
            // this.dialogRef.close({ action: this.action, response });
            console.log(this.timeBookingForm.value, "Success>>>");

           /*  Object.keys(this.timeBookingForm.controls).forEach((key) => {
              this.timeBookingForm.get(key).setErrors(null);
            }); */

          /*   this.timeBookingForm.controls["hrsType"].setValue("Billable");
            this.time1IsBeforeTime2 = true; */
            
          } else {
            /** spinner hide */
            this.spinner.hide();
            this.sharedService.showNotification(
              "snackbar-danger",
              response?.body?.message,
              "bottom",
              "center"
            );
          }
        } catch (e) {
          console.log("error", e);
          /** spinner hide */
          this.spinner.hide();
          this.sharedService.showNotification(
            "snackbar-danger",
            e?.body?.message,
            "bottom",
            "center"
          );
        }
      } else {
        this.sharedService.showNotification(
          "snackbar-danger",
          "You have already reached proposed Hours",
          "bottom",
          "center"
        );

        this.spinner.hide();
      }
    }
  }

  getTimeAsNumberOfMinutes(time) {
    var timeParts = time.split(":");

    //   var timeInMinutes = (timeParts[0] * 60) + timeParts[1];

    var startHour = timeParts[0];
    var startMinute = timeParts[1];
    var startSecond = timeParts[2];

    //  var endHour =   timeParts[0];
    //  var endMinute = timeParts[1];
    //  var endSecond = timeParts[2];

    //Create date object and set the time to that
    var startTimeObject = new Date();
    startTimeObject.setHours(startHour, startMinute, startSecond);

    return startTimeObject;

    //Create date object and set the time to that
    //  var endTimeObject = new Date(startTimeObject);
    //  endTimeObject.setHours(endHour, endMinute, endSecond);

    //Now we are ready to compare both the dates
    //  if(startTimeObject > endTimeObject)
    //  {
    //  alert('End time should be after start time.');
    //  }
    //  else
    //  {
    //  alert('Entries are perfect.');
    //  }

    //return timeInMinutes;
  }

  /* Create Ticket dialog close function,
any changes without save ,condition checked here.
Confirmation dialog added.
 */
  onNoClick(): void {
    const fieldValueArr = Object.values(this.timeBookingForm.value);
    //  console.log('fieldValueArr',fieldValueArr);
    const isChanged = fieldValueArr.filter((v) => {
      if (v && v != 0) {
        console.log(v);
        return v;
      }
    });
    //  console.log(isChanged, 'test>>>>>');
    if (isChanged.length > 0) {
      this.isDistruct = false;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: "320px",
        data: {
          name: "Your changes won’t be saved",
          msg: "We won’t be able to save your data if you move away from this page.",
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.event === "Yes") {
          this.dialogRef.close({ action: "close" });
        }
        this.isDistruct = true;
      });
    } else {
      this.dialogRef.close({ action: "close" });
      this.isDistruct = true;
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.isDistruct) {
      this.onNoClick();
    }
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  /* onSubmitClick() {
    console.log('Form Value', this.timeBookingForm.value);
  } */

  totalSpentHour(f, e) {
    this.initialDateResponse = false;
    let userObj = this.timeBookingForm.value;
    //  const day = new Date(userObj.idBookedDate).toLocaleDateString();
    //   console.log("first date",day)
    //   const [month, date, year] = day.split('/');
    //   const dob = [year, month, date].join('-');
    //   console.log("dateeee",dob)
    // //  let startTime = userObj.idFromTime;
    //   this.startTime = new Date(userObj.idFromTime).getTime();
    //   console.log("Start time",this.startTime);
    //   //let endTime = userObj.toTime;
    //  this.endTime = new Date(userObj.toTime).getTime();
    //   console.log("End time",this.endTime);
    //  let sum =  this.endTime - this.startTime;
    //   let hours = Math.floor((sum % (1000 * 60 * 60 * 24)) / (1000 * 60));
    //   let mins = hours  % 60;
    //   let hrs = (hours - mins) / 60;
    //   let fhrs = hrs < 9 ? '0'+hrs : hrs;
    //   let fmins = mins < 9 ? '0'+mins : mins;
    //   this.hrs.nativeElement.value =  fhrs + ':' + fmins;
    //   this.hours = fhrs;
    // //  var sum =Math.abs(new Date(startTime).getTime() - new Date(endTime).getTime());

    //  console.log("different hours",hours);
    let time1 = new Date(userObj.idFromTime).toLocaleTimeString("en-GB");
    let time2 = new Date(userObj.toTime).toLocaleTimeString("en-GB");

    this.sTime = time1.split(":");
    this.eTime = time2.split(":");
    var startDate = new Date(0, 0, 0, this.sTime[0], this.sTime[1], 0);
    var endDate = new Date(0, 0, 0, this.eTime[0], this.eTime[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);
    var fHours = hours <= 9 ? "0" + hours : hours ? hours : "";
    var fMinutes = minutes <= 9 ? "0" + minutes : minutes ? minutes : "";

    if (fHours == "" && fMinutes == "") {
      this.hrs.nativeElement.value = "";
      this.hours = "";
    } else {
      this.hrs.nativeElement.value = fHours + ":" + fMinutes;
      this.hours = fHours + ":" + fMinutes;
    }
    console.log("different hours", this.hrs.nativeElement.value);

    if (time1 == "" && time2 == "") {
    } else {
      var time1InMinutesForTime1 = this.getTimeAsNumberOfMinutes(time1);
      var time1InMinutesForTime2 = this.getTimeAsNumberOfMinutes(time2);

      //  this.time1IsBeforeTime2 = time1InMinutesForTime1 < time1InMinutesForTime2;

      if (
        time1InMinutesForTime1 > time1InMinutesForTime2 ||
        this.hours == "00:00"
      ) {
        this.time1IsBeforeTime2 = false;

        this.timeBookingForm.get("hours").reset();
        console.log("false");
        // this.sharedService.showNotification(
        //   "snackbar-danger",
        //   "You have selected Start/End invalid Time",
        //   "bottom",
        //   "center"
        // );
      } else {
        this.time1IsBeforeTime2 = true;
        // alert('Entries are perfect.');
      }

      // if(this.time1IsBeforeTime2){
      //  console.log('true');
      // }
      // else{
      //   console.log('false');
      //   this.sharedService.showNotification(
      //     "snackbar-danger",
      //     'Mismatched Start Time and End Time',
      //     "top",
      //     "right"
      //   );
      // }
      console.log("time1IsBeforeTime2", this.time1IsBeforeTime2);
    }

    // console.log(msBetweenDates);
    /*   const msBetweenDates = date2.getTime() - date1.getTime();

      console.log(msBetweenDates);*/
    /* let fromTime = f=='from' ? new Date(e.value): new Date();
    let toTime = f=='to' ? new Date(e.value): new Date();
   if(fromTime  && toTime){
    Math.floor((Date.UTC(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate()) - Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate()) ) /(1000 * 60 * 60 * 24));
  console.log("mathhhhhhh",Math.floor((Date.UTC(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate()) - Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate()) ) /(1000 * 60 * 60 * 24)))
   }*/
  }

  public onDateChange(event): void {
    this.initialDateResponse = true;

    if (
      this.timeBookingForm.get("idFromTime").value == "" ||
      this.timeBookingForm.get("idFromTime").value == null ||
      this.timeBookingForm.get("idFromTime").value == undefined
    ) {
    } else {
      this.timeBookingForm.get("idFromTime").reset();
    }

    if (
      this.timeBookingForm.get("toTime").value == "" ||
      this.timeBookingForm.get("toTime").value == null ||
      this.timeBookingForm.get("toTime").value == undefined
    ) {
    } else {
      this.timeBookingForm.get("toTime").reset();
    }

    this.timeBookingForm.get("hours").reset();
    console.log("change date", event.value);
    if (event.value == null || event.value == undefined || event.value == "") {
      this.timeBookingForm.get("idFromTime").disable();
      this.timeBookingForm.get("toTime").disable();
      this.timeBookingForm.get("hoursMode").disable();
      this.timeBookingForm.get("minsMode").disable();
    } else {
      let dateCheck = this.isDate(event.value);
      console.log("value", dateCheck);
      if (dateCheck) {
        this.timeBookingForm.get("idFromTime").enable();
        this.timeBookingForm.get("toTime").enable();
        this.timeBookingForm.get("hoursMode").enable();
       // this.timeBookingForm.get("minsMode").enable();
      } else {
        this.timeBookingForm.get("idFromTime").disable();
        this.timeBookingForm.get("toTime").disable();
        this.timeBookingForm.get("hoursMode").disable();
        this.timeBookingForm.get("minsMode").disable();
      }
    }
  }

  isDate(dateStr) {
    return !isNaN(new Date(dateStr).getDate());
  }
  convertH2M(timeInHour) {
    return Math.floor(timeInHour * 60);
  }
  convertDurationToSeconds(duration) {
    const [hours, minutes] = duration.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }
  tConvert(time) {
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(2, 3) + ampm;
    return time;
  }
  ChangeFormateDate(Date) {
    return Date.toString().split("-").reverse().join("-");
  }

  nextPage(e: any) {
    console.log("event ----> ", e);
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadData();
  }
  formatDate(d) {
    let dd: any;
    let mm: any;
    let formatDate = new Date(d);
    dd = formatDate.getDate();
    mm = formatDate.getMonth() + 1;
    let yyyy = formatDate.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (d = dd + "-" + mm + "-" + yyyy);
  }
  space(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.target.selectionStart === 0 && event.code === "Enter")
    ) {
      event.preventDefault();
    }
  }
  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  hrsMinsValidation() {
    const hrsMins = this.sharedService.toHoursAndMinutes(this.bookingTimeAllowed);
    console.log("hrsMins", hrsMins);

    /* this.hrsLimitArray = this.hoursArray.filter(
      (value) => value >= hrsMins.hours
    ); 
    this.minsLimitArray = this.minsArray.filter(
      (value) => value >= hrsMins.minutes
    ); */
    
  }

  chkHrsMin(type){
    const hrsMins = this.sharedService.toHoursAndMinutes(this.bookingTimeAllowed);
    this.bookingTimeAllowedConv = `${hrsMins.hours} : ${hrsMins.minutes}`;
    const hrsCon =  hrsMins.hours ? Number(hrsMins.hours): 0;
    const minCon =  hrsMins.minutes ? Number(hrsMins.minutes): 0;
    const hrsVal = this.timeBookingForm.controls['hoursMode'].value;
    const minVal = this.timeBookingForm.controls['minsMode'].value;
    const hrsValNum = hrsVal ? Number(hrsVal): 0;
    const minValNum = minVal ? Number(minVal): 0;
    if(type == 'hrs'){
      if(hrsValNum == 0){
        if(minValNum == 0){
          this.timeBookingForm.controls['minsMode'].setValue(hrsMins.minutes);
        }
      }else{
        if(minValNum == 0){
          this.timeBookingForm.controls['minsMode'].setValue("00");
        }
      }
      
    }else if(type == 'min'){
      if(minValNum == 0){
        if(hrsValNum == 0){
          this.timeBookingForm.controls['hoursMode'].setValue("01");
        }
      }else if(minValNum > 0){
        if(hrsValNum == 0){
          this.timeBookingForm.controls['hoursMode'].setValue("00");
        }
      }
    }
    // debugger;
    const hrsMinSel = (Number(this.timeBookingForm.controls['hoursMode'].value))*60 + Number(this.timeBookingForm.controls['minsMode'].value);
      if(hrsMinSel < this.bookingTimeAllowed){
        this.hrsMinInValid = true;
      }else{
        this.hrsMinInValid = false;
        
      }
      this.hours = `${this.timeBookingForm.controls['hoursMode'].value} : ${this.timeBookingForm.controls['minsMode'].value}`;
        this.timeBookingForm.controls['hours'].setValue(this.hours);
    
  }

  clearDropDowns(key) {
    if (key == "Hours") {
      setTimeout(() => (this.searchHours = ""), 2000);
    }
  }
}
