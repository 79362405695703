import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { TicketTimeDialogModel } from "./time-history-dialog.model";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "src/app/ticket/ticket.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatMenuTrigger } from "@angular/material/menu";
import * as moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { ViewEncapsulation } from "@angular/core";
import { EventService } from "../../../core/service/event.service";
import { B } from "@angular/cdk/keycodes";

@Component({
  selector: "app-ticket-time-dialog",
  templateUrl: "./time-history-dialog.component.html",
  styleUrls: ["./time-history-dialog.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TicketHistoryDialogComponent implements OnInit {
  isLoading: boolean;
  public columns: any = [];
  public allColumns: any = [];
  ticketTimeBooking: any = [];
  dataSource = new MatTableDataSource(this.ticketTimeBooking);
  pageSize: number;
  pageIndex: number;
  totalLength: number;
  sortColumn: string;
  sortOrder: boolean;
  searchCriteria: any;
  searchField: any;
  searchValue: any;
  pageSizeOptions = [25,50,75,100];
  searchCriteriaBody: any = {};
   currentUser: any;
    bookingMode:any;
  public afterInit: boolean = false;
  retrieveForm: FormGroup;
  assignedSubGroupName: any;
  userName: any;
  dialogTitle: any;
  minDate: any;
  minStartDate: any;
  userCode: any;
  idTicketCode: any;
  hourType: any;
  subject: any;
  flag: any;

  displayedColumns: string[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TicketHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventService: EventService,
    public sharedService: SharedService,
    private ticketService: TicketService
  ) {
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      this.bookingMode = this.currentUser.data[0].timeBookingMode;
      console.log("this.bookingMode", this.bookingMode);
      if (this.bookingMode == "HOURS_BASED") {
          this.displayedColumns = [
              "idBookedDate",
              "userName",
              "BookingType",
              "hours",
              "comments",
          ];
      }else{
          this.displayedColumns = [
              "idBookedDate",
              "userName",
              "BookingType",
              "idFromTime",
              "toTime",
              "hours",
              "comments",
          ];

      }
    console.log("booking Data", this.data);
    if (this.data.flag == "N") {
      this.searchCriteriaBody["idTicketCode"] =
        this.data.ticketEditDialog.ticketagent.idTicketCode;
      this.searchCriteriaBody["idUserCode"] = this.data.ticketEditDialog.ticketagent.fromService === 'Y'? this.data.ticketEditDialog.ticketagent.idUserCode : this.data.ticketEditDialog.ticketagent.idUserCode.idUserCode;
      this.searchCriteriaBody["hrsType"] = this.data.ticketEditDialog.value;
      this.dialogTitle = "Time Booking List";
      this.assignedSubGroupName =
        data.ticketEditDialog.ticketagent.assignedSubGroupName;
      this.userName = data.ticketEditDialog.ticketagent.userName;
      this.userCode = this.data.ticketEditDialog.ticketagent.idUserCode;
      this.idTicketCode = this.data.ticketEditDialog.ticketagent.idTicketCode;
      this.hourType = this.data.ticketEditDialog.value;
      this.subject = this.data.subject;
    } else if (this.data.flag == "Y") {
      this.idTicketCode =
        this.data.totalTimeBookingData.ticketData.idTicketCode;
      this.hourType = this.data.totalTimeBookingData.value;
      this.subject = this.data.subject;
      this.searchCriteriaBody["idTicketCode"] =
        this.data.totalTimeBookingData.ticketData.idTicketCode;
      this.searchCriteriaBody["hrsType"] = this.data.totalTimeBookingData.value;
      this.searchCriteriaBody["idUserCode"] = "";
      this.dialogTitle = "Total Time Booking List";
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  public ngOnInit(): void {
    this.pageSize = 25;
    this.pageIndex = 0;
    this.sortColumn = "id";
    this.sortOrder = true;
    this.loadData();
  }
  nextPage(e: any) {
    console.log("event ----> ", e);
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadData();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }

  async loadData() {
    this.isLoading = true;
    this.ticketTimeBooking = [];
    this.dataSource.data = [];
    // this.dataSource.data = [];
    this.searchCriteria = {
      pageNo: this.pageIndex,
      sortOrder: this.sortOrder,
      sortColumn: this.sortColumn,
      pageSize: this.pageSize,
      searchColumn: this.searchField,
      searchValue: this.searchValue,
      action: "GetListWithPage",
    };

    const response = await this.ticketService.getRetrieveTimeBookingList(
      this.searchCriteria,
      this.searchCriteriaBody
    );
    console.log("searchCriteriaBody", this.searchCriteriaBody);

    console.log("response?.body>>", response?.body);
    if (response?.body?.success) {
      this.totalLength = response?.body?.totalCount;
      this.ticketTimeBooking = response?.body?.transactions;
   //   this.dataSource.data = this.ticketTimeBooking;
      this.sortList();

      this.isLoading = false;
    } else {
      this.totalLength = 0;
      this.ticketTimeBooking = [];
      this.isLoading = false;
    }
  }

     /* List sorting function */
     sortList() {

      this.ticketTimeBooking = this.ticketTimeBooking || [];
      this.ticketTimeBooking.sort(function (a, b) {
          // convert date object into number to resolve issue in typescript 
          return +new Date(b.idBookedDate)- +new Date(a.idBookedDate);
      });
      console.log('this.ticketTimeBooking',this.ticketTimeBooking);
      this.dataSource.data = this.ticketTimeBooking;

  }
  tConvert(time) {
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(2, 3) + ampm;
    return time;
  }
  ChangeFormateDate(Date) {
    return Date.toString().split("-").reverse().join("-");
  }
}
