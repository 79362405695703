import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterBase} from '../../../core/service/filter-base.service';
import { FilterService } from '../../../core/service/filter.service';
import { FilterControllerService } from '../../../core/service/filter-controller.service';
import { ApiService } from '../../../core/service/api.service';
import { SharedService } from '../../../core/service/shared.service';
import { ProjectmasterService } from 'src/app/projectmaster/projectmaster.service';

@Component({
  selector: 'app-basic-filter-dialog',
  templateUrl: './basic-filter-dialog.component.html',
  styleUrls: ['./basic-filter-dialog.component.sass']
})
export class BasicFilterDialogComponent implements OnInit {
  public filters: FilterBase<any>[];
  public form: FormGroup;
  public pageName:any;
  public uploadCompleted: boolean = false; 
  public projectList: any = [];
  public filtered: any = {};
 

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<BasicFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     public filterService: FilterService,
      public filterController: FilterControllerService,
      public apiService: ApiService,
      public sharedService: SharedService
      ) {
   
    
  }

  
  public ngOnInit(): void {

   // Ticket List 
    this.pageName = this.data.pageName;
    if(this.data.pageName == 'Ticket'){
    this.projectList =  this.data.projectList;
    console.log('this.data.ticketTypeList',this.data.ticketTypeList);
     
    this.filtered =  this.data.filtered;
    console.log(this.filtered, 'filtered Ticket');
    this.filters = this.filterService.ticketManagement(this.projectList, this.data.ticketTypeList, this.data.ticketPriorityList, this.data.ticketStatusList,this.filtered);
    this.form = this.filterController.toFormGroup(this.filters);
    }
    // Project List
    else if(this.data.pageName == 'Project'){
    this.filtered = this.data.filtered;
    console.log(this.filtered, 'filtered Project');
    this.filters = this.filterService.projectMaster(this.data.companyName,this.data.productName,this.data.projectNameList,this.filtered);
    this.form = this.filterController.toFormGroup(this.filters);
    }
    else if(this.data.pageName == 'Asset-Master'){
      this.filtered = this.data.filtered;
      console.log(this.filtered, 'filtered Project');
      this.filters = this.filterService.assetMaster(
        this.data.departmentList,
        this.data.categoryList,
        this.filtered,
      );
      this.form = this.filterController.toFormGroup(this.filters);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmitClick() {
   this.sharedService.setSearch(this.form.value);
   this.dialogRef.close();
  }
  resetClick() {
    this.form.reset();
    this.sharedService.setSearch(this.form.value);
    this.dialogRef.close();
   }
}
