import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

import { TicketNewService } from "src/app/ticket-new/ticket.service";

import { EkomasterService } from "../../../eko-master/eko-master.service";

export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  selector: "app-consumable-verify-asset-tag",
  templateUrl: "./consumable-verify-asset-tag.component.html",
  styleUrls: ["./consumable-verify-asset-tag.component.css"],
})
export class ConsumableVerifyAssetTagComponent implements OnInit {
  ticketCode: any;
  public Editor = ClassicEditor;
  materialList: any = [];
  dataSource: any = [];
  // dataSourcetable: [] | null;
  base64File: string = null;
  filename: string = null;
  fileType: string = null;
  tabs = ["First", "Second", "Third"];
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  stickerData: any;
  assetStickerData: any;
  fileValidation: boolean = false;
  filteredOptions: Observable<string[]>;
  consumableAssetIntakeForm: FormGroup;
  materialForm: FormGroup;
  isEditFilter = false;
  ticketData: any;
  myControl = new FormControl("");
  gNo: string;
  isProxyAllowed: boolean;
  bikeKmHide: boolean;
  busKmHide: boolean;
  noneHide: boolean;

  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
    hmbVal: boolean = true;
  apicallSub?: Subscription;
  public referenceTicketList: any = [];
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  panelOpenState = false;
  @ViewChild("tagInput", { static: true })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild("priority", { static: false }) priorityField: any;
  @ViewChild("myInput", { static: true }) myInput: ElementRef =
    Object.create(null);
  routerUrl: any;
  assetStatus: any = [];
  // transportList: any = [];
  baseFile: any = [];
  attachmentFormat: any;
  attachmentError: boolean = false;
  billImage: any;
  defaultImg = "assets/images/user/usrbig1.jpg";
  transportList: any = [];
  uomList: any = [
    {
      uomName: "Ml",
      uomCode: "ml",
    },
    {
      uomName: "Ltr",
      uomCode: "ltr",
    },
  ];
  actionBtnLabel: any = "Save";
  position: any;

  //fileList: File[] = [];
  listOfFiles: File[] = [];
  isLoading = false;

  /* auto search */

  constructor(
    public dialogRef: MatDialogRef<ConsumableVerifyAssetTagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    
  ) {
    this.routerUrl = this.sharedService.routerUrl();
    console.log(this.routerUrl, "currentUrl");

  //  this.action = data.action;

    this.ticketData = data.data
      console.log("ticket list data", this.ticketData);
    this.ticketCode = this.ticketData.ticketCode;

    this.dialogTitle = `Consumable Asset Intake :: Ticket - ${this.ticketCode} :: Asset - ${this.ticketData.hapAssetCode}`;
    this.transportDropdown();
    this.consumableAssetIntakeForm = this.createIntakeForm();

    this.stickerData = [
      {
        label: "Screw Not Available",
        value: "Screw Not Available",
      },
      {
        label: "Available",
        value: "Available",
      },
      {
        label: "Not Available",
        value: "Not Available",
      },
    ];
    this.assetStickerData = [
      {
        label: "Damaged",
        value: "Damaged",
      },
      {
        label: "Available",
        value: "Available",
      },
      {
        label: "Not Available",
        value: "Not Available",
      },
    ];
    this.assetStatus = [
      {
        label: "Good",
        value: "Good",
      },
      {
        label: "Bad",
        value: "Bad",
      },
      {
        label: "Damaged",
        value: "Damaged",
      },
    ];
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.consumableAssetIntakeForm
      .get("assetName")
      .setValue(this.ticketData.hapAssetName);
    console.log("fromPlace",this.ticketData.hmbName)
    this.consumableAssetIntakeForm.get("fromPlace").setValue(this.ticketData.hmbName);
    this.consumableAssetIntakeForm.get("toPlace").setValue(this.ticketData.hubName);
    this.ticketAssetIntake();
    this.ticketTravelAllowance();
      this.hapHmbCode();
  }
  //from place validation
    hapHmbCode(){
        if(typeof this.ticketData.hapHmbCode!='undefined' && this.ticketData.hapHmbCode){

            this.consumableAssetIntakeForm.get("fromPlace").disable();
        }else{
            this.consumableAssetIntakeForm.get("fromPlace").enable();
        }
    }
  getFileSize(size) {
    return Math.round(size / 1024);
  }

  createIntakeForm(): FormGroup {
    return this.fb.group({
      assetName: new FormControl(
        {
          value: "",
          disabled: true,
        },
        Validators.pattern("^[a-zA-Z -']+")
      ),

      assetStatus: ["", [Validators.required]],
     // hologramSticker: ["", [Validators.required]],
      //assetSticker: ["", [Validators.required]],
      fromPlace: [
        {
          value: "",

        },
        Validators.pattern("^[a-zA-Z -']+"),
      ],
      toPlace: [
        {
          value: "",
          disabled: true,
        },
        Validators.pattern("^[a-zA-Z -']+"),
      ],
      transportMode: ["", [Validators.required]],
      traveledDistance: ["", [this.numberValidator]],

      upFare: ["0", [this.numberValidator]],
      downFare: [
        {
          value: "0",
          disabled: true,
        },

        this.numberValidator,
      ],
      totalFare: new FormControl(
        {
          value: "",
          disabled: true,
        },
        [this.numberValidator]
      ),
      personName: ["", Validators.pattern("^[a-zA-Z -']+")],
      mobileNo: ["", Validators.pattern("[6-9]\\d{9}")],
      files: [],
    });
  }
  onSelectTransport(value: any) {
    console.log("onselectt Transport", value);
    //changed to id to Name
    if (value === "Bus") {
      this.busKmHide = true;
      this.bikeKmHide = false;
    } else if (value === "Bike") {
      this.bikeKmHide = true;
      this.busKmHide = false;
    } else {
      this.bikeKmHide = false;
      this.busKmHide = false;
    }
  }
  //save material

  detailsUser(content) {
    this.modalService.open(content, { size: "xl" });
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
  }
  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  customValidator(control: FormControl) {
    if (
      (("" + control.value).match(/^\d+\.?\d*%?$/) || false) &&
      parseFloat(control.value) >= 0 &&
      parseFloat(control.value) <= 100
    ) {
      return null;
    }
    return {
      customValidator: true,
    };
  }
  fareChange(searchValue: any) {
    console.log("fare change", searchValue);

    //const ctrl = this.assetIntakeForm.get('downFare');
    if (searchValue == "" || searchValue == 0) {
      // ctrl.disable();
      this.consumableAssetIntakeForm.get("downFare").setValue(searchValue);
      this.totalFare();
    } else {
      // ctrl.enable();
      this.consumableAssetIntakeForm.get("downFare").setValue(searchValue);
      this.totalFare();
    }
  }
  totalFare() {
    let totalFare =
      parseInt(
        this.consumableAssetIntakeForm.get("upFare").value
          ? this.consumableAssetIntakeForm.get("upFare").value
          : "0"
      ) +
      parseInt(
        this.consumableAssetIntakeForm.get("downFare").value
          ? this.consumableAssetIntakeForm.get("downFare").value
          : "0"
      );

    this.consumableAssetIntakeForm.get("totalFare").setValue(totalFare);
  }

  public async updateTicket() {
    await this.getCurrentLocation();

    this.consumableAssetIntakeForm.get("files").setValue(this.listOfFiles);

    if (this.position) {
      console.log("assetIntakeForm value", this.consumableAssetIntakeForm.getRawValue);
      let formObj = this.consumableAssetIntakeForm.getRawValue();
      /** spinner start */
      this.spinner.show();

      this.isSubmitted = true;

      let assetIntakeObj: any = {
        assetStatus: formObj["assetStatus"] ? formObj["assetStatus"] : "",
        assetStickerAvailable: formObj["assetSticker"]
          ? formObj["assetSticker"]
          : "",
        hologramSticketAvailable: formObj["hologramSticker"]
          ? formObj["hologramSticker"]
          : "",
        idAssetCode: this.ticketData.hapAssetCode
          ? this.ticketData.hapAssetCode
          : "",
        idTicketCode: this.ticketCode,
        statusFlag: "A",
      };
      let assetTravelObj: any = {
        //     billContentType: this.fileType ? this.fileType : '',
        downFare: formObj["downFare"] ? parseInt(formObj["downFare"]) : 0,
        fromLocation: formObj["fromPlace"] ? formObj["fromPlace"] : "",
        hmbCode: this.ticketData.hapHmbCode ? this.ticketData.hapHmbCode : "",
        idAssetCode: this.ticketData.hapAssetCode
          ? this.ticketData.hapAssetCode
          : "",
        idGNo: "",
        idTicketCode: this.ticketCode,
        inchargeCode: this.ticketData.inchargeCode
          ? this.ticketData.inchargeCode
          : "",
        mobileNumber: formObj["mobileNo"] ? formObj["mobileNo"] : "",
        modeOfTransport: formObj["transportMode"]
          ? formObj["transportMode"]
          : "",
        personName: formObj["personName"] ? formObj["personName"] : "",
        statusFlag: "A",
        //  ticketBillAttcahment: this.baseFile,
        toLocation: formObj["toPlace"] ? formObj["toPlace"] : "",
        totalFare: formObj["totalFare"] ? parseInt(formObj["totalFare"]) : 0,
        travelledDistance: formObj["traveledDistance"]
          ? formObj["traveledDistance"]
          : 0,
        upFare: formObj["upFare"] ? parseInt(formObj["upFare"]) : 0,
      };
      let masterObj: any = {
          hapRemarks:"",
          hapGeoAddress: "",
        cancelRemarks: "",
        hapHubCode: "",
        hapHubRemarks: "",
        hapLat: this.position ? this.position.lat : "",
        hapLong: this.position ? this.position.lng : "",
        ticketCode: this.ticketCode,
        ticketStatusCode: 4, // HUB,
        ticketSubStatusCode: 16, //Job Card Pending,
      };

      const formData = new FormData();

      formData.append("assetIntake", JSON.stringify(assetIntakeObj));
      formData.append("assetTravel", JSON.stringify(assetTravelObj));
      formData.append("master", JSON.stringify(masterObj));

      if (formObj["files"]?.length > 0) {
        for (var i = 0; i < formObj["files"].length; i++) {
          formData.append("travelImage", formObj["files"][i]);
        }
      } else {
        formData.append("travelImage", "");
      }
        console.log("formData", formData);
        try {
        this.isSubmitted = true;
        let response = await this.ticketService.assetTravelExpense(formData);

        if (response?.body?.success) {
          this.isSubmitted = false;

          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );

          await this.spinner.hide();
          this.dialogRef.close({ action: "assetIntake" });
        } else {
          this.isSubmitted = false;
          /** spinner hide */
          this.spinner.hide();
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
        }
      } catch (e) {
        this.isSubmitted = false;
        await this.spinner.hide();
        this.dialogRef.close({ action: "close" });
      }
    } else {
      this.sharedService.showNotification(
        "snackbar-danger",
        "This app needs the Location service, please turn on the Location",
        "bottom",
        "center"
      );
    }
  }
  //Transport Get List
  async transportDropdown() {
    const response = await this.ticketService.getHmbModeOfTransportMaster();
    this.transportList = response.data || [];
  }
  // Asset Intake Data

  async ticketAssetIntake() {
    let ticketCode = this.ticketCode;
    let resObj = { ticketCode };
    const response = await this.ticketService.getHapTicketAssetInTake(resObj);
    console.log("response asset intake", response.data);
    let assetIntakeData = response.data || [];
    if (assetIntakeData.length > 0) {
      this.actionBtnLabel = "Update";
    } else {
      this.actionBtnLabel = "Save";
    }
    this.consumableAssetIntakeForm.patchValue({
      assetStatus:
        assetIntakeData.length > 0 ? assetIntakeData[0].assetStatus : "",
      hologramSticker:
        assetIntakeData.length > 0
          ? assetIntakeData[0].hologramSticketAvailable
          : [],
      assetSticker:
        assetIntakeData.length > 0
          ? assetIntakeData[0].assetStickerAvailable
          : [],
    });
  }

  // ticket Travels Data

  async ticketTravelAllowance() {
    let ticketCode = this.ticketCode;
    let resObj = { ticketCode };
    const response = await this.ticketService.getHapTicketAssetTravelExpense(
      resObj
    );
    console.log("response travel expense", response.body.data);
    let travelExpenseData = response.body.data || [];
    this.billImage =
      travelExpenseData.length > 0
        ? travelExpenseData[0].ticketBillAttcahment
        : "";
    this.consumableAssetIntakeForm.patchValue({
      //     fromPlace:
      //       travelExpenseData.length > 0 ? travelExpenseData[0].fromLocation : '',
      //    toPlace:
      //       travelExpenseData.length > 0 ? travelExpenseData[0].toLocation : '',
      transportMode:
        travelExpenseData.length > 0
          ? travelExpenseData[0].modeOfTransport
          : "",
      traveledDistance:
        travelExpenseData.length > 0
          ? travelExpenseData[0].travelledDistance
          : "",
      upFare: travelExpenseData.length > 0 ? travelExpenseData[0].upFare : 0,
      downFare:
        travelExpenseData.length > 0 ? travelExpenseData[0].downFare : 0,
      totalFare:
        travelExpenseData.length > 0 ? travelExpenseData[0].totalFare : 0,
      personName:
        travelExpenseData.length > 0 ? travelExpenseData[0].personName : "",
      mobileNo:
        travelExpenseData.length > 0 ? travelExpenseData[0].mobileNumber : "",
    });
  }

  onFileSelectProject(e: any): void {
    //console.log(e.target.files.type);
    /*   const input = e.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.listOfFiles = Array.from(input.files).filter((file) =>
        file.type.startsWith("image/")
      );
    } else {
      this.listOfFiles = [];
    } */
    try {
      const file = e.target.files;
      this.checkMultipleFile(file);

      // this.filename = file.name;
      // this.fileType = file.type;

      // this.attachmentFormat =
      //   this.fileType.indexOf('video') > -1 ? 'video' : this.fileType;

      // if (this.attachmentFormat == 'video') {
      //   console.log('video format');
      //   this.attachmentError = true;
      //   this.filename = null;
      //   this.fileType = null;
      //   this.base64File = null;
      //   this.fileValidation = false;
      // } else {
      //   this.attachmentError = false;
      //   if (file.size > 5e6) {
      //     console.log('File is too large. Over 10MB');
      //     this.filename = null;
      //     this.fileType = null;
      //     this.base64File = null;
      //     this.fileValidation = true;
      //   } else {
      //     console.log('File is valid');
      //     this.assetIntakeForm.get('file').setValue(file);
      //     this.fileValidation = false;
      //     this.getBase64FullName(file).then((data) => {
      //       this.baseFile = data;
      //     });
      //     // console.log('base64File', this.baseFile);
      //   }
      // }
    } catch (error) {
      this.consumableAssetIntakeForm.get("files").setValue("");
      console.log("no file was selected...");
    }
  }

  getTotalFileSize(fileList) {
    let sum = 0;

    fileList.forEach((element) => {
      sum += element.size;
    });

    console.log("totalSize", sum);

    this.fileValidation =
      fileList?.length > 0 ? (sum > 5e6 ? true : false) : false;
  }

  checkMultipleFile(file) {
    if (file.length < 6) {
      this.isLoading = true;
      for (var i = 0; i <= file.length - 1; i++) {
        var selectedFile = file[i];
        if (selectedFile.type.startsWith("image/")) {
          this.listOfFiles.push(selectedFile);
        } else {
          return;
        }
      }
    }
    //this.assetIntakeForm.get("files").setValue(this.listOfFiles);
    this.getTotalFileSize(this.listOfFiles);
    this.isLoading = false;

    // const selFiles = [];
    // for (var i = 0; i < file.length; i++) {
    //   let fileType =
    //     file[i].type.indexOf("image") > -1 ? "image" : file[i].type;
    //   if (file[i].name && fileType == "image") {
    //     selFiles.push(file[i]);
    //     this.assetIntakeForm.get("file").setValue(selFiles);
    //     return true;
    //   }
    // }

    return false;
  }
  getBase64FullName(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, "position");
    } catch (e) {
      console.log("e", e);
      this.position = null;
    }
  }
  removeSelectedFile(index) {
    this.listOfFiles.splice(index, 1);
    this.getTotalFileSize(this.listOfFiles);
    //  this.assetIntakeForm.get("file").setValue(this.listOfFiles);
  }
  removeAllFile() {
    this.listOfFiles = [];
  }
}
