import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
// import marked from 'marked';

@Pipe({
  name: 'textFilter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    return items.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
      });
    });
   }
}
@Pipe({
  name: "numberFilter",
  pure: false,
})
export class NumberFilterPipe implements PipeTransform {
  transform(items: any[], term: string): any {
    // I am unsure what id is here. did you mean title?
    return items.filter((item) => item.indexOf(term) !== -1);
  }
}

/* Ticket list filter pipe
based on
irtFlag,
myListFlag,
myTeamFlag,
myListFlag
boolean values
 */
@Pipe({
  name: 'ticketFilter',
  pure: false
})
export class TicketFilterPipe implements PipeTransform {
  transform(items: any[], searchTicket: any): any[] {
    // debugger;
    if (!items) { return []; }
    if (!searchTicket) { return items; }
    let ticketList = [];
    ticketList = items.filter(item => {
      return Object.keys(searchTicket).some(key => {
        return item[key] == searchTicket[key];
      });
    });
    // console.log('ticketList>>>>>>', ticketList);
    return ticketList;
   }
}

/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/

@Pipe({
  name: 'sortBy',
  pure: false
})
export class SortByPipe implements PipeTransform {

  transform(value: any[], order = '', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
      if (order === 'asc'){return value.sort(); }
      else if (order === 'desc' && column === 'dat')
      {return value.sort((a, b) => {
        return  new Date(b.dat).getTime() as any -  new Date(a.dat).getTime() as any ; });
      }else {return value.sort().reverse(); }
    } // sort 1d array
    return orderBy(value, [column], [order]);
  }
}


@Pipe({
  name: 'striptTag',
  pure: false
})
export class StriptTagPipe implements PipeTransform {
  step;
  transform(value: string): string {
    this.step = value.replace(/^BODY:/, '');
    this.step = this.step.replace(/^FOOTER/, '');
    /*this.step = this.step.replace(/<[^>]+>/gm, '');
    this.step = this.step.replace(/<[^p](?:.|\n)*?>/gm, '');
    this.step = this.step.replace(/\sstyle=\"(.*?)\"/gm, '');*/
    this.step = this.step.replace(/\n/gm , '\n');
    this.step = this.step.replace(/\r?\n|\r/g, '\n');
    this.step = this.step.replace(/[\n\r]/g, '\n');
    this.step = this.step.replace(/&nbsp;/gm, '');
    return this.step.trim();
  }
}

@Pipe({
  name: 'safeHtml',
  pure: false
})
export class SafePipe implements PipeTransform{
  constructor(private sanitizer: DomSanitizer ){}
  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

@Pipe({
  name: 'markdown',
  pure: false
})

/* export class MarkdownPipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    if (value && value.length > 0) {
      return marked(value);
    }
    return value;
  }

} */

@Pipe({
  name: 'trimName',
  pure: false
})
export class TrimNamePipe implements PipeTransform {
  fName;
  transform(value: string): string {
    this.fName = value.length > 15 ? value.substr(0,15) + '...' : value;
    return this.fName;
  }
}

@Pipe({
  name: 'linkify',
  pure: false
})
export class LinkifyPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    return this._domSanitizer.bypassSecurityTrustHtml(this.stylize(value));
  }

  private stylize(text: string): string {
    let stylizedText: string = '';
    if (text && text.length > 0) {
      for (let t of text.split(' ')) {
        if ((t.startsWith('http') || t.startsWith('https')) && t.length > 1){
          stylizedText += `<a href="${t.substring(0)}" target="_blank" style="word-break: break-all;">${t}</a> `;
        }else{
          stylizedText += t + ' ';
        }
      }
      return stylizedText;
    }
    else{
      return text;
    }
  }

}


