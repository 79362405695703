import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "../../app/core/service/api.service";
import { TransportType } from "./transport-type.model";

@Injectable({
  providedIn: "root",
})
export class TransportTypeService {
  isTblLoading = true;
  dataChange: BehaviorSubject<TransportType[]> = new BehaviorSubject<
    TransportType[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {}
  get data(): TransportType[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  async getTransportType() {
    const transportTypeList$ = this.apiService.get(
      "/api/hap/master/getHapTransportTypeMaster"
    );
    let response = await lastValueFrom(transportTypeList$);
    return response;
  }
  async getAssetTravelExpenseAttachment(ticketCode, assetCode) {
    const transportAttachList$ = this.apiService.get(
      "/api/nerve/ticket/getAssetTravelExpenseAttachment",
      { assetCode, ticketCode },
      true
    );
    let response = await lastValueFrom(transportAttachList$);
    return response;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }
  async addTransportType(transportData) {
    this.dialogData = transportData;
    console.log("this.dialogData", this.dialogData);
    const addRes$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateTransport",
      this.dialogData
    );
    let response = await lastValueFrom(addRes$);
    return response;
  }
}
