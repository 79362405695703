// response.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResponseService {
    public errorStatusCode: any;
    public errorResponse: any = [];
    private responseSubject = new Subject<boolean | 'cancel'>();

}
