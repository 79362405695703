import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";
import { filter, pairwise } from "rxjs/operators";
import { ApiService } from "./api.service";
// import {NotifierService} from 'angular-notifier';

@Injectable({
  providedIn: "root",
})
export class SharedService {
  account = null;
  productId = null;
  products = null;
  parentUrl = "";

  public notifyMessage: any;
  public notifyCount: any;

  public sharedValue: any; // This is the property to store the value you want to share.
  public loaderFlag: any;

  private showColSubject = new Subject<any>();
  private msgSubject = new Subject<any>();
  private filterSubject = new Subject<any>();
  //  private flagSubject = new  BehaviorSubject<any>();
  private flagSubject = new BehaviorSubject<boolean>(false);
  currentMessage = this.flagSubject.asObservable();

  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = new BehaviorSubject<any>(null);
  private ticketAttachSubject = new Subject<any>();
  // private readonly notifier: NotifierService;
  public webSocket = false;
  private history = [];
  //private multiDataReport = new Subject<any>();
  private multiDataRP = new BehaviorSubject<any>(null);

  getMultiDataRP = this.multiDataRP.asObservable();
  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private _location: Location,
    public apiService: ApiService
  ) {
    //  console.log(this.currentMessage,'current');
    //private notifierService: NotifierService
    // this.notifier = notifierService;
  }
  setColumn(column) {
    this.showColSubject.next(column);
  }
  getColumn(): Observable<any> {
    return this.showColSubject.asObservable();
  }
  setSearch(val) {
    this.filterSubject.next(val);
  }
  getSearch(): Observable<any> {
    return this.filterSubject.asObservable();
  }
  setFlag(val) {
    this.flagSubject.next(val);
    //   this.currentMessage = this.flagSubject.asObservable();
  }

  setTicketAttach(arr) {
    this.ticketAttachSubject.next(arr);
  }
  getTicketAttach(): Observable<any> {
    return this.ticketAttachSubject.asObservable();
  }
  //  Duplicate array obj remove function
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      //  store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      //  eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }
  //  Remove obj in array (deselected dropdown value remove)

  removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return {
      hours: this.padToTwoDigits(hours),
      minutes: this.padToTwoDigits(minutes),
    };
  }
  convertDurationToMinutes(duration) {
    const [hours, minutes] = duration.split(":");
    return Number(hours) * 60 + Number(minutes);
  }
  convertDurationToHours(duration) {
    const [hours, minutes] = duration.split(":");
    // return Number(hours);
    return Number(hours) * 60 + Number(minutes);
  }
  convertMinutes(duration) {
    let minutes = duration.split(":")[1];
    return Number(minutes);
  }

  // Notification Function
  /*  showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  } */
  // Notification to chat page
  /*  sendChatPage(contact) {
    this.msgSubject.next(contact);
  } */

  // Notification Function

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 5000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  } // Notification Function

  showNotificationLock(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 3000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  showNotificationWithCloseBtn(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "Close", {
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  // get first one letter function
  getFLtr(fullName) {
    let fmtName;
    //const fName = fullName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    /*  fmtName = fullName.split(' ').map(name => name[0]).join('').toUpperCase();
    if (fmtName.length > 3) { return fmtName.substr(0, 1); } else { return fmtName; } */
    return "M";
  }

  /* Back Option  */
  back(): void {
    //this.router.navigate([this.parentUrl]);
    const sesParantUrl = sessionStorage.getItem("parentUrl");
    if (sesParantUrl) {
      this.router.navigate([sesParantUrl]);
    } else {
      this.router.navigate([this.parentUrl]);
    }
  }
  routerUrl() {
    //console.log(this.router.url);
    return this.router.url;
  }
  /* Convert Float to Time (Hours and Minutes) */
  convertNumToTime(number) {
    // Check sign of given number
    let sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    let hour = Math.floor(number);
    let decpart = number - hour;

    let min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    let minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    // Add Sign in final result
    let signFinal = sign == 1 ? "" : "-";

    // Concate hours and minutes
    let time = signFinal + hour + ":" + minute;

    return time;
  }
  // Group List of objects by specified key
  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }
  // List values sum method
  getSum(total, num) {
    let totalVal = Number(total);
    let numVal = Number(num);
    return totalVal + Math.round(numVal);
  }

  padToTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  /*Find min/max date function*/
  minmax_date(all_dates, type) {
    let min_dt = all_dates[0],
      min_dtObj = new Date(all_dates[0]);
    all_dates.forEach(function (dt, index) {
      if (type == "min") {
        if (new Date(dt) < min_dtObj) {
          min_dt = dt;
          min_dtObj = new Date(dt);
        }
      } else if (type == "max") {
        if (new Date(dt) > min_dtObj) {
          min_dt = dt;
          min_dtObj = new Date(dt);
        }
      }
    });
    return min_dt;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }
  currentUserData() {
    return JSON.parse(sessionStorage.getItem("currentUser"));
  }
  // Multi report
  enableSubHeader(data) {
    //this.currentMessage.next(data)
  }
  sendDataRP(data) {
    return this.multiDataRP.next(data);
    // console.log("service", data);
    //  this.multiDataRP = data;
  }
  // getDataRP() {
  //   // console.log(
  //   //   "this.multiDataReport.asObservable();",
  //   //   this.multiDataReport.asObservable()
  //   // );
  //   return this.multiDataRP.asObservable();
  //   // return this.multiDataRP;
  // }
  /* Get page name  */
  get getPageName() {
    //debugger;
    const pageName = sessionStorage.getItem("tileName");
    return pageName;
  }
  async deleteMaster(obj, apiName) {
    const deleteMaster$ = this.apiService.post(
      `/api/hap/master/${apiName}`,
      obj
    );
    let response = await lastValueFrom(deleteMaster$);
    return response;
  }

  // Get geolocation
  getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              console.log(
                "Latitude: " +
                  position.coords.latitude +
                  "Longitude: " +
                  position.coords.longitude
              );
              let lat = position.coords.latitude;
              let lng = position.coords.longitude;

              const location = {
                lat,
                lng,
              };
              resolve(location);
            }
          },
          (error) => {
            reject(error);
            console.log(error);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  }
}
