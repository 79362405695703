import { AuthService } from "../service/auth.service";
import { Injectable } from "@angular/core";

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen } from "rxjs/operators";
import { SharedService } from "../service/shared.service";
import { ResponseService } from "../../service/response.service";
import { Page404Component } from "../../authentication/page404/page404.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  checkError: boolean;

  constructor(
    private authenticationService: AuthService,
    public sharedService: SharedService,
    public responseService: ResponseService,
    public dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log("err", err);
        let errorMessage = "";
        if (err.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${err.error.message}`;
          this.checkError = false;
        } else {
          // server-side error
          errorMessage = `Error Status: ${err.status}\nMessage: ${err.message}`;
          this.checkError = true;
        }
        console.log(errorMessage);

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.sharedService.showNotification(
            "snackbar-danger",
            "Session Expired. Please Login Again",
            "bottom",
            "center"
          );
          // this.authenticationService.logout();
          //  location.reload();
        }

        if (err.status === 0) {
          // auto logout if 401 response returned from api
          this.sharedService.showNotification(
            "snackbar-danger",
            "Something went wrong.Please try again later",
            "bottom",
            "center"
          );
        }
        if (err.status === 504) {
          // auto logout if 401 response returned from api
          this.sharedService.showNotification(
            "snackbar-danger",
            "Something went wrong.Please try again later",
            "bottom",
            "center"
          );
        }
        if (err.status === 404) {
          const dialogRef = this.dialog.open(Page404Component, {
            width: "500px",
            height: "auto",
            disableClose: false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            console.log("result view", result.action);
          });
        }
        if (err.status === 409) {
          console.log("error message", err);
          this.responseService.errorStatusCode = err.status;
          this.responseService.errorResponse = err.error.data;
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
