import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";
import { MatTableDataSource } from "@angular/material/table";
import { CreatedbyDialogComponent } from "../createdby-dialog/createdby-dialog.component";
@Component({
  selector: "app-timesheetview",
  templateUrl: "./timesheetview.component.html",
  styleUrls: ["./timesheetview.component.sass"],
})
export class TimeSheetviewComponent implements OnInit, AfterViewInit {
  public afterInit: boolean = false;
  isLoading: boolean = true;
  historyList: boolean = false;
  leavePermissionList: boolean = false;
  attendanceList: boolean = false;
  bookedHistory: any = [];
  timesheet: any = [];
  bookedReport: any = [];
  leaveHistory: any = [];
  attendanceData: any = [];
  attendanceLoading: any = [];
  attendanceHeader: any = {};
  searchCriteria: any;
  bookedDate: any;
  key: any;
    currentUser: any;
   hrsBased :boolean = true;
  clockBased :boolean = true;
    timeBookMode: any;
  leavekey: any;
  idUserCode: any = [];
  userName: any;
  checkInTime: any = "--:--";
  checkOutTime: any = "--:--";
  hrsMins: any;
  status: any = "";
  defaultImg = "assets/images/user/usrbig1.jpg";
  constructor(
    public dialogRef: MatDialogRef<TimeSheetviewComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    private ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  dataSource = new MatTableDataSource(this.bookedReport);

  public ngOnInit(): void {
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      this.timeBookMode = this.currentUser.data[0].timeBookingMode;
      if (this.timeBookMode == "HOURS_BASED") {
          this.clockBased = false;
      }else{
          this.clockBased = true;
      }
    this.isLoading = false;
    this.bookedHistory = this.data.ticket.bookedHistory;
    this.timesheet = this.data.ticket;
    this.key = this.data.key;

    this.bookedDate = this.timesheet.bookedDate;
    this.idUserCode = this.timesheet.idUserCode;
    console.log("thjlkjlk", this.key);
    this.userName = this.timesheet.userName;
    if (this.key == "history") {
      this.getBookedData();
      this.historyList = true;
      this.leavePermissionList = false;
      this.attendanceList = false;
    } else if (this.key == "leave") {
      this.getLeavePermissionData();
      this.historyList = false;
      this.leavePermissionList = true;
      this.attendanceList = false;
    } else if (this.key == "attendance") {
      this.getAttendanceData();
      this.historyList = false;
      this.leavePermissionList = false;
      this.attendanceList = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  tConvert(time) {
    var H = +time.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    time = h + time.substr(2, 3) + ampm;
    return time;
  }

  ngAfterViewInit() {}

  /**Booked list**/
  async getBookedData() {
    this.isLoading = true;
    var getData = {
      idBookedDate: this.bookedDate,
      idUserCode: this.idUserCode,
      pageSize: "0",
    };
    console.log("report whole data", getData);
    const response = await this.ticketService.getbookedHrsViewService(getData);
    console.log(response, "response");
    console.log("response?.body?", response?.body);
    if (response?.body?.success) {
      this.bookedReport = response?.body?.transactions;
      console.log("reppooouoi", this.bookedReport);
      this.dataSource.data = this.bookedReport;
      this.isLoading = false;
      // this.dataSource.sort = this.sort;
    }
  }
  /**Leave/Permission list**/
  async getLeavePermissionData() {
    this.isLoading = true;
    var getleavePermission = {
      appliedDate: this.bookedDate,
      historyType: "leave",
      userCodes: [this.idUserCode],
    };

    const response = await this.ticketService.getLeavePermissionList(
      getleavePermission
    );
    console.log(response, "response");
    console.log("response?.body?", response?.body);
    if (response?.body?.success) {
      //   this.totalLength = response?.body?.totalCount;
      this.leaveHistory = response?.body?.transactions;
      console.log("reppooouoi", this.leaveHistory);
      this.dataSource.data = this.leaveHistory;
      this.isLoading = false;
      // this.dataSource.sort = this.sort;
      //   this.isSubmitted = false;
    }
  }
  /**Attendance list**/
  async getAttendanceData() {
    this.isLoading = true;
    var getleavePermission = {
      appliedDate: this.bookedDate,
      historyType: "attendance",
      userCodes: [this.idUserCode],
    };
    console.log("report whole data", getleavePermission);
    const response = await this.ticketService.getLeavePermissionList(
      getleavePermission
    );
    if (response?.body?.success) {
      //   this.totalLength = response?.body?.totalCount;
      this.attendanceLoading = response?.body?.transactions;
      this.attendanceData =
        response?.body?.transactions[0]?.checkInOuthistory || [];
      this.attendanceHeader = { ...response?.body?.transactions[0] };
      console.log("this.attendanceHeader", this.attendanceHeader);
      if (this.attendanceHeader.checkInTime) {
        this.checkInTime = this.tConvert(this.attendanceHeader.checkInTime);
      }
      if (this.attendanceHeader.checkOutTime) {
        this.checkOutTime = this.tConvert(this.attendanceHeader.checkOutTime);
      }
      this.status = this.attendanceHeader.status;
      this.hrsMins = this.attendanceHeader.hrsMins;
      this.dataSource.data = this.attendanceData;
      // this.dataSource.sort = this.sort;
      this.isLoading = false;

      //   this.isSubmitted = false;
    }
  }
  //in and  out image show dialog
  inImage(value): void {
    console.log(value, "inImages");
    const dialogRef = this.dialog.open(CreatedbyDialogComponent, {
      width: "350px",
      height: "400px",
      disableClose: true,
      data: {
        image: value,
        action: "Attendance",
      },
    });
  }
  outImage(value): void {
    const dialogRef = this.dialog.open(CreatedbyDialogComponent, {
      width: "350px",
      height: "400px",
      disableClose: true,
      data: {
        image: value,
        action: "Attendance",
      },
    });
  }
}
