import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/core/service/shared.service';
import { FieldMsg } from 'src/app/core/service/field-msg.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { PartRequestService } from "../../../part-request/part-request.service";
import { TicketNewService } from 'src/app/ticket-new/ticket.service';

@Component({
  selector: 'app-verify-device-tag',
  templateUrl: './verify-device-tag.component.html',
  styleUrls: ['./verify-device-tag.component.sass'],
})
export class VerifyDeviceTagComponent implements OnInit {
  dialogTitle: any;
  tagNo: any;
  hapAssetCode: any;
  isPopupVisible: boolean = false;
  scanTitleText: any;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  position: any;
  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  tryHarder = false;
  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;

  constructor(
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<VerifyDeviceTagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg,
    public ticketService: TicketNewService,
  ) {

    this.dialogTitle = `Verify Asset Tag :: ${this.data.ticketData.data.ticketCode}`;
    this.hapAssetCode = this.data.ticketData.data.hapAssetCode;
  }

  ngOnInit(): void { }

  onNoClick() {
    this.dialogRef.close({ action: 'close' });
  }
  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, 'position');
    } catch (e) {
      console.log('e', e);
      this.position = null;
    }
  }
  async saveTag() {
    await this.getCurrentLocation();
    let tracker = {
      hapCompanyCode: this.data.ticketData.data.hapCompanyCode,
      hapDeptCode: this.data.ticketData.data.hapDeptCode,
      hapCustomerCode: this.data.ticketData.data.hapCustomerCode,
      hapHmbCode: this.data.ticketData.data.hapHmbCode,
      hapRetailerCode: this.data.ticketData.data.hapRetailerCode,
      idAssetCode: this.data.ticketData.data.hapAssetCode,
      idGNo: "",
      latitude: this.position ? this.position.lat : '',
      longitude: this.position ? this.position.lng : '',
      moduleCode: this.data.ticketData.data.moduleCode,
      projectCode: this.data.ticketData.data.projectCode
    }
    if (this.tagNo) {
      this.spinner.show();
      if (this.hapAssetCode === this.tagNo) {
        this.sharedService.showNotification(
          'snackbar-success',
          'Asset Tag Matched',
          'bottom',
          'center',
        );
        setTimeout(() => {
          this.dialogRef.close({ action: 'save' });
          this.spinner.hide();
        }, 2000);
      } else {
        try {
          let response = await this.ticketService.saveAssetTracker(tracker);
          console.log("responseeeSwetha", response);
        }
        catch (e) {
          // this.dialogRef.close();
        }
        this.sharedService.showNotification(
          'snackbar-danger',
          'Please Enter Valid Asset Tag No',
          'bottom',
          'center',
        );
        this.spinner.hide();
      }
    } else {
      this.sharedService.showNotification(
        'snackbar-danger',
        'Please Enter Asset Tag No',
        'bottom',
        'center',
      );
    }
  }
  closeScanPopup() {
    this.scanner.enable = false;
    this.isPopupVisible = false;
  }
  assetScan() {
    this.scanTitleText = this.dialogTitle;
    this.scanner.enable = true;
    this.isPopupVisible = true;

  }
  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    console.log('resultString', resultString);
    this.qrResultString = resultString;

    if (this.qrResultString) {
      this.scanner.enable = false;
      this.isPopupVisible = false;
      this.tagNo = this.qrResultString;
    }
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {

    this.hasPermission = has;
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
