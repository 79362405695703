import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "../../app/core/service/api.service";
import { PartsStockComponent } from "./parts-stock.component";
import { PartsStock } from "./parts-stock.model";

@Injectable({
  providedIn: "root",
})
export class PartsStockService {
  isTblLoading = true;
  // Temporarily stores data from dialogs
  dialogData: any;
  dataChange: BehaviorSubject<PartsStock[]> = new BehaviorSubject<PartsStock[]>(
    []
  );
  private destroy$ = new Subject<void>();

  constructor(private httpClient: HttpClient, public apiService: ApiService) {}
  get data(): PartsStock[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  async getMaterialStock() {
    const List$ = this.apiService.get(
      "/api/hap/master/getMaterialStockMaintenances"
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }

  async addMaterialStock(res) {
    this.dialogData = res;
    //console.log("this.dialogData", this.dialogData);
    const addRes$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateMaterialStock",
      this.dialogData
    );
    let response = await lastValueFrom(addRes$);
    return response;
  }
  async getMaterialLog(res) {
    this.dialogData = res;
    //console.log("this.dialogData", this.dialogData);
    const getLog$ = this.apiService.post(
      "/api/hap/master/getHapMaterialStockMaintenanceLog",
      this.dialogData
    );
    let response = await lastValueFrom(getLog$);
    return response;
  }
  async getListDepartment(searchCriteria) {
    let companyCode = searchCriteria.companyCode;

    const departmentList$ = this.apiService.get(
      "/api/admin/master/getDepartmentByCompanyCode",
      { companyCode },
      false
    );
    let response = await lastValueFrom(departmentList$);
    return response;
  }
  async getAssetCategory(searchCriteria) {
    let companyCode = searchCriteria.companyCode;
    let deptCode = searchCriteria.deptCode;
    let assetType = searchCriteria.assetType;

    const departmentList$ = this.apiService.get(
      "/api/hap/master/v1/getHapAssetCategroyByDept",
      { companyCode, deptCode, assetType },
      false
    );
    let response = await lastValueFrom(departmentList$);
    return response;
  }
  async getHubMaster() {
    const roleMasterList$ = this.apiService.get(
      "/api/hap/master/getHapHubMasters"
    );
    let response = await lastValueFrom(roleMasterList$);
    return response;
  }
  async getMSP() {
    const productMasterList$ = this.apiService.get(
      "/api/hap/master/getHapMspMaster"
    );
    let response = await lastValueFrom(productMasterList$);
    return response;
  }
  async getMaterial(searchCriteria) {
    let catId = searchCriteria.catId;
    let companyCode = searchCriteria.companyCode;
    let deptCode = searchCriteria.deptCode;
    let materialFlag = searchCriteria.materialFlag;
    // let catList=[catId];
    const List$ = this.apiService.get(
      "/api/hap/master/getMaterialsByMaterialFlag",
      { catId, companyCode, deptCode, materialFlag },
      false
    );
    let response = await lastValueFrom(List$);
    return response;
  }

  cancelRequests(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
  }
  bulkExcelValidation(formData): Observable<any> {
    console.log("FILEEEEEE", formData);
    const abortController = new AbortController();
    return this.apiService
      .post(
        `/api/hap/master/bulkupload/uploadMaterialStockMaintenance`,
        formData,
        { signal: abortController.signal },
        false
      )
      .pipe(takeUntil(this.destroy$));
  }
  uploadExcel(excelData): Observable<any> {
    const abortController = new AbortController();
    return this.apiService
      .post(
        `/api/hap/master/bulkupload/uploadMaterialStockMaintenanceData`,
        excelData,
        { signal: abortController.signal },
        false
      )
      .pipe(takeUntil(this.destroy$));
  }
  async downloadMaterialExcel(excelData) {
    const addRoleMaster$ = this.apiService.post(
      "/api/hap/master/downloadHapMaterialStockMaintenanceView",
      excelData,
      "",
      false,
      "blob"
    );
    let response = await lastValueFrom(addRoleMaster$);
    return response?.body;
  }
}
