import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
// import { LicensetypeService } from '../change-company-dialog.service';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
// import { LicensetypeModel } from '../change-company-dialog.model';
import { formatDate } from "@angular/common";
import { Router } from "@angular/router";
import { ProjectmasterService } from "src/app/projectmaster/projectmaster.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

@Component({
  selector: "app-ticket-status-priority",
  templateUrl: "./ticket-status-priority-dialog.component.html",
  styleUrls: ["./ticket-status-priority-dialog.component.sass"],
})
export class TicketStatusPriorityDialogComponent {
  action: string;
  dialogTitle: string;
  error: string;
  submitted = false;
    zeroError = false;
    lessError = false;
  statusForm: FormGroup;
  priorityForm: FormGroup;
  userInfo: any[];
  ticketStatusTip: any;
  ticketSubStatusTip: any;
  ticketSLATip: any;
  ticketHolidaySLATip: any;
  ticketAllowRemovalTip: any;
  ticketPriorityNameTip: any;
  ticketIRTTip: any;
  ticketMRTTip: any;
  ticketSLAExtendedTip: any;
  ticketTypeList: any;
  ticketStatusList: any[];
  ticketPriorityList: any[];
  //ticketStatusFieldList: FormArray;
  // projectPriorityType: FormArray;
  initialRow: number = 0;
  initialRowPriority: number = 0;
  selectedTab: string = "Status";
  projectStatusTypeSaved: any[] = [];
  projectPrioritySaved: any[] = [];
  isReady: boolean = false;
  slaRequired: any;
  projectData: any;
  priorityFlag: any;
  attentionName: any;
  help: any;
  extendedHours: any = [];
  extended: boolean = false;
  supportingHoursList: any = [];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TicketStatusPriorityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public projectmasterService: ProjectmasterService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService
  ) {
    // Set the defaults
    this.action = data.action;
    this.ticketTypeList = data.ticket;
    this.priorityFlag = data.priorityFlag;

    if (this.priorityFlag == "Y") {
      this.attentionName = "Priority";
    } else if (this.priorityFlag == "N") {
      this.attentionName = "Severity";
    }
    this.projectData = data.slaRequired;
    this.slaRequired = this.projectData.slaRequired ? true : false;

    console.log(this.slaRequired, "sla", this.projectData);

    // this.projectStatusTypeSaved = this.ticketTypeList.projectStatusType;
    // this.projectPrioritySaved = this.ticketTypeList.projectPriority;
    this.ticketStatusList = data.ticketStatusList;
    this.ticketPriorityList = data.ticketPriorityList;

    console.log("ticketStatusList", this.ticketStatusList);
    console.log("ticketPriorityList", this.ticketPriorityList);
  }

  ngOnInit() {
    this.tooltipCheck();
    let { projectName, ticketTypeName, ttClassificationName } =
      this.data.ticket;
    /* Get method status and priority saved for project */
    this.getProjectPrioritySaved(this.ticketTypeList);
    this.getProjectStatusTypeSaved(this.ticketTypeList);
    this.getSupportingList(this.ticketTypeList.idProjectCode);

    this.submitted = false;
    this.dialogTitle = `Status & ${this.attentionName}  :: ${projectName} :: ${ticketTypeName} :: ${ttClassificationName}`;
  }

  tooltipCheck() {
    this.help = localStorage.getItem("Help");
    console.log("help", this.help);
    if (this.help == "ToolTip ON") {
      console.log("true tooltip");

      this.ticketStatusTip = `By default, Initial Response Pending and Closed statuses are selected for all classifications and will not be allowed to be deselected for all the classification.`;
      this.ticketSubStatusTip = `When the status is active and if the status has more than one sub-status, at least one sub-status should be activated.`;
      this.ticketSLATip = `Switch ON SLA Timer only when the status and substatus combination belongs to the service provider.`;
      this.ticketHolidaySLATip = `Holiday SLA Timer is enabled only when the corresponding SLA TImer is enabled.
     Select the appropriate option from the below list:
     N - Stands for None - None of the holidays will be considered for SLA Timer and the SLA timer will not be passed on holidays.
     S - Stands for Service Provider - Only on the service provider's holiday SLA timer will be passed.
     C - Stands for Customer - Only on the customer's holiday SLA timer will be passed.
     B - Stands for Both - SLA timer will be passed on the holidays of both irrespective of the working day of another party. 
     Note: Holiday calendar has to be maintained for this function to work.`;
      this.ticketAllowRemovalTip = `Only when all the tickets are in the status with Allow Removal on, removal of the corresponding classification from the ticket type for the project is allowed.
     Allow Removal will be switched on by default for closed and cancelled status if the status is activated and the same cannot be deactivated.
     Note: Removal of the classification, will not affect the old tickets and reports for the old tickets.`;
      this.ticketPriorityNameTip = `Select the different Priority/Severity enabled for the Ticket Type and Classification Type combination.
     If a Priority/Severity is not available, kindly create the Priority/Severity in the Priority/Severity master creation menu in the Configuration tab.`;
      this.ticketIRTTip = `IRT - Initial Response Time is mandatory for all the enabled Priority/Severity when the SLA Req field is enabled in the details tab of the project.
     Maintain the IRT for all the enabled Priority/Severity as per the contract signed with the customer.`;
      this.ticketMRTTip = `MRT - Maximum Resolution Time is mandatory for all the enabled Priority/Severity when the SLA Req field is enabled in the details tab of the project.
     Maintain the MRT for all the enabled Priority/Severity as per the contract signed with the customer.`;
      this.ticketSLAExtendedTip = `When the SLA Extended field is enabled, the SLA timer will keep running during the extended hours also.
     There should be at lease one extended hours configured in the project to enable this function.`;
    } else {
      console.log("false tooltip");

      this.ticketStatusTip = undefined;
      this.ticketSubStatusTip = undefined;
      this.ticketSLATip = undefined;
      this.ticketHolidaySLATip = undefined;
      this.ticketAllowRemovalTip = undefined;
      this.ticketPriorityNameTip = undefined;
      this.ticketIRTTip = undefined;
      this.ticketMRTTip = undefined;
      this.ticketSLAExtendedTip = undefined;
    }
  }

  loadStatus() {
    //Status
    this.statusForm = this.fb.group({
      idProjectCode: [this.ticketTypeList.idProjectCode],
      idFromDate: [this.ticketTypeList.idFromDate],
      idTtCode: [this.ticketTypeList.idTtCode],
      idTtClassificationCode: [this.ticketTypeList.idTtClassificationCode],
      ticketStatusFieldList: this.fb.array([]),
    });
    //this.ticketStatusFieldList = this.fb.array([]);
    this.initialRow = this.ticketStatusList.length;
    // this.statusForm.addControl("ticketStatusFieldList", this.ticketStatusFieldList);
    // if (this.ticketStatusFieldList.length < this.initialRow) {
    for (let i = 0; i < this.initialRow; i++) {
      this.onAddTicketStatusFieldList(this.ticketStatusList[i]);
      for (let s = 0; s < this.ticketStatusList[i].SubStatusList.length; s++) {
        this.onAddTicketSubStatusFieldList(
          i,
          this.ticketStatusList[i],
          this.ticketStatusList[i].SubStatusList[s]
        );
      }
      // console.log("----", this.statusForm);
    }
    // }
  }

  loadPriority() {
    //Priority
    this.priorityForm = this.fb.group({
      idProjectCode: [this.ticketTypeList.idProjectCode],
      idFromDate: [this.ticketTypeList.idFromDate],
      idTtCode: [this.ticketTypeList.idTtCode],
      idTtClassificationCode: [this.ticketTypeList.idTtClassificationCode],
      projectPriorityType: this.fb.array([]),
    });
    // this.projectPriorityType = this.fb.array([]);
    this.initialRowPriority = this.ticketPriorityList.length;

    // this.priorityForm.addControl("projectPriorityType", this.projectPriorityType);
    // if (this.projectPriorityType.length < this.initialRowPriority) {
    for (let y = 0; y < this.initialRowPriority; y++) {
      this.onAddRowPriority(this.ticketPriorityList[y]);
      // console.log("----", this.priorityForm);
    }
    // }
  }

  get f() {
    return this.statusForm.controls;
  }

  saveForm(tab) {
    if (tab == "Status") {
      this.onSubmit();
    } else if (tab == "Priority" || tab == "Severity") {
      this.onSubmit2();
    }
  }

  async onSubmit() {
    this.submitted = true;
    this.spinner.show();
    console.log("this.statusForm", this.statusForm.value);
    const statusFormValue = this.statusForm.value;
    let projectStatusType = [];
    for (let statusLst of statusFormValue.ticketStatusFieldList) {
      let proList = statusLst.projectStatusType
        .filter((item) => item.subStatus == true)
        .map((obj) => {
          return {
            holidayTimer: obj.holidayTimer,
            idStatusCode: "" + obj.idStatusCode,
            slaTimer: obj.slaTimer ? "Y" : "N",
            allowRemoval: obj.allowRemoval ? "Y" : "N",
            subStatusCode: "" + obj.subStatusCode,
          };
        });
      projectStatusType = [...projectStatusType, ...proList];
    }
    // console.log("projectStatusType", projectStatusType);
    let statusObj = {
      idProjectCode: statusFormValue.idProjectCode,
      idFromDate: new Date(statusFormValue.idFromDate),
      idTtCode: statusFormValue.idTtCode,
      idTtClassificationCode: statusFormValue.idTtClassificationCode,
      projectStatusType,
    };
    if (projectStatusType.length > 0) {
      try {
        const response = await this.projectmasterService.setTicketSubStatus(
          statusObj
        );
        if (response.body.success) {
          this.spinner.hide();
          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
          this.spinner.hide();
        }
      } catch (e) {
        console.log("Error>>>>>>>>>>>>", e);
        let error = e.error;
        this.sharedService.showNotification(
          "snackbar-danger",
          e,
          "bottom",
          "center"
        );
        this.spinner.hide();
      }
    } else {
      this.sharedService.showNotification(
        "snackbar-danger",
        "Please Select Sub Status..!!",
        "bottom",
        "center"
      );
      this.spinner.hide();
    }
  }

  async onSubmit2() {
    this.submitted = true;
    this.spinner.show();

    const priorityFormValue = this.priorityForm.value;
    let projectPriorityType = [];
    let priList = priorityFormValue.projectPriorityType
      .filter((item) => item.isCheck == true)
      .map((obj) => {
        return {
          idPriorityCode: obj.idPriorityCode,
          slaIrtHrs: obj.irtHour ? obj.irtHour : 0,
            slaIrtMins: obj.irtMinute ? obj.irtMinute : 0,
          slaMrtHrs: obj.mrtHour ? obj.mrtHour : 0,
          slaMrtMins: obj.mrtMinute ? obj.mrtMinute : 0,
          slaTimerExtendedHrs: obj.slaTimerExtendedHrs ? "Y" : "N",
        };
      });
    projectPriorityType = [...projectPriorityType, ...priList];

    console.log("projectPriorityType", projectPriorityType)

      let lessValidation = projectPriorityType.map(item => item.slaIrtHrs * 60 + item.slaIrtMins < item.slaMrtHrs * 60 + item.slaMrtMins );
      console.log("lessslesss", lessValidation)
      let irtHrsValidation = projectPriorityType.some(item => item.slaIrtHrs === 0);
      let irtMinsValidation = projectPriorityType.some(item => item.slaIrtMins === 0);
      let mrtHrsValidation = projectPriorityType.some(item => item.slaMrtHrs === 0);
      let mrtMinsValidation = projectPriorityType.some(item => item.slaMrtMins === 0);
     let irtmrtVal = lessValidation.some(item => item === false);
      console.log("irtmrtVal", irtmrtVal)
      if((irtHrsValidation || irtMinsValidation  === true) && (mrtHrsValidation || mrtMinsValidation) === true){
          console.log("trueeeeeeee");
          this.zeroError =true;
          this.spinner.hide();
      } if(irtmrtVal === true){
          this.lessError =true;
          this.spinner.hide();
      }else {
              this.zeroError = false;
              this.lessError = false;

          let priorityObj = {
              idProjectCode: "" + priorityFormValue.idProjectCode,
              idFromDate: new Date(priorityFormValue.idFromDate),
              idTtCode: priorityFormValue.idTtCode,
              idTtClassificationCode: priorityFormValue.idTtClassificationCode,
              projectPriorityType,
          };
          if (projectPriorityType.length > 0) {
              try {
                  const response = await this.projectmasterService.setTicketPriority(
                      priorityObj
                  );
                  if (response.body.success) {
                      this.spinner.hide();
                      this.sharedService.showNotification(
                          "snackbar-success",
                          response ?.body ?.message,
                          "bottom",
                          "center"
                  )
                      ;
                  } else {
                      this.sharedService.showNotification(
                          "snackbar-danger",
                          response ?.body ?.message,
                          "bottom",
                          "center"
                  )
                      ;
                      this.spinner.hide();
                  }
              } catch (e) {
                  console.log("Error>>>>>>>>>>>>", e);
                  let error = e.error;
                  this.sharedService.showNotification(
                      "snackbar-danger",
                      e,
                      "bottom",
                      "center"
                  );
                  this.spinner.hide();
              }
          } else {
              this.sharedService.showNotification(
                  "snackbar-danger",
                  "Please Select Priority..!!",
                  "bottom",
                  "center"
              );
              this.spinner.hide();
          }
      }
  }
  onNoClick(): void {
    // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   disableClose: true,
    //   data: {
    //     name: 'Cancel'
    //   }
    // });
    // dialogRef.afterClosed().subscribe(res => {
    //   if (res.event === 'Yes') {
    //     this.dialogRef.close({action: 'close'});
    //   }
    // });
    this.dialogRef.close({ action: "close" });
    this.statusForm.reset();
    this.priorityForm.reset();
  }
  public async confirmAdd() {
    /* let response = await this.licensetypeService.addLicenseType(this.licenseTypeForm.getRawValue());
        console.log('response????' , response);
        if(response?.body){
            this.dialogRef.close({action: this.action ,response});
        } */
  }

  ticketStatusFieldList(): FormArray {
    return this.statusForm.get("ticketStatusFieldList") as FormArray;
  }

  onAddTicketStatusFieldList(statusObj) {
    this.ticketStatusFieldList().push(
      this.createTicketStatusFormGroup(statusObj)
    );
  }

  subStatus(statusIndex: number): FormArray {
    // console.log("this.ticketStatusFieldList", this.ticketStatusFieldList);
    return this.ticketStatusFieldList()
      .at(statusIndex)
      .get("projectStatusType") as FormArray;
  }

  onAddTicketSubStatusFieldList(statusIndex, statusObj, subStatusObj) {
    this.subStatus(statusIndex).push(
      this.createTicketSubStatusFormGroup(statusObj, subStatusObj)
    );
  }

  createTicketStatusFormGroup(statusObj): FormGroup {
    let projectStatusSaved = [];
    projectStatusSaved = this.projectStatusTypeSaved.filter(
      (obj) => obj.idStatusCode == statusObj.idStatusCode
    );
    // console.log('projectStatusSaved',projectStatusSaved);
    if (projectStatusSaved.length > 0) {
      return this.fb.group({
        status: [true],
        subStatus: [projectStatusSaved[0].slaTimer ? true : false],
        sla: [projectStatusSaved[0].slaTimer == "Y" ? true : false],
        holidayOffTimer: [null],
        allowRemoval: [
          projectStatusSaved[0].allowRemoval == "Y" ? true : false,
        ],
        projectStatusType: this.fb.array([]),
      });
    } else {
      // console.log('statusObj>>>', statusObj.idStatusCode);
      return this.fb.group({
        status: [
          statusObj.idStatusCode == 1 || statusObj.idStatusCode == 7
            ? true
            : false,
        ],
        // subStatus: [statusObj.idStatusCode == 1 || statusObj.idStatusCode == 7 ? true: false],
        // status: [false],
        // subStatus: [false],
        sla: [null],
        holidayOffTimer: [null],
        allowRemoval: [null],
        projectStatusType: this.fb.array([]),
      });
    }
  }
  createTicketSubStatusFormGroup(statusObj, subStatusObj): FormGroup {
    // console.log('statusObj', statusObj);
    // console.log('subStatusObj', subStatusObj);
    let projectStatusSaved = [];
    // console.log('projectStatusTypeSaved', this.projectStatusTypeSaved);
    projectStatusSaved = this.projectStatusTypeSaved.filter(
      (obj) => obj.idSubStatusCode == subStatusObj.idSubStatusCode
    );
    if (projectStatusSaved.length > 0) {
      return this.fb.group({
        idStatusCode: [statusObj.idStatusCode],
        subStatusCode: [subStatusObj.idSubStatusCode],
        subStatus: [true],
        holidayTimer: [projectStatusSaved[0].holidayTimer],
        slaTimer: [projectStatusSaved[0].slaTimer == "Y" ? true : false],
        allowRemoval: [
          projectStatusSaved[0].allowRemoval == "Y" ? true : false,
        ],
      });
    } else {
      return this.fb.group({
        /* idFromDate: [this.ticketTypeList.idFromDate], */
        idStatusCode: [statusObj.idStatusCode],
        subStatusCode: [subStatusObj.idSubStatusCode],
        subStatus: [
          statusObj.idStatusCode == 1 || statusObj.idStatusCode == 7
            ? true
            : false,
        ],
        //subStatus: [false],
        holidayTimer: ["N"],
        slaTimer: [false],
        allowRemoval: [statusObj.idStatusCode == 7 ? true : false],
      });
    }
  }

  projectPriorityType(): FormArray {
    // console.log('this.projectPriorityType', this.projectPriorityType);
    return this.priorityForm.get("projectPriorityType") as FormArray;
  }
  onAddRowPriority(priority) {
    this.projectPriorityType().push(this.createItemFormGroupPriority(priority));
  }

  createItemFormGroupPriority(priority): FormGroup {
    console.log("priority", priority);
    let projectPrioritySaved = [];
    // console.log(this.projectPrioritySaved , 'this.projectPrioritySaved');
    projectPrioritySaved = this.projectPrioritySaved.filter(
      (obj) => obj.idPriorityCode == priority.idpriorityCode
    );

    if (projectPrioritySaved.length > 0) {
      return this.fb.group({
        /* idFromDate: [this.ticketTypeList.idFromDate], */
        idPriorityCode: [priority.idpriorityCode],
        isCheck: [true],
        irtHour: [projectPrioritySaved[0].slaIrtHrs],
        irtMinute: [projectPrioritySaved[0].slaIrtMins],
        mrtHour: [projectPrioritySaved[0].slaMrtHrs],
        mrtMinute: [projectPrioritySaved[0].slaMrtMins],
        slaTimerExtendedHrs: [
          projectPrioritySaved[0].slaTimerExtendedHrs == "Y" ? true : false,
        ],
      });
    } else {
      return this.fb.group({
        /* idFromDate: [this.ticketTypeList.idFromDate], */
        idPriorityCode: [priority.idpriorityCode],
        isCheck: [false],
        irtHour: [0],
        irtMinute: [0],
        mrtHour: [0],
        mrtMinute: [0],
        slaTimerExtendedHrs: [false],
      });
    }
  }

  checkTab(tab) {
    this.selectedTab = tab.tab?.textLabel;
  }

  checkStatusInitial(index, key, subIndex?) {
    // console.log('Status check', (<FormArray>this.ticketStatusFieldList()).controls[i].value);
    const subStatusList = (<FormArray>this.ticketStatusFieldList()).controls[
      index
    ].value.projectStatusType;
    // console.log("subStatusList", subStatusList);
    // console.log("key", key);
    const status = (<FormArray>this.ticketStatusFieldList()).controls[index]
      .value["status"];
    if (key == "status") {
      if (this.ticketStatusList[index].SubStatusList.length == 1) {
        if (this.ticketStatusList[index].idStatusCode == 9) {
          if (!status) {
            (<FormArray>(
              this.ticketStatusFieldList().at(index).get("projectStatusType")
            )).controls[0].patchValue({
              subStatus: status,
              holidayTimer: "N",
              slaTimer: false,
              allowRemoval: false,
            });
          } else {
            (<FormArray>(
              this.ticketStatusFieldList().at(index).get("projectStatusType")
            )).controls[0].patchValue({
              subStatus: status,
              holidayTimer: "N",
              slaTimer: false,
              allowRemoval: true,
            });
          }
        } else {
          (<FormArray>(
            this.ticketStatusFieldList().at(index).get("projectStatusType")
          )).controls[0].patchValue({
            subStatus: status,
            holidayTimer: "N",
            slaTimer: false,
            allowRemoval: false,
          });
        }
      } else if (!status) {
        for (let i = 0; i < subStatusList.length; i++) {
          (<FormArray>(
            this.ticketStatusFieldList().at(index).get("projectStatusType")
          )).controls[i].patchValue({
            subStatus: status,
            slaTimer: status,
            holidayTimer: "N",
            allowRemoval: status,
          });
        }
      }
    } else if (key == "subStatus") {
      const subStatus = (<FormArray>(
        this.ticketStatusFieldList().at(index).get("projectStatusType")
      )).controls[subIndex].value["subStatus"];
      // console.log("subStatus", subStatus);
      if (!subStatus) {
        (<FormArray>(
          this.ticketStatusFieldList().at(index).get("projectStatusType")
        )).controls[subIndex].patchValue({
          slaTimer: subStatus,
          allowRemoval: subStatus,
          holidayTimer: "N",
        });
      }
    } else if (key == "slaTimer") {
      const slaTimer = (<FormArray>(
        this.ticketStatusFieldList().at(index).get("projectStatusType")
      )).controls[subIndex].value["slaTimer"];
      console.log("slaTimer", slaTimer);
      if (!slaTimer) {
        (<FormArray>(
          this.ticketStatusFieldList().at(index).get("projectStatusType")
        )).controls[subIndex].patchValue({ holidayTimer: "N" });
      }
    }

    // return (<FormArray>this.statusForm.get("ticketStatusFieldList")).controls[i].invalid;
  }
  checkStatus(index, subIndex, key) {
    const status = (<FormArray>this.ticketStatusFieldList()).controls[index]
      .value["status"];

    const subStatus = (<FormArray>(
      this.ticketStatusFieldList().at(index).get("projectStatusType")
    )).controls[subIndex].value["subStatus"];
    const slaTimer = (<FormArray>(
      this.ticketStatusFieldList().at(index).get("projectStatusType")
    )).controls[subIndex].value["slaTimer"];

    //console.log(status + ' - ' + subStatus + ' - ' +slaTimer);
    if (key == "subStatus") {
      if (
        this.ticketStatusList[index].SubStatusList.length == 1 &&
        subIndex == 0
      ) {
        return true;
      } else if (!status) {
        return true;
      }
      return false;
    } else if (key == "slaTimer") {
      // if(this.slaRequired == ""){
      //   return true;
      // }else{

      if (!status || !subStatus) {
        return true;
      }
      return false;
      // }
    } else if (key == "holidayTimer") {
      if (!status || !subStatus || !slaTimer) {
        return true;
      }
      return false;
    } else if (key == "allowRemoval") {
      if (!status || !subStatus) {
        return true;
      }
      return false;
    }
  }

  /* Check Priority initial method */

  checkPriorityInitial(index, key) {
    const isCheck = (<FormArray>this.projectPriorityType()).controls[index]
      .value["isCheck"];
    console.log('isCheck',isCheck)
     
    let pForm = (<FormArray>(
      this.projectPriorityType()
    )).at(index);
    if(isCheck){
      // this.priorityForm.get("irtHour").setValidators([Validators.required]);
      // this.priorityForm.get("irtMinute").setValidators([Validators.required]);
      // this.priorityForm.get("irtHour").updateValueAndValidity();
      // this.priorityForm.get("irtMinute").updateValueAndValidity();
    }
    else{
      // this.priorityForm.get("irtHour").clearValidators();
      // this.priorityForm.get("irtMinute").clearValidators();
      // this.priorityForm.get("irtHour").updateValueAndValidity();
      // this.priorityForm.get("irtMinute").updateValueAndValidity();
    }
    if (key == "isCheck") {
      pForm.patchValue({
          irtHour: 0,
          irtMinute: 0,
          mrtHour: 0,
          mrtMinute: 0,
          slaTimerExtendedHrs: false,
        });
        // irtHour: isCheck ? [{ value: [0, [Validators.required]] }]: 0,
        // irtMinute: isCheck ? [{ value: [0, [Validators.required]] }]: 0,
    }
  }

  async getSupportingList(projectCode) {
    const response = await this.projectmasterService.getSupportingList(
      projectCode
    );

    console.log("response?.body>>", response?.body);

    this.supportingHoursList = response?.body?.data;
    if (this.supportingHoursList.length > 0) {
      let extendedList = this.supportingHoursList.filter(
        (obj) => obj.idSuportingType == "Extended"
      );
      const unique = [
        ...new Set(extendedList.map((item) => item.priorityName)),
      ];
      let x = Array.from(new Set(unique.toString().split(",")));
      console.log("uniqueExtendedList", x);
      this.extendedHours = x;
    } else {
    }
  }
  checkPriority(index, key) {
    //console.log('(<FormArray>this.projectPriorityType()).controls[index]',(<FormArray>this.projectPriorityType()).controls);
    /* console.log('Muthu',(<FormArray>(
      this.projectPriorityType().at(index).get("projectPriorityType")
    )).controls[0]); */
    const isCheck = (<FormArray>this.projectPriorityType()).controls[index]
      .value["isCheck"];
    let Name = this.ticketPriorityList[index].priorityName;
    //   console.log('name',Name);
    if (!isCheck) {
      //  console.log('index',index);

      //  (<FormArray>(
      //   this.projectPriorityType().at(index).get("projectPriorityType")
      // )).controls[0].patchValue({
      //   irtHour: [0],
      //   irtMinute: [0],
      //   mrtHour: [0],
      //   mrtMinute: [0],
      //   slaTimerExtendedHrs: [false]
      //  });

      return true;
    } else {
      return false;
      //   console.log('key',key);
      //   if(key == "slaTimerExtendedHrs"){
      //   console.log('extended Hours',this.extendedHours);
      //   for(var i=0;i<this.ticketPriorityList.length; i++){

      //     if(this.ticketPriorityList[index].priorityName == this.extendedHours[i]){

      //       return false;

      //     }
      //     else{
      //       return true;
      //     }

      //  }
      //   }
      //   else{

      // }
    }
  }
  ticketPriorityDisabled(priorityIndex) {
    let n = this.extendedHours.includes(
      this.ticketPriorityList[priorityIndex].priorityName
    );

    if (n) {
      return true;
    } else {
      return false;
    }
  }
  async getProjectPrioritySaved(ticketDetails) {
    this.isReady = false;
    const response = await this.projectmasterService.getProjectPrioritySaved(
      ticketDetails
    );

    if (response?.body?.success) {
      this.projectPrioritySaved = response?.body?.data;
      this.isReady = true;
    } else {
      this.projectPrioritySaved = [];
      this.isReady = true;
    }
    await this.loadPriority();
  }
  async getProjectStatusTypeSaved(ticketDetails) {
    console.log("ready");
    this.isReady = false;
    this.spinner.show();
    const response = await this.projectmasterService.getProjectStatusTypeSaved(
      ticketDetails
    );

    if (response?.body?.success) {
      this.projectStatusTypeSaved = response?.body?.data;
      console.log("response>>>>>", this.projectStatusTypeSaved);
      this.isReady = true;
    } else {
      this.projectStatusTypeSaved = [];
      this.isReady = true;
    }
    await this.loadStatus();
    await this.spinner.hide();
  }
}
