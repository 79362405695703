import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { ReasonMaster } from "./reason-master.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "../../app/core/service/api.service";

@Injectable({
  providedIn: "root",
})
export class ReasonMasterService {
  isTblLoading = true;
  dataChange: BehaviorSubject<ReasonMaster[]> = new BehaviorSubject<
    ReasonMaster[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    // super();
  }
  get data(): ReasonMaster[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  async getReasonCategory() {
    const List$ = this.apiService.get(
      "/api/hap/master/getHapReasonCategoryMaster"
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getReason(searchCriteria) {
    let categoryId = searchCriteria.categoryId;

    const List$ = this.apiService.get(
      "/api/hap/master/getHapReasonList",
      { categoryId },
      false
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getRoleValidation(tileUrl) {
    const roleValidation$ = this.apiService.get(
      "/api/admin/master/v1/getTilePrivilege?tileUrl=" + tileUrl
    );
    let response = await lastValueFrom(roleValidation$);
    return response;
  }

  async addReason(res) {
    this.dialogData = res;
    //console.log("this.dialogData", this.dialogData);
    const addRes$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateReason",
      this.dialogData
    );
    let response = await lastValueFrom(addRes$);
    return response;
  }
  async getListRoleMaster(searchCriteria) {
    let page = searchCriteria.pageNo;
    let size = 0;
    let sort = "";
    let sortByAscending = false;
    const roleMasterList$ = this.apiService.post(
      "/api/admin/master/getRoleList",
      {},
      { page, size, sort, sortByAscending },
      false
    );
    let response = await lastValueFrom(roleMasterList$);
    return response;
  }
}
