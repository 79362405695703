import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventService } from "src/app/core/service/event.service";
import { SharedService } from "src/app/core/service/shared.service";
import { TicketService } from "../../../ticket/ticket.service";

@Component({
  selector: "app-show-column-dialog",
  templateUrl: "./agent-history-dialog.component.html",
  styleUrls: ["./agent-history-dialog.component.sass"],
})
export class AgentHistoryDialogComponent implements OnInit, AfterViewInit {
  ticketCode: any;
  agentList: any[];
  public columns: any = [];
  public allColumns: any = [];
  public afterInit: boolean = false;
  isLoading: boolean;
  userCode: any;
  userName: any;
  assignedSubGroupCode: any;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AgentHistoryDialogComponent>,
    public eventService: EventService,
    public sharedService: SharedService,
    private ticketService: TicketService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ticketCode = data.agentData.idTicketCode;
    this.userCode = data.agentData.idUserCode;
    this.userName = data.agentData.userName;
    this.assignedSubGroupCode = data.agentData.idAssignedSubGroup;
    this.getAgentHistory(
      this.ticketCode,
      this.userCode,
      this.assignedSubGroupCode
    );
  }

  public ngOnInit(): void {}

  /* Version List */
  async getAgentHistory(ticketCode, userCode, assignedSubGroup) {
    this.isLoading = true;

    let reqObj = { ticketCode, userCode, assignedSubGroup };
    const response = await this.ticketService.getAgentHistory(reqObj);
    this.agentList = response.data;
    this.isLoading = false;
    // this.ticketTypeList = response.ticketTypeList;
    this.sortVersion();
  }
  /* List sorting function */
  sortVersion() {
    this.agentList = this.agentList || [];
    this.agentList.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return +new Date(b.createdOn) - +new Date(a.createdOn);
    });

    console.log("agentList", this.agentList);
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelColumns(cols) {
    this.sharedService.setColumn(cols);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    if (this.afterInit) {
      this.onSelColumns(this.columns);
    }
  }

  isChecked(col) {
    return (
      this.columns.find((c) => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
}
