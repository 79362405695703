import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {SharedService} from 'src/app/core/service/shared.service';

import {TicketNewService} from 'src/app/ticket-new/ticket.service';

import {EkomasterService} from '../../../eko-master/eko-master.service';

export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  selector: 'app-device-replacement-dialog',
  templateUrl: './device-replacement-dialog.component.html',
  styleUrls: ['./device-replacement-dialog.component.css'],
})
export class DeviceReplacementDialogComponent implements OnInit {
  ticketCode: any;
    hapAssetType: any;
    idJobCardNo: any;
  scrapCode: any;
  public Editor = ClassicEditor;
  materialList: any = [];
  dataSource: any = [];
  // dataSourcetable: [] | null;
  base64File: string = null;
  filename: string = null;
  fileType: string = null;
  tabs = ['First', 'Second', 'Third'];
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  stickerData: any;
  assetStickerData: any;
  fileValidation: boolean = false;
    alertMess: boolean = false;
    assetVal: boolean = false;
    saveBtn: boolean = false;
    getHide: boolean = false;
    passetDisable: boolean = false;
  filteredOptions: Observable<string[]>;
    deviceReplacenentForm: FormGroup;
  materialForm: FormGroup;
  isEditFilter = false;
  ticketData: any;
    deptCode: any;
    assetType: any;
  myControl = new FormControl('');
  gNo: string;
  isProxyAllowed: boolean;
    eqpModel: any;
    natureOfComplaintList: any = [];
    deviceReplacementPresentData: any = [];
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
  apicallSub?: Subscription;
  public referenceTicketList: any = [];
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  panelOpenState = false;
  @ViewChild('tagInput', {static: true})
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;
  @ViewChild('priority', {static: false}) priorityField: any;
  @ViewChild('myInput', {static: true}) myInput: ElementRef =
    Object.create(null);
  routerUrl: any;
  assetStatus: any = [];
    equipNumberList: any = [];

  defaultImg = 'assets/images/user/usrbig1.jpg';

    speciesName: any;
  position: any;

  isLoading = false;
    replacedAsset:any=[];


  constructor(
    public dialogRef: MatDialogRef<DeviceReplacementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
  ) {
    this.routerUrl = this.sharedService.routerUrl();
      this.ticketData = data;
      this.ticketCode = this.ticketData.ticketCode;
      this.hapAssetType = this.ticketData.hapAssetType;
      this.scrapCode = this.ticketData.scrapCode;
      this.dialogTitle = `Device Replacement :: Ticket - ${this.ticketCode} `;
      this.deviceReplacenentForm = this.createIntakeForm();
  }
  ngOnDestroy(): void {}

  ngOnInit() {
   this.deviceReplacement();
   this.jobCardDetails();
  }


  createIntakeForm(): FormGroup {
    return this.fb.group({
        equipmentName :[ {
            value: '',
            disabled: true,
        }],
        equipmentModel :[ {
            value: '',
            disabled: true,
        }],
        assetNo :[ {
            value: '',
            disabled: true,
        }],
        equipmentSerialNo :[ {
            value: '',
            disabled: true,
        }],
        pequipmentName :[{
            value: '',
            disabled: true,
        }],
        pequipmentModel :[{
            value: '',
            disabled: true,
        }],

        passetNo :[''],
        pequipmentSerialNo :[{
            value: '',
            disabled: true,
        }],

    });
  }
    async jobCardDetails() {
        let ticketCode = this.ticketCode;
        let resObj = {ticketCode};
        const response = await this.ticketService.getJobCardData(resObj);
        let jobCardDetails = response ?.body ?.data[0].jobCards || [];
        this.idJobCardNo = jobCardDetails[0].idJobCardNo;
    }
deviceReplacement(){
    this.isLoading = true;
    this.deviceReplacenentForm.patchValue({
        equipmentName: this.ticketData.equipmentName,
        equipmentModel: this.ticketData.equipmentModel,
        assetNo: this.ticketData.hapAssetCode,
        equipmentSerialNo: this.ticketData.equipmentSerialNo,
        pequipmentName:this.ticketData.rEquipmentName,
        pequipmentModel:this.ticketData.rEquipmentModel,
        passetNo:this.ticketData.rAssetCode,
        pequipmentSerialNo:this.ticketData.rEquipmentSerialNo

    });
    if(this.ticketData.rAssetCode === "" || this.ticketData.rAssetCode === null){
        this.saveBtn = true;
        this.getHide = true;
        this.deviceReplacenentForm.get("passetNo").enable();
    }else{
        this.saveBtn = true;
        this.getHide = false;
        this.deviceReplacenentForm.get("passetNo").disable();
    }
    this.deptCode =this.ticketData.hapDeptCode;
    this.assetType  = this.ticketData.hapAssetType;
    let deviceReplacementPresentData = [
        this.ticketData.equipmentName,
       this.ticketData.equipmentModel,
       this.ticketData.hapAssetCode,
       this.ticketData.equipmentSerialNo,
    ];
    this.deviceReplacementPresentData = deviceReplacementPresentData.every(element => element === null);
    this.isLoading = false;
}

  onNoClick() {
    this.dialogRef.close({action: 'close'});
  }


    getSearchResult() {
    this.isSubmitted = true;
        let assetCode = this.deviceReplacenentForm.value.passetNo;
        let presentAsset =this.ticketData.hapAssetCode;
        if(presentAsset === assetCode){
            this.assetVal = true;
            this.isSubmitted = false;
        }else{
            this.assetVal = false;
            this.pAssetList(assetCode);
        }

    }
    async pAssetList(assetCode){
    let deptCode =this.deptCode
        let assetType =this.hapAssetType;
        let reqObj = { assetCode, deptCode,assetType };
        const response = await this.ticketService.getHapAssetDetailsByAssetCodeAndDept(reqObj);
        this.replacedAsset = response.data[0] || [];
        this.isSubmitted = false;
        if(this.replacedAsset.assetFound === true){
            this.alertMess = false;
            this.saveBtn = false;
            this.deviceReplacenentForm.patchValue({
                pequipmentName: this.replacedAsset.assetCatName,
                pequipmentModel: this.replacedAsset.model,
                pequipmentSerialNo: this.replacedAsset.deviceSerialNo,

            });
        }else{
            this.alertMess = true;
            this.saveBtn = true;
            this.deviceReplacenentForm.patchValue({
                pequipmentName: "",
                pequipmentModel:"",
                pequipmentSerialNo: "",

            });
        }
    }
    async getCurrentLocation() {
        try {
            this.position = await this.sharedService.getCurrentLocation();
            console.log(this.position, 'position');
        } catch (e) {
            console.log('e', e);
            this.position = null;
        }
    }
    async updateDeviceReplacement(){
        await this.getCurrentLocation();
        if (this.position) {
            this.isSubmitted = true;
            let formObj = this.deviceReplacenentForm.getRawValue();
            let assetCode = formObj.passetNo;
            let deptCode = this.deptCode;
            let assetType =this.hapAssetType;
            let reqObj = {assetCode, deptCode,assetType};
            const responseAsset = await this.ticketService.getHapAssetDetailsByAssetCodeAndDept(reqObj);
            this.replacedAsset = responseAsset.data[0] || [];
            if (this.replacedAsset.assetFound === true) {
                let assetScrap = [
                    {
                        approvedRemarks: "", // On Approve Reject Scenario
                        approvedStatus: "",  // On Approve / Reject Scenario
                        assetCode: formObj.assetNo, // Mandatory
                        deptCode: this.deptCode,  // Mandatory
                        rAssetCode: formObj.passetNo, //ON PART REPLACEMENT -> MANDATORY
                        rEquipmentModel: formObj.pequipmentModel,  //ON PART REPLACEMENT -> MANDATORY
                        rEquipmentName: formObj.pequipmentName,  //ON PART REPLACEMENT -> MANDATORY
                        rEquipmentSerialNo: formObj.pequipmentSerialNo,  //ON PART REPLACEMENT -> MANDATORY
                        recommendRemarks: "",  // On Recommend Reject Scenario
                        recommendStatus: "",   //  On Recommend / Reject Scenario
                        scrapId: this.scrapCode,  // Mandatory
                        ticketCode: "",
                        verifiedRemarks: "",  // On Verify Reject Scenario
                        verifiedStatus: ""  // On Verify, Approve / Reject Scenario
                    }
                ];
                let hapAssetType =this.ticketData.hapAssetType;
                let masters;
                let jobCardDetails;
                if((hapAssetType === 'H' && deptCode ==='DP20000002') ||
                    (hapAssetType === 'HAP' && ['DP20000003', 'DP20000004', 'DP20000005'].includes(deptCode))){
                    masters = [
                        {
                            hapRemarks: "" ,
                            hapGeoAddress: "",
                            hapLat: this.position ? this.position.lat : '',
                            hapLong: this.position ? this.position.lng : '',
                            ticketCode: "",
                            ticketStatusCode: 21,
                            ticketSubStatusCode: 33
                        }
                    ];
                     jobCardDetails = [{
                         idJobCardNo: this.idJobCardNo,
                         idTicketCode: this.ticketCode,
                        actionTake :'Device Replaced',
                    }];
                }else{
                    masters = [];
                    jobCardDetails = [];
                }

                let ticketEdit = {
                    assetIntake: [],
                    assetMaterials: [],
                    assetReplacments: [],
                    assetTravel: [],
                    assetScrap: assetScrap,
                    comments: [],
                    fuelClaim: [],
                    jobCard: jobCardDetails,
                    master: masters,
                    users: [],
                    ticketCode: this.ticketCode,
                    preventive: []
                };
                console.log("SAve device Replacement", ticketEdit);

                try {

                    let response = await this.ticketService.assetIntakeTicket(ticketEdit);
                    if (response ?.body ?.success)
                    {
                        this.isSubmitted = false;
                        this.sharedService.showNotification(
                            'snackbar-success',
                            'Device Replacement Saved Successfully',
                            'bottom',
                            'center',
                        );

                        await this.spinner.hide();
                        this.dialogRef.close();
                    }
                else
                    {
                        this.isSubmitted = false;
                        /** spinner hide */
                        this.spinner.hide();
                        this.sharedService.showNotification(
                            'snackbar-danger',
                            response ?.body ?.message,
                            'bottom',
                            'center',);
                    }
                } catch (e) {
                    this.isSubmitted = false;
                    this.dialogRef.close();
                }

            } else {
                this.alertMess = true;
                this.saveBtn = true;
            }
        }else {
            this.sharedService.showNotification(
                'snackbar-danger',
                'This app needs the Location service, please turn on the Location',
                'bottom',
                'center',
            );
        }

    }

}
