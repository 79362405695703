import { TicketNewService } from "src/app/ticket-new/ticket.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FieldMsg } from "src/app/core//service/field-msg.service";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Observable, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";

export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  selector: "app-asset-material",
  templateUrl: "./asset-material.component.html",
  styleUrls: ["./asset-material.component.css"],
})
export class AssetMaterialComponent implements OnInit {
  public Editor = ClassicEditor;
  base64File: string = null;
  filename: string = null;
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  materialList: any = [];
  assetMaterialList: any = [];
  fileValidation: boolean = false;
  filteredOptions: Observable<string[]>;
  materialForm: FormGroup;
  isEditFilter = false;
  ticketData: any;
  searchMaterialCode: any;
  myControl = new FormControl("");
  gNo: string;
  isProxyAllowed: boolean;
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
  checkStatus: boolean = false;
  apicallSub?: Subscription;
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  ticketCode: any;
  position: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  currentDate: any;
  @ViewChild("tagInput", { static: true })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild("priority", { static: false }) priorityField: any;
  @ViewChild("myInput", { static: true }) myInput: ElementRef =
    Object.create(null);
  routerUrl: any;
  currentTime: any;
  statusList: any = [];
  employeeList: any = [];
  uomList: any = [
    {
      uomName: "Ml",
      uomCode: "ml",
    },
    {
      uomName: "Ltr",
      uomCode: "ltr",
    },
  ];
  panelOpenState = false;

  /* auto search */
  minValue: any = 0;
  maxValue: any = 2;
  constructor(
    public dialogRef: MatDialogRef<AssetMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg
  ) {
    this.routerUrl = this.sharedService.routerUrl();
    console.log(this.routerUrl, "currentUrl");
    this.action = data.action;
    this.ticketData = data.assetMaterial.data;
    this.ticketCode = this.ticketData.ticketCode;

    console.log("asset data >>>now ", this.ticketData);

    this.materialForm = this.createMaterialForm();
    this.dialogTitle = `Material :: Ticket - ${this.ticketCode} :: Asset - ${this.ticketData.hapAssetCode}`;
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.ticketAssetMaterial();
    this.getMaterialsByAsset();
    this.getHAPStatus(this.ticketData.projectCode, this.ticketData.moduleCode);
    this.statusValidation();
  }
  //Material Form Validation based on status
  statusValidation() {
    if (
      this.ticketData.ticketStatusCode === 4 &&
      this.ticketData.ticketSubStatusCode === 29
    ) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }
  async getHAPStatus(projectCode, moduleCode) {
    let reqObj = { projectCode, moduleCode };
    const response = await this.ticketService.getHAPStatus(reqObj);
    this.statusList = response.data || [];
  }

  createMaterialForm(): FormGroup {
    return this.fb.group({
      materialCode: [{ value: "", disabled: false }, [Validators.required]],
      materialName: [{ value: "", disabled: true }],
      uom: [""],
      quantity: ["", [Validators.pattern("^[1-9][0-9]*$")]], //[Validators.pattern('^(0|[1-9][0-9]*)$')]
    });
  }
  detailsUser(content) {
    this.modalService.open(content, { size: "xl" });
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
  }

  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }
  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
      console.log(this.position, "position");
    } catch (e) {
      console.log("e", e);
      this.position = null;
    }
  }
  async materialAdd() {
    this.spinner.show();

    console.log("this.materialForm", this.materialForm.getRawValue());

    await this.getCurrentLocation();
    if (this.position) {
      let materialObj = this.materialForm.getRawValue();
      //OLD
      /* let material = [
           {
               idAssetCode: this.ticketData.hapAssetCode
                   ? this.ticketData.hapAssetCode
                   : '',
               idCatId: materialObj['materialCode'].catId
                   ? materialObj['materialCode'].catId
                   : '',
               idCompanyCode: materialObj['materialCode'].companyCode
                   ? materialObj['materialCode'].companyCode
                   : '',
               idDeptCode: materialObj['materialCode'].deptCode
                   ? materialObj['materialCode'].deptCode
                   : '',
               idSubCatId: materialObj['materialCode'].subCatId
                   ? materialObj['materialCode'].subCatId
                   : 0,
               idMaterialCode: materialObj['materialCode'].materialCode
                   ? materialObj['materialCode'].materialCode
                   : 0,
               idTicketCode: '',
               quantity: materialObj['quantity']
                   ? parseInt(materialObj['quantity'])
                   : '',
               statusFlag: 'A',
               uom: materialObj['uom'] ? materialObj['uom'] : '',
               idGNo: '',
           },
       ];*/
      let material = [
        {
          calibrationFlag: materialObj.materialCode.calibrationFlag,
          idAssetCode: this.ticketData.hapAssetCode
            ? this.ticketData.hapAssetCode
            : "",
          idGNo: "",
          idMaterialCode: materialObj["materialCode"].materialCode
            ? materialObj["materialCode"].materialCode
            : 0,
          idTicketCode: "",
          materialDesc: materialObj.materialName
            ? materialObj.materialName
            : "",
          quantity: materialObj.quantity ? materialObj.quantity : "",
          statusFlag: "A",
        },
      ];
      let master = [
        {
          hapRemarks: "",
          hapGeoAddress: "",
          hapLat: this.position ? this.position.lat : "",
          hapLong: this.position ? this.position.lng : "",
          ticketCode: "",
          ticketStatusCode: 4,
          ticketSubStatusCode: 29,
        },
      ];

      let ticketEdit = {
        ticketCode: this.ticketCode,
        assetCompliants: [],
        assetIntake: [],
        //assetMaterials: material, OLD
        assetMaterials: [],
        assetReplacments: material,
        assetScrap: [],
        assetTravel: [],
        comments: [],
        fuelClaim: [],
        jobCard: [],
        master: master, //newly added
        users: [],
        preventive: [],
      };
      try {
        this.isSubmitted = true;
        console.log("WHOLE EDIT DATA", ticketEdit);
        let response = await this.ticketService.assetIntakeTicket(ticketEdit);

        console.log("response", response);
        if (response?.body?.success) {
          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
        }
        this.materialForm.reset();
        this.ticketAssetMaterial();
        this.isSubmitted = false;
        await this.spinner.hide();
      } catch (e) {
        console.log("error>>>", e);
        this.sharedService.showNotification(
          "snackbar-danger",
          e?.error?.message,
          "bottom",
          "center"
        );

        this.isSubmitted = false;
        await this.spinner.hide();
      }
    }
  }
  async getUOMList() {
    const response = await this.ticketService.getUOMList();
    console.log("UOM List", response.data);
    this.uomList = response.data || [];
  }
  compareFn(d1, d2): boolean {
    if (d1.subStatusCode) {
      return d1 && d2 ? d1.subStatusCode === d2.ticketSubStatusCode : d1 === d2;
    }
  }

  async onChangeBasicDetails(key, event) {
    if (key == "status") {
      let ticketStatus;
      if (event.value.ticketCurrentStatus.indexOf("-") > 0) {
        let split = event.value.ticketCurrentStatus.split("-");
        ticketStatus = split[0].trim();
      } else {
        ticketStatus = event.value.ticketCurrentStatus;
      }
    }
  }
  //Material List
  async ticketAssetMaterial() {
    let ticketCode = this.ticketCode;
    let resObj = { ticketCode };
    const response = await this.ticketService.getMaterialList(resObj);
    this.assetMaterialList = response.body.data || [];
  }
  async getMaterialsByAsset() {
    console.log("this.ticketData this.ticketData", this.ticketData.hapDeptCode);
    let assetCode = this.ticketData.hapAssetCode;
    let deptCode = this.ticketData.hapDeptCode;
    let assetType = this.ticketData.hapAssetType;
    let sapCode = this.ticketData.mspCode;
    let resObj = { assetCode, deptCode, assetType, sapCode };
    const response = await this.ticketService.getMaterialsByAsset(resObj);
    this.materialList = response.body.data || [];
  }
  onSelectDropDwn(value) {
    this.materialForm.get("quantity").reset();
    if (value) {
      console.log("....", value);
      this.materialForm.get("materialName").setValue(value.materialName);
      this.minValue = value.minAllowed;
      this.maxValue = value.maxAllowed;
    } else {
      this.materialForm.get("materialName").setValue("");
      this.minValue = 0;
      this.maxValue = 2;
    }
  }

  //Material Delete
  async deleteMaterial(value, row) {
    this.spinner.show();
    await this.getCurrentLocation();
    if (this.position) {
      let materialObj = row.data;

      let material = [
        {
          idAssetCode: this.ticketData.hapAssetCode
            ? this.ticketData.hapAssetCode
            : "",
          idGNo: "",
          idMaterialCode: row.data.idMaterialCode,
          idTicketCode: "",
          materialDesc: materialObj.materialDesc
            ? materialObj.materialDesc
            : "",
          quantity: materialObj.quantity ? materialObj.quantity : "",
          statusFlag: value,
        },
      ];
      let master = [
        {
          hapRemarks: "",
          hapGeoAddress: "",
          hapLat: this.position ? this.position.lat : "",
          hapLong: this.position ? this.position.lng : "",
          ticketCode: "",
          ticketStatusCode: 4,
          ticketSubStatusCode: 29,
        },
      ];

      let ticketEdit = {
        ticketCode: this.ticketCode,
        assetCompliants: [],
        assetIntake: [],
        //assetMaterials: material, OLD
        assetMaterials: [],
        assetReplacments: material,
        assetScrap: [],
        assetTravel: [],
        comments: [],
        fuelClaim: [],
        jobCard: [],
        master: master, //newly added
        users: [],
        preventive: [],
      };
      try {
        this.isSubmitted = true;
        console.log("WHOLE EDIT DATA", ticketEdit);
        let response = await this.ticketService.assetIntakeTicket(ticketEdit);

        console.log("response", response);
        if (response?.body?.success) {
          this.sharedService.showNotification(
            "snackbar-success",
            response?.body?.message,
            "bottom",
            "center"
          );
        } else {
          this.sharedService.showNotification(
            "snackbar-danger",
            response?.body?.message,
            "bottom",
            "center"
          );
        }

        this.ticketAssetMaterial();
        this.isSubmitted = false;
        await this.spinner.hide();
      } catch (e) {
        console.log("error>>>", e);
        this.sharedService.showNotification(
          "snackbar-danger",
          e?.error?.message,
          "bottom",
          "center"
        );

        this.isSubmitted = false;
        await this.spinner.hide();
      }
    }
  }
  checkMax(e) {
    //console.log(e);
    let quantityVal = parseInt(e.value);
    if (quantityVal > this.maxValue || quantityVal < this.minValue) {
      this.materialForm.get("quantity").setValue("");
    }
  }
}
